export const HSRUpdateData = [
  {
    slug: 'acheron',
    build: 3.0,
    review: 3.0
  },
  {
    slug: 'aglaea',
    build: 3.0,
    review: 3.0
  },
  {
    slug: 'argenti',
    build: 3.0,
    review: 3.0
  },
  {
    slug: 'arlan',
    build: 3.0,
    review: 3.0
  },
  {
    slug: 'asta',
    build: 3.0,
    review: 3.0
  },
  {
    slug: 'aventurine',
    build: 3.0,
    review: 3.0
  },
  {
    slug: 'bailu',
    build: 3.0,
    review: 3.0
  },
  {
    slug: 'black-swan',
    build: 2,
    review: 3.0
  },
  {
    slug: 'blade',
    build: 2.7,
    review: 3.0
  },
  {
    slug: 'boothill',
    build: 2.7,
    review: 3.0
  },
  {
    slug: 'bronya',
    build: 3.0,
    review: 3.0
  },
  {
    slug: 'clara',
    build: 3.0,
    review: 3.0
  },
  {
    slug: 'dan-heng',
    build: 3.0,
    review: 3.0
  },
  {
    slug: 'dr-ratio',
    build: 3.0,
    review: 3.0
  },
  {
    slug: 'feixiao',
    build: 3.0,
    review: 3.0
  },
  {
    slug: 'firefly',
    build: 3.0,
    review: 3.0
  },
  {
    slug: 'fu-xuan',
    build: 3.0,
    review: 3.0
  },
  {
    slug: 'gallagher',
    build: 3.0,
    review: 3.0
  },
  {
    slug: 'gepard',
    build: 3.0,
    review: 3.0
  },
  {
    slug: 'guinaifen',
    build: 3.0,
    review: 3.0
  },
  {
    slug: 'hanya',
    build: 3.0,
    review: 3.0
  },
  {
    slug: 'herta',
    build: 2.7,
    review: 3.0
  },
  {
    slug: 'the-herta',
    build: 3.0,
    review: 3.0
  },
  {
    slug: 'himeko',
    build: 2.6,
    review: 3.0
  },
  {
    slug: 'hook',
    build: 3.0,
    review: 3.0
  },
  {
    slug: 'huohuo',
    build: 3.0,
    review: 3.0
  },
  {
    slug: 'imbibitor-lunae',
    build: 3.0,
    review: 3.0
  },
  {
    slug: 'jade',
    build: 3.0,
    review: 3.0
  },
  {
    slug: 'jiaoqiu',
    build: 2.7,
    review: 3.0
  },
  {
    slug: 'jing-yuan',
    build: 2.7,
    review: 2.7
  },
  {
    slug: 'jingliu',
    build: 3.0,
    review: 3.0
  },
  {
    slug: 'kafka',
    build: 2.6,
    review: 2.6
  },
  {
    slug: 'lingsha',
    build: 2.7,
    review: 2.5
  },
  {
    slug: 'luka',
    build: 2,
    review: 2.5
  },
  {
    slug: 'luocha',
    build: 2.6,
    review: 2.6
  },
  {
    slug: 'lynx',
    build: 2.6,
    review: 2.5
  },
  {
    slug: 'march-7th',
    build: 2.6,
    review: 2.6
  },
  {
    slug: 'march-7th-swordmaster',
    build: 2.6,
    review: 2.4
  },
  {
    slug: 'misha',
    build: 2.6,
    review: 2.5
  },
  {
    slug: 'moze',
    build: 2.6,
    review: 2.5
  },
  {
    slug: 'natasha',
    build: 2.6,
    review: 2.6
  },
  {
    slug: 'pela',
    build: 2.6,
    review: 2.6
  },
  {
    slug: 'qingque',
    build: 3.0,
    review: 2.6
  },
  {
    slug: 'rappa',
    build: 2.6,
    review: 2.6
  },
  {
    slug: 'robin',
    build: 2.7,
    review: 2.6
  },
  {
    slug: 'ruan-mei',
    build: 2.6,
    review: 2.6
  },
  {
    slug: 'sampo',
    build: 2.6,
    review: 2.6
  },
  {
    slug: 'seele',
    build: 2.6,
    review: 2.6
  },
  {
    slug: 'serval',
    build: 3.0,
    review: 2.6
  },
  {
    slug: 'silver-wolf',
    build: 2.7,
    review: 2.6
  },
  {
    slug: 'sparkle',
    build: 2.6,
    review: 2.6
  },
  {
    slug: 'sunday',
    build: 2.7,
    review: 2.7
  },
  {
    slug: 'sushang',
    build: 2.6,
    review: 2.6
  },
  {
    slug: 'tingyun',
    build: 2.6,
    review: 2.6
  },
  {
    slug: 'tingyun-fugue',
    build: 2.7,
    review: 2.7
  },
  {
    slug: 'topaz',
    build: 2.6,
    review: 2.5
  },
  {
    slug: 'trailblazer-preservation',
    build: 2.6,
    review: 2.6
  },
  {
    slug: 'trailblazer-harmony',
    build: 2.6,
    review: 2.6
  },
  {
    slug: 'trailblazer-destruction',
    build: 2.6,
    review: 2.6
  },
  {
    slug: 'trailblazer-remembrance',
    build: 3.0,
    review: 3.0
  },
  {
    slug: 'welt',
    build: 2.6,
    review: 2.6
  },
  {
    slug: 'xueyi',
    build: 2.7,
    review: 2.5
  },
  {
    slug: 'yanqing',
    build: 2.6,
    review: 2.6
  },
  {
    slug: 'yunli',
    build: 3.0,
    review: 2.6
  },
  {
    slug: 'yukong',
    build: 2.6,
    review: 2.6
  }
];
