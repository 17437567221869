export const PFCharacterData = [
  {
    char: 'acheron',
    name: 'Acheron',
    current_app_rate: 54.1,
    prev_app_rate: 45.08,
    current_avg_score: 33422,
    prev_avg_score: 33640,
    round_eidolons: 38754,
    app_free: 2.67,
    round_free: 31595,
    rarity: 'Limited 5*'
  },
  {
    char: 'aglaea',
    name: 'Aglaea',
    current_app_rate: 1.93,
    prev_app_rate: 0,
    current_avg_score: 32337,
    prev_avg_score: 0,
    round_eidolons: 37838,
    app_free: 0.12,
    round_free: 30994,
    rarity: 'Limited 5*'
  },
  {
    char: 'argenti',
    name: 'Argenti',
    current_app_rate: 11.81,
    prev_app_rate: 5.8,
    current_avg_score: 33097,
    prev_avg_score: 38218,
    round_eidolons: 35544,
    app_free: 2.59,
    round_free: 31499,
    rarity: 'Limited 5*'
  },
  {
    char: 'arlan',
    name: 'Arlan',
    current_app_rate: 0.01,
    prev_app_rate: 0.03,
    current_avg_score: 0,
    prev_avg_score: 0,
    round_eidolons: 0,
    app_free: 0,
    round_free: 0,
    rarity: '4*'
  },
  {
    char: 'asta',
    name: 'Asta',
    current_app_rate: 0.15,
    prev_app_rate: 0.48,
    current_avg_score: 0,
    prev_avg_score: 31637,
    round_eidolons: 0,
    app_free: 0.04,
    round_free: 0,
    rarity: '4*'
  },
  {
    char: 'aventurine',
    name: 'Aventurine',
    current_app_rate: 53.18,
    prev_app_rate: 53.97,
    current_avg_score: 34110,
    prev_avg_score: 33788,
    round_eidolons: 38564,
    app_free: 6.94,
    round_free: 32873,
    rarity: 'Limited 5*'
  },
  {
    char: 'bailu',
    name: 'Bailu',
    current_app_rate: 0.57,
    prev_app_rate: 0.87,
    current_avg_score: 30074,
    prev_avg_score: 30633,
    round_eidolons: 32507,
    app_free: 0.12,
    round_free: 30692,
    rarity: 'Standard 5*'
  },
  {
    char: 'black-swan',
    name: 'Black Swan',
    current_app_rate: 14.05,
    prev_app_rate: 16.77,
    current_avg_score: 29318,
    prev_avg_score: 32381,
    round_eidolons: 33207,
    app_free: 0.69,
    round_free: 25359,
    rarity: 'Limited 5*'
  },
  {
    char: 'blade',
    name: 'Blade',
    current_app_rate: 0.45,
    prev_app_rate: 0.42,
    current_avg_score: 26163,
    prev_avg_score: 27406,
    round_eidolons: 31951,
    app_free: 0.03,
    round_free: 0,
    rarity: 'Limited 5*'
  },
  {
    char: 'boothill',
    name: 'Boothill',
    current_app_rate: 0.15,
    prev_app_rate: 0.17,
    current_avg_score: 0,
    prev_avg_score: 0,
    round_eidolons: 31737,
    app_free: 0.02,
    round_free: 0,
    rarity: 'Limited 5*'
  },
  {
    char: 'bronya',
    name: 'Bronya',
    current_app_rate: 3.73,
    prev_app_rate: 5.15,
    current_avg_score: 29496,
    prev_avg_score: 32834,
    round_eidolons: 37195,
    app_free: 0.37,
    round_free: 25739,
    rarity: 'Standard 5*'
  },
  {
    char: 'clara',
    name: 'Clara',
    current_app_rate: 3.17,
    prev_app_rate: 3.41,
    current_avg_score: 24664,
    prev_avg_score: 30826,
    round_eidolons: 27019,
    app_free: 0.91,
    round_free: 24666,
    rarity: 'Standard 5*'
  },
  {
    char: 'dan-heng',
    name: 'Dan Heng',
    current_app_rate: 0.01,
    prev_app_rate: 0,
    current_avg_score: 0,
    prev_avg_score: 0,
    round_eidolons: 0,
    app_free: 0,
    round_free: 0,
    rarity: '4*'
  },
  {
    char: 'dr-ratio',
    name: 'Dr. Ratio',
    current_app_rate: 0.03,
    prev_app_rate: 0.14,
    current_avg_score: 0,
    prev_avg_score: 0,
    round_eidolons: 0,
    app_free: 0,
    round_free: 0,
    rarity: 'Limited 5*'
  },
  {
    char: 'feixiao',
    name: 'Feixiao',
    current_app_rate: 2.68,
    prev_app_rate: 4.88,
    current_avg_score: 26045,
    prev_avg_score: 31121,
    round_eidolons: 32319,
    app_free: 0.17,
    round_free: 25058,
    rarity: 'Limited 5*'
  },
  {
    char: 'firefly',
    name: 'Firefly',
    current_app_rate: 13.26,
    prev_app_rate: 29.76,
    current_avg_score: 25654,
    prev_avg_score: 29791,
    round_eidolons: 33478,
    app_free: 0.43,
    round_free: 25837,
    rarity: 'Limited 5*'
  },
  {
    char: 'fu-xuan',
    name: 'Fu Xuan',
    current_app_rate: 28.86,
    prev_app_rate: 23.74,
    current_avg_score: 32400,
    prev_avg_score: 32155,
    round_eidolons: 35206,
    app_free: 2.85,
    round_free: 30710,
    rarity: 'Limited 5*'
  },
  {
    char: 'gallagher',
    name: 'Gallagher',
    current_app_rate: 24.82,
    prev_app_rate: 38.01,
    current_avg_score: 33577,
    prev_avg_score: 32532,
    round_eidolons: 33869,
    app_free: 6.3,
    round_free: 32640,
    rarity: '4*'
  },
  {
    char: 'gepard',
    name: 'Gepard',
    current_app_rate: 1.7,
    prev_app_rate: 1.52,
    current_avg_score: 29431,
    prev_avg_score: 31514,
    round_eidolons: 34775,
    app_free: 0.12,
    round_free: 24251,
    rarity: 'Standard 5*'
  },
  {
    char: 'guinaifen',
    name: 'Guinaifen',
    current_app_rate: 0.49,
    prev_app_rate: 0.71,
    current_avg_score: 25807,
    prev_avg_score: 28675,
    round_eidolons: 30836,
    app_free: 0.07,
    round_free: 0,
    rarity: '4*'
  },
  {
    char: 'hanya',
    name: 'Hanya',
    current_app_rate: 0.1,
    prev_app_rate: 0.11,
    current_avg_score: 0,
    prev_avg_score: 0,
    round_eidolons: 0,
    app_free: 0.03,
    round_free: 0,
    rarity: '4*'
  },
  {
    char: 'herta',
    name: 'Herta',
    current_app_rate: 63.2,
    prev_app_rate: 62.33,
    current_avg_score: 34469,
    prev_avg_score: 33458,
    round_eidolons: 38400,
    app_free: 18.42,
    round_free: 32900,
    rarity: '4*'
  },
  {
    char: 'himeko',
    name: 'Himeko',
    current_app_rate: 11.06,
    prev_app_rate: 54.96,
    current_avg_score: 26109,
    prev_avg_score: 32731,
    round_eidolons: 31295,
    app_free: 3.11,
    round_free: 24977,
    rarity: 'Standard 5*'
  },
  {
    char: 'hook',
    name: 'Hook',
    current_app_rate: 0,
    prev_app_rate: 0,
    current_avg_score: 0,
    prev_avg_score: 0,
    round_eidolons: 0,
    app_free: 0,
    round_free: 0,
    rarity: '4*'
  },
  {
    char: 'huohuo',
    name: 'Huohuo',
    current_app_rate: 27.53,
    prev_app_rate: 22.67,
    current_avg_score: 33804,
    prev_avg_score: 33540,
    round_eidolons: 37354,
    app_free: 5.09,
    round_free: 32501,
    rarity: 'Limited 5*'
  },
  {
    char: 'imbibitor-lunae',
    name: 'Imbibitor Lunae',
    current_app_rate: 0.4,
    prev_app_rate: 0.71,
    current_avg_score: 0,
    prev_avg_score: 0,
    round_eidolons: 30833,
    app_free: 0,
    round_free: 0,
    rarity: 'Limited 5*'
  },
  {
    char: 'jade',
    name: 'Jade',
    current_app_rate: 27.33,
    prev_app_rate: 16.07,
    current_avg_score: 37440,
    prev_avg_score: 37800,
    round_eidolons: 39502,
    app_free: 5.55,
    round_free: 35008,
    rarity: 'Limited 5*'
  },
  {
    char: 'jiaoqiu',
    name: 'Jiaoqiu',
    current_app_rate: 27.96,
    prev_app_rate: 23.62,
    current_avg_score: 38766,
    prev_avg_score: 35816,
    round_eidolons: 40000,
    app_free: 1.53,
    round_free: 34928,
    rarity: 'Limited 5*'
  },
  {
    char: 'jing-yuan',
    name: 'Jing Yuan',
    current_app_rate: 14.65,
    prev_app_rate: 21.79,
    current_avg_score: 30876,
    prev_avg_score: 34902,
    round_eidolons: 34983,
    app_free: 1.23,
    round_free: 27498,
    rarity: 'Limited 5*'
  },
  {
    char: 'jingliu',
    name: 'Jingliu',
    current_app_rate: 0.57,
    prev_app_rate: 0.95,
    current_avg_score: 23860,
    prev_avg_score: 26846,
    round_eidolons: 24813,
    app_free: 0.07,
    round_free: 0,
    rarity: 'Limited 5*'
  },
  {
    char: 'kafka',
    name: 'Kafka',
    current_app_rate: 16.91,
    prev_app_rate: 17.11,
    current_avg_score: 30519,
    prev_avg_score: 32301,
    round_eidolons: 33780,
    app_free: 0.94,
    round_free: 25911,
    rarity: 'Limited 5*'
  },
  {
    char: 'lingsha',
    name: 'Lingsha',
    current_app_rate: 39.45,
    prev_app_rate: 32.3,
    current_avg_score: 34713,
    prev_avg_score: 35299,
    round_eidolons: 38028,
    app_free: 7.18,
    round_free: 33303,
    rarity: 'Limited 5*'
  },
  {
    char: 'luka',
    name: 'Luka',
    current_app_rate: 0,
    prev_app_rate: 0,
    current_avg_score: 0,
    prev_avg_score: 0,
    round_eidolons: 0,
    app_free: 0,
    round_free: 0,
    rarity: '4*'
  },
  {
    char: 'luocha',
    name: 'Luocha',
    current_app_rate: 8.15,
    prev_app_rate: 9.06,
    current_avg_score: 32463,
    prev_avg_score: 32672,
    round_eidolons: 34345,
    app_free: 1.46,
    round_free: 30477,
    rarity: 'Limited 5*'
  },
  {
    char: 'lynx',
    name: 'Lynx',
    current_app_rate: 0.43,
    prev_app_rate: 0.31,
    current_avg_score: 25883,
    prev_avg_score: 31027,
    round_eidolons: 30416,
    app_free: 0.08,
    round_free: 0,
    rarity: '4*'
  },
  {
    char: 'march-7th',
    name: 'March 7th',
    current_app_rate: 0.51,
    prev_app_rate: 0.14,
    current_avg_score: 32644,
    prev_avg_score: 0,
    round_eidolons: 34532,
    app_free: 0.08,
    round_free: 0,
    rarity: '4*'
  },
  {
    char: 'march-7th-swordmaster',
    name: 'March 7th - The Hunt',
    current_app_rate: 0.49,
    prev_app_rate: 1.51,
    current_avg_score: 24360,
    prev_avg_score: 28658,
    round_eidolons: 26757,
    app_free: 0.08,
    round_free: 0,
    rarity: '4*'
  },
  {
    char: 'misha',
    name: 'Misha',
    current_app_rate: 0.03,
    prev_app_rate: 0.03,
    current_avg_score: 0,
    prev_avg_score: 0,
    round_eidolons: 0,
    app_free: 0,
    round_free: 0,
    rarity: '4*'
  },
  {
    char: 'moze',
    name: 'Moze',
    current_app_rate: 0.03,
    prev_app_rate: 0.2,
    current_avg_score: 0,
    prev_avg_score: 0,
    round_eidolons: 0,
    app_free: 0,
    round_free: 0,
    rarity: '4*'
  },
  {
    char: 'natasha',
    name: 'Natasha',
    current_app_rate: 0.1,
    prev_app_rate: 0.1,
    current_avg_score: 0,
    prev_avg_score: 0,
    round_eidolons: 0,
    app_free: 0.01,
    round_free: 0,
    rarity: '4*'
  },
  {
    char: 'pela',
    name: 'Pela',
    current_app_rate: 20.99,
    prev_app_rate: 15.32,
    current_avg_score: 33796,
    prev_avg_score: 33065,
    round_eidolons: 37055,
    app_free: 1.65,
    round_free: 31860,
    rarity: '4*'
  },
  {
    char: 'qingque',
    name: 'Qingque',
    current_app_rate: 0.13,
    prev_app_rate: 0.05,
    current_avg_score: 0,
    prev_avg_score: 0,
    round_eidolons: 24967,
    app_free: 0,
    round_free: 0,
    rarity: '4*'
  },
  {
    char: 'rappa',
    name: 'Rappa',
    current_app_rate: 5.56,
    prev_app_rate: 16.07,
    current_avg_score: 29430,
    prev_avg_score: 35215,
    round_eidolons: 34322,
    app_free: 1.08,
    round_free: 27104,
    rarity: 'Limited 5*'
  },
  {
    char: 'robin',
    name: 'Robin',
    current_app_rate: 53.44,
    prev_app_rate: 58,
    current_avg_score: 33342,
    prev_avg_score: 34133,
    round_eidolons: 38159,
    app_free: 10.15,
    round_free: 31939,
    rarity: 'Limited 5*'
  },
  {
    char: 'ruan-mei',
    name: 'Ruan Mei',
    current_app_rate: 37.89,
    prev_app_rate: 75.82,
    current_avg_score: 29820,
    prev_avg_score: 33561,
    round_eidolons: 33691,
    app_free: 5.43,
    round_free: 28526,
    rarity: 'Limited 5*'
  },
  {
    char: 'sampo',
    name: 'Sampo',
    current_app_rate: 0.08,
    prev_app_rate: 0.12,
    current_avg_score: 0,
    prev_avg_score: 0,
    round_eidolons: 0,
    app_free: 0,
    round_free: 0,
    rarity: '4*'
  },
  {
    char: 'seele',
    name: 'Seele',
    current_app_rate: 0.21,
    prev_app_rate: 0.32,
    current_avg_score: 0,
    prev_avg_score: 0,
    round_eidolons: 30556,
    app_free: 0,
    round_free: 0,
    rarity: 'Limited 5*'
  },
  {
    char: 'serval',
    name: 'Serval',
    current_app_rate: 5.54,
    prev_app_rate: 0.69,
    current_avg_score: 32763,
    prev_avg_score: 29935,
    round_eidolons: 38833,
    app_free: 1.13,
    round_free: 30607,
    rarity: '4*'
  },
  {
    char: 'silver-wolf',
    name: 'Silver Wolf',
    current_app_rate: 3.4,
    prev_app_rate: 2.03,
    current_avg_score: 26904,
    prev_avg_score: 27984,
    round_eidolons: 32868,
    app_free: 0.18,
    round_free: 25291,
    rarity: 'Limited 5*'
  },
  {
    char: 'sparkle',
    name: 'Sparkle',
    current_app_rate: 21.14,
    prev_app_rate: 19.94,
    current_avg_score: 30696,
    prev_avg_score: 32389,
    round_eidolons: 38628,
    app_free: 1.13,
    round_free: 28744,
    rarity: 'Limited 5*'
  },
  {
    char: 'sunday',
    name: 'Sunday',
    current_app_rate: 41.52,
    prev_app_rate: 38.05,
    current_avg_score: 33244,
    prev_avg_score: 34902,
    round_eidolons: 37757,
    app_free: 3.83,
    round_free: 30609,
    rarity: 'Limited 5*'
  },
  {
    char: 'sushang',
    name: 'Sushang',
    current_app_rate: 0,
    prev_app_rate: 0,
    current_avg_score: 0,
    prev_avg_score: 0,
    round_eidolons: 0,
    app_free: 0,
    round_free: 0,
    rarity: '4*'
  },
  {
    char: 'the-herta',
    name: 'The Herta',
    current_app_rate: 71.92,
    prev_app_rate: 0,
    current_avg_score: 37786,
    prev_avg_score: 0,
    round_eidolons: 39970,
    app_free: 18.2,
    round_free: 35645,
    rarity: 'Limited 5*'
  },
  {
    char: 'tingyun',
    name: 'Tingyun',
    current_app_rate: 5.79,
    prev_app_rate: 7.9,
    current_avg_score: 29693,
    prev_avg_score: 33902,
    round_eidolons: 32900,
    app_free: 1.13,
    round_free: 27251,
    rarity: '4*'
  },
  {
    char: 'tingyun-fugue',
    name: 'Fugue',
    current_app_rate: 24.82,
    prev_app_rate: 19.81,
    current_avg_score: 29963,
    prev_avg_score: 37602,
    round_eidolons: 34302,
    app_free: 2.87,
    round_free: 26836,
    rarity: 'Limited 5*'
  },
  {
    char: 'topaz',
    name: 'Topaz & Numby',
    current_app_rate: 0.81,
    prev_app_rate: 2.65,
    current_avg_score: 24576,
    prev_avg_score: 27852,
    round_eidolons: 27321,
    app_free: 0.05,
    round_free: 0,
    rarity: 'Limited 5*'
  },
  {
    char: 'trailblazer-destruction',
    name: 'Trailblazer - Destruction',
    current_app_rate: 0.01,
    prev_app_rate: 0,
    current_avg_score: 0,
    prev_avg_score: 0,
    round_eidolons: 0,
    app_free: 0,
    round_free: 0,
    rarity: '4*'
  },
  {
    char: 'trailblazer-harmony',
    name: 'Trailblazer - Harmony',
    current_app_rate: 5.45,
    prev_app_rate: 41.09,
    current_avg_score: 25205,
    prev_avg_score: 32676,
    round_eidolons: 32432,
    app_free: 0.28,
    round_free: 24807,
    rarity: '4*'
  },
  {
    char: 'trailblazer-preservation',
    name: 'Trailblazer - Preservation',
    current_app_rate: 0.04,
    prev_app_rate: 0.1,
    current_avg_score: 0,
    prev_avg_score: 0,
    round_eidolons: 0,
    app_free: 0,
    round_free: 0,
    rarity: '4*'
  },
  {
    char: 'trailblazer-remembrance',
    name: 'Trailblazer - Remembrance',
    current_app_rate: 41.49,
    prev_app_rate: 0,
    current_avg_score: 37758,
    prev_avg_score: 0,
    round_eidolons: 39658,
    app_free: 12.34,
    round_free: 36356,
    rarity: '4*'
  },
  {
    char: 'welt',
    name: 'Welt',
    current_app_rate: 0.3,
    prev_app_rate: 1.05,
    current_avg_score: 24978,
    prev_avg_score: 27670,
    round_eidolons: 29333,
    app_free: 0.04,
    round_free: 0,
    rarity: 'Standard 5*'
  },
  {
    char: 'xueyi',
    name: 'Xueyi',
    current_app_rate: 0.15,
    prev_app_rate: 0.08,
    current_avg_score: 0,
    prev_avg_score: 0,
    round_eidolons: 27447,
    app_free: 0.04,
    round_free: 0,
    rarity: '4*'
  },
  {
    char: 'yanqing',
    name: 'Yanqing',
    current_app_rate: 0,
    prev_app_rate: 0.01,
    current_avg_score: 0,
    prev_avg_score: 0,
    round_eidolons: 0,
    app_free: 0,
    round_free: 0,
    rarity: 'Standard 5*'
  },
  {
    char: 'yukong',
    name: 'Yukong',
    current_app_rate: 0.01,
    prev_app_rate: 0.02,
    current_avg_score: 0,
    prev_avg_score: 0,
    round_eidolons: 0,
    app_free: 0.01,
    round_free: 0,
    rarity: '4*'
  },
  {
    char: 'yunli',
    name: 'Yunli',
    current_app_rate: 5.16,
    prev_app_rate: 5.9,
    current_avg_score: 26182,
    prev_avg_score: 33653,
    round_eidolons: 32146,
    app_free: 0.69,
    round_free: 25656,
    rarity: 'Limited 5*'
  }
];
