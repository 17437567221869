export const PFDuos = [
  {
    char: 'the-herta',
    app: 71.92,
    char_1: 'herta',
    app_rate_1: '63.32%',
    avg_round_1: 37752,
    app_flat_1: 3237,
    char_2: 'trailblazer-remembrance',
    app_rate_2: '45.84%',
    avg_round_2: 38547,
    app_flat_2: 2498,
    char_3: 'robin',
    app_rate_3: '29.51%',
    avg_round_3: 37798,
    app_flat_3: 1294,
    char_4: 'lingsha',
    app_rate_4: '24.86%',
    avg_round_4: 38568,
    app_flat_4: 1239,
    char_5: 'jade',
    app_rate_5: '23.57%',
    avg_round_5: 38919,
    app_flat_5: 1056,
    char_6: 'aventurine',
    app_rate_6: '20.23%',
    avg_round_6: 38151,
    app_flat_6: 1005,
    char_7: 'huohuo',
    app_rate_7: '17.93%',
    avg_round_7: 37740,
    app_flat_7: 866,
    char_8: 'sunday',
    app_rate_8: '15.48%',
    avg_round_8: 37018,
    app_flat_8: 752,
    char_9: 'gallagher',
    app_rate_9: '13.55%',
    avg_round_9: 37272,
    app_flat_9: 828,
    char_10: 'ruan-mei',
    app_rate_10: '9.36%',
    avg_round_10: 35310,
    app_flat_10: 348,
    char_11: 'fu-xuan',
    app_rate_11: '9.11%',
    avg_round_11: 37033,
    app_flat_11: 361,
    char_12: 'serval',
    app_rate_12: '6.25%',
    avg_round_12: 34321,
    app_flat_12: 271,
    char_13: 'argenti',
    app_rate_13: '5.36%',
    avg_round_13: 35878,
    app_flat_13: 240,
    char_14: 'luocha',
    app_rate_14: '4.52%',
    avg_round_14: 35813,
    app_flat_14: 219,
    char_15: 'himeko',
    app_rate_15: '4.08%',
    avg_round_15: 32590,
    app_flat_15: 185,
    char_16: 'sparkle',
    app_rate_16: '3.18%',
    avg_round_16: 34927,
    app_flat_16: 87,
    char_17: 'pela',
    app_rate_17: '0.74%',
    avg_round_17: 31679,
    app_flat_17: 28,
    char_18: 'tingyun-fugue',
    app_rate_18: '0.5%',
    avg_round_18: 29992,
    app_flat_18: 16,
    char_19: 'bronya',
    app_rate_19: '0.34%',
    avg_round_19: 33013,
    app_flat_19: 12,
    char_20: 'yunli',
    app_rate_20: '0.31%',
    avg_round_20: 30283,
    app_flat_20: 13,
    char_21: 'bailu',
    app_rate_21: '0.29%',
    avg_round_21: 34584,
    app_flat_21: 17,
    char_22: 'tingyun',
    app_rate_22: '0.23%',
    avg_round_22: 31133,
    app_flat_22: 6,
    char_23: 'gepard',
    app_rate_23: '0.21%',
    avg_round_23: 31232,
    app_flat_23: 7,
    char_24: 'clara',
    app_rate_24: '0.18%',
    avg_round_24: 28945,
    app_flat_24: 8,
    char_25: 'march-7th',
    app_rate_25: '0.17%',
    avg_round_25: 34743,
    app_flat_25: 7,
    char_26: 'jiaoqiu',
    app_rate_26: '0.17%',
    avg_round_26: 33920,
    app_flat_26: 6,
    char_27: 'lynx',
    app_rate_27: '0.13%',
    avg_round_27: 33060,
    app_flat_27: 6,
    char_28: 'jing-yuan',
    app_rate_28: '0.12%',
    avg_round_28: 31900,
    app_flat_28: 4,
    char_29: 'rappa',
    app_rate_29: '0.08%',
    avg_round_29: 28224,
    app_flat_29: 5,
    char_30: 'natasha',
    app_rate_30: '0.08%',
    avg_round_30: 31280,
    app_flat_30: 1
  },
  {
    char: 'herta',
    app: 63.2,
    char_1: 'the-herta',
    app_rate_1: '72.05%',
    avg_round_1: 37752,
    app_flat_1: 3237,
    char_2: 'trailblazer-remembrance',
    app_rate_2: '37.81%',
    avg_round_2: 38106,
    app_flat_2: 1860,
    char_3: 'robin',
    app_rate_3: '36.28%',
    avg_round_3: 33420,
    app_flat_3: 1406,
    char_4: 'aventurine',
    app_rate_4: '22.71%',
    avg_round_4: 34727,
    app_flat_4: 938,
    char_5: 'lingsha',
    app_rate_5: '18.68%',
    avg_round_5: 37138,
    app_flat_5: 872,
    char_6: 'ruan-mei',
    app_rate_6: '16.13%',
    avg_round_6: 30337,
    app_flat_6: 562,
    char_7: 'huohuo',
    app_rate_7: '15.93%',
    avg_round_7: 34882,
    app_flat_7: 691,
    char_8: 'gallagher',
    app_rate_8: '14.94%',
    avg_round_8: 34065,
    app_flat_8: 790,
    char_9: 'sunday',
    app_rate_9: '12.36%',
    avg_round_9: 36310,
    app_flat_9: 543,
    char_10: 'himeko',
    app_rate_10: '10.4%',
    avg_round_10: 25189,
    app_flat_10: 421,
    char_11: 'fu-xuan',
    app_rate_11: '9.74%',
    avg_round_11: 34344,
    app_flat_11: 326,
    char_12: 'jade',
    app_rate_12: '7.66%',
    avg_round_12: 33144,
    app_flat_12: 261,
    char_13: 'luocha',
    app_rate_13: '4.25%',
    avg_round_13: 33484,
    app_flat_13: 171,
    char_14: 'sparkle',
    app_rate_14: '4.22%',
    avg_round_14: 30490,
    app_flat_14: 98,
    char_15: 'tingyun-fugue',
    app_rate_15: '3.24%',
    avg_round_15: 26281,
    app_flat_15: 112,
    char_16: 'clara',
    app_rate_16: '2.54%',
    avg_round_16: 24975,
    app_flat_16: 106,
    char_17: 'feixiao',
    app_rate_17: '1.93%',
    avg_round_17: 27296,
    app_flat_17: 45,
    char_18: 'yunli',
    app_rate_18: '1.66%',
    avg_round_18: 27075,
    app_flat_18: 66,
    char_19: 'argenti',
    app_rate_19: '1.65%',
    avg_round_19: 27335,
    app_flat_19: 69,
    char_20: 'pela',
    app_rate_20: '0.91%',
    avg_round_20: 28665,
    app_flat_20: 33,
    char_21: 'bronya',
    app_rate_21: '0.71%',
    avg_round_21: 27963,
    app_flat_21: 29,
    char_22: 'tingyun',
    app_rate_22: '0.49%',
    avg_round_22: 26069,
    app_flat_22: 19,
    char_23: 'firefly',
    app_rate_23: '0.46%',
    avg_round_23: 24160,
    app_flat_23: 4,
    char_24: 'gepard',
    app_rate_24: '0.34%',
    avg_round_24: 27794,
    app_flat_24: 14,
    char_25: 'bailu',
    app_rate_25: '0.3%',
    avg_round_25: 32115,
    app_flat_25: 16,
    char_26: 'trailblazer-harmony',
    app_rate_26: '0.3%',
    avg_round_26: 25088,
    app_flat_26: 10,
    char_27: 'lynx',
    app_rate_27: '0.25%',
    avg_round_27: 26713,
    app_flat_27: 12,
    char_28: 'march-7th',
    app_rate_28: '0.24%',
    avg_round_28: 30895,
    app_flat_28: 8,
    char_29: 'jingliu',
    app_rate_29: '0.24%',
    avg_round_29: 23910,
    app_flat_29: 8,
    char_30: 'jiaoqiu',
    app_rate_30: '0.24%',
    avg_round_30: 34250,
    app_flat_30: 4
  },
  {
    char: 'acheron',
    app: 54.1,
    char_1: 'jiaoqiu',
    app_rate_1: '50.03%',
    avg_round_1: 38845,
    app_flat_1: 1171,
    char_2: 'aventurine',
    app_rate_2: '42.33%',
    avg_round_2: 34398,
    app_flat_2: 964,
    char_3: 'pela',
    app_rate_3: '37.14%',
    avg_round_3: 33969,
    app_flat_3: 1146,
    char_4: 'fu-xuan',
    app_rate_4: '28.82%',
    avg_round_4: 32213,
    app_flat_4: 581,
    char_5: 'kafka',
    app_rate_5: '25.69%',
    avg_round_5: 31032,
    app_flat_5: 640,
    char_6: 'sparkle',
    app_rate_6: '23.39%',
    avg_round_6: 32292,
    app_flat_6: 143,
    char_7: 'black-swan',
    app_rate_7: '21.36%',
    avg_round_7: 29734,
    app_flat_7: 512,
    char_8: 'gallagher',
    app_rate_8: '12.34%',
    avg_round_8: 34198,
    app_flat_8: 345,
    char_9: 'sunday',
    app_rate_9: '10.41%',
    avg_round_9: 34606,
    app_flat_9: 131,
    char_10: 'tingyun-fugue',
    app_rate_10: '8.15%',
    avg_round_10: 33305,
    app_flat_10: 266,
    char_11: 'silver-wolf',
    app_rate_11: '6.19%',
    avg_round_11: 26905,
    app_flat_11: 165,
    char_12: 'lingsha',
    app_rate_12: '6.09%',
    avg_round_12: 33623,
    app_flat_12: 158,
    char_13: 'robin',
    app_rate_13: '4.7%',
    avg_round_13: 33878,
    app_flat_13: 77,
    char_14: 'ruan-mei',
    app_rate_14: '3.89%',
    avg_round_14: 31296,
    app_flat_14: 49,
    char_15: 'huohuo',
    app_rate_15: '3.28%',
    avg_round_15: 29505,
    app_flat_15: 55,
    char_16: 'bronya',
    app_rate_16: '3.07%',
    avg_round_16: 26407,
    app_flat_16: 10,
    char_17: 'gepard',
    app_rate_17: '2.65%',
    avg_round_17: 29948,
    app_flat_17: 46,
    char_18: 'trailblazer-remembrance',
    app_rate_18: '2.41%',
    avg_round_18: 33428,
    app_flat_18: 39,
    char_19: 'luocha',
    app_rate_19: '1.72%',
    avg_round_19: 31574,
    app_flat_19: 35,
    char_20: 'jade',
    app_rate_20: '1.51%',
    avg_round_20: 31084,
    app_flat_20: 30,
    char_21: 'guinaifen',
    app_rate_21: '0.88%',
    avg_round_21: 25807,
    app_flat_21: 33,
    char_22: 'himeko',
    app_rate_22: '0.68%',
    avg_round_22: 30315,
    app_flat_22: 17,
    char_23: 'march-7th',
    app_rate_23: '0.56%',
    avg_round_23: 33141,
    app_flat_23: 17,
    char_24: 'welt',
    app_rate_24: '0.54%',
    avg_round_24: 24978,
    app_flat_24: 17,
    char_25: 'yunli',
    app_rate_25: '0.29%',
    avg_round_25: 31395,
    app_flat_25: 8,
    char_26: 'herta',
    app_rate_26: '0.28%',
    avg_round_26: 34813,
    app_flat_26: 3,
    char_27: 'bailu',
    app_rate_27: '0.24%',
    avg_round_27: 24208,
    app_flat_27: 7,
    char_28: 'serval',
    app_rate_28: '0.24%',
    avg_round_28: 24090,
    app_flat_28: 8,
    char_29: 'firefly',
    app_rate_29: '0.23%',
    avg_round_29: 24160,
    app_flat_29: 1,
    char_30: 'sampo',
    app_rate_30: '0.1%',
    avg_round_30: 26080,
    app_flat_30: 3
  },
  {
    char: 'robin',
    app: 53.44,
    char_1: 'herta',
    app_rate_1: '42.9%',
    avg_round_1: 33420,
    app_flat_1: 1406,
    char_2: 'the-herta',
    app_rate_2: '39.71%',
    avg_round_2: 37798,
    app_flat_2: 1294,
    char_3: 'aventurine',
    app_rate_3: '32.54%',
    avg_round_3: 33191,
    app_flat_3: 869,
    char_4: 'sunday',
    app_rate_4: '26.87%',
    avg_round_4: 32013,
    app_flat_4: 611,
    char_5: 'jade',
    app_rate_5: '23.06%',
    avg_round_5: 37529,
    app_flat_5: 663,
    char_6: 'huohuo',
    app_rate_6: '19.04%',
    avg_round_6: 32500,
    app_flat_6: 509,
    char_7: 'jing-yuan',
    app_rate_7: '16.89%',
    avg_round_7: 32311,
    app_flat_7: 388,
    char_8: 'lingsha',
    app_rate_8: '15.74%',
    avg_round_8: 37426,
    app_flat_8: 510,
    char_9: 'gallagher',
    app_rate_9: '9.52%',
    avg_round_9: 33108,
    app_flat_9: 314,
    char_10: 'fu-xuan',
    app_rate_10: '8.69%',
    avg_round_10: 32313,
    app_flat_10: 216,
    char_11: 'trailblazer-remembrance',
    app_rate_11: '7.34%',
    avg_round_11: 32917,
    app_flat_11: 205,
    char_12: 'yunli',
    app_rate_12: '6.85%',
    avg_round_12: 26312,
    app_flat_12: 185,
    char_13: 'argenti',
    app_rate_13: '5.8%',
    avg_round_13: 32303,
    app_flat_13: 150,
    char_14: 'himeko',
    app_rate_14: '4.79%',
    avg_round_14: 25342,
    app_flat_14: 172,
    char_15: 'acheron',
    app_rate_15: '4.76%',
    avg_round_15: 33878,
    app_flat_15: 77,
    char_16: 'luocha',
    app_rate_16: '4.65%',
    avg_round_16: 31575,
    app_flat_16: 110,
    char_17: 'feixiao',
    app_rate_17: '4.51%',
    avg_round_17: 26199,
    app_flat_17: 67,
    char_18: 'clara',
    app_rate_18: '3.71%',
    avg_round_18: 24999,
    app_flat_18: 107,
    char_19: 'aglaea',
    app_rate_19: '2.65%',
    avg_round_19: 32263,
    app_flat_19: 51,
    char_20: 'jiaoqiu',
    app_rate_20: '2.58%',
    avg_round_20: 36627,
    app_flat_20: 44,
    char_21: 'sparkle',
    app_rate_21: '2.34%',
    avg_round_21: 26423,
    app_flat_21: 28,
    char_22: 'tingyun',
    app_rate_22: '2.05%',
    avg_round_22: 26199,
    app_flat_22: 55,
    char_23: 'ruan-mei',
    app_rate_23: '1.86%',
    avg_round_23: 26886,
    app_flat_23: 47,
    char_24: 'kafka',
    app_rate_24: '1.83%',
    avg_round_24: 29127,
    app_flat_24: 28,
    char_25: 'serval',
    app_rate_25: '1.39%',
    avg_round_25: 29652,
    app_flat_25: 41,
    char_26: 'black-swan',
    app_rate_26: '1.32%',
    avg_round_26: 24381,
    app_flat_26: 15,
    char_27: 'topaz',
    app_rate_27: '1.23%',
    avg_round_27: 24620,
    app_flat_27: 12,
    char_28: 'pela',
    app_rate_28: '1.12%',
    avg_round_28: 28156,
    app_flat_28: 19,
    char_29: 'march-7th-swordmaster',
    app_rate_29: '0.77%',
    avg_round_29: 24580,
    app_flat_29: 24,
    char_30: 'bronya',
    app_rate_30: '0.76%',
    avg_round_30: 31878,
    app_flat_30: 19
  },
  {
    char: 'aventurine',
    app: 53.18,
    char_1: 'acheron',
    app_rate_1: '43.06%',
    avg_round_1: 34398,
    app_flat_1: 964,
    char_2: 'robin',
    app_rate_2: '32.7%',
    avg_round_2: 33191,
    app_flat_2: 869,
    char_3: 'the-herta',
    app_rate_3: '27.36%',
    avg_round_3: 38151,
    app_flat_3: 1005,
    char_4: 'herta',
    app_rate_4: '26.99%',
    avg_round_4: 34727,
    app_flat_4: 938,
    char_5: 'jiaoqiu',
    app_rate_5: '25.29%',
    avg_round_5: 39123,
    app_flat_5: 558,
    char_6: 'sunday',
    app_rate_6: '24.97%',
    avg_round_6: 33203,
    app_flat_6: 577,
    char_7: 'pela',
    app_rate_7: '16.22%',
    avg_round_7: 34671,
    app_flat_7: 497,
    char_8: 'jade',
    app_rate_8: '13.57%',
    avg_round_8: 36967,
    app_flat_8: 396,
    char_9: 'sparkle',
    app_rate_9: '12.92%',
    avg_round_9: 31430,
    app_flat_9: 135,
    char_10: 'trailblazer-remembrance',
    app_rate_10: '12.05%',
    avg_round_10: 38239,
    app_flat_10: 469,
    char_11: 'jing-yuan',
    app_rate_11: '11.28%',
    avg_round_11: 31478,
    app_flat_11: 247,
    char_12: 'kafka',
    app_rate_12: '9.48%',
    avg_round_12: 32069,
    app_flat_12: 252,
    char_13: 'black-swan',
    app_rate_13: '8.54%',
    avg_round_13: 31110,
    app_flat_13: 218,
    char_14: 'ruan-mei',
    app_rate_14: '5.93%',
    avg_round_14: 30035,
    app_flat_14: 124,
    char_15: 'argenti',
    app_rate_15: '4.54%',
    avg_round_15: 31681,
    app_flat_15: 127,
    char_16: 'himeko',
    app_rate_16: '3.71%',
    avg_round_16: 25494,
    app_flat_16: 124,
    char_17: 'feixiao',
    app_rate_17: '3.46%',
    avg_round_17: 26277,
    app_flat_17: 45,
    char_18: 'tingyun-fugue',
    app_rate_18: '3.09%',
    avg_round_18: 33339,
    app_flat_18: 93,
    char_19: 'silver-wolf',
    app_rate_19: '2.29%',
    avg_round_19: 28099,
    app_flat_19: 70,
    char_20: 'bronya',
    app_rate_20: '2.01%',
    avg_round_20: 27166,
    app_flat_20: 25,
    char_21: 'clara',
    app_rate_21: '1.96%',
    avg_round_21: 25028,
    app_flat_21: 62,
    char_22: 'yunli',
    app_rate_22: '1.94%',
    avg_round_22: 26022,
    app_flat_22: 52,
    char_23: 'tingyun',
    app_rate_23: '1.84%',
    avg_round_23: 28693,
    app_flat_23: 58,
    char_24: 'serval',
    app_rate_24: '1.54%',
    avg_round_24: 33179,
    app_flat_24: 62,
    char_25: 'topaz',
    app_rate_25: '1.11%',
    avg_round_25: 24776,
    app_flat_25: 10,
    char_26: 'march-7th-swordmaster',
    app_rate_26: '0.56%',
    avg_round_26: 24080,
    app_flat_26: 18,
    char_27: 'guinaifen',
    app_rate_27: '0.28%',
    avg_round_27: 25847,
    app_flat_27: 12,
    char_28: 'aglaea',
    app_rate_28: '0.19%',
    avg_round_28: 28200,
    app_flat_28: 1,
    char_29: 'welt',
    app_rate_29: '0.19%',
    avg_round_29: 30897,
    app_flat_29: 7,
    char_30: 'firefly',
    app_rate_30: '0.18%',
    avg_round_30: 0,
    app_flat_30: 0
  },
  {
    char: 'sunday',
    app: 41.52,
    char_1: 'robin',
    app_rate_1: '34.58%',
    avg_round_1: 32013,
    app_flat_1: 611,
    char_2: 'jing-yuan',
    app_rate_2: '34.36%',
    avg_round_2: 31044,
    app_flat_2: 612,
    char_3: 'aventurine',
    app_rate_3: '31.98%',
    avg_round_3: 33203,
    app_flat_3: 577,
    char_4: 'the-herta',
    app_rate_4: '26.81%',
    avg_round_4: 37018,
    app_flat_4: 752,
    char_5: 'huohuo',
    app_rate_5: '21.11%',
    avg_round_5: 33042,
    app_flat_5: 428,
    char_6: 'herta',
    app_rate_6: '18.81%',
    avg_round_6: 36310,
    app_flat_6: 543,
    char_7: 'acheron',
    app_rate_7: '13.56%',
    avg_round_7: 34606,
    app_flat_7: 131,
    char_8: 'fu-xuan',
    app_rate_8: '11.93%',
    avg_round_8: 31100,
    app_flat_8: 212,
    char_9: 'argenti',
    app_rate_9: '11.37%',
    avg_round_9: 33325,
    app_flat_9: 263,
    char_10: 'jade',
    app_rate_10: '11.28%',
    avg_round_10: 35299,
    app_flat_10: 250,
    char_11: 'lingsha',
    app_rate_11: '10.67%',
    avg_round_11: 35165,
    app_flat_11: 254,
    char_12: 'gallagher',
    app_rate_12: '9.61%',
    avg_round_12: 34048,
    app_flat_12: 230,
    char_13: 'jiaoqiu',
    app_rate_13: '9.11%',
    avg_round_13: 38036,
    app_flat_13: 91,
    char_14: 'trailblazer-remembrance',
    app_rate_14: '8.54%',
    avg_round_14: 32840,
    app_flat_14: 190,
    char_15: 'luocha',
    app_rate_15: '6.74%',
    avg_round_15: 32573,
    app_flat_15: 140,
    char_16: 'sparkle',
    app_rate_16: '5.79%',
    avg_round_16: 28951,
    app_flat_16: 93,
    char_17: 'ruan-mei',
    app_rate_17: '5.58%',
    avg_round_17: 30396,
    app_flat_17: 96,
    char_18: 'tingyun',
    app_rate_18: '5.54%',
    avg_round_18: 30782,
    app_flat_18: 132,
    char_19: 'aglaea',
    app_rate_19: '4.16%',
    avg_round_19: 32658,
    app_flat_19: 55,
    char_20: 'pela',
    app_rate_20: '3.59%',
    avg_round_20: 30103,
    app_flat_20: 37,
    char_21: 'serval',
    app_rate_21: '3.46%',
    avg_round_21: 33009,
    app_flat_21: 94,
    char_22: 'yunli',
    app_rate_22: '2.4%',
    avg_round_22: 25933,
    app_flat_22: 53,
    char_23: 'bronya',
    app_rate_23: '1.65%',
    avg_round_23: 33378,
    app_flat_23: 26,
    char_24: 'tingyun-fugue',
    app_rate_24: '1.24%',
    avg_round_24: 27717,
    app_flat_24: 12,
    char_25: 'himeko',
    app_rate_25: '0.97%',
    avg_round_25: 32186,
    app_flat_25: 26,
    char_26: 'clara',
    app_rate_26: '0.93%',
    avg_round_26: 24493,
    app_flat_26: 21,
    char_27: 'kafka',
    app_rate_27: '0.57%',
    avg_round_27: 26300,
    app_flat_27: 6,
    char_28: 'imbibitor-lunae',
    app_rate_28: '0.5%',
    avg_round_28: 0,
    app_flat_28: 0,
    char_29: 'jingliu',
    app_rate_29: '0.45%',
    avg_round_29: 24240,
    app_flat_29: 7,
    char_30: 'gepard',
    app_rate_30: '0.41%',
    avg_round_30: 30410,
    app_flat_30: 6
  },
  {
    char: 'trailblazer-remembrance',
    app: 41.49,
    char_1: 'the-herta',
    app_rate_1: '79.46%',
    avg_round_1: 38547,
    app_flat_1: 2498,
    char_2: 'herta',
    app_rate_2: '57.59%',
    avg_round_2: 38106,
    app_flat_2: 1860,
    char_3: 'lingsha',
    app_rate_3: '19.77%',
    avg_round_3: 38866,
    app_flat_3: 637,
    char_4: 'huohuo',
    app_rate_4: '19.07%',
    avg_round_4: 37823,
    app_flat_4: 572,
    char_5: 'aventurine',
    app_rate_5: '15.45%',
    avg_round_5: 38239,
    app_flat_5: 469,
    char_6: 'jade',
    app_rate_6: '15.04%',
    avg_round_6: 39355,
    app_flat_6: 434,
    char_7: 'gallagher',
    app_rate_7: '14.59%',
    avg_round_7: 37706,
    app_flat_7: 529,
    char_8: 'argenti',
    app_rate_8: '10.18%',
    avg_round_8: 35198,
    app_flat_8: 282,
    char_9: 'robin',
    app_rate_9: '9.45%',
    avg_round_9: 32917,
    app_flat_9: 205,
    char_10: 'fu-xuan',
    app_rate_10: '8.6%',
    avg_round_10: 37402,
    app_flat_10: 193,
    char_11: 'sunday',
    app_rate_11: '8.55%',
    avg_round_11: 32840,
    app_flat_11: 190,
    char_12: 'ruan-mei',
    app_rate_12: '6.85%',
    avg_round_12: 33449,
    app_flat_12: 152,
    char_13: 'serval',
    app_rate_13: '6.79%',
    avg_round_13: 34739,
    app_flat_13: 176,
    char_14: 'luocha',
    app_rate_14: '4.98%',
    avg_round_14: 35032,
    app_flat_14: 139,
    char_15: 'himeko',
    app_rate_15: '3.39%',
    avg_round_15: 31921,
    app_flat_15: 98,
    char_16: 'acheron',
    app_rate_16: '3.14%',
    avg_round_16: 33428,
    app_flat_16: 39,
    char_17: 'sparkle',
    app_rate_17: '2.1%',
    avg_round_17: 30855,
    app_flat_17: 38,
    char_18: 'jing-yuan',
    app_rate_18: '1.83%',
    avg_round_18: 29375,
    app_flat_18: 35,
    char_19: 'tingyun',
    app_rate_19: '1.76%',
    avg_round_19: 31690,
    app_flat_19: 46,
    char_20: 'yunli',
    app_rate_20: '1.65%',
    avg_round_20: 27315,
    app_flat_20: 38,
    char_21: 'jiaoqiu',
    app_rate_21: '1.54%',
    avg_round_21: 37025,
    app_flat_21: 16,
    char_22: 'pela',
    app_rate_22: '1.54%',
    avg_round_22: 32243,
    app_flat_22: 27,
    char_23: 'aglaea',
    app_rate_23: '1.38%',
    avg_round_23: 33030,
    app_flat_23: 12,
    char_24: 'tingyun-fugue',
    app_rate_24: '0.88%',
    avg_round_24: 27862,
    app_flat_24: 17,
    char_25: 'clara',
    app_rate_25: '0.61%',
    avg_round_25: 24217,
    app_flat_25: 13,
    char_26: 'bronya',
    app_rate_26: '0.59%',
    avg_round_26: 30560,
    app_flat_26: 14,
    char_27: 'kafka',
    app_rate_27: '0.57%',
    avg_round_27: 33009,
    app_flat_27: 9,
    char_28: 'bailu',
    app_rate_28: '0.43%',
    avg_round_28: 32823,
    app_flat_28: 14,
    char_29: 'march-7th',
    app_rate_29: '0.36%',
    avg_round_29: 35651,
    app_flat_29: 11,
    char_30: 'feixiao',
    app_rate_30: '0.27%',
    avg_round_30: 27613,
    app_flat_30: 3
  },
  {
    char: 'lingsha',
    app: 39.45,
    char_1: 'the-herta',
    app_rate_1: '45.32%',
    avg_round_1: 38568,
    app_flat_1: 1239,
    char_2: 'ruan-mei',
    app_rate_2: '38.73%',
    avg_round_2: 30385,
    app_flat_2: 429,
    char_3: 'tingyun-fugue',
    app_rate_3: '35.67%',
    avg_round_3: 30757,
    app_flat_3: 396,
    char_4: 'herta',
    app_rate_4: '29.93%',
    avg_round_4: 37138,
    app_flat_4: 872,
    char_5: 'firefly',
    app_rate_5: '21.75%',
    avg_round_5: 26259,
    app_flat_5: 82,
    char_6: 'robin',
    app_rate_6: '21.32%',
    avg_round_6: 37426,
    app_flat_6: 510,
    char_7: 'trailblazer-remembrance',
    app_rate_7: '20.79%',
    avg_round_7: 38866,
    app_flat_7: 637,
    char_8: 'jade',
    app_rate_8: '20.65%',
    avg_round_8: 38941,
    app_flat_8: 492,
    char_9: 'sunday',
    app_rate_9: '11.23%',
    avg_round_9: 35165,
    app_flat_9: 254,
    char_10: 'rappa',
    app_rate_10: '8.54%',
    avg_round_10: 31085,
    app_flat_10: 167,
    char_11: 'acheron',
    app_rate_11: '8.35%',
    avg_round_11: 33623,
    app_flat_11: 158,
    char_12: 'trailblazer-harmony',
    app_rate_12: '6.26%',
    avg_round_12: 25141,
    app_flat_12: 37,
    char_13: 'himeko',
    app_rate_13: '5.04%',
    avg_round_13: 28933,
    app_flat_13: 115,
    char_14: 'jiaoqiu',
    app_rate_14: '3.59%',
    avg_round_14: 38319,
    app_flat_14: 67,
    char_15: 'sparkle',
    app_rate_15: '3.16%',
    avg_round_15: 32153,
    app_flat_15: 48,
    char_16: 'serval',
    app_rate_16: '2.83%',
    avg_round_16: 34783,
    app_flat_16: 67,
    char_17: 'argenti',
    app_rate_17: '2.74%',
    avg_round_17: 32884,
    app_flat_17: 71,
    char_18: 'kafka',
    app_rate_18: '2.74%',
    avg_round_18: 29467,
    app_flat_18: 45,
    char_19: 'pela',
    app_rate_19: '2.43%',
    avg_round_19: 34234,
    app_flat_19: 61,
    char_20: 'black-swan',
    app_rate_20: '1.97%',
    avg_round_20: 27480,
    app_flat_20: 32,
    char_21: 'jing-yuan',
    app_rate_21: '1.14%',
    avg_round_21: 29336,
    app_flat_21: 20,
    char_22: 'yunli',
    app_rate_22: '1.05%',
    avg_round_22: 27216,
    app_flat_22: 29,
    char_23: 'bronya',
    app_rate_23: '0.86%',
    avg_round_23: 36594,
    app_flat_23: 13,
    char_24: 'tingyun',
    app_rate_24: '0.67%',
    avg_round_24: 30972,
    app_flat_24: 20,
    char_25: 'clara',
    app_rate_25: '0.62%',
    avg_round_25: 25526,
    app_flat_25: 20,
    char_26: 'aglaea',
    app_rate_26: '0.55%',
    avg_round_26: 33325,
    app_flat_26: 8,
    char_27: 'feixiao',
    app_rate_27: '0.36%',
    avg_round_27: 26048,
    app_flat_27: 7,
    char_28: 'silver-wolf',
    app_rate_28: '0.33%',
    avg_round_28: 29700,
    app_flat_28: 6,
    char_29: 'blade',
    app_rate_29: '0.21%',
    avg_round_29: 25840,
    app_flat_29: 2,
    char_30: 'topaz',
    app_rate_30: '0.17%',
    avg_round_30: 25533,
    app_flat_30: 5
  },
  {
    char: 'ruan-mei',
    app: 37.89,
    char_1: 'tingyun-fugue',
    app_rate_1: '47.71%',
    avg_round_1: 28975,
    app_flat_1: 455,
    char_2: 'lingsha',
    app_rate_2: '40.33%',
    avg_round_2: 30385,
    app_flat_2: 429,
    char_3: 'firefly',
    app_rate_3: '31.68%',
    avg_round_3: 25795,
    app_flat_3: 108,
    char_4: 'herta',
    app_rate_4: '26.9%',
    avg_round_4: 30337,
    app_flat_4: 562,
    char_5: 'the-herta',
    app_rate_5: '17.76%',
    avg_round_5: 35310,
    app_flat_5: 348,
    char_6: 'gallagher',
    app_rate_6: '13.3%',
    avg_round_6: 25494,
    app_flat_6: 209,
    char_7: 'rappa',
    app_rate_7: '13.25%',
    avg_round_7: 29562,
    app_flat_7: 253,
    char_8: 'himeko',
    app_rate_8: '12.26%',
    avg_round_8: 25338,
    app_flat_8: 201,
    char_9: 'trailblazer-harmony',
    app_rate_9: '11.2%',
    avg_round_9: 24681,
    app_flat_9: 56,
    char_10: 'aventurine',
    app_rate_10: '8.32%',
    avg_round_10: 30035,
    app_flat_10: 124,
    char_11: 'trailblazer-remembrance',
    app_rate_11: '7.51%',
    avg_round_11: 33449,
    app_flat_11: 152,
    char_12: 'huohuo',
    app_rate_12: '7.31%',
    avg_round_12: 28814,
    app_flat_12: 100,
    char_13: 'kafka',
    app_rate_13: '7.06%',
    avg_round_13: 24763,
    app_flat_13: 59,
    char_14: 'jade',
    app_rate_14: '6.47%',
    avg_round_14: 32862,
    app_flat_14: 112,
    char_15: 'fu-xuan',
    app_rate_15: '6.34%',
    avg_round_15: 28865,
    app_flat_15: 92,
    char_16: 'sunday',
    app_rate_16: '6.12%',
    avg_round_16: 30396,
    app_flat_16: 96,
    char_17: 'acheron',
    app_rate_17: '5.55%',
    avg_round_17: 31296,
    app_flat_17: 49,
    char_18: 'black-swan',
    app_rate_18: '5.23%',
    avg_round_18: 24514,
    app_flat_18: 35,
    char_19: 'argenti',
    app_rate_19: '4.63%',
    avg_round_19: 31573,
    app_flat_19: 82,
    char_20: 'jing-yuan',
    app_rate_20: '3.12%',
    avg_round_20: 29222,
    app_flat_20: 50,
    char_21: 'luocha',
    app_rate_21: '2.63%',
    avg_round_21: 26882,
    app_flat_21: 44,
    char_22: 'robin',
    app_rate_22: '2.63%',
    avg_round_22: 26886,
    app_flat_22: 47,
    char_23: 'jiaoqiu',
    app_rate_23: '1.88%',
    avg_round_23: 34649,
    app_flat_23: 14,
    char_24: 'clara',
    app_rate_24: '1.29%',
    avg_round_24: 24940,
    app_flat_24: 30,
    char_25: 'pela',
    app_rate_25: '1.24%',
    avg_round_25: 25845,
    app_flat_25: 16,
    char_26: 'tingyun',
    app_rate_26: '1.16%',
    avg_round_26: 30525,
    app_flat_26: 23,
    char_27: 'sparkle',
    app_rate_27: '1.11%',
    avg_round_27: 26160,
    app_flat_27: 14,
    char_28: 'yunli',
    app_rate_28: '1.09%',
    avg_round_28: 25356,
    app_flat_28: 22,
    char_29: 'serval',
    app_rate_29: '0.99%',
    avg_round_29: 28402,
    app_flat_29: 16,
    char_30: 'bronya',
    app_rate_30: '0.87%',
    avg_round_30: 27823,
    app_flat_30: 12
  },
  {
    char: 'fu-xuan',
    app: 28.86,
    char_1: 'acheron',
    app_rate_1: '54.02%',
    avg_round_1: 32213,
    app_flat_1: 581,
    char_2: 'jiaoqiu',
    app_rate_2: '23.61%',
    avg_round_2: 38509,
    app_flat_2: 263,
    char_3: 'sparkle',
    app_rate_3: '23.19%',
    avg_round_3: 30030,
    app_flat_3: 117,
    char_4: 'the-herta',
    app_rate_4: '22.7%',
    avg_round_4: 37033,
    app_flat_4: 361,
    char_5: 'pela',
    app_rate_5: '22.05%',
    avg_round_5: 32978,
    app_flat_5: 325,
    char_6: 'herta',
    app_rate_6: '21.33%',
    avg_round_6: 34344,
    app_flat_6: 326,
    char_7: 'sunday',
    app_rate_7: '17.17%',
    avg_round_7: 31100,
    app_flat_7: 212,
    char_8: 'kafka',
    app_rate_8: '16.1%',
    avg_round_8: 30038,
    app_flat_8: 203,
    char_9: 'robin',
    app_rate_9: '16.1%',
    avg_round_9: 32313,
    app_flat_9: 216,
    char_10: 'black-swan',
    app_rate_10: '12.49%',
    avg_round_10: 28098,
    app_flat_10: 148,
    char_11: 'trailblazer-remembrance',
    app_rate_11: '12.36%',
    avg_round_11: 37402,
    app_flat_11: 193,
    char_12: 'jade',
    app_rate_12: '12.07%',
    avg_round_12: 33901,
    app_flat_12: 161,
    char_13: 'ruan-mei',
    app_rate_13: '8.33%',
    avg_round_13: 28865,
    app_flat_13: 92,
    char_14: 'jing-yuan',
    app_rate_14: '7.93%',
    avg_round_14: 28965,
    app_flat_14: 108,
    char_15: 'silver-wolf',
    app_rate_15: '5.11%',
    avg_round_15: 26089,
    app_flat_15: 59,
    char_16: 'himeko',
    app_rate_16: '4.07%',
    avg_round_16: 25020,
    app_flat_16: 52,
    char_17: 'tingyun-fugue',
    app_rate_17: '3.74%',
    avg_round_17: 27195,
    app_flat_17: 64,
    char_18: 'serval',
    app_rate_18: '2.89%',
    avg_round_18: 26771,
    app_flat_18: 44,
    char_19: 'argenti',
    app_rate_19: '2.83%',
    avg_round_19: 32247,
    app_flat_19: 42,
    char_20: 'tingyun',
    app_rate_20: '2.24%',
    avg_round_20: 27226,
    app_flat_20: 33,
    char_21: 'bronya',
    app_rate_21: '2.15%',
    avg_round_21: 25537,
    app_flat_21: 11,
    char_22: 'yunli',
    app_rate_22: '1.79%',
    avg_round_22: 25123,
    app_flat_22: 25,
    char_23: 'clara',
    app_rate_23: '0.91%',
    avg_round_23: 24330,
    app_flat_23: 14,
    char_24: 'aglaea',
    app_rate_24: '0.68%',
    avg_round_24: 33640,
    app_flat_24: 2,
    char_25: 'feixiao',
    app_rate_25: '0.59%',
    avg_round_25: 23260,
    app_flat_25: 2,
    char_26: 'guinaifen',
    app_rate_26: '0.59%',
    avg_round_26: 26580,
    app_flat_26: 10,
    char_27: 'rappa',
    app_rate_27: '0.42%',
    avg_round_27: 24480,
    app_flat_27: 6,
    char_28: 'blade',
    app_rate_28: '0.33%',
    avg_round_28: 28733,
    app_flat_28: 3,
    char_29: 'seele',
    app_rate_29: '0.29%',
    avg_round_29: 23920,
    app_flat_29: 1,
    char_30: 'qingque',
    app_rate_30: '0.29%',
    avg_round_30: 21760,
    app_flat_30: 2
  },
  {
    char: 'jiaoqiu',
    app: 27.96,
    char_1: 'acheron',
    app_rate_1: '96.81%',
    avg_round_1: 38845,
    app_flat_1: 1171,
    char_2: 'aventurine',
    app_rate_2: '48.1%',
    avg_round_2: 39123,
    app_flat_2: 558,
    char_3: 'pela',
    app_rate_3: '28.26%',
    avg_round_3: 39516,
    app_flat_3: 614,
    char_4: 'sparkle',
    app_rate_4: '26.59%',
    avg_round_4: 35640,
    app_flat_4: 80,
    char_5: 'fu-xuan',
    app_rate_5: '24.37%',
    avg_round_5: 38509,
    app_flat_5: 263,
    char_6: 'gallagher',
    app_rate_6: '14.8%',
    avg_round_6: 39092,
    app_flat_6: 236,
    char_7: 'sunday',
    app_rate_7: '13.53%',
    avg_round_7: 38036,
    app_flat_7: 91,
    char_8: 'kafka',
    app_rate_8: '6.48%',
    avg_round_8: 39268,
    app_flat_8: 99,
    char_9: 'black-swan',
    app_rate_9: '5.54%',
    avg_round_9: 34941,
    app_flat_9: 75,
    char_10: 'lingsha',
    app_rate_10: '5.07%',
    avg_round_10: 38319,
    app_flat_10: 67,
    char_11: 'tingyun-fugue',
    app_rate_11: '4.93%',
    avg_round_11: 38547,
    app_flat_11: 104,
    char_12: 'robin',
    app_rate_12: '4.93%',
    avg_round_12: 36627,
    app_flat_12: 44,
    char_13: 'bronya',
    app_rate_13: '2.92%',
    avg_round_13: 29632,
    app_flat_13: 5,
    char_14: 'ruan-mei',
    app_rate_14: '2.55%',
    avg_round_14: 34649,
    app_flat_14: 14,
    char_15: 'huohuo',
    app_rate_15: '2.32%',
    avg_round_15: 34091,
    app_flat_15: 22,
    char_16: 'trailblazer-remembrance',
    app_rate_16: '2.28%',
    avg_round_16: 37025,
    app_flat_16: 16,
    char_17: 'luocha',
    app_rate_17: '2.11%',
    avg_round_17: 34482,
    app_flat_17: 24,
    char_18: 'silver-wolf',
    app_rate_18: '2.05%',
    avg_round_18: 34081,
    app_flat_18: 31,
    char_19: 'jade',
    app_rate_19: '1.07%',
    avg_round_19: 35844,
    app_flat_19: 11,
    char_20: 'gepard',
    app_rate_20: '1.07%',
    avg_round_20: 34387,
    app_flat_20: 6,
    char_21: 'jing-yuan',
    app_rate_21: '0.97%',
    avg_round_21: 31980,
    app_flat_21: 4,
    char_22: 'argenti',
    app_rate_22: '0.91%',
    avg_round_22: 29187,
    app_flat_22: 3,
    char_23: 'herta',
    app_rate_23: '0.54%',
    avg_round_23: 34250,
    app_flat_23: 4,
    char_24: 'the-herta',
    app_rate_24: '0.44%',
    avg_round_24: 33920,
    app_flat_24: 6,
    char_25: 'tingyun',
    app_rate_25: '0.2%',
    avg_round_25: 0,
    app_flat_25: 0,
    char_26: 'welt',
    app_rate_26: '0.17%',
    avg_round_26: 34133,
    app_flat_26: 3,
    char_27: 'himeko',
    app_rate_27: '0.13%',
    avg_round_27: 29080,
    app_flat_27: 1,
    char_28: 'yunli',
    app_rate_28: '0.1%',
    avg_round_28: 0,
    app_flat_28: 0,
    char_29: 'guinaifen',
    app_rate_29: '0.1%',
    avg_round_29: 40000,
    app_flat_29: 2,
    char_30: 'bailu',
    app_rate_30: '0.1%',
    avg_round_30: 27140,
    app_flat_30: 2
  },
  {
    char: 'huohuo',
    app: 27.53,
    char_1: 'the-herta',
    app_rate_1: '46.85%',
    avg_round_1: 37740,
    app_flat_1: 866,
    char_2: 'robin',
    app_rate_2: '36.96%',
    avg_round_2: 32500,
    app_flat_2: 509,
    char_3: 'herta',
    app_rate_3: '36.58%',
    avg_round_3: 34882,
    app_flat_3: 691,
    char_4: 'sunday',
    app_rate_4: '31.84%',
    avg_round_4: 33042,
    app_flat_4: 428,
    char_5: 'trailblazer-remembrance',
    app_rate_5: '28.74%',
    avg_round_5: 37823,
    app_flat_5: 572,
    char_6: 'jade',
    app_rate_6: '14.69%',
    avg_round_6: 37551,
    app_flat_6: 232,
    char_7: 'argenti',
    app_rate_7: '13.26%',
    avg_round_7: 33964,
    app_flat_7: 222,
    char_8: 'jing-yuan',
    app_rate_8: '11.18%',
    avg_round_8: 31482,
    app_flat_8: 129,
    char_9: 'ruan-mei',
    app_rate_9: '10.06%',
    avg_round_9: 28814,
    app_flat_9: 100,
    char_10: 'sparkle',
    app_rate_10: '8.9%',
    avg_round_10: 30603,
    app_flat_10: 92,
    char_11: 'kafka',
    app_rate_11: '8.46%',
    avg_round_11: 24738,
    app_flat_11: 50,
    char_12: 'yunli',
    app_rate_12: '8.01%',
    avg_round_12: 26759,
    app_flat_12: 108,
    char_13: 'black-swan',
    app_rate_13: '7.81%',
    avg_round_13: 24710,
    app_flat_13: 46,
    char_14: 'tingyun',
    app_rate_14: '7.77%',
    avg_round_14: 30523,
    app_flat_14: 120,
    char_15: 'acheron',
    app_rate_15: '6.44%',
    avg_round_15: 29505,
    app_flat_15: 55,
    char_16: 'serval',
    app_rate_16: '4.36%',
    avg_round_16: 34088,
    app_flat_16: 68,
    char_17: 'himeko',
    app_rate_17: '3.48%',
    avg_round_17: 25811,
    app_flat_17: 64,
    char_18: 'aglaea',
    app_rate_18: '3.03%',
    avg_round_18: 30071,
    app_flat_18: 26,
    char_19: 'jiaoqiu',
    app_rate_19: '2.35%',
    avg_round_19: 34091,
    app_flat_19: 22,
    char_20: 'clara',
    app_rate_20: '2.18%',
    avg_round_20: 24465,
    app_flat_20: 24,
    char_21: 'tingyun-fugue',
    app_rate_21: '1.33%',
    avg_round_21: 25634,
    app_flat_21: 11,
    char_22: 'pela',
    app_rate_22: '1.19%',
    avg_round_22: 33554,
    app_flat_22: 21,
    char_23: 'bronya',
    app_rate_23: '0.75%',
    avg_round_23: 29227,
    app_flat_23: 9,
    char_24: 'jingliu',
    app_rate_24: '0.75%',
    avg_round_24: 23634,
    app_flat_24: 7,
    char_25: 'imbibitor-lunae',
    app_rate_25: '0.61%',
    avg_round_25: 0,
    app_flat_25: 0,
    char_26: 'rappa',
    app_rate_26: '0.61%',
    avg_round_26: 26712,
    app_flat_26: 5,
    char_27: 'blade',
    app_rate_27: '0.27%',
    avg_round_27: 24000,
    app_flat_27: 1,
    char_28: 'march-7th-swordmaster',
    app_rate_28: '0.24%',
    avg_round_28: 23827,
    app_flat_28: 3,
    char_29: 'topaz',
    app_rate_29: '0.2%',
    avg_round_29: 24040,
    app_flat_29: 2,
    char_30: 'feixiao',
    app_rate_30: '0.2%',
    avg_round_30: 24973,
    app_flat_30: 3
  },
  {
    char: 'jade',
    app: 27.33,
    char_1: 'the-herta',
    app_rate_1: '62.02%',
    avg_round_1: 38919,
    app_flat_1: 1056,
    char_2: 'robin',
    app_rate_2: '45.09%',
    avg_round_2: 37529,
    app_flat_2: 663,
    char_3: 'lingsha',
    app_rate_3: '29.81%',
    avg_round_3: 38941,
    app_flat_3: 492,
    char_4: 'aventurine',
    app_rate_4: '26.41%',
    avg_round_4: 36967,
    app_flat_4: 396,
    char_5: 'trailblazer-remembrance',
    app_rate_5: '22.84%',
    avg_round_5: 39355,
    app_flat_5: 434,
    char_6: 'herta',
    app_rate_6: '17.72%',
    avg_round_6: 33144,
    app_flat_6: 261,
    char_7: 'sunday',
    app_rate_7: '17.14%',
    avg_round_7: 35299,
    app_flat_7: 250,
    char_8: 'huohuo',
    app_rate_8: '14.8%',
    avg_round_8: 37551,
    app_flat_8: 232,
    char_9: 'fu-xuan',
    app_rate_9: '12.74%',
    avg_round_9: 33901,
    app_flat_9: 161,
    char_10: 'ruan-mei',
    app_rate_10: '8.96%',
    avg_round_10: 32862,
    app_flat_10: 112,
    char_11: 'gallagher',
    app_rate_11: '6.77%',
    avg_round_11: 35060,
    app_flat_11: 142,
    char_12: 'jing-yuan',
    app_rate_12: '4.84%',
    avg_round_12: 29531,
    app_flat_12: 61,
    char_13: 'luocha',
    app_rate_13: '4.81%',
    avg_round_13: 34806,
    app_flat_13: 72,
    char_14: 'sparkle',
    app_rate_14: '3.98%',
    avg_round_14: 33849,
    app_flat_14: 38,
    char_15: 'acheron',
    app_rate_15: '2.99%',
    avg_round_15: 31084,
    app_flat_15: 30,
    char_16: 'tingyun-fugue',
    app_rate_16: '2.61%',
    avg_round_16: 31324,
    app_flat_16: 32,
    char_17: 'himeko',
    app_rate_17: '1.96%',
    avg_round_17: 25425,
    app_flat_17: 32,
    char_18: 'feixiao',
    app_rate_18: '1.89%',
    avg_round_18: 27780,
    app_flat_18: 4,
    char_19: 'yunli',
    app_rate_19: '1.68%',
    avg_round_19: 32981,
    app_flat_19: 19,
    char_20: 'bronya',
    app_rate_20: '1.24%',
    avg_round_20: 30699,
    app_flat_20: 19,
    char_21: 'serval',
    app_rate_21: '1.24%',
    avg_round_21: 27611,
    app_flat_21: 21,
    char_22: 'jiaoqiu',
    app_rate_22: '1.1%',
    avg_round_22: 35844,
    app_flat_22: 11,
    char_23: 'kafka',
    app_rate_23: '1.06%',
    avg_round_23: 30222,
    app_flat_23: 11,
    char_24: 'argenti',
    app_rate_24: '1.03%',
    avg_round_24: 34156,
    app_flat_24: 10,
    char_25: 'blade',
    app_rate_25: '0.96%',
    avg_round_25: 26760,
    app_flat_25: 10,
    char_26: 'pela',
    app_rate_26: '0.86%',
    avg_round_26: 31573,
    app_flat_26: 12,
    char_27: 'clara',
    app_rate_27: '0.79%',
    avg_round_27: 26260,
    app_flat_27: 8,
    char_28: 'tingyun',
    app_rate_28: '0.48%',
    avg_round_28: 34600,
    app_flat_28: 9,
    char_29: 'bailu',
    app_rate_29: '0.31%',
    avg_round_29: 37453,
    app_flat_29: 5,
    char_30: 'aglaea',
    app_rate_30: '0.27%',
    avg_round_30: 33960,
    app_flat_30: 2
  },
  {
    char: 'tingyun-fugue',
    app: 24.82,
    char_1: 'ruan-mei',
    app_rate_1: '72.82%',
    avg_round_1: 28975,
    app_flat_1: 455,
    char_2: 'lingsha',
    app_rate_2: '56.67%',
    avg_round_2: 30757,
    app_flat_2: 396,
    char_3: 'firefly',
    app_rate_3: '44.84%',
    avg_round_3: 26163,
    app_flat_3: 100,
    char_4: 'rappa',
    app_rate_4: '20.45%',
    avg_round_4: 29969,
    app_flat_4: 248,
    char_5: 'acheron',
    app_rate_5: '17.77%',
    avg_round_5: 33305,
    app_flat_5: 266,
    char_6: 'gallagher',
    app_rate_6: '13.35%',
    avg_round_6: 25845,
    app_flat_6: 137,
    char_7: 'himeko',
    app_rate_7: '12.89%',
    avg_round_7: 25786,
    app_flat_7: 119,
    char_8: 'trailblazer-harmony',
    app_rate_8: '12.36%',
    avg_round_8: 27625,
    app_flat_8: 39,
    char_9: 'herta',
    app_rate_9: '8.24%',
    avg_round_9: 26281,
    app_flat_9: 112,
    char_10: 'aventurine',
    app_rate_10: '6.62%',
    avg_round_10: 33339,
    app_flat_10: 93,
    char_11: 'jiaoqiu',
    app_rate_11: '5.56%',
    avg_round_11: 38547,
    app_flat_11: 104,
    char_12: 'fu-xuan',
    app_rate_12: '4.35%',
    avg_round_12: 27195,
    app_flat_12: 64,
    char_13: 'pela',
    app_rate_13: '4.23%',
    avg_round_13: 31783,
    app_flat_13: 83,
    char_14: 'jade',
    app_rate_14: '2.87%',
    avg_round_14: 31324,
    app_flat_14: 32,
    char_15: 'kafka',
    app_rate_15: '2.57%',
    avg_round_15: 30389,
    app_flat_15: 35,
    char_16: 'sunday',
    app_rate_16: '2.08%',
    avg_round_16: 27717,
    app_flat_16: 12,
    char_17: 'trailblazer-remembrance',
    app_rate_17: '1.47%',
    avg_round_17: 27862,
    app_flat_17: 17,
    char_18: 'huohuo',
    app_rate_18: '1.47%',
    avg_round_18: 25634,
    app_flat_18: 11,
    char_19: 'the-herta',
    app_rate_19: '1.44%',
    avg_round_19: 29992,
    app_flat_19: 16,
    char_20: 'sparkle',
    app_rate_20: '1.25%',
    avg_round_20: 27552,
    app_flat_20: 7,
    char_21: 'argenti',
    app_rate_21: '0.95%',
    avg_round_21: 30923,
    app_flat_21: 13,
    char_22: 'black-swan',
    app_rate_22: '0.68%',
    avg_round_22: 30867,
    app_flat_22: 6,
    char_23: 'robin',
    app_rate_23: '0.64%',
    avg_round_23: 24440,
    app_flat_23: 4,
    char_24: 'luocha',
    app_rate_24: '0.64%',
    avg_round_24: 25143,
    app_flat_24: 7,
    char_25: 'bronya',
    app_rate_25: '0.57%',
    avg_round_25: 24440,
    app_flat_25: 1,
    char_26: 'boothill',
    app_rate_26: '0.45%',
    avg_round_26: 23760,
    app_flat_26: 1,
    char_27: 'yunli',
    app_rate_27: '0.42%',
    avg_round_27: 25624,
    app_flat_27: 7,
    char_28: 'serval',
    app_rate_28: '0.38%',
    avg_round_28: 24880,
    app_flat_28: 7,
    char_29: 'silver-wolf',
    app_rate_29: '0.38%',
    avg_round_29: 27010,
    app_flat_29: 4,
    char_30: 'march-7th',
    app_rate_30: '0.3%',
    avg_round_30: 26760,
    app_flat_30: 3
  },
  {
    char: 'gallagher',
    app: 24.82,
    char_1: 'the-herta',
    app_rate_1: '39.24%',
    avg_round_1: 37272,
    app_flat_1: 828,
    char_2: 'herta',
    app_rate_2: '38.03%',
    avg_round_2: 34065,
    app_flat_2: 790,
    char_3: 'acheron',
    app_rate_3: '26.88%',
    avg_round_3: 34198,
    app_flat_3: 345,
    char_4: 'trailblazer-remembrance',
    app_rate_4: '24.39%',
    avg_round_4: 37706,
    app_flat_4: 529,
    char_5: 'robin',
    app_rate_5: '20.49%',
    avg_round_5: 33108,
    app_flat_5: 314,
    char_6: 'ruan-mei',
    app_rate_6: '20.3%',
    avg_round_6: 25494,
    app_flat_6: 209,
    char_7: 'jiaoqiu',
    app_rate_7: '16.67%',
    avg_round_7: 39092,
    app_flat_7: 236,
    char_8: 'sunday',
    app_rate_8: '16.07%',
    avg_round_8: 34048,
    app_flat_8: 230,
    char_9: 'tingyun-fugue',
    app_rate_9: '13.35%',
    avg_round_9: 25845,
    app_flat_9: 137,
    char_10: 'pela',
    app_rate_10: '11.23%',
    avg_round_10: 34251,
    app_flat_10: 196,
    char_11: 'firefly',
    app_rate_11: '8.2%',
    avg_round_11: 23309,
    app_flat_11: 21,
    char_12: 'kafka',
    app_rate_12: '8.05%',
    avg_round_12: 30709,
    app_flat_12: 101,
    char_13: 'jade',
    app_rate_13: '7.45%',
    avg_round_13: 35060,
    app_flat_13: 142,
    char_14: 'himeko',
    app_rate_14: '6.2%',
    avg_round_14: 24784,
    app_flat_14: 105,
    char_15: 'black-swan',
    app_rate_15: '6.16%',
    avg_round_15: 26956,
    app_flat_15: 70,
    char_16: 'sparkle',
    app_rate_16: '6.09%',
    avg_round_16: 31804,
    app_flat_16: 34,
    char_17: 'jing-yuan',
    app_rate_17: '4.8%',
    avg_round_17: 31619,
    app_flat_17: 55,
    char_18: 'argenti',
    app_rate_18: '4.39%',
    avg_round_18: 33996,
    app_flat_18: 83,
    char_19: 'rappa',
    app_rate_19: '4.31%',
    avg_round_19: 26035,
    app_flat_19: 71,
    char_20: 'serval',
    app_rate_20: '3.82%',
    avg_round_20: 33150,
    app_flat_20: 67,
    char_21: 'trailblazer-harmony',
    app_rate_21: '3.63%',
    avg_round_21: 24384,
    app_flat_21: 20,
    char_22: 'tingyun',
    app_rate_22: '2.16%',
    avg_round_22: 28031,
    app_flat_22: 31,
    char_23: 'bronya',
    app_rate_23: '1.97%',
    avg_round_23: 28131,
    app_flat_23: 14,
    char_24: 'silver-wolf',
    app_rate_24: '1.17%',
    avg_round_24: 24973,
    app_flat_24: 17,
    char_25: 'clara',
    app_rate_25: '1.13%',
    avg_round_25: 24595,
    app_flat_25: 17,
    char_26: 'aglaea',
    app_rate_26: '0.72%',
    avg_round_26: 34540,
    app_flat_26: 8,
    char_27: 'yunli',
    app_rate_27: '0.72%',
    avg_round_27: 26110,
    app_flat_27: 6,
    char_28: 'feixiao',
    app_rate_28: '0.68%',
    avg_round_28: 26080,
    app_flat_28: 9,
    char_29: 'welt',
    app_rate_29: '0.34%',
    avg_round_29: 24247,
    app_flat_29: 6,
    char_30: 'jingliu',
    app_rate_30: '0.26%',
    avg_round_30: 22800,
    app_flat_30: 2
  },
  {
    char: 'sparkle',
    app: 21.14,
    char_1: 'acheron',
    app_rate_1: '59.86%',
    avg_round_1: 32292,
    app_flat_1: 143,
    char_2: 'jiaoqiu',
    app_rate_2: '35.17%',
    avg_round_2: 35640,
    app_flat_2: 80,
    char_3: 'aventurine',
    app_rate_3: '32.5%',
    avg_round_3: 31430,
    app_flat_3: 135,
    char_4: 'fu-xuan',
    app_rate_4: '31.66%',
    avg_round_4: 30030,
    app_flat_4: 117,
    char_5: 'pela',
    app_rate_5: '15.28%',
    avg_round_5: 28314,
    app_flat_5: 44,
    char_6: 'herta',
    app_rate_6: '12.61%',
    avg_round_6: 30490,
    app_flat_6: 98,
    char_7: 'huohuo',
    app_rate_7: '11.59%',
    avg_round_7: 30603,
    app_flat_7: 92,
    char_8: 'sunday',
    app_rate_8: '11.37%',
    avg_round_8: 28951,
    app_flat_8: 93,
    char_9: 'the-herta',
    app_rate_9: '10.83%',
    avg_round_9: 34927,
    app_flat_9: 87,
    char_10: 'argenti',
    app_rate_10: '8.88%',
    avg_round_10: 31536,
    app_flat_10: 114,
    char_11: 'gallagher',
    app_rate_11: '7.15%',
    avg_round_11: 31804,
    app_flat_11: 34,
    char_12: 'jing-yuan',
    app_rate_12: '6.71%',
    avg_round_12: 26265,
    app_flat_12: 55,
    char_13: 'tingyun',
    app_rate_13: '5.95%',
    avg_round_13: 28213,
    app_flat_13: 73,
    char_14: 'lingsha',
    app_rate_14: '5.91%',
    avg_round_14: 32153,
    app_flat_14: 48,
    char_15: 'robin',
    app_rate_15: '5.91%',
    avg_round_15: 26423,
    app_flat_15: 28,
    char_16: 'jade',
    app_rate_16: '5.15%',
    avg_round_16: 33849,
    app_flat_16: 38,
    char_17: 'trailblazer-remembrance',
    app_rate_17: '4.13%',
    avg_round_17: 30855,
    app_flat_17: 38,
    char_18: 'yunli',
    app_rate_18: '4.04%',
    avg_round_18: 25698,
    app_flat_18: 23,
    char_19: 'silver-wolf',
    app_rate_19: '3.2%',
    avg_round_19: 23160,
    app_flat_19: 2,
    char_20: 'luocha',
    app_rate_20: '2.84%',
    avg_round_20: 26640,
    app_flat_20: 22,
    char_21: 'kafka',
    app_rate_21: '2.71%',
    avg_round_21: 26120,
    app_flat_21: 7,
    char_22: 'ruan-mei',
    app_rate_22: '2.0%',
    avg_round_22: 26160,
    app_flat_22: 14,
    char_23: 'clara',
    app_rate_23: '1.95%',
    avg_round_23: 24167,
    app_flat_23: 22,
    char_24: 'black-swan',
    app_rate_24: '1.69%',
    avg_round_24: 27320,
    app_flat_24: 3,
    char_25: 'gepard',
    app_rate_25: '1.64%',
    avg_round_25: 24140,
    app_flat_25: 4,
    char_26: 'tingyun-fugue',
    app_rate_26: '1.47%',
    avg_round_26: 27552,
    app_flat_26: 7,
    char_27: 'himeko',
    app_rate_27: '1.42%',
    avg_round_27: 23977,
    app_flat_27: 11,
    char_28: 'imbibitor-lunae',
    app_rate_28: '0.98%',
    avg_round_28: 0,
    app_flat_28: 0,
    char_29: 'serval',
    app_rate_29: '0.89%',
    avg_round_29: 24160,
    app_flat_29: 4,
    char_30: 'bronya',
    app_rate_30: '0.89%',
    avg_round_30: 29000,
    app_flat_30: 8
  },
  {
    char: 'pela',
    app: 20.99,
    char_1: 'acheron',
    app_rate_1: '95.75%',
    avg_round_1: 33969,
    app_flat_1: 1146,
    char_2: 'aventurine',
    app_rate_2: '41.1%',
    avg_round_2: 34671,
    app_flat_2: 497,
    char_3: 'jiaoqiu',
    app_rate_3: '37.66%',
    avg_round_3: 39516,
    app_flat_3: 614,
    char_4: 'fu-xuan',
    app_rate_4: '30.32%',
    avg_round_4: 32978,
    app_flat_4: 325,
    char_5: 'sparkle',
    app_rate_5: '15.38%',
    avg_round_5: 28314,
    app_flat_5: 44,
    char_6: 'gallagher',
    app_rate_6: '13.28%',
    avg_round_6: 34251,
    app_flat_6: 196,
    char_7: 'silver-wolf',
    app_rate_7: '7.6%',
    avg_round_7: 26016,
    app_flat_7: 115,
    char_8: 'kafka',
    app_rate_8: '7.47%',
    avg_round_8: 32722,
    app_flat_8: 102,
    char_9: 'sunday',
    app_rate_9: '7.11%',
    avg_round_9: 30103,
    app_flat_9: 37,
    char_10: 'tingyun-fugue',
    app_rate_10: '5.01%',
    avg_round_10: 31783,
    app_flat_10: 83,
    char_11: 'black-swan',
    app_rate_11: '4.56%',
    avg_round_11: 27145,
    app_flat_11: 59,
    char_12: 'lingsha',
    app_rate_12: '4.56%',
    avg_round_12: 34234,
    app_flat_12: 61,
    char_13: 'gepard',
    app_rate_13: '3.44%',
    avg_round_13: 29618,
    app_flat_13: 29,
    char_14: 'trailblazer-remembrance',
    app_rate_14: '3.04%',
    avg_round_14: 32243,
    app_flat_14: 27,
    char_15: 'robin',
    app_rate_15: '2.86%',
    avg_round_15: 28156,
    app_flat_15: 19,
    char_16: 'bronya',
    app_rate_16: '2.82%',
    avg_round_16: 24720,
    app_flat_16: 6,
    char_17: 'herta',
    app_rate_17: '2.73%',
    avg_round_17: 28665,
    app_flat_17: 33,
    char_18: 'the-herta',
    app_rate_18: '2.55%',
    avg_round_18: 31679,
    app_flat_18: 28,
    char_19: 'ruan-mei',
    app_rate_19: '2.24%',
    avg_round_19: 25845,
    app_flat_19: 16,
    char_20: 'guinaifen',
    app_rate_20: '1.79%',
    avg_round_20: 25357,
    app_flat_20: 28,
    char_21: 'huohuo',
    app_rate_21: '1.57%',
    avg_round_21: 33554,
    app_flat_21: 21,
    char_22: 'luocha',
    app_rate_22: '1.34%',
    avg_round_22: 31421,
    app_flat_22: 15,
    char_23: 'march-7th',
    app_rate_23: '1.25%',
    avg_round_23: 33695,
    app_flat_23: 16,
    char_24: 'jade',
    app_rate_24: '1.12%',
    avg_round_24: 31573,
    app_flat_24: 12,
    char_25: 'welt',
    app_rate_25: '0.81%',
    avg_round_25: 24060,
    app_flat_25: 12,
    char_26: 'serval',
    app_rate_26: '0.49%',
    avg_round_26: 24180,
    app_flat_26: 6,
    char_27: 'himeko',
    app_rate_27: '0.45%',
    avg_round_27: 24760,
    app_flat_27: 6,
    char_28: 'argenti',
    app_rate_28: '0.27%',
    avg_round_28: 27460,
    app_flat_28: 4,
    char_29: 'bailu',
    app_rate_29: '0.22%',
    avg_round_29: 23940,
    app_flat_29: 4,
    char_30: 'trailblazer-harmony',
    app_rate_30: '0.18%',
    avg_round_30: 23400,
    app_flat_30: 2
  },
  {
    char: 'kafka',
    app: 16.91,
    char_1: 'acheron',
    app_rate_1: '82.19%',
    avg_round_1: 31032,
    app_flat_1: 640,
    char_2: 'black-swan',
    app_rate_2: '63.04%',
    avg_round_2: 27745,
    app_flat_2: 401,
    char_3: 'aventurine',
    app_rate_3: '29.8%',
    avg_round_3: 32069,
    app_flat_3: 252,
    char_4: 'fu-xuan',
    app_rate_4: '27.47%',
    avg_round_4: 30038,
    app_flat_4: 203,
    char_5: 'ruan-mei',
    app_rate_5: '15.82%',
    avg_round_5: 24763,
    app_flat_5: 59,
    char_6: 'huohuo',
    app_rate_6: '13.76%',
    avg_round_6: 24738,
    app_flat_6: 50,
    char_7: 'gallagher',
    app_rate_7: '11.82%',
    avg_round_7: 30709,
    app_flat_7: 101,
    char_8: 'jiaoqiu',
    app_rate_8: '10.71%',
    avg_round_8: 39268,
    app_flat_8: 99,
    char_9: 'pela',
    app_rate_9: '9.27%',
    avg_round_9: 32722,
    app_flat_9: 102,
    char_10: 'lingsha',
    app_rate_10: '6.38%',
    avg_round_10: 29467,
    app_flat_10: 45,
    char_11: 'robin',
    app_rate_11: '5.77%',
    avg_round_11: 29127,
    app_flat_11: 28,
    char_12: 'luocha',
    app_rate_12: '3.77%',
    avg_round_12: 25173,
    app_flat_12: 22,
    char_13: 'tingyun-fugue',
    app_rate_13: '3.77%',
    avg_round_13: 30389,
    app_flat_13: 35,
    char_14: 'sparkle',
    app_rate_14: '3.39%',
    avg_round_14: 26120,
    app_flat_14: 7,
    char_15: 'gepard',
    app_rate_15: '2.72%',
    avg_round_15: 31158,
    app_flat_15: 19,
    char_16: 'jade',
    app_rate_16: '1.72%',
    avg_round_16: 30222,
    app_flat_16: 11,
    char_17: 'sunday',
    app_rate_17: '1.39%',
    avg_round_17: 26300,
    app_flat_17: 6,
    char_18: 'trailblazer-remembrance',
    app_rate_18: '1.39%',
    avg_round_18: 33009,
    app_flat_18: 9,
    char_19: 'silver-wolf',
    app_rate_19: '1.05%',
    avg_round_19: 28076,
    app_flat_19: 10,
    char_20: 'bailu',
    app_rate_20: '0.67%',
    avg_round_20: 23453,
    app_flat_20: 3,
    char_21: 'tingyun',
    app_rate_21: '0.5%',
    avg_round_21: 25453,
    app_flat_21: 3,
    char_22: 'serval',
    app_rate_22: '0.5%',
    avg_round_22: 24027,
    app_flat_22: 5,
    char_23: 'himeko',
    app_rate_23: '0.44%',
    avg_round_23: 24387,
    app_flat_23: 3,
    char_24: 'bronya',
    app_rate_24: '0.39%',
    avg_round_24: 24280,
    app_flat_24: 1,
    char_25: 'herta',
    app_rate_25: '0.33%',
    avg_round_25: 24140,
    app_flat_25: 4,
    char_26: 'march-7th',
    app_rate_26: '0.28%',
    avg_round_26: 40000,
    app_flat_26: 2,
    char_27: 'guinaifen',
    app_rate_27: '0.22%',
    avg_round_27: 27020,
    app_flat_27: 2,
    char_28: 'lynx',
    app_rate_28: '0.17%',
    avg_round_28: 0,
    app_flat_28: 0,
    char_29: 'natasha',
    app_rate_29: '0.17%',
    avg_round_29: 0,
    app_flat_29: 0,
    char_30: 'asta',
    app_rate_30: '0.17%',
    avg_round_30: 0,
    app_flat_30: 0
  },
  {
    char: 'jing-yuan',
    app: 14.65,
    char_1: 'sunday',
    app_rate_1: '97.37%',
    avg_round_1: 31044,
    app_flat_1: 612,
    char_2: 'robin',
    app_rate_2: '61.63%',
    avg_round_2: 32311,
    app_flat_2: 388,
    char_3: 'aventurine',
    app_rate_3: '40.94%',
    avg_round_3: 31478,
    app_flat_3: 247,
    char_4: 'huohuo',
    app_rate_4: '21.01%',
    avg_round_4: 31482,
    app_flat_4: 129,
    char_5: 'fu-xuan',
    app_rate_5: '15.63%',
    avg_round_5: 28965,
    app_flat_5: 108,
    char_6: 'sparkle',
    app_rate_6: '9.67%',
    avg_round_6: 26265,
    app_flat_6: 55,
    char_7: 'jade',
    app_rate_7: '9.03%',
    avg_round_7: 29531,
    app_flat_7: 61,
    char_8: 'gallagher',
    app_rate_8: '8.14%',
    avg_round_8: 31619,
    app_flat_8: 55,
    char_9: 'ruan-mei',
    app_rate_9: '8.07%',
    avg_round_9: 29222,
    app_flat_9: 50,
    char_10: 'luocha',
    app_rate_10: '8.01%',
    avg_round_10: 30077,
    app_flat_10: 47,
    char_11: 'tingyun',
    app_rate_11: '5.89%',
    avg_round_11: 28838,
    app_flat_11: 44,
    char_12: 'trailblazer-remembrance',
    app_rate_12: '5.19%',
    avg_round_12: 29375,
    app_flat_12: 35,
    char_13: 'lingsha',
    app_rate_13: '3.07%',
    avg_round_13: 29336,
    app_flat_13: 20,
    char_14: 'bronya',
    app_rate_14: '2.24%',
    avg_round_14: 30568,
    app_flat_14: 10,
    char_15: 'jiaoqiu',
    app_rate_15: '1.86%',
    avg_round_15: 31980,
    app_flat_15: 4,
    char_16: 'the-herta',
    app_rate_16: '0.58%',
    avg_round_16: 31900,
    app_flat_16: 4,
    char_17: 'herta',
    app_rate_17: '0.45%',
    avg_round_17: 0,
    app_flat_17: 0,
    char_18: 'bailu',
    app_rate_18: '0.26%',
    avg_round_18: 30000,
    app_flat_18: 2,
    char_19: 'gepard',
    app_rate_19: '0.19%',
    avg_round_19: 29307,
    app_flat_19: 3,
    char_20: 'himeko',
    app_rate_20: '0.13%',
    avg_round_20: 24180,
    app_flat_20: 2,
    char_21: 'tingyun-fugue',
    app_rate_21: '0.06%',
    avg_round_21: 0,
    app_flat_21: 0,
    char_22: 'moze',
    app_rate_22: '0.06%',
    avg_round_22: 24720,
    app_flat_22: 1,
    char_23: 'kafka',
    app_rate_23: '0.06%',
    avg_round_23: 0,
    app_flat_23: 0,
    char_24: 'pela',
    app_rate_24: '0.06%',
    avg_round_24: 0,
    app_flat_24: 0,
    char_25: 'sampo',
    app_rate_25: '0.06%',
    avg_round_25: 0,
    app_flat_25: 0,
    char_26: 'lynx',
    app_rate_26: '0.06%',
    avg_round_26: 27240,
    app_flat_26: 1,
    char_27: 'topaz',
    app_rate_27: '0.06%',
    avg_round_27: 24000,
    app_flat_27: 1,
    char_28: 'asta',
    app_rate_28: '0.06%',
    avg_round_28: 32240,
    app_flat_28: 1,
    char_29: 'blade',
    app_rate_29: '0.06%',
    avg_round_29: 0,
    app_flat_29: 0,
    char_30: 'serval',
    app_rate_30: '0.06%',
    avg_round_30: 24160,
    app_flat_30: 1
  },
  {
    char: 'black-swan',
    app: 14.05,
    char_1: 'acheron',
    app_rate_1: '82.23%',
    avg_round_1: 29734,
    app_flat_1: 512,
    char_2: 'kafka',
    app_rate_2: '75.89%',
    avg_round_2: 27745,
    app_flat_2: 401,
    char_3: 'aventurine',
    app_rate_3: '32.33%',
    avg_round_3: 31110,
    app_flat_3: 218,
    char_4: 'fu-xuan',
    app_rate_4: '25.65%',
    avg_round_4: 28098,
    app_flat_4: 148,
    char_5: 'huohuo',
    app_rate_5: '15.3%',
    avg_round_5: 24710,
    app_flat_5: 46,
    char_6: 'ruan-mei',
    app_rate_6: '14.09%',
    avg_round_6: 24514,
    app_flat_6: 35,
    char_7: 'jiaoqiu',
    app_rate_7: '11.02%',
    avg_round_7: 34941,
    app_flat_7: 75,
    char_8: 'gallagher',
    app_rate_8: '10.89%',
    avg_round_8: 26956,
    app_flat_8: 70,
    char_9: 'pela',
    app_rate_9: '6.81%',
    avg_round_9: 27145,
    app_flat_9: 59,
    char_10: 'lingsha',
    app_rate_10: '5.54%',
    avg_round_10: 27480,
    app_flat_10: 32,
    char_11: 'robin',
    app_rate_11: '5.01%',
    avg_round_11: 24381,
    app_flat_11: 15,
    char_12: 'luocha',
    app_rate_12: '3.87%',
    avg_round_12: 24904,
    app_flat_12: 18,
    char_13: 'gepard',
    app_rate_13: '2.74%',
    avg_round_13: 29828,
    app_flat_13: 17,
    char_14: 'sparkle',
    app_rate_14: '2.54%',
    avg_round_14: 27320,
    app_flat_14: 3,
    char_15: 'tingyun-fugue',
    app_rate_15: '1.2%',
    avg_round_15: 30867,
    app_flat_15: 6,
    char_16: 'sunday',
    app_rate_16: '0.87%',
    avg_round_16: 26640,
    app_flat_16: 1,
    char_17: 'bailu',
    app_rate_17: '0.8%',
    avg_round_17: 23240,
    app_flat_17: 2,
    char_18: 'trailblazer-remembrance',
    app_rate_18: '0.53%',
    avg_round_18: 26080,
    app_flat_18: 1,
    char_19: 'bronya',
    app_rate_19: '0.4%',
    avg_round_19: 0,
    app_flat_19: 0,
    char_20: 'silver-wolf',
    app_rate_20: '0.33%',
    avg_round_20: 26093,
    app_flat_20: 3,
    char_21: 'tingyun',
    app_rate_21: '0.27%',
    avg_round_21: 0,
    app_flat_21: 0,
    char_22: 'march-7th',
    app_rate_22: '0.27%',
    avg_round_22: 28420,
    app_flat_22: 2,
    char_23: 'lynx',
    app_rate_23: '0.2%',
    avg_round_23: 0,
    app_flat_23: 0,
    char_24: 'jade',
    app_rate_24: '0.2%',
    avg_round_24: 0,
    app_flat_24: 0,
    char_25: 'natasha',
    app_rate_25: '0.13%',
    avg_round_25: 0,
    app_flat_25: 0,
    char_26: 'himeko',
    app_rate_26: '0.13%',
    avg_round_26: 24400,
    app_flat_26: 1,
    char_27: 'asta',
    app_rate_27: '0.13%',
    avg_round_27: 0,
    app_flat_27: 0,
    char_28: 'herta',
    app_rate_28: '0.07%',
    avg_round_28: 24160,
    app_flat_28: 1,
    char_29: 'trailblazer-preservation',
    app_rate_29: '0.07%',
    avg_round_29: 0,
    app_flat_29: 0,
    char_30: 'yunli',
    app_rate_30: '0.07%',
    avg_round_30: 0,
    app_flat_30: 0
  },
  {
    char: 'firefly',
    app: 13.26,
    char_1: 'ruan-mei',
    app_rate_1: '90.52%',
    avg_round_1: 25795,
    app_flat_1: 108,
    char_2: 'tingyun-fugue',
    app_rate_2: '83.93%',
    avg_round_2: 26163,
    app_flat_2: 100,
    char_3: 'lingsha',
    app_rate_3: '64.69%',
    avg_round_3: 26259,
    app_flat_3: 82,
    char_4: 'trailblazer-harmony',
    app_rate_4: '28.52%',
    avg_round_4: 22645,
    app_flat_4: 16,
    char_5: 'gallagher',
    app_rate_5: '15.36%',
    avg_round_5: 23309,
    app_flat_5: 21,
    char_6: 'himeko',
    app_rate_6: '7.57%',
    avg_round_6: 24711,
    app_flat_6: 13,
    char_7: 'herta',
    app_rate_7: '2.19%',
    avg_round_7: 24160,
    app_flat_7: 4,
    char_8: 'rappa',
    app_rate_8: '1.91%',
    avg_round_8: 25000,
    app_flat_8: 4,
    char_9: 'acheron',
    app_rate_9: '0.92%',
    avg_round_9: 24160,
    app_flat_9: 1,
    char_10: 'bronya',
    app_rate_10: '0.85%',
    avg_round_10: 0,
    app_flat_10: 0,
    char_11: 'aventurine',
    app_rate_11: '0.71%',
    avg_round_11: 0,
    app_flat_11: 0,
    char_12: 'trailblazer-remembrance',
    app_rate_12: '0.64%',
    avg_round_12: 0,
    app_flat_12: 0,
    char_13: 'fu-xuan',
    app_rate_13: '0.57%',
    avg_round_13: 20240,
    app_flat_13: 1,
    char_14: 'robin',
    app_rate_14: '0.28%',
    avg_round_14: 0,
    app_flat_14: 0,
    char_15: 'huohuo',
    app_rate_15: '0.21%',
    avg_round_15: 0,
    app_flat_15: 0,
    char_16: 'sunday',
    app_rate_16: '0.14%',
    avg_round_16: 0,
    app_flat_16: 0,
    char_17: 'pela',
    app_rate_17: '0.14%',
    avg_round_17: 0,
    app_flat_17: 0,
    char_18: 'luocha',
    app_rate_18: '0.14%',
    avg_round_18: 0,
    app_flat_18: 0,
    char_19: 'kafka',
    app_rate_19: '0.07%',
    avg_round_19: 0,
    app_flat_19: 0,
    char_20: 'bailu',
    app_rate_20: '0.07%',
    avg_round_20: 24160,
    app_flat_20: 1,
    char_21: 'argenti',
    app_rate_21: '0.07%',
    avg_round_21: 0,
    app_flat_21: 0,
    char_22: 'sparkle',
    app_rate_22: '0.07%',
    avg_round_22: 0,
    app_flat_22: 0,
    char_23: 'asta',
    app_rate_23: '0.07%',
    avg_round_23: 0,
    app_flat_23: 0,
    char_24: 'jiaoqiu',
    app_rate_24: '0.07%',
    avg_round_24: 0,
    app_flat_24: 0,
    char_25: 'clara',
    app_rate_25: '0.07%',
    avg_round_25: 0,
    app_flat_25: 0,
    char_26: 'guinaifen',
    app_rate_26: '0.07%',
    avg_round_26: 0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'argenti',
    app: 11.81,
    char_1: 'sunday',
    app_rate_1: '39.98%',
    avg_round_1: 33325,
    app_flat_1: 263,
    char_2: 'trailblazer-remembrance',
    app_rate_2: '35.77%',
    avg_round_2: 35198,
    app_flat_2: 282,
    char_3: 'the-herta',
    app_rate_3: '32.67%',
    avg_round_3: 35878,
    app_flat_3: 240,
    char_4: 'huohuo',
    app_rate_4: '30.92%',
    avg_round_4: 33964,
    app_flat_4: 222,
    char_5: 'robin',
    app_rate_5: '26.23%',
    avg_round_5: 32303,
    app_flat_5: 150,
    char_6: 'tingyun',
    app_rate_6: '23.61%',
    avg_round_6: 31748,
    app_flat_6: 174,
    char_7: 'aventurine',
    app_rate_7: '20.43%',
    avg_round_7: 31681,
    app_flat_7: 127,
    char_8: 'sparkle',
    app_rate_8: '15.9%',
    avg_round_8: 31536,
    app_flat_8: 114,
    char_9: 'ruan-mei',
    app_rate_9: '14.86%',
    avg_round_9: 31573,
    app_flat_9: 82,
    char_10: 'luocha',
    app_rate_10: '11.13%',
    avg_round_10: 32395,
    app_flat_10: 75,
    char_11: 'gallagher',
    app_rate_11: '9.22%',
    avg_round_11: 33996,
    app_flat_11: 83,
    char_12: 'lingsha',
    app_rate_12: '9.14%',
    avg_round_12: 32884,
    app_flat_12: 71,
    char_13: 'herta',
    app_rate_13: '8.82%',
    avg_round_13: 27335,
    app_flat_13: 69,
    char_14: 'fu-xuan',
    app_rate_14: '6.92%',
    avg_round_14: 32247,
    app_flat_14: 42,
    char_15: 'bronya',
    app_rate_15: '3.9%',
    avg_round_15: 28874,
    app_flat_15: 33,
    char_16: 'jade',
    app_rate_16: '2.38%',
    avg_round_16: 34156,
    app_flat_16: 10,
    char_17: 'jiaoqiu',
    app_rate_17: '2.15%',
    avg_round_17: 29187,
    app_flat_17: 3,
    char_18: 'tingyun-fugue',
    app_rate_18: '1.99%',
    avg_round_18: 30923,
    app_flat_18: 13,
    char_19: 'himeko',
    app_rate_19: '1.19%',
    avg_round_19: 24800,
    app_flat_19: 5,
    char_20: 'hanya',
    app_rate_20: '0.48%',
    avg_round_20: 26660,
    app_flat_20: 2,
    char_21: 'pela',
    app_rate_21: '0.48%',
    avg_round_21: 27460,
    app_flat_21: 4,
    char_22: 'trailblazer-harmony',
    app_rate_22: '0.24%',
    avg_round_22: 25620,
    app_flat_22: 2,
    char_23: 'yunli',
    app_rate_23: '0.24%',
    avg_round_23: 24800,
    app_flat_23: 1,
    char_24: 'lynx',
    app_rate_24: '0.16%',
    avg_round_24: 40000,
    app_flat_24: 1,
    char_25: 'welt',
    app_rate_25: '0.16%',
    avg_round_25: 0,
    app_flat_25: 0,
    char_26: 'natasha',
    app_rate_26: '0.16%',
    avg_round_26: 27720,
    app_flat_26: 2,
    char_27: 'gepard',
    app_rate_27: '0.16%',
    avg_round_27: 24200,
    app_flat_27: 1,
    char_28: 'boothill',
    app_rate_28: '0.08%',
    avg_round_28: 0,
    app_flat_28: 0,
    char_29: 'jingliu',
    app_rate_29: '0.08%',
    avg_round_29: 25360,
    app_flat_29: 1,
    char_30: 'xueyi',
    app_rate_30: '0.08%',
    avg_round_30: 24160,
    app_flat_30: 1
  },
  {
    char: 'himeko',
    app: 11.06,
    char_1: 'herta',
    app_rate_1: '59.42%',
    avg_round_1: 25189,
    app_flat_1: 421,
    char_2: 'ruan-mei',
    app_rate_2: '42.02%',
    avg_round_2: 25338,
    app_flat_2: 201,
    char_3: 'tingyun-fugue',
    app_rate_3: '28.95%',
    avg_round_3: 25786,
    app_flat_3: 119,
    char_4: 'the-herta',
    app_rate_4: '26.57%',
    avg_round_4: 32590,
    app_flat_4: 185,
    char_5: 'robin',
    app_rate_5: '23.17%',
    avg_round_5: 25342,
    app_flat_5: 172,
    char_6: 'lingsha',
    app_rate_6: '18.0%',
    avg_round_6: 28933,
    app_flat_6: 115,
    char_7: 'aventurine',
    app_rate_7: '17.83%',
    avg_round_7: 25494,
    app_flat_7: 124,
    char_8: 'gallagher',
    app_rate_8: '13.92%',
    avg_round_8: 24784,
    app_flat_8: 105,
    char_9: 'trailblazer-remembrance',
    app_rate_9: '12.73%',
    avg_round_9: 31921,
    app_flat_9: 98,
    char_10: 'fu-xuan',
    app_rate_10: '10.61%',
    avg_round_10: 25020,
    app_flat_10: 52,
    char_11: 'firefly',
    app_rate_11: '9.08%',
    avg_round_11: 24711,
    app_flat_11: 13,
    char_12: 'huohuo',
    app_rate_12: '8.66%',
    avg_round_12: 25811,
    app_flat_12: 64,
    char_13: 'jade',
    app_rate_13: '4.84%',
    avg_round_13: 25425,
    app_flat_13: 32,
    char_14: 'sunday',
    app_rate_14: '3.65%',
    avg_round_14: 32186,
    app_flat_14: 26,
    char_15: 'acheron',
    app_rate_15: '3.31%',
    avg_round_15: 30315,
    app_flat_15: 17,
    char_16: 'luocha',
    app_rate_16: '3.31%',
    avg_round_16: 24597,
    app_flat_16: 22,
    char_17: 'sparkle',
    app_rate_17: '2.72%',
    avg_round_17: 23977,
    app_flat_17: 11,
    char_18: 'trailblazer-harmony',
    app_rate_18: '1.95%',
    avg_round_18: 22120,
    app_flat_18: 2,
    char_19: 'clara',
    app_rate_19: '1.61%',
    avg_round_19: 24235,
    app_flat_19: 16,
    char_20: 'argenti',
    app_rate_20: '1.27%',
    avg_round_20: 24800,
    app_flat_20: 5,
    char_21: 'serval',
    app_rate_21: '1.19%',
    avg_round_21: 24300,
    app_flat_21: 8,
    char_22: 'pela',
    app_rate_22: '0.85%',
    avg_round_22: 24760,
    app_flat_22: 6,
    char_23: 'kafka',
    app_rate_23: '0.68%',
    avg_round_23: 24387,
    app_flat_23: 3,
    char_24: 'rappa',
    app_rate_24: '0.59%',
    avg_round_24: 29720,
    app_flat_24: 1,
    char_25: 'feixiao',
    app_rate_25: '0.42%',
    avg_round_25: 23253,
    app_flat_25: 3,
    char_26: 'jiaoqiu',
    app_rate_26: '0.34%',
    avg_round_26: 29080,
    app_flat_26: 1,
    char_27: 'march-7th',
    app_rate_27: '0.25%',
    avg_round_27: 28540,
    app_flat_27: 2,
    char_28: 'bailu',
    app_rate_28: '0.17%',
    avg_round_28: 24180,
    app_flat_28: 2,
    char_29: 'jing-yuan',
    app_rate_29: '0.17%',
    avg_round_29: 24180,
    app_flat_29: 2,
    char_30: 'tingyun',
    app_rate_30: '0.17%',
    avg_round_30: 0,
    app_flat_30: 0
  },
  {
    char: 'luocha',
    app: 8.15,
    char_1: 'the-herta',
    app_rate_1: '39.86%',
    avg_round_1: 35813,
    app_flat_1: 219,
    char_2: 'sunday',
    app_rate_2: '34.33%',
    avg_round_2: 32573,
    app_flat_2: 140,
    char_3: 'herta',
    app_rate_3: '32.95%',
    avg_round_3: 33484,
    app_flat_3: 171,
    char_4: 'robin',
    app_rate_4: '30.53%',
    avg_round_4: 31575,
    app_flat_4: 110,
    char_5: 'trailblazer-remembrance',
    app_rate_5: '25.35%',
    avg_round_5: 35032,
    app_flat_5: 139,
    char_6: 'jade',
    app_rate_6: '16.13%',
    avg_round_6: 34806,
    app_flat_6: 72,
    char_7: 'argenti',
    app_rate_7: '16.13%',
    avg_round_7: 32395,
    app_flat_7: 75,
    char_8: 'jing-yuan',
    app_rate_8: '14.4%',
    avg_round_8: 30077,
    app_flat_8: 47,
    char_9: 'ruan-mei',
    app_rate_9: '12.21%',
    avg_round_9: 26882,
    app_flat_9: 44,
    char_10: 'acheron',
    app_rate_10: '11.41%',
    avg_round_10: 31574,
    app_flat_10: 35,
    char_11: 'kafka',
    app_rate_11: '7.83%',
    avg_round_11: 25173,
    app_flat_11: 22,
    char_12: 'sparkle',
    app_rate_12: '7.37%',
    avg_round_12: 26640,
    app_flat_12: 22,
    char_13: 'jiaoqiu',
    app_rate_13: '7.26%',
    avg_round_13: 34482,
    app_flat_13: 24,
    char_14: 'black-swan',
    app_rate_14: '6.68%',
    avg_round_14: 24904,
    app_flat_14: 18,
    char_15: 'tingyun',
    app_rate_15: '6.45%',
    avg_round_15: 28706,
    app_flat_15: 31,
    char_16: 'himeko',
    app_rate_16: '4.49%',
    avg_round_16: 24597,
    app_flat_16: 22,
    char_17: 'clara',
    app_rate_17: '4.38%',
    avg_round_17: 24370,
    app_flat_17: 23,
    char_18: 'pela',
    app_rate_18: '3.46%',
    avg_round_18: 31421,
    app_flat_18: 15,
    char_19: 'serval',
    app_rate_19: '3.34%',
    avg_round_19: 28560,
    app_flat_19: 16,
    char_20: 'bronya',
    app_rate_20: '2.88%',
    avg_round_20: 29147,
    app_flat_20: 10,
    char_21: 'yunli',
    app_rate_21: '2.65%',
    avg_round_21: 24640,
    app_flat_21: 12,
    char_22: 'tingyun-fugue',
    app_rate_22: '1.96%',
    avg_round_22: 25143,
    app_flat_22: 7,
    char_23: 'aglaea',
    app_rate_23: '1.61%',
    avg_round_23: 36320,
    app_flat_23: 2,
    char_24: 'imbibitor-lunae',
    app_rate_24: '1.38%',
    avg_round_24: 0,
    app_flat_24: 0,
    char_25: 'blade',
    app_rate_25: '1.27%',
    avg_round_25: 25984,
    app_flat_25: 5,
    char_26: 'jingliu',
    app_rate_26: '1.04%',
    avg_round_26: 23733,
    app_flat_26: 3,
    char_27: 'feixiao',
    app_rate_27: '1.04%',
    avg_round_27: 23200,
    app_flat_27: 1,
    char_28: 'rappa',
    app_rate_28: '0.58%',
    avg_round_28: 25627,
    app_flat_28: 3,
    char_29: 'hanya',
    app_rate_29: '0.23%',
    avg_round_29: 27920,
    app_flat_29: 1,
    char_30: 'silver-wolf',
    app_rate_30: '0.23%',
    avg_round_30: 40000,
    app_flat_30: 1
  },
  {
    char: 'tingyun',
    app: 5.79,
    char_1: 'argenti',
    app_rate_1: '48.14%',
    avg_round_1: 31748,
    app_flat_1: 174,
    char_2: 'sunday',
    app_rate_2: '39.71%',
    avg_round_2: 30782,
    app_flat_2: 132,
    char_3: 'huohuo',
    app_rate_3: '36.95%',
    avg_round_3: 30523,
    app_flat_3: 120,
    char_4: 'sparkle',
    app_rate_4: '21.72%',
    avg_round_4: 28213,
    app_flat_4: 73,
    char_5: 'yunli',
    app_rate_5: '19.61%',
    avg_round_5: 25766,
    app_flat_5: 57,
    char_6: 'robin',
    app_rate_6: '18.96%',
    avg_round_6: 26199,
    app_flat_6: 55,
    char_7: 'aventurine',
    app_rate_7: '16.86%',
    avg_round_7: 28693,
    app_flat_7: 58,
    char_8: 'jing-yuan',
    app_rate_8: '14.91%',
    avg_round_8: 28838,
    app_flat_8: 44,
    char_9: 'trailblazer-remembrance',
    app_rate_9: '12.64%',
    avg_round_9: 31690,
    app_flat_9: 46,
    char_10: 'fu-xuan',
    app_rate_10: '11.18%',
    avg_round_10: 27226,
    app_flat_10: 33,
    char_11: 'gallagher',
    app_rate_11: '9.24%',
    avg_round_11: 28031,
    app_flat_11: 31,
    char_12: 'luocha',
    app_rate_12: '9.08%',
    avg_round_12: 28706,
    app_flat_12: 31,
    char_13: 'ruan-mei',
    app_rate_13: '7.62%',
    avg_round_13: 30525,
    app_flat_13: 23,
    char_14: 'herta',
    app_rate_14: '5.35%',
    avg_round_14: 26069,
    app_flat_14: 19,
    char_15: 'lingsha',
    app_rate_15: '4.54%',
    avg_round_15: 30972,
    app_flat_15: 20,
    char_16: 'clara',
    app_rate_16: '4.38%',
    avg_round_16: 24412,
    app_flat_16: 16,
    char_17: 'the-herta',
    app_rate_17: '2.92%',
    avg_round_17: 31133,
    app_flat_17: 6,
    char_18: 'serval',
    app_rate_18: '2.43%',
    avg_round_18: 28594,
    app_flat_18: 7,
    char_19: 'jade',
    app_rate_19: '2.27%',
    avg_round_19: 34600,
    app_flat_19: 9,
    char_20: 'bronya',
    app_rate_20: '1.78%',
    avg_round_20: 26806,
    app_flat_20: 7,
    char_21: 'imbibitor-lunae',
    app_rate_21: '1.78%',
    avg_round_21: 0,
    app_flat_21: 0,
    char_22: 'kafka',
    app_rate_22: '1.46%',
    avg_round_22: 25453,
    app_flat_22: 3,
    char_23: 'jiaoqiu',
    app_rate_23: '0.97%',
    avg_round_23: 0,
    app_flat_23: 0,
    char_24: 'tingyun-fugue',
    app_rate_24: '0.81%',
    avg_round_24: 29133,
    app_flat_24: 3,
    char_25: 'lynx',
    app_rate_25: '0.81%',
    avg_round_25: 23860,
    app_flat_25: 2,
    char_26: 'black-swan',
    app_rate_26: '0.65%',
    avg_round_26: 0,
    app_flat_26: 0,
    char_27: 'acheron',
    app_rate_27: '0.49%',
    avg_round_27: 26180,
    app_flat_27: 2,
    char_28: 'seele',
    app_rate_28: '0.32%',
    avg_round_28: 0,
    app_flat_28: 0,
    char_29: 'himeko',
    app_rate_29: '0.32%',
    avg_round_29: 0,
    app_flat_29: 0,
    char_30: 'hanya',
    app_rate_30: '0.32%',
    avg_round_30: 25400,
    app_flat_30: 1
  },
  {
    char: 'rappa',
    app: 5.56,
    char_1: 'tingyun-fugue',
    app_rate_1: '91.39%',
    avg_round_1: 29969,
    app_flat_1: 248,
    char_2: 'ruan-mei',
    app_rate_2: '90.37%',
    avg_round_2: 29562,
    app_flat_2: 253,
    char_3: 'lingsha',
    app_rate_3: '60.64%',
    avg_round_3: 31085,
    app_flat_3: 167,
    char_4: 'gallagher',
    app_rate_4: '19.26%',
    avg_round_4: 26035,
    app_flat_4: 71,
    char_5: 'trailblazer-harmony',
    app_rate_5: '18.58%',
    avg_round_5: 26270,
    app_flat_5: 47,
    char_6: 'firefly',
    app_rate_6: '4.56%',
    avg_round_6: 25000,
    app_flat_6: 4,
    char_7: 'huohuo',
    app_rate_7: '3.04%',
    avg_round_7: 26712,
    app_flat_7: 5,
    char_8: 'fu-xuan',
    app_rate_8: '2.2%',
    avg_round_8: 24480,
    app_flat_8: 6,
    char_9: 'herta',
    app_rate_9: '1.52%',
    avg_round_9: 26954,
    app_flat_9: 7,
    char_10: 'himeko',
    app_rate_10: '1.18%',
    avg_round_10: 29720,
    app_flat_10: 1,
    char_11: 'aventurine',
    app_rate_11: '1.18%',
    avg_round_11: 24800,
    app_flat_11: 4,
    char_12: 'the-herta',
    app_rate_12: '1.01%',
    avg_round_12: 28224,
    app_flat_12: 5,
    char_13: 'luocha',
    app_rate_13: '0.84%',
    avg_round_13: 25627,
    app_flat_13: 3,
    char_14: 'trailblazer-remembrance',
    app_rate_14: '0.68%',
    avg_round_14: 31520,
    app_flat_14: 2,
    char_15: 'yunli',
    app_rate_15: '0.51%',
    avg_round_15: 27400,
    app_flat_15: 2,
    char_16: 'jade',
    app_rate_16: '0.51%',
    avg_round_16: 0,
    app_flat_16: 0,
    char_17: 'acheron',
    app_rate_17: '0.34%',
    avg_round_17: 0,
    app_flat_17: 0,
    char_18: 'kafka',
    app_rate_18: '0.34%',
    avg_round_18: 32080,
    app_flat_18: 2,
    char_19: 'march-7th',
    app_rate_19: '0.34%',
    avg_round_19: 24160,
    app_flat_19: 1,
    char_20: 'sunday',
    app_rate_20: '0.34%',
    avg_round_20: 25860,
    app_flat_20: 2,
    char_21: 'boothill',
    app_rate_21: '0.17%',
    avg_round_21: 0,
    app_flat_21: 0,
    char_22: 'bronya',
    app_rate_22: '0.17%',
    avg_round_22: 0,
    app_flat_22: 0,
    char_23: 'pela',
    app_rate_23: '0.17%',
    avg_round_23: 22800,
    app_flat_23: 1,
    char_24: 'gepard',
    app_rate_24: '0.17%',
    avg_round_24: 0,
    app_flat_24: 0,
    char_25: 'silver-wolf',
    app_rate_25: '0.17%',
    avg_round_25: 0,
    app_flat_25: 0,
    char_26: 'argenti',
    app_rate_26: '0.17%',
    avg_round_26: 0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'serval',
    app: 5.54,
    char_1: 'the-herta',
    app_rate_1: '81.19%',
    avg_round_1: 34321,
    app_flat_1: 271,
    char_2: 'trailblazer-remembrance',
    app_rate_2: '50.85%',
    avg_round_2: 34739,
    app_flat_2: 176,
    char_3: 'sunday',
    app_rate_3: '25.93%',
    avg_round_3: 33009,
    app_flat_3: 94,
    char_4: 'huohuo',
    app_rate_4: '21.69%',
    avg_round_4: 34088,
    app_flat_4: 68,
    char_5: 'lingsha',
    app_rate_5: '20.17%',
    avg_round_5: 34783,
    app_flat_5: 67,
    char_6: 'gallagher',
    app_rate_6: '17.12%',
    avg_round_6: 33150,
    app_flat_6: 67,
    char_7: 'fu-xuan',
    app_rate_7: '15.08%',
    avg_round_7: 26771,
    app_flat_7: 44,
    char_8: 'aventurine',
    app_rate_8: '14.75%',
    avg_round_8: 33179,
    app_flat_8: 62,
    char_9: 'robin',
    app_rate_9: '13.39%',
    avg_round_9: 29652,
    app_flat_9: 41,
    char_10: 'ruan-mei',
    app_rate_10: '6.78%',
    avg_round_10: 28402,
    app_flat_10: 16,
    char_11: 'jade',
    app_rate_11: '6.1%',
    avg_round_11: 27611,
    app_flat_11: 21,
    char_12: 'luocha',
    app_rate_12: '4.92%',
    avg_round_12: 28560,
    app_flat_12: 16,
    char_13: 'sparkle',
    app_rate_13: '3.39%',
    avg_round_13: 24160,
    app_flat_13: 4,
    char_14: 'tingyun',
    app_rate_14: '2.54%',
    avg_round_14: 28594,
    app_flat_14: 7,
    char_15: 'himeko',
    app_rate_15: '2.37%',
    avg_round_15: 24300,
    app_flat_15: 8,
    char_16: 'acheron',
    app_rate_16: '2.37%',
    avg_round_16: 24090,
    app_flat_16: 8,
    char_17: 'herta',
    app_rate_17: '1.86%',
    avg_round_17: 25832,
    app_flat_17: 7,
    char_18: 'pela',
    app_rate_18: '1.86%',
    avg_round_18: 24180,
    app_flat_18: 6,
    char_19: 'tingyun-fugue',
    app_rate_19: '1.69%',
    avg_round_19: 24880,
    app_flat_19: 7,
    char_20: 'kafka',
    app_rate_20: '1.53%',
    avg_round_20: 24027,
    app_flat_20: 5,
    char_21: 'bronya',
    app_rate_21: '1.36%',
    avg_round_21: 27740,
    app_flat_21: 4,
    char_22: 'gepard',
    app_rate_22: '0.51%',
    avg_round_22: 0,
    app_flat_22: 0,
    char_23: 'trailblazer-harmony',
    app_rate_23: '0.51%',
    avg_round_23: 29200,
    app_flat_23: 1,
    char_24: 'hanya',
    app_rate_24: '0.17%',
    avg_round_24: 0,
    app_flat_24: 0,
    char_25: 'feixiao',
    app_rate_25: '0.17%',
    avg_round_25: 0,
    app_flat_25: 0,
    char_26: 'yunli',
    app_rate_26: '0.17%',
    avg_round_26: 0,
    app_flat_26: 0,
    char_27: 'march-7th',
    app_rate_27: '0.17%',
    avg_round_27: 24280,
    app_flat_27: 1,
    char_28: 'silver-wolf',
    app_rate_28: '0.17%',
    avg_round_28: 0,
    app_flat_28: 0,
    char_29: 'xueyi',
    app_rate_29: '0.17%',
    avg_round_29: 24000,
    app_flat_29: 1,
    char_30: 'asta',
    app_rate_30: '0.17%',
    avg_round_30: 24120,
    app_flat_30: 1
  },
  {
    char: 'trailblazer-harmony',
    app: 5.45,
    char_1: 'ruan-mei',
    app_rate_1: '77.8%',
    avg_round_1: 24681,
    app_flat_1: 56,
    char_2: 'firefly',
    app_rate_2: '69.36%',
    avg_round_2: 22645,
    app_flat_2: 16,
    char_3: 'tingyun-fugue',
    app_rate_3: '56.28%',
    avg_round_3: 27625,
    app_flat_3: 39,
    char_4: 'lingsha',
    app_rate_4: '45.27%',
    avg_round_4: 25141,
    app_flat_4: 37,
    char_5: 'rappa',
    app_rate_5: '18.93%',
    avg_round_5: 26270,
    app_flat_5: 47,
    char_6: 'gallagher',
    app_rate_6: '16.52%',
    avg_round_6: 24384,
    app_flat_6: 20,
    char_7: 'himeko',
    app_rate_7: '3.96%',
    avg_round_7: 22120,
    app_flat_7: 2,
    char_8: 'herta',
    app_rate_8: '3.44%',
    avg_round_8: 25088,
    app_flat_8: 10,
    char_9: 'bronya',
    app_rate_9: '1.38%',
    avg_round_9: 24200,
    app_flat_9: 1,
    char_10: 'acheron',
    app_rate_10: '0.86%',
    avg_round_10: 24000,
    app_flat_10: 1,
    char_11: 'fu-xuan',
    app_rate_11: '0.86%',
    avg_round_11: 23507,
    app_flat_11: 3,
    char_12: 'sunday',
    app_rate_12: '0.69%',
    avg_round_12: 0,
    app_flat_12: 0,
    char_13: 'pela',
    app_rate_13: '0.69%',
    avg_round_13: 23400,
    app_flat_13: 2,
    char_14: 'argenti',
    app_rate_14: '0.52%',
    avg_round_14: 25620,
    app_flat_14: 2,
    char_15: 'aventurine',
    app_rate_15: '0.52%',
    avg_round_15: 21400,
    app_flat_15: 1,
    char_16: 'robin',
    app_rate_16: '0.52%',
    avg_round_16: 0,
    app_flat_16: 0,
    char_17: 'serval',
    app_rate_17: '0.52%',
    avg_round_17: 29200,
    app_flat_17: 1,
    char_18: 'boothill',
    app_rate_18: '0.34%',
    avg_round_18: 0,
    app_flat_18: 0,
    char_19: 'jade',
    app_rate_19: '0.34%',
    avg_round_19: 30540,
    app_flat_19: 2,
    char_20: 'huohuo',
    app_rate_20: '0.34%',
    avg_round_20: 0,
    app_flat_20: 0,
    char_21: 'gepard',
    app_rate_21: '0.17%',
    avg_round_21: 0,
    app_flat_21: 0,
    char_22: 'jingliu',
    app_rate_22: '0.17%',
    avg_round_22: 20360,
    app_flat_22: 1,
    char_23: 'the-herta',
    app_rate_23: '0.17%',
    avg_round_23: 29520,
    app_flat_23: 1,
    char_24: 'kafka',
    app_rate_24: '0.17%',
    avg_round_24: 28280,
    app_flat_24: 1,
    char_25: '',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'yunli',
    app: 5.16,
    char_1: 'robin',
    app_rate_1: '70.91%',
    avg_round_1: 26312,
    app_flat_1: 185,
    char_2: 'huohuo',
    app_rate_2: '42.73%',
    avg_round_2: 26759,
    app_flat_2: 108,
    char_3: 'tingyun',
    app_rate_3: '22.0%',
    avg_round_3: 25766,
    app_flat_3: 57,
    char_4: 'herta',
    app_rate_4: '20.36%',
    avg_round_4: 27075,
    app_flat_4: 66,
    char_5: 'aventurine',
    app_rate_5: '20.0%',
    avg_round_5: 26022,
    app_flat_5: 52,
    char_6: 'sunday',
    app_rate_6: '19.27%',
    avg_round_6: 25933,
    app_flat_6: 53,
    char_7: 'sparkle',
    app_rate_7: '16.55%',
    avg_round_7: 25698,
    app_flat_7: 23,
    char_8: 'trailblazer-remembrance',
    app_rate_8: '13.27%',
    avg_round_8: 27315,
    app_flat_8: 38,
    char_9: 'fu-xuan',
    app_rate_9: '10.0%',
    avg_round_9: 25123,
    app_flat_9: 25,
    char_10: 'jade',
    app_rate_10: '8.91%',
    avg_round_10: 32981,
    app_flat_10: 19,
    char_11: 'lingsha',
    app_rate_11: '8.0%',
    avg_round_11: 27216,
    app_flat_11: 29,
    char_12: 'ruan-mei',
    app_rate_12: '8.0%',
    avg_round_12: 25356,
    app_flat_12: 22,
    char_13: 'clara',
    app_rate_13: '6.36%',
    avg_round_13: 24611,
    app_flat_13: 18,
    char_14: 'the-herta',
    app_rate_14: '4.36%',
    avg_round_14: 30283,
    app_flat_14: 13,
    char_15: 'luocha',
    app_rate_15: '4.18%',
    avg_round_15: 24640,
    app_flat_15: 12,
    char_16: 'topaz',
    app_rate_16: '3.45%',
    avg_round_16: 25233,
    app_flat_16: 6,
    char_17: 'gallagher',
    app_rate_17: '3.45%',
    avg_round_17: 26110,
    app_flat_17: 6,
    char_18: 'acheron',
    app_rate_18: '3.09%',
    avg_round_18: 31395,
    app_flat_18: 8,
    char_19: 'lynx',
    app_rate_19: '2.91%',
    avg_round_19: 24292,
    app_flat_19: 10,
    char_20: 'feixiao',
    app_rate_20: '2.18%',
    avg_round_20: 24710,
    app_flat_20: 4,
    char_21: 'march-7th-swordmaster',
    app_rate_21: '2.18%',
    avg_round_21: 24714,
    app_flat_21: 11,
    char_22: 'tingyun-fugue',
    app_rate_22: '2.0%',
    avg_round_22: 25624,
    app_flat_22: 7,
    char_23: 'bailu',
    app_rate_23: '0.73%',
    avg_round_23: 27000,
    app_flat_23: 2,
    char_24: 'blade',
    app_rate_24: '0.55%',
    avg_round_24: 0,
    app_flat_24: 0,
    char_25: 'rappa',
    app_rate_25: '0.55%',
    avg_round_25: 27400,
    app_flat_25: 2,
    char_26: 'jiaoqiu',
    app_rate_26: '0.55%',
    avg_round_26: 0,
    app_flat_26: 0,
    char_27: 'pela',
    app_rate_27: '0.55%',
    avg_round_27: 26800,
    app_flat_27: 1,
    char_28: 'argenti',
    app_rate_28: '0.55%',
    avg_round_28: 24800,
    app_flat_28: 1,
    char_29: 'kafka',
    app_rate_29: '0.36%',
    avg_round_29: 24320,
    app_flat_29: 1,
    char_30: 'bronya',
    app_rate_30: '0.36%',
    avg_round_30: 0,
    app_flat_30: 0
  },
  {
    char: 'bronya',
    app: 3.73,
    char_1: 'acheron',
    app_rate_1: '44.58%',
    avg_round_1: 26407,
    app_flat_1: 10,
    char_2: 'aventurine',
    app_rate_2: '28.72%',
    avg_round_2: 27166,
    app_flat_2: 25,
    char_3: 'jiaoqiu',
    app_rate_3: '21.91%',
    avg_round_3: 29632,
    app_flat_3: 5,
    char_4: 'sunday',
    app_rate_4: '18.39%',
    avg_round_4: 33378,
    app_flat_4: 26,
    char_5: 'fu-xuan',
    app_rate_5: '16.62%',
    avg_round_5: 25537,
    app_flat_5: 11,
    char_6: 'pela',
    app_rate_6: '15.87%',
    avg_round_6: 24720,
    app_flat_6: 6,
    char_7: 'gallagher',
    app_rate_7: '13.1%',
    avg_round_7: 28131,
    app_flat_7: 14,
    char_8: 'argenti',
    app_rate_8: '12.34%',
    avg_round_8: 28874,
    app_flat_8: 33,
    char_9: 'herta',
    app_rate_9: '12.09%',
    avg_round_9: 27963,
    app_flat_9: 29,
    char_10: 'robin',
    app_rate_10: '10.83%',
    avg_round_10: 31878,
    app_flat_10: 19,
    char_11: 'jade',
    app_rate_11: '9.07%',
    avg_round_11: 30699,
    app_flat_11: 19,
    char_12: 'lingsha',
    app_rate_12: '9.07%',
    avg_round_12: 36594,
    app_flat_12: 13,
    char_13: 'ruan-mei',
    app_rate_13: '8.82%',
    avg_round_13: 27823,
    app_flat_13: 12,
    char_14: 'jing-yuan',
    app_rate_14: '8.82%',
    avg_round_14: 30568,
    app_flat_14: 10,
    char_15: 'trailblazer-remembrance',
    app_rate_15: '6.55%',
    avg_round_15: 30560,
    app_flat_15: 14,
    char_16: 'the-herta',
    app_rate_16: '6.55%',
    avg_round_16: 33013,
    app_flat_16: 12,
    char_17: 'luocha',
    app_rate_17: '6.3%',
    avg_round_17: 29147,
    app_flat_17: 10,
    char_18: 'huohuo',
    app_rate_18: '5.54%',
    avg_round_18: 29227,
    app_flat_18: 9,
    char_19: 'sparkle',
    app_rate_19: '5.04%',
    avg_round_19: 29000,
    app_flat_19: 8,
    char_20: 'aglaea',
    app_rate_20: '4.28%',
    avg_round_20: 38032,
    app_flat_20: 7,
    char_21: 'blade',
    app_rate_21: '3.78%',
    avg_round_21: 25810,
    app_flat_21: 4,
    char_22: 'tingyun-fugue',
    app_rate_22: '3.78%',
    avg_round_22: 24440,
    app_flat_22: 1,
    char_23: 'gepard',
    app_rate_23: '3.53%',
    avg_round_23: 22580,
    app_flat_23: 2,
    char_24: 'firefly',
    app_rate_24: '3.02%',
    avg_round_24: 0,
    app_flat_24: 0,
    char_25: 'jingliu',
    app_rate_25: '2.77%',
    avg_round_25: 21560,
    app_flat_25: 2,
    char_26: 'tingyun',
    app_rate_26: '2.77%',
    avg_round_26: 26806,
    app_flat_26: 7,
    char_27: 'serval',
    app_rate_27: '2.02%',
    avg_round_27: 27740,
    app_flat_27: 4,
    char_28: 'trailblazer-harmony',
    app_rate_28: '2.02%',
    avg_round_28: 24200,
    app_flat_28: 1,
    char_29: 'silver-wolf',
    app_rate_29: '1.76%',
    avg_round_29: 0,
    app_flat_29: 0,
    char_30: 'kafka',
    app_rate_30: '1.76%',
    avg_round_30: 24280,
    app_flat_30: 1
  },
  {
    char: 'silver-wolf',
    app: 3.4,
    char_1: 'acheron',
    app_rate_1: '98.62%',
    avg_round_1: 26905,
    app_flat_1: 165,
    char_2: 'pela',
    app_rate_2: '46.96%',
    avg_round_2: 26016,
    app_flat_2: 115,
    char_3: 'fu-xuan',
    app_rate_3: '43.37%',
    avg_round_3: 26089,
    app_flat_3: 59,
    char_4: 'aventurine',
    app_rate_4: '35.91%',
    avg_round_4: 28099,
    app_flat_4: 70,
    char_5: 'sparkle',
    app_rate_5: '19.89%',
    avg_round_5: 23160,
    app_flat_5: 2,
    char_6: 'jiaoqiu',
    app_rate_6: '16.85%',
    avg_round_6: 34081,
    app_flat_6: 31,
    char_7: 'gallagher',
    app_rate_7: '8.56%',
    avg_round_7: 24973,
    app_flat_7: 17,
    char_8: 'gepard',
    app_rate_8: '5.8%',
    avg_round_8: 28275,
    app_flat_8: 8,
    char_9: 'kafka',
    app_rate_9: '5.25%',
    avg_round_9: 28076,
    app_flat_9: 10,
    char_10: 'lingsha',
    app_rate_10: '3.87%',
    avg_round_10: 29700,
    app_flat_10: 6,
    char_11: 'tingyun-fugue',
    app_rate_11: '2.76%',
    avg_round_11: 27010,
    app_flat_11: 4,
    char_12: 'bronya',
    app_rate_12: '1.93%',
    avg_round_12: 0,
    app_flat_12: 0,
    char_13: 'sunday',
    app_rate_13: '1.93%',
    avg_round_13: 0,
    app_flat_13: 0,
    char_14: 'black-swan',
    app_rate_14: '1.38%',
    avg_round_14: 26093,
    app_flat_14: 3,
    char_15: 'robin',
    app_rate_15: '0.83%',
    avg_round_15: 0,
    app_flat_15: 0,
    char_16: 'bailu',
    app_rate_16: '0.83%',
    avg_round_16: 24347,
    app_flat_16: 3,
    char_17: 'jade',
    app_rate_17: '0.83%',
    avg_round_17: 0,
    app_flat_17: 0,
    char_18: 'ruan-mei',
    app_rate_18: '0.55%',
    avg_round_18: 30280,
    app_flat_18: 1,
    char_19: 'luocha',
    app_rate_19: '0.55%',
    avg_round_19: 40000,
    app_flat_19: 1,
    char_20: 'huohuo',
    app_rate_20: '0.55%',
    avg_round_20: 0,
    app_flat_20: 0,
    char_21: 'march-7th-swordmaster',
    app_rate_21: '0.28%',
    avg_round_21: 0,
    app_flat_21: 0,
    char_22: 'welt',
    app_rate_22: '0.28%',
    avg_round_22: 0,
    app_flat_22: 0,
    char_23: 'serval',
    app_rate_23: '0.28%',
    avg_round_23: 0,
    app_flat_23: 0,
    char_24: 'the-herta',
    app_rate_24: '0.28%',
    avg_round_24: 0,
    app_flat_24: 0,
    char_25: 'herta',
    app_rate_25: '0.28%',
    avg_round_25: 0,
    app_flat_25: 0,
    char_26: 'march-7th',
    app_rate_26: '0.28%',
    avg_round_26: 0,
    app_flat_26: 0,
    char_27: 'qingque',
    app_rate_27: '0.28%',
    avg_round_27: 0,
    app_flat_27: 0,
    char_28: 'rappa',
    app_rate_28: '0.28%',
    avg_round_28: 0,
    app_flat_28: 0,
    char_29: 'clara',
    app_rate_29: '0.28%',
    avg_round_29: 0,
    app_flat_29: 0,
    char_30: 'seele',
    app_rate_30: '0.28%',
    avg_round_30: 0,
    app_flat_30: 0
  },
  {
    char: 'clara',
    app: 3.17,
    char_1: 'robin',
    app_rate_1: '62.43%',
    avg_round_1: 24999,
    app_flat_1: 107,
    char_2: 'herta',
    app_rate_2: '50.59%',
    avg_round_2: 24975,
    app_flat_2: 106,
    char_3: 'aventurine',
    app_rate_3: '32.84%',
    avg_round_3: 25028,
    app_flat_3: 62,
    char_4: 'huohuo',
    app_rate_4: '18.93%',
    avg_round_4: 24465,
    app_flat_4: 24,
    char_5: 'ruan-mei',
    app_rate_5: '15.38%',
    avg_round_5: 24940,
    app_flat_5: 30,
    char_6: 'sparkle',
    app_rate_6: '13.02%',
    avg_round_6: 24167,
    app_flat_6: 22,
    char_7: 'sunday',
    app_rate_7: '12.13%',
    avg_round_7: 24493,
    app_flat_7: 21,
    char_8: 'luocha',
    app_rate_8: '11.24%',
    avg_round_8: 24370,
    app_flat_8: 23,
    char_9: 'yunli',
    app_rate_9: '10.36%',
    avg_round_9: 24611,
    app_flat_9: 18,
    char_10: 'gallagher',
    app_rate_10: '8.88%',
    avg_round_10: 24595,
    app_flat_10: 17,
    char_11: 'fu-xuan',
    app_rate_11: '8.28%',
    avg_round_11: 24330,
    app_flat_11: 14,
    char_12: 'tingyun',
    app_rate_12: '7.99%',
    avg_round_12: 24412,
    app_flat_12: 16,
    char_13: 'trailblazer-remembrance',
    app_rate_13: '7.99%',
    avg_round_13: 24217,
    app_flat_13: 13,
    char_14: 'lingsha',
    app_rate_14: '7.69%',
    avg_round_14: 25526,
    app_flat_14: 20,
    char_15: 'jade',
    app_rate_15: '6.8%',
    avg_round_15: 26260,
    app_flat_15: 8,
    char_16: 'himeko',
    app_rate_16: '5.62%',
    avg_round_16: 24235,
    app_flat_16: 16,
    char_17: 'the-herta',
    app_rate_17: '4.14%',
    avg_round_17: 28945,
    app_flat_17: 8,
    char_18: 'feixiao',
    app_rate_18: '2.96%',
    avg_round_18: 25140,
    app_flat_18: 4,
    char_19: 'topaz',
    app_rate_19: '2.96%',
    avg_round_19: 24133,
    app_flat_19: 5,
    char_20: 'lynx',
    app_rate_20: '2.07%',
    avg_round_20: 24570,
    app_flat_20: 4,
    char_21: 'tingyun-fugue',
    app_rate_21: '1.18%',
    avg_round_21: 25320,
    app_flat_21: 1,
    char_22: 'acheron',
    app_rate_22: '1.18%',
    avg_round_22: 34040,
    app_flat_22: 1,
    char_23: 'bailu',
    app_rate_23: '0.89%',
    avg_round_23: 0,
    app_flat_23: 0,
    char_24: 'bronya',
    app_rate_24: '0.89%',
    avg_round_24: 0,
    app_flat_24: 0,
    char_25: 'jingliu',
    app_rate_25: '0.89%',
    avg_round_25: 23520,
    app_flat_25: 2,
    char_26: 'march-7th-swordmaster',
    app_rate_26: '0.59%',
    avg_round_26: 24460,
    app_flat_26: 2,
    char_27: 'kafka',
    app_rate_27: '0.3%',
    avg_round_27: 0,
    app_flat_27: 0,
    char_28: 'march-7th',
    app_rate_28: '0.3%',
    avg_round_28: 0,
    app_flat_28: 0,
    char_29: 'firefly',
    app_rate_29: '0.3%',
    avg_round_29: 0,
    app_flat_29: 0,
    char_30: 'black-swan',
    app_rate_30: '0.3%',
    avg_round_30: 0,
    app_flat_30: 0
  },
  {
    char: 'feixiao',
    app: 2.68,
    char_1: 'robin',
    app_rate_1: '89.86%',
    avg_round_1: 26199,
    app_flat_1: 67,
    char_2: 'aventurine',
    app_rate_2: '68.53%',
    avg_round_2: 26277,
    app_flat_2: 45,
    char_3: 'herta',
    app_rate_3: '45.45%',
    avg_round_3: 27296,
    app_flat_3: 45,
    char_4: 'jade',
    app_rate_4: '19.23%',
    avg_round_4: 27780,
    app_flat_4: 4,
    char_5: 'topaz',
    app_rate_5: '16.43%',
    avg_round_5: 24340,
    app_flat_5: 4,
    char_6: 'march-7th-swordmaster',
    app_rate_6: '11.19%',
    avg_round_6: 23717,
    app_flat_6: 13,
    char_7: 'fu-xuan',
    app_rate_7: '6.29%',
    avg_round_7: 23260,
    app_flat_7: 2,
    char_8: 'gallagher',
    app_rate_8: '6.29%',
    avg_round_8: 26080,
    app_flat_8: 9,
    char_9: 'lingsha',
    app_rate_9: '5.24%',
    avg_round_9: 26048,
    app_flat_9: 7,
    char_10: 'yunli',
    app_rate_10: '4.2%',
    avg_round_10: 24710,
    app_flat_10: 4,
    char_11: 'trailblazer-remembrance',
    app_rate_11: '4.2%',
    avg_round_11: 27613,
    app_flat_11: 3,
    char_12: 'clara',
    app_rate_12: '3.5%',
    avg_round_12: 25140,
    app_flat_12: 4,
    char_13: 'luocha',
    app_rate_13: '3.15%',
    avg_round_13: 23200,
    app_flat_13: 1,
    char_14: 'sunday',
    app_rate_14: '2.8%',
    avg_round_14: 0,
    app_flat_14: 0,
    char_15: 'ruan-mei',
    app_rate_15: '2.1%',
    avg_round_15: 27080,
    app_flat_15: 2,
    char_16: 'huohuo',
    app_rate_16: '2.1%',
    avg_round_16: 24973,
    app_flat_16: 3,
    char_17: 'sparkle',
    app_rate_17: '2.1%',
    avg_round_17: 0,
    app_flat_17: 0,
    char_18: 'himeko',
    app_rate_18: '1.75%',
    avg_round_18: 23253,
    app_flat_18: 3,
    char_19: 'bronya',
    app_rate_19: '1.05%',
    avg_round_19: 0,
    app_flat_19: 0,
    char_20: 'acheron',
    app_rate_20: '1.05%',
    avg_round_20: 0,
    app_flat_20: 0,
    char_21: 'the-herta',
    app_rate_21: '0.7%',
    avg_round_21: 30680,
    app_flat_21: 1,
    char_22: 'jiaoqiu',
    app_rate_22: '0.7%',
    avg_round_22: 0,
    app_flat_22: 0,
    char_23: 'serval',
    app_rate_23: '0.35%',
    avg_round_23: 0,
    app_flat_23: 0,
    char_24: 'kafka',
    app_rate_24: '0.35%',
    avg_round_24: 0,
    app_flat_24: 0,
    char_25: 'black-swan',
    app_rate_25: '0.35%',
    avg_round_25: 0,
    app_flat_25: 0,
    char_26: 'moze',
    app_rate_26: '0.35%',
    avg_round_26: 24160,
    app_flat_26: 1,
    char_27: 'march-7th',
    app_rate_27: '0.35%',
    avg_round_27: 25160,
    app_flat_27: 1,
    char_28: '',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'aglaea',
    app: 1.93,
    char_1: 'sunday',
    app_rate_1: '89.32%',
    avg_round_1: 32658,
    app_flat_1: 55,
    char_2: 'robin',
    app_rate_2: '73.3%',
    avg_round_2: 32263,
    app_flat_2: 51,
    char_3: 'huohuo',
    app_rate_3: '43.2%',
    avg_round_3: 30071,
    app_flat_3: 26,
    char_4: 'trailblazer-remembrance',
    app_rate_4: '29.61%',
    avg_round_4: 33030,
    app_flat_4: 12,
    char_5: 'lingsha',
    app_rate_5: '11.17%',
    avg_round_5: 33325,
    app_flat_5: 8,
    char_6: 'fu-xuan',
    app_rate_6: '10.19%',
    avg_round_6: 33640,
    app_flat_6: 2,
    char_7: 'gallagher',
    app_rate_7: '9.22%',
    avg_round_7: 34540,
    app_flat_7: 8,
    char_8: 'bronya',
    app_rate_8: '8.25%',
    avg_round_8: 38032,
    app_flat_8: 7,
    char_9: 'luocha',
    app_rate_9: '6.8%',
    avg_round_9: 36320,
    app_flat_9: 2,
    char_10: 'aventurine',
    app_rate_10: '5.34%',
    avg_round_10: 28200,
    app_flat_10: 1,
    char_11: 'jade',
    app_rate_11: '3.88%',
    avg_round_11: 33960,
    app_flat_11: 2,
    char_12: 'bailu',
    app_rate_12: '2.43%',
    avg_round_12: 25920,
    app_flat_12: 1,
    char_13: 'ruan-mei',
    app_rate_13: '1.94%',
    avg_round_13: 31920,
    app_flat_13: 1,
    char_14: 'sparkle',
    app_rate_14: '1.46%',
    avg_round_14: 0,
    app_flat_14: 0,
    char_15: 'herta',
    app_rate_15: '0.97%',
    avg_round_15: 0,
    app_flat_15: 0,
    char_16: 'acheron',
    app_rate_16: '0.97%',
    avg_round_16: 0,
    app_flat_16: 0,
    char_17: 'the-herta',
    app_rate_17: '0.49%',
    avg_round_17: 0,
    app_flat_17: 0,
    char_18: 'kafka',
    app_rate_18: '0.49%',
    avg_round_18: 0,
    app_flat_18: 0,
    char_19: 'himeko',
    app_rate_19: '0.49%',
    avg_round_19: 0,
    app_flat_19: 0,
    char_20: 'tingyun',
    app_rate_20: '0.49%',
    avg_round_20: 24280,
    app_flat_20: 1,
    char_21: '',
    app_rate_21: '0.00%',
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '',
    app_rate_22: '0.00%',
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'gepard',
    app: 1.7,
    char_1: 'acheron',
    app_rate_1: '84.53%',
    avg_round_1: 29948,
    app_flat_1: 46,
    char_2: 'pela',
    app_rate_2: '42.54%',
    avg_round_2: 29618,
    app_flat_2: 29,
    char_3: 'kafka',
    app_rate_3: '27.07%',
    avg_round_3: 31158,
    app_flat_3: 19,
    char_4: 'black-swan',
    app_rate_4: '22.65%',
    avg_round_4: 29828,
    app_flat_4: 17,
    char_5: 'sparkle',
    app_rate_5: '20.44%',
    avg_round_5: 24140,
    app_flat_5: 4,
    char_6: 'jiaoqiu',
    app_rate_6: '17.68%',
    avg_round_6: 34387,
    app_flat_6: 6,
    char_7: 'herta',
    app_rate_7: '12.71%',
    avg_round_7: 27794,
    app_flat_7: 14,
    char_8: 'silver-wolf',
    app_rate_8: '11.6%',
    avg_round_8: 28275,
    app_flat_8: 8,
    char_9: 'sunday',
    app_rate_9: '9.94%',
    avg_round_9: 30410,
    app_flat_9: 6,
    char_10: 'robin',
    app_rate_10: '8.84%',
    avg_round_10: 26520,
    app_flat_10: 10,
    char_11: 'the-herta',
    app_rate_11: '8.84%',
    avg_round_11: 31232,
    app_flat_11: 7,
    char_12: 'bronya',
    app_rate_12: '7.73%',
    avg_round_12: 22580,
    app_flat_12: 2,
    char_13: 'trailblazer-remembrance',
    app_rate_13: '6.08%',
    avg_round_13: 32060,
    app_flat_13: 4,
    char_14: 'guinaifen',
    app_rate_14: '4.42%',
    avg_round_14: 27350,
    app_flat_14: 4,
    char_15: 'ruan-mei',
    app_rate_15: '4.42%',
    avg_round_15: 25173,
    app_flat_15: 3,
    char_16: 'serval',
    app_rate_16: '1.66%',
    avg_round_16: 0,
    app_flat_16: 0,
    char_17: 'jing-yuan',
    app_rate_17: '1.66%',
    avg_round_17: 29307,
    app_flat_17: 3,
    char_18: 'argenti',
    app_rate_18: '1.1%',
    avg_round_18: 24200,
    app_flat_18: 1,
    char_19: 'tingyun-fugue',
    app_rate_19: '1.1%',
    avg_round_19: 27200,
    app_flat_19: 1,
    char_20: 'himeko',
    app_rate_20: '1.1%',
    avg_round_20: 24100,
    app_flat_20: 2,
    char_21: 'gallagher',
    app_rate_21: '0.55%',
    avg_round_21: 0,
    app_flat_21: 0,
    char_22: 'rappa',
    app_rate_22: '0.55%',
    avg_round_22: 0,
    app_flat_22: 0,
    char_23: 'trailblazer-harmony',
    app_rate_23: '0.55%',
    avg_round_23: 0,
    app_flat_23: 0,
    char_24: 'yunli',
    app_rate_24: '0.55%',
    avg_round_24: 0,
    app_flat_24: 0,
    char_25: 'luocha',
    app_rate_25: '0.55%',
    avg_round_25: 0,
    app_flat_25: 0,
    char_26: 'welt',
    app_rate_26: '0.55%',
    avg_round_26: 0,
    app_flat_26: 0,
    char_27: 'jade',
    app_rate_27: '0.55%',
    avg_round_27: 0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'topaz',
    app: 0.81,
    char_1: 'robin',
    app_rate_1: '81.4%',
    avg_round_1: 24620,
    app_flat_1: 12,
    char_2: 'aventurine',
    app_rate_2: '73.26%',
    avg_round_2: 24776,
    app_flat_2: 10,
    char_3: 'feixiao',
    app_rate_3: '54.65%',
    avg_round_3: 24340,
    app_flat_3: 4,
    char_4: 'yunli',
    app_rate_4: '22.09%',
    avg_round_4: 25233,
    app_flat_4: 6,
    char_5: 'clara',
    app_rate_5: '11.63%',
    avg_round_5: 24133,
    app_flat_5: 5,
    char_6: 'fu-xuan',
    app_rate_6: '9.3%',
    avg_round_6: 26000,
    app_flat_6: 1,
    char_7: 'jade',
    app_rate_7: '8.14%',
    avg_round_7: 33300,
    app_flat_7: 2,
    char_8: 'lingsha',
    app_rate_8: '8.14%',
    avg_round_8: 25533,
    app_flat_8: 5,
    char_9: 'huohuo',
    app_rate_9: '6.98%',
    avg_round_9: 24040,
    app_flat_9: 2,
    char_10: 'sparkle',
    app_rate_10: '3.49%',
    avg_round_10: 24080,
    app_flat_10: 1,
    char_11: 'sunday',
    app_rate_11: '3.49%',
    avg_round_11: 24100,
    app_flat_11: 2,
    char_12: 'ruan-mei',
    app_rate_12: '2.33%',
    avg_round_12: 0,
    app_flat_12: 0,
    char_13: 'acheron',
    app_rate_13: '2.33%',
    avg_round_13: 0,
    app_flat_13: 0,
    char_14: 'trailblazer-remembrance',
    app_rate_14: '2.33%',
    avg_round_14: 26000,
    app_flat_14: 1,
    char_15: 'herta',
    app_rate_15: '2.33%',
    avg_round_15: 24080,
    app_flat_15: 1,
    char_16: 'jiaoqiu',
    app_rate_16: '1.16%',
    avg_round_16: 0,
    app_flat_16: 0,
    char_17: 'the-herta',
    app_rate_17: '1.16%',
    avg_round_17: 24080,
    app_flat_17: 1,
    char_18: 'bailu',
    app_rate_18: '1.16%',
    avg_round_18: 0,
    app_flat_18: 0,
    char_19: 'dr-ratio',
    app_rate_19: '1.16%',
    avg_round_19: 0,
    app_flat_19: 0,
    char_20: 'jing-yuan',
    app_rate_20: '1.16%',
    avg_round_20: 24000,
    app_flat_20: 1,
    char_21: 'bronya',
    app_rate_21: '1.16%',
    avg_round_21: 0,
    app_flat_21: 0,
    char_22: 'gallagher',
    app_rate_22: '1.16%',
    avg_round_22: 0,
    app_flat_22: 0,
    char_23: '',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'jingliu',
    app: 0.57,
    char_1: 'ruan-mei',
    app_rate_1: '44.26%',
    avg_round_1: 24248,
    app_flat_1: 9,
    char_2: 'huohuo',
    app_rate_2: '36.07%',
    avg_round_2: 23634,
    app_flat_2: 7,
    char_3: 'sunday',
    app_rate_3: '32.79%',
    avg_round_3: 24240,
    app_flat_3: 7,
    char_4: 'robin',
    app_rate_4: '31.15%',
    avg_round_4: 24093,
    app_flat_4: 6,
    char_5: 'herta',
    app_rate_5: '26.23%',
    avg_round_5: 23910,
    app_flat_5: 8,
    char_6: 'bronya',
    app_rate_6: '18.03%',
    avg_round_6: 21560,
    app_flat_6: 2,
    char_7: 'trailblazer-remembrance',
    app_rate_7: '16.39%',
    avg_round_7: 23880,
    app_flat_7: 3,
    char_8: 'luocha',
    app_rate_8: '14.75%',
    avg_round_8: 23733,
    app_flat_8: 3,
    char_9: 'gallagher',
    app_rate_9: '11.48%',
    avg_round_9: 22800,
    app_flat_9: 2,
    char_10: 'lingsha',
    app_rate_10: '11.48%',
    avg_round_10: 24952,
    app_flat_10: 5,
    char_11: 'fu-xuan',
    app_rate_11: '9.84%',
    avg_round_11: 21680,
    app_flat_11: 1,
    char_12: 'aventurine',
    app_rate_12: '8.2%',
    avg_round_12: 22240,
    app_flat_12: 2,
    char_13: 'blade',
    app_rate_13: '8.2%',
    avg_round_13: 0,
    app_flat_13: 0,
    char_14: 'sparkle',
    app_rate_14: '4.92%',
    avg_round_14: 21680,
    app_flat_14: 1,
    char_15: 'pela',
    app_rate_15: '4.92%',
    avg_round_15: 0,
    app_flat_15: 0,
    char_16: 'clara',
    app_rate_16: '4.92%',
    avg_round_16: 23520,
    app_flat_16: 2,
    char_17: 'himeko',
    app_rate_17: '3.28%',
    avg_round_17: 23080,
    app_flat_17: 1,
    char_18: 'yunli',
    app_rate_18: '3.28%',
    avg_round_18: 24120,
    app_flat_18: 1,
    char_19: 'argenti',
    app_rate_19: '1.64%',
    avg_round_19: 25360,
    app_flat_19: 1,
    char_20: 'the-herta',
    app_rate_20: '1.64%',
    avg_round_20: 0,
    app_flat_20: 0,
    char_21: 'trailblazer-harmony',
    app_rate_21: '1.64%',
    avg_round_21: 20360,
    app_flat_21: 1,
    char_22: 'tingyun',
    app_rate_22: '1.64%',
    avg_round_22: 24120,
    app_flat_22: 1,
    char_23: 'tingyun-fugue',
    app_rate_23: '1.64%',
    avg_round_23: 0,
    app_flat_23: 0,
    char_24: 'march-7th',
    app_rate_24: '1.64%',
    avg_round_24: 0,
    app_flat_24: 0,
    char_25: '',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'bailu',
    app: 0.57,
    char_1: 'the-herta',
    app_rate_1: '36.07%',
    avg_round_1: 34584,
    app_flat_1: 17,
    char_2: 'herta',
    app_rate_2: '32.79%',
    avg_round_2: 32115,
    app_flat_2: 16,
    char_3: 'trailblazer-remembrance',
    app_rate_3: '31.15%',
    avg_round_3: 32823,
    app_flat_3: 14,
    char_4: 'acheron',
    app_rate_4: '22.95%',
    avg_round_4: 24208,
    app_flat_4: 7,
    char_5: 'kafka',
    app_rate_5: '19.67%',
    avg_round_5: 23453,
    app_flat_5: 3,
    char_6: 'black-swan',
    app_rate_6: '19.67%',
    avg_round_6: 23240,
    app_flat_6: 2,
    char_7: 'sunday',
    app_rate_7: '18.03%',
    avg_round_7: 28640,
    app_flat_7: 5,
    char_8: 'robin',
    app_rate_8: '16.39%',
    avg_round_8: 31153,
    app_flat_8: 6,
    char_9: 'jade',
    app_rate_9: '14.75%',
    avg_round_9: 37453,
    app_flat_9: 5,
    char_10: 'ruan-mei',
    app_rate_10: '13.11%',
    avg_round_10: 24200,
    app_flat_10: 1,
    char_11: 'aglaea',
    app_rate_11: '8.2%',
    avg_round_11: 25920,
    app_flat_11: 1,
    char_12: 'pela',
    app_rate_12: '8.2%',
    avg_round_12: 23940,
    app_flat_12: 4,
    char_13: 'bronya',
    app_rate_13: '6.56%',
    avg_round_13: 23140,
    app_flat_13: 2,
    char_14: 'jing-yuan',
    app_rate_14: '6.56%',
    avg_round_14: 30000,
    app_flat_14: 2,
    char_15: 'yunli',
    app_rate_15: '6.56%',
    avg_round_15: 27000,
    app_flat_15: 2,
    char_16: 'sparkle',
    app_rate_16: '6.56%',
    avg_round_16: 26920,
    app_flat_16: 1,
    char_17: 'silver-wolf',
    app_rate_17: '4.92%',
    avg_round_17: 24347,
    app_flat_17: 3,
    char_18: 'clara',
    app_rate_18: '4.92%',
    avg_round_18: 0,
    app_flat_18: 0,
    char_19: 'jiaoqiu',
    app_rate_19: '4.92%',
    avg_round_19: 27140,
    app_flat_19: 2,
    char_20: 'himeko',
    app_rate_20: '3.28%',
    avg_round_20: 24180,
    app_flat_20: 2,
    char_21: 'tingyun',
    app_rate_21: '3.28%',
    avg_round_21: 28960,
    app_flat_21: 1,
    char_22: 'blade',
    app_rate_22: '1.64%',
    avg_round_22: 0,
    app_flat_22: 0,
    char_23: 'argenti',
    app_rate_23: '1.64%',
    avg_round_23: 29280,
    app_flat_23: 1,
    char_24: 'firefly',
    app_rate_24: '1.64%',
    avg_round_24: 24160,
    app_flat_24: 1,
    char_25: 'tingyun-fugue',
    app_rate_25: '1.64%',
    avg_round_25: 24160,
    app_flat_25: 1,
    char_26: 'topaz',
    app_rate_26: '1.64%',
    avg_round_26: 0,
    app_flat_26: 0,
    char_27: 'hanya',
    app_rate_27: '1.64%',
    avg_round_27: 0,
    app_flat_27: 0,
    char_28: 'serval',
    app_rate_28: '1.64%',
    avg_round_28: 0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'march-7th',
    app: 0.51,
    char_1: 'acheron',
    app_rate_1: '59.26%',
    avg_round_1: 33141,
    app_flat_1: 17,
    char_2: 'pela',
    app_rate_2: '51.85%',
    avg_round_2: 33695,
    app_flat_2: 16,
    char_3: 'herta',
    app_rate_3: '29.63%',
    avg_round_3: 30895,
    app_flat_3: 8,
    char_4: 'trailblazer-remembrance',
    app_rate_4: '29.63%',
    avg_round_4: 35651,
    app_flat_4: 11,
    char_5: 'the-herta',
    app_rate_5: '24.07%',
    avg_round_5: 34743,
    app_flat_5: 7,
    char_6: 'tingyun-fugue',
    app_rate_6: '14.81%',
    avg_round_6: 26760,
    app_flat_6: 3,
    char_7: 'ruan-mei',
    app_rate_7: '12.96%',
    avg_round_7: 29780,
    app_flat_7: 2,
    char_8: 'robin',
    app_rate_8: '9.26%',
    avg_round_8: 28936,
    app_flat_8: 5,
    char_9: 'kafka',
    app_rate_9: '9.26%',
    avg_round_9: 40000,
    app_flat_9: 2,
    char_10: 'sparkle',
    app_rate_10: '9.26%',
    avg_round_10: 24160,
    app_flat_10: 1,
    char_11: 'black-swan',
    app_rate_11: '7.41%',
    avg_round_11: 28420,
    app_flat_11: 2,
    char_12: 'guinaifen',
    app_rate_12: '7.41%',
    avg_round_12: 28973,
    app_flat_12: 3,
    char_13: 'himeko',
    app_rate_13: '5.56%',
    avg_round_13: 28540,
    app_flat_13: 2,
    char_14: 'sampo',
    app_rate_14: '3.7%',
    avg_round_14: 29280,
    app_flat_14: 1,
    char_15: 'rappa',
    app_rate_15: '3.7%',
    avg_round_15: 24160,
    app_flat_15: 1,
    char_16: 'jiaoqiu',
    app_rate_16: '3.7%',
    avg_round_16: 0,
    app_flat_16: 0,
    char_17: 'imbibitor-lunae',
    app_rate_17: '1.85%',
    avg_round_17: 0,
    app_flat_17: 0,
    char_18: 'sunday',
    app_rate_18: '1.85%',
    avg_round_18: 0,
    app_flat_18: 0,
    char_19: 'serval',
    app_rate_19: '1.85%',
    avg_round_19: 24280,
    app_flat_19: 1,
    char_20: 'clara',
    app_rate_20: '1.85%',
    avg_round_20: 0,
    app_flat_20: 0,
    char_21: 'silver-wolf',
    app_rate_21: '1.85%',
    avg_round_21: 0,
    app_flat_21: 0,
    char_22: 'bronya',
    app_rate_22: '1.85%',
    avg_round_22: 0,
    app_flat_22: 0,
    char_23: 'argenti',
    app_rate_23: '1.85%',
    avg_round_23: 39240,
    app_flat_23: 1,
    char_24: 'feixiao',
    app_rate_24: '1.85%',
    avg_round_24: 25160,
    app_flat_24: 1,
    char_25: 'jingliu',
    app_rate_25: '1.85%',
    avg_round_25: 0,
    app_flat_25: 0,
    char_26: 'asta',
    app_rate_26: '1.85%',
    avg_round_26: 0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'guinaifen',
    app: 0.49,
    char_1: 'acheron',
    app_rate_1: '98.08%',
    avg_round_1: 25807,
    app_flat_1: 33,
    char_2: 'pela',
    app_rate_2: '76.92%',
    avg_round_2: 25357,
    app_flat_2: 28,
    char_3: 'fu-xuan',
    app_rate_3: '34.62%',
    avg_round_3: 26580,
    app_flat_3: 10,
    char_4: 'aventurine',
    app_rate_4: '30.77%',
    avg_round_4: 25847,
    app_flat_4: 12,
    char_5: 'gepard',
    app_rate_5: '15.38%',
    avg_round_5: 27350,
    app_flat_5: 4,
    char_6: 'kafka',
    app_rate_6: '7.69%',
    avg_round_6: 27020,
    app_flat_6: 2,
    char_7: 'march-7th',
    app_rate_7: '7.69%',
    avg_round_7: 28973,
    app_flat_7: 3,
    char_8: 'gallagher',
    app_rate_8: '5.77%',
    avg_round_8: 23000,
    app_flat_8: 2,
    char_9: 'jiaoqiu',
    app_rate_9: '5.77%',
    avg_round_9: 40000,
    app_flat_9: 2,
    char_10: 'lingsha',
    app_rate_10: '5.77%',
    avg_round_10: 23480,
    app_flat_10: 2,
    char_11: 'bronya',
    app_rate_11: '1.92%',
    avg_round_11: 0,
    app_flat_11: 0,
    char_12: 'sparkle',
    app_rate_12: '1.92%',
    avg_round_12: 0,
    app_flat_12: 0,
    char_13: 'sunday',
    app_rate_13: '1.92%',
    avg_round_13: 0,
    app_flat_13: 0,
    char_14: 'firefly',
    app_rate_14: '1.92%',
    avg_round_14: 0,
    app_flat_14: 0,
    char_15: 'tingyun-fugue',
    app_rate_15: '1.92%',
    avg_round_15: 0,
    app_flat_15: 0,
    char_16: 'black-swan',
    app_rate_16: '1.92%',
    avg_round_16: 24280,
    app_flat_16: 1,
    char_17: '',
    app_rate_17: '0.00%',
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '',
    app_rate_18: '0.00%',
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '',
    app_rate_19: '0.00%',
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '',
    app_rate_20: '0.00%',
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '',
    app_rate_21: '0.00%',
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '',
    app_rate_22: '0.00%',
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'march-7th-swordmaster',
    app: 0.49,
    char_1: 'robin',
    app_rate_1: '84.62%',
    avg_round_1: 24580,
    app_flat_1: 24,
    char_2: 'aventurine',
    app_rate_2: '61.54%',
    avg_round_2: 24080,
    app_flat_2: 18,
    char_3: 'feixiao',
    app_rate_3: '61.54%',
    avg_round_3: 23717,
    app_flat_3: 13,
    char_4: 'yunli',
    app_rate_4: '23.08%',
    avg_round_4: 24714,
    app_flat_4: 11,
    char_5: 'huohuo',
    app_rate_5: '13.46%',
    avg_round_5: 23827,
    app_flat_5: 3,
    char_6: 'fu-xuan',
    app_rate_6: '7.69%',
    avg_round_6: 23840,
    app_flat_6: 2,
    char_7: 'herta',
    app_rate_7: '7.69%',
    avg_round_7: 26933,
    app_flat_7: 3,
    char_8: 'lingsha',
    app_rate_8: '5.77%',
    avg_round_8: 24400,
    app_flat_8: 3,
    char_9: 'sunday',
    app_rate_9: '5.77%',
    avg_round_9: 26440,
    app_flat_9: 1,
    char_10: 'acheron',
    app_rate_10: '3.85%',
    avg_round_10: 24200,
    app_flat_10: 1,
    char_11: 'clara',
    app_rate_11: '3.85%',
    avg_round_11: 24460,
    app_flat_11: 2,
    char_12: 'trailblazer-remembrance',
    app_rate_12: '3.85%',
    avg_round_12: 22280,
    app_flat_12: 1,
    char_13: 'tingyun',
    app_rate_13: '1.92%',
    avg_round_13: 25120,
    app_flat_13: 1,
    char_14: 'silver-wolf',
    app_rate_14: '1.92%',
    avg_round_14: 0,
    app_flat_14: 0,
    char_15: 'sparkle',
    app_rate_15: '1.92%',
    avg_round_15: 0,
    app_flat_15: 0,
    char_16: 'jade',
    app_rate_16: '1.92%',
    avg_round_16: 0,
    app_flat_16: 0,
    char_17: 'ruan-mei',
    app_rate_17: '1.92%',
    avg_round_17: 24080,
    app_flat_17: 1,
    char_18: 'tingyun-fugue',
    app_rate_18: '1.92%',
    avg_round_18: 24200,
    app_flat_18: 1,
    char_19: 'himeko',
    app_rate_19: '1.92%',
    avg_round_19: 20840,
    app_flat_19: 1,
    char_20: 'kafka',
    app_rate_20: '1.92%',
    avg_round_20: 0,
    app_flat_20: 0,
    char_21: 'gallagher',
    app_rate_21: '1.92%',
    avg_round_21: 29400,
    app_flat_21: 1,
    char_22: '',
    app_rate_22: '0.00%',
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'blade',
    app: 0.45,
    char_1: 'jade',
    app_rate_1: '58.33%',
    avg_round_1: 26760,
    app_flat_1: 10,
    char_2: 'bronya',
    app_rate_2: '31.25%',
    avg_round_2: 25810,
    app_flat_2: 4,
    char_3: 'robin',
    app_rate_3: '31.25%',
    avg_round_3: 26400,
    app_flat_3: 3,
    char_4: 'luocha',
    app_rate_4: '22.92%',
    avg_round_4: 25984,
    app_flat_4: 5,
    char_5: 'sunday',
    app_rate_5: '22.92%',
    avg_round_5: 24200,
    app_flat_5: 1,
    char_6: 'fu-xuan',
    app_rate_6: '20.83%',
    avg_round_6: 28733,
    app_flat_6: 3,
    char_7: 'lingsha',
    app_rate_7: '18.75%',
    avg_round_7: 25840,
    app_flat_7: 2,
    char_8: 'ruan-mei',
    app_rate_8: '18.75%',
    avg_round_8: 25600,
    app_flat_8: 2,
    char_9: 'huohuo',
    app_rate_9: '16.67%',
    avg_round_9: 24000,
    app_flat_9: 1,
    char_10: 'sparkle',
    app_rate_10: '12.5%',
    avg_round_10: 26620,
    app_flat_10: 4,
    char_11: 'jingliu',
    app_rate_11: '10.42%',
    avg_round_11: 0,
    app_flat_11: 0,
    char_12: 'gallagher',
    app_rate_12: '10.42%',
    avg_round_12: 0,
    app_flat_12: 0,
    char_13: 'yunli',
    app_rate_13: '6.25%',
    avg_round_13: 0,
    app_flat_13: 0,
    char_14: 'herta',
    app_rate_14: '6.25%',
    avg_round_14: 24160,
    app_flat_14: 2,
    char_15: 'lynx',
    app_rate_15: '4.17%',
    avg_round_15: 24240,
    app_flat_15: 1,
    char_16: 'trailblazer-remembrance',
    app_rate_16: '4.17%',
    avg_round_16: 24080,
    app_flat_16: 1,
    char_17: 'bailu',
    app_rate_17: '2.08%',
    avg_round_17: 0,
    app_flat_17: 0,
    char_18: 'jing-yuan',
    app_rate_18: '2.08%',
    avg_round_18: 0,
    app_flat_18: 0,
    char_19: '',
    app_rate_19: '0.00%',
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '',
    app_rate_20: '0.00%',
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '',
    app_rate_21: '0.00%',
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '',
    app_rate_22: '0.00%',
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'lynx',
    app: 0.43,
    char_1: 'herta',
    app_rate_1: '36.96%',
    avg_round_1: 26713,
    app_flat_1: 12,
    char_2: 'yunli',
    app_rate_2: '34.78%',
    avg_round_2: 24292,
    app_flat_2: 10,
    char_3: 'robin',
    app_rate_3: '32.61%',
    avg_round_3: 25013,
    app_flat_3: 10,
    char_4: 'sparkle',
    app_rate_4: '21.74%',
    avg_round_4: 25093,
    app_flat_4: 5,
    char_5: 'the-herta',
    app_rate_5: '21.74%',
    avg_round_5: 33060,
    app_flat_5: 6,
    char_6: 'trailblazer-remembrance',
    app_rate_6: '21.74%',
    avg_round_6: 30857,
    app_flat_6: 7,
    char_7: 'sunday',
    app_rate_7: '19.57%',
    avg_round_7: 25480,
    app_flat_7: 8,
    char_8: 'clara',
    app_rate_8: '15.22%',
    avg_round_8: 24570,
    app_flat_8: 4,
    char_9: 'jade',
    app_rate_9: '15.22%',
    avg_round_9: 27693,
    app_flat_9: 5,
    char_10: 'ruan-mei',
    app_rate_10: '13.04%',
    avg_round_10: 24760,
    app_flat_10: 2,
    char_11: 'acheron',
    app_rate_11: '10.87%',
    avg_round_11: 0,
    app_flat_11: 0,
    char_12: 'tingyun',
    app_rate_12: '10.87%',
    avg_round_12: 23860,
    app_flat_12: 2,
    char_13: 'bronya',
    app_rate_13: '10.87%',
    avg_round_13: 0,
    app_flat_13: 0,
    char_14: 'kafka',
    app_rate_14: '6.52%',
    avg_round_14: 0,
    app_flat_14: 0,
    char_15: 'black-swan',
    app_rate_15: '6.52%',
    avg_round_15: 0,
    app_flat_15: 0,
    char_16: 'pela',
    app_rate_16: '4.35%',
    avg_round_16: 0,
    app_flat_16: 0,
    char_17: 'argenti',
    app_rate_17: '4.35%',
    avg_round_17: 40000,
    app_flat_17: 1,
    char_18: 'blade',
    app_rate_18: '4.35%',
    avg_round_18: 24240,
    app_flat_18: 1,
    char_19: 'jiaoqiu',
    app_rate_19: '4.35%',
    avg_round_19: 0,
    app_flat_19: 0,
    char_20: 'tingyun-fugue',
    app_rate_20: '2.17%',
    avg_round_20: 24160,
    app_flat_20: 1,
    char_21: 'jing-yuan',
    app_rate_21: '2.17%',
    avg_round_21: 27240,
    app_flat_21: 1,
    char_22: '',
    app_rate_22: '0.00%',
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'imbibitor-lunae',
    app: 0.4,
    char_1: 'sparkle',
    app_rate_1: '51.16%',
    avg_round_1: 0,
    app_flat_1: 0,
    char_2: 'sunday',
    app_rate_2: '51.16%',
    avg_round_2: 0,
    app_flat_2: 0,
    char_3: 'huohuo',
    app_rate_3: '41.86%',
    avg_round_3: 0,
    app_flat_3: 0,
    char_4: 'robin',
    app_rate_4: '39.53%',
    avg_round_4: 0,
    app_flat_4: 0,
    char_5: 'luocha',
    app_rate_5: '27.91%',
    avg_round_5: 0,
    app_flat_5: 0,
    char_6: 'tingyun',
    app_rate_6: '25.58%',
    avg_round_6: 0,
    app_flat_6: 0,
    char_7: 'ruan-mei',
    app_rate_7: '20.93%',
    avg_round_7: 0,
    app_flat_7: 0,
    char_8: 'aventurine',
    app_rate_8: '16.28%',
    avg_round_8: 0,
    app_flat_8: 0,
    char_9: 'gallagher',
    app_rate_9: '9.3%',
    avg_round_9: 0,
    app_flat_9: 0,
    char_10: 'jiaoqiu',
    app_rate_10: '4.65%',
    avg_round_10: 0,
    app_flat_10: 0,
    char_11: 'tingyun-fugue',
    app_rate_11: '2.33%',
    avg_round_11: 0,
    app_flat_11: 0,
    char_12: 'march-7th',
    app_rate_12: '2.33%',
    avg_round_12: 0,
    app_flat_12: 0,
    char_13: 'trailblazer-remembrance',
    app_rate_13: '2.33%',
    avg_round_13: 0,
    app_flat_13: 0,
    char_14: 'jade',
    app_rate_14: '2.33%',
    avg_round_14: 0,
    app_flat_14: 0,
    char_15: 'fu-xuan',
    app_rate_15: '2.33%',
    avg_round_15: 0,
    app_flat_15: 0,
    char_16: '',
    app_rate_16: '0.00%',
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '',
    app_rate_17: '0.00%',
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '',
    app_rate_18: '0.00%',
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '',
    app_rate_19: '0.00%',
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '',
    app_rate_20: '0.00%',
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '',
    app_rate_21: '0.00%',
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '',
    app_rate_22: '0.00%',
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'welt',
    app: 0.3,
    char_1: 'acheron',
    app_rate_1: '96.88%',
    avg_round_1: 24978,
    app_flat_1: 17,
    char_2: 'pela',
    app_rate_2: '56.25%',
    avg_round_2: 24060,
    app_flat_2: 12,
    char_3: 'aventurine',
    app_rate_3: '34.38%',
    avg_round_3: 30897,
    app_flat_3: 7,
    char_4: 'gallagher',
    app_rate_4: '28.12%',
    avg_round_4: 24247,
    app_flat_4: 6,
    char_5: 'jiaoqiu',
    app_rate_5: '15.62%',
    avg_round_5: 34133,
    app_flat_5: 3,
    char_6: 'fu-xuan',
    app_rate_6: '12.5%',
    avg_round_6: 22800,
    app_flat_6: 1,
    char_7: 'ruan-mei',
    app_rate_7: '12.5%',
    avg_round_7: 24740,
    app_flat_7: 2,
    char_8: 'sparkle',
    app_rate_8: '9.38%',
    avg_round_8: 24400,
    app_flat_8: 1,
    char_9: 'argenti',
    app_rate_9: '6.25%',
    avg_round_9: 0,
    app_flat_9: 0,
    char_10: 'lingsha',
    app_rate_10: '6.25%',
    avg_round_10: 25480,
    app_flat_10: 1,
    char_11: 'tingyun-fugue',
    app_rate_11: '6.25%',
    avg_round_11: 26840,
    app_flat_11: 1,
    char_12: 'silver-wolf',
    app_rate_12: '3.12%',
    avg_round_12: 0,
    app_flat_12: 0,
    char_13: 'robin',
    app_rate_13: '3.12%',
    avg_round_13: 0,
    app_flat_13: 0,
    char_14: 'jade',
    app_rate_14: '3.12%',
    avg_round_14: 0,
    app_flat_14: 0,
    char_15: 'sunday',
    app_rate_15: '3.12%',
    avg_round_15: 0,
    app_flat_15: 0,
    char_16: 'gepard',
    app_rate_16: '3.12%',
    avg_round_16: 0,
    app_flat_16: 0,
    char_17: '',
    app_rate_17: '0.00%',
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '',
    app_rate_18: '0.00%',
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '',
    app_rate_19: '0.00%',
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '',
    app_rate_20: '0.00%',
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '',
    app_rate_21: '0.00%',
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '',
    app_rate_22: '0.00%',
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'seele',
    app: 0.21,
    char_1: 'robin',
    app_rate_1: '59.09%',
    avg_round_1: 27680,
    app_flat_1: 2,
    char_2: 'sparkle',
    app_rate_2: '59.09%',
    avg_round_2: 23920,
    app_flat_2: 1,
    char_3: 'sunday',
    app_rate_3: '40.91%',
    avg_round_3: 0,
    app_flat_3: 0,
    char_4: 'fu-xuan',
    app_rate_4: '40.91%',
    avg_round_4: 23920,
    app_flat_4: 1,
    char_5: 'jade',
    app_rate_5: '22.73%',
    avg_round_5: 0,
    app_flat_5: 0,
    char_6: 'herta',
    app_rate_6: '18.18%',
    avg_round_6: 26720,
    app_flat_6: 1,
    char_7: 'trailblazer-remembrance',
    app_rate_7: '13.64%',
    avg_round_7: 26280,
    app_flat_7: 2,
    char_8: 'huohuo',
    app_rate_8: '13.64%',
    avg_round_8: 0,
    app_flat_8: 0,
    char_9: 'tingyun',
    app_rate_9: '9.09%',
    avg_round_9: 0,
    app_flat_9: 0,
    char_10: 'lingsha',
    app_rate_10: '9.09%',
    avg_round_10: 28640,
    app_flat_10: 1,
    char_11: 'gallagher',
    app_rate_11: '9.09%',
    avg_round_11: 26720,
    app_flat_11: 1,
    char_12: 'silver-wolf',
    app_rate_12: '4.55%',
    avg_round_12: 0,
    app_flat_12: 0,
    char_13: '',
    app_rate_13: '0.00%',
    avg_round_13: 0.0,
    app_flat_13: 0,
    char_14: '',
    app_rate_14: '0.00%',
    avg_round_14: 0.0,
    app_flat_14: 0,
    char_15: '',
    app_rate_15: '0.00%',
    avg_round_15: 0.0,
    app_flat_15: 0,
    char_16: '',
    app_rate_16: '0.00%',
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '',
    app_rate_17: '0.00%',
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '',
    app_rate_18: '0.00%',
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '',
    app_rate_19: '0.00%',
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '',
    app_rate_20: '0.00%',
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '',
    app_rate_21: '0.00%',
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '',
    app_rate_22: '0.00%',
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'boothill',
    app: 0.15,
    char_1: 'tingyun-fugue',
    app_rate_1: '75.0%',
    avg_round_1: 23760,
    app_flat_1: 1,
    char_2: 'ruan-mei',
    app_rate_2: '75.0%',
    avg_round_2: 23760,
    app_flat_2: 1,
    char_3: 'herta',
    app_rate_3: '18.75%',
    avg_round_3: 0,
    app_flat_3: 0,
    char_4: 'bronya',
    app_rate_4: '18.75%',
    avg_round_4: 0,
    app_flat_4: 0,
    char_5: 'lingsha',
    app_rate_5: '18.75%',
    avg_round_5: 23760,
    app_flat_5: 1,
    char_6: 'gallagher',
    app_rate_6: '18.75%',
    avg_round_6: 0,
    app_flat_6: 0,
    char_7: 'trailblazer-harmony',
    app_rate_7: '12.5%',
    avg_round_7: 0,
    app_flat_7: 0,
    char_8: 'sunday',
    app_rate_8: '12.5%',
    avg_round_8: 0,
    app_flat_8: 0,
    char_9: 'argenti',
    app_rate_9: '6.25%',
    avg_round_9: 0,
    app_flat_9: 0,
    char_10: 'rappa',
    app_rate_10: '6.25%',
    avg_round_10: 0,
    app_flat_10: 0,
    char_11: 'robin',
    app_rate_11: '6.25%',
    avg_round_11: 0,
    app_flat_11: 0,
    char_12: 'the-herta',
    app_rate_12: '6.25%',
    avg_round_12: 0,
    app_flat_12: 0,
    char_13: 'sparkle',
    app_rate_13: '6.25%',
    avg_round_13: 0,
    app_flat_13: 0,
    char_14: 'acheron',
    app_rate_14: '6.25%',
    avg_round_14: 28000,
    app_flat_14: 1,
    char_15: 'jiaoqiu',
    app_rate_15: '6.25%',
    avg_round_15: 28000,
    app_flat_15: 1,
    char_16: 'aventurine',
    app_rate_16: '6.25%',
    avg_round_16: 28000,
    app_flat_16: 1,
    char_17: '',
    app_rate_17: '0.00%',
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '',
    app_rate_18: '0.00%',
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '',
    app_rate_19: '0.00%',
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '',
    app_rate_20: '0.00%',
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '',
    app_rate_21: '0.00%',
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '',
    app_rate_22: '0.00%',
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'xueyi',
    app: 0.15,
    char_1: 'ruan-mei',
    app_rate_1: '56.25%',
    avg_round_1: 24587,
    app_flat_1: 3,
    char_2: 'tingyun-fugue',
    app_rate_2: '43.75%',
    avg_round_2: 24587,
    app_flat_2: 3,
    char_3: 'lingsha',
    app_rate_3: '31.25%',
    avg_round_3: 24600,
    app_flat_3: 3,
    char_4: 'jade',
    app_rate_4: '25.0%',
    avg_round_4: 24200,
    app_flat_4: 1,
    char_5: 'sunday',
    app_rate_5: '18.75%',
    avg_round_5: 24080,
    app_flat_5: 2,
    char_6: 'fu-xuan',
    app_rate_6: '18.75%',
    avg_round_6: 24100,
    app_flat_6: 2,
    char_7: 'sparkle',
    app_rate_7: '18.75%',
    avg_round_7: 24200,
    app_flat_7: 1,
    char_8: 'robin',
    app_rate_8: '12.5%',
    avg_round_8: 0,
    app_flat_8: 0,
    char_9: 'trailblazer-remembrance',
    app_rate_9: '12.5%',
    avg_round_9: 0,
    app_flat_9: 0,
    char_10: 'gallagher',
    app_rate_10: '12.5%',
    avg_round_10: 24120,
    app_flat_10: 1,
    char_11: 'aventurine',
    app_rate_11: '12.5%',
    avg_round_11: 0,
    app_flat_11: 0,
    char_12: 'argenti',
    app_rate_12: '6.25%',
    avg_round_12: 24160,
    app_flat_12: 1,
    char_13: 'luocha',
    app_rate_13: '6.25%',
    avg_round_13: 0,
    app_flat_13: 0,
    char_14: 'serval',
    app_rate_14: '6.25%',
    avg_round_14: 24000,
    app_flat_14: 1,
    char_15: 'huohuo',
    app_rate_15: '6.25%',
    avg_round_15: 0,
    app_flat_15: 0,
    char_16: 'himeko',
    app_rate_16: '6.25%',
    avg_round_16: 0,
    app_flat_16: 0,
    char_17: 'kafka',
    app_rate_17: '6.25%',
    avg_round_17: 0,
    app_flat_17: 0,
    char_18: '',
    app_rate_18: '0.00%',
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '',
    app_rate_19: '0.00%',
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '',
    app_rate_20: '0.00%',
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '',
    app_rate_21: '0.00%',
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '',
    app_rate_22: '0.00%',
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'asta',
    app: 0.15,
    char_1: 'herta',
    app_rate_1: '50.0%',
    avg_round_1: 27387,
    app_flat_1: 6,
    char_2: 'huohuo',
    app_rate_2: '25.0%',
    avg_round_2: 29620,
    app_flat_2: 2,
    char_3: 'the-herta',
    app_rate_3: '25.0%',
    avg_round_3: 37693,
    app_flat_3: 3,
    char_4: 'gallagher',
    app_rate_4: '18.75%',
    avg_round_4: 27640,
    app_flat_4: 2,
    char_5: 'aventurine',
    app_rate_5: '18.75%',
    avg_round_5: 31400,
    app_flat_5: 3,
    char_6: 'kafka',
    app_rate_6: '18.75%',
    avg_round_6: 0,
    app_flat_6: 0,
    char_7: 'lingsha',
    app_rate_7: '12.5%',
    avg_round_7: 40000,
    app_flat_7: 1,
    char_8: 'himeko',
    app_rate_8: '12.5%',
    avg_round_8: 21460,
    app_flat_8: 2,
    char_9: 'fu-xuan',
    app_rate_9: '12.5%',
    avg_round_9: 20480,
    app_flat_9: 1,
    char_10: 'acheron',
    app_rate_10: '12.5%',
    avg_round_10: 32840,
    app_flat_10: 1,
    char_11: 'black-swan',
    app_rate_11: '12.5%',
    avg_round_11: 0,
    app_flat_11: 0,
    char_12: 'trailblazer-remembrance',
    app_rate_12: '6.25%',
    avg_round_12: 0,
    app_flat_12: 0,
    char_13: 'jade',
    app_rate_13: '6.25%',
    avg_round_13: 40000,
    app_flat_13: 1,
    char_14: 'argenti',
    app_rate_14: '6.25%',
    avg_round_14: 24000,
    app_flat_14: 1,
    char_15: 'tingyun',
    app_rate_15: '6.25%',
    avg_round_15: 24000,
    app_flat_15: 1,
    char_16: 'firefly',
    app_rate_16: '6.25%',
    avg_round_16: 0,
    app_flat_16: 0,
    char_17: 'tingyun-fugue',
    app_rate_17: '6.25%',
    avg_round_17: 0,
    app_flat_17: 0,
    char_18: 'serval',
    app_rate_18: '6.25%',
    avg_round_18: 24120,
    app_flat_18: 1,
    char_19: 'hanya',
    app_rate_19: '6.25%',
    avg_round_19: 24200,
    app_flat_19: 1,
    char_20: 'pela',
    app_rate_20: '6.25%',
    avg_round_20: 24200,
    app_flat_20: 1,
    char_21: 'jiaoqiu',
    app_rate_21: '6.25%',
    avg_round_21: 32840,
    app_flat_21: 1,
    char_22: 'jing-yuan',
    app_rate_22: '6.25%',
    avg_round_22: 32240,
    app_flat_22: 1,
    char_23: 'sunday',
    app_rate_23: '6.25%',
    avg_round_23: 32240,
    app_flat_23: 1,
    char_24: 'march-7th',
    app_rate_24: '6.25%',
    avg_round_24: 0,
    app_flat_24: 0,
    char_25: '',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'qingque',
    app: 0.13,
    char_1: 'sparkle',
    app_rate_1: '92.86%',
    avg_round_1: 21547,
    app_flat_1: 3,
    char_2: 'fu-xuan',
    app_rate_2: '64.29%',
    avg_round_2: 21760,
    app_flat_2: 2,
    char_3: 'sunday',
    app_rate_3: '28.57%',
    avg_round_3: 21560,
    app_flat_3: 1,
    char_4: 'herta',
    app_rate_4: '21.43%',
    avg_round_4: 0,
    app_flat_4: 0,
    char_5: 'robin',
    app_rate_5: '21.43%',
    avg_round_5: 26780,
    app_flat_5: 2,
    char_6: 'jade',
    app_rate_6: '14.29%',
    avg_round_6: 0,
    app_flat_6: 0,
    char_7: 'lingsha',
    app_rate_7: '14.29%',
    avg_round_7: 21120,
    app_flat_7: 1,
    char_8: 'silver-wolf',
    app_rate_8: '7.14%',
    avg_round_8: 0,
    app_flat_8: 0,
    char_9: 'aventurine',
    app_rate_9: '7.14%',
    avg_round_9: 0,
    app_flat_9: 0,
    char_10: 'serval',
    app_rate_10: '7.14%',
    avg_round_10: 0,
    app_flat_10: 0,
    char_11: 'the-herta',
    app_rate_11: '7.14%',
    avg_round_11: 31600,
    app_flat_11: 1,
    char_12: 'trailblazer-remembrance',
    app_rate_12: '7.14%',
    avg_round_12: 31600,
    app_flat_12: 1,
    char_13: 'tingyun-fugue',
    app_rate_13: '7.14%',
    avg_round_13: 21120,
    app_flat_13: 1,
    char_14: '',
    app_rate_14: '0.00%',
    avg_round_14: 0.0,
    app_flat_14: 0,
    char_15: '',
    app_rate_15: '0.00%',
    avg_round_15: 0.0,
    app_flat_15: 0,
    char_16: '',
    app_rate_16: '0.00%',
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '',
    app_rate_17: '0.00%',
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '',
    app_rate_18: '0.00%',
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '',
    app_rate_19: '0.00%',
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '',
    app_rate_20: '0.00%',
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '',
    app_rate_21: '0.00%',
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '',
    app_rate_22: '0.00%',
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'natasha',
    app: 0.1,
    char_1: 'the-herta',
    app_rate_1: '54.55%',
    avg_round_1: 31280,
    app_flat_1: 1,
    char_2: 'ruan-mei',
    app_rate_2: '36.36%',
    avg_round_2: 0,
    app_flat_2: 0,
    char_3: 'robin',
    app_rate_3: '27.27%',
    avg_round_3: 31280,
    app_flat_3: 1,
    char_4: 'kafka',
    app_rate_4: '27.27%',
    avg_round_4: 0,
    app_flat_4: 0,
    char_5: 'herta',
    app_rate_5: '27.27%',
    avg_round_5: 24160,
    app_flat_5: 1,
    char_6: 'trailblazer-remembrance',
    app_rate_6: '27.27%',
    avg_round_6: 24160,
    app_flat_6: 1,
    char_7: 'argenti',
    app_rate_7: '18.18%',
    avg_round_7: 27720,
    app_flat_7: 2,
    char_8: 'himeko',
    app_rate_8: '18.18%',
    avg_round_8: 0,
    app_flat_8: 0,
    char_9: 'black-swan',
    app_rate_9: '18.18%',
    avg_round_9: 0,
    app_flat_9: 0,
    char_10: 'jade',
    app_rate_10: '18.18%',
    avg_round_10: 0,
    app_flat_10: 0,
    char_11: 'acheron',
    app_rate_11: '9.09%',
    avg_round_11: 0,
    app_flat_11: 0,
    char_12: 'sunday',
    app_rate_12: '9.09%',
    avg_round_12: 0,
    app_flat_12: 0,
    char_13: 'yunli',
    app_rate_13: '9.09%',
    avg_round_13: 0,
    app_flat_13: 0,
    char_14: '',
    app_rate_14: '0.00%',
    avg_round_14: 0.0,
    app_flat_14: 0,
    char_15: '',
    app_rate_15: '0.00%',
    avg_round_15: 0.0,
    app_flat_15: 0,
    char_16: '',
    app_rate_16: '0.00%',
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '',
    app_rate_17: '0.00%',
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '',
    app_rate_18: '0.00%',
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '',
    app_rate_19: '0.00%',
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '',
    app_rate_20: '0.00%',
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '',
    app_rate_21: '0.00%',
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '',
    app_rate_22: '0.00%',
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'hanya',
    app: 0.1,
    char_1: 'argenti',
    app_rate_1: '54.55%',
    avg_round_1: 26660,
    app_flat_1: 2,
    char_2: 'the-herta',
    app_rate_2: '36.36%',
    avg_round_2: 0,
    app_flat_2: 0,
    char_3: 'huohuo',
    app_rate_3: '36.36%',
    avg_round_3: 0,
    app_flat_3: 0,
    char_4: 'herta',
    app_rate_4: '36.36%',
    avg_round_4: 24200,
    app_flat_4: 1,
    char_5: 'tingyun',
    app_rate_5: '18.18%',
    avg_round_5: 25400,
    app_flat_5: 1,
    char_6: 'luocha',
    app_rate_6: '18.18%',
    avg_round_6: 27920,
    app_flat_6: 1,
    char_7: 'sunday',
    app_rate_7: '18.18%',
    avg_round_7: 0,
    app_flat_7: 0,
    char_8: 'serval',
    app_rate_8: '9.09%',
    avg_round_8: 0,
    app_flat_8: 0,
    char_9: 'bronya',
    app_rate_9: '9.09%',
    avg_round_9: 27920,
    app_flat_9: 1,
    char_10: 'asta',
    app_rate_10: '9.09%',
    avg_round_10: 24200,
    app_flat_10: 1,
    char_11: 'pela',
    app_rate_11: '9.09%',
    avg_round_11: 24200,
    app_flat_11: 1,
    char_12: 'bailu',
    app_rate_12: '9.09%',
    avg_round_12: 0,
    app_flat_12: 0,
    char_13: 'ruan-mei',
    app_rate_13: '9.09%',
    avg_round_13: 0,
    app_flat_13: 0,
    char_14: 'gallagher',
    app_rate_14: '9.09%',
    avg_round_14: 0,
    app_flat_14: 0,
    char_15: 'trailblazer-remembrance',
    app_rate_15: '9.09%',
    avg_round_15: 0,
    app_flat_15: 0,
    char_16: 'aventurine',
    app_rate_16: '9.09%',
    avg_round_16: 25400,
    app_flat_16: 1,
    char_17: '',
    app_rate_17: '0.00%',
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '',
    app_rate_18: '0.00%',
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '',
    app_rate_19: '0.00%',
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '',
    app_rate_20: '0.00%',
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '',
    app_rate_21: '0.00%',
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '',
    app_rate_22: '0.00%',
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'sampo',
    app: 0.08,
    char_1: 'acheron',
    app_rate_1: '75.0%',
    avg_round_1: 26080,
    app_flat_1: 3,
    char_2: 'pela',
    app_rate_2: '25.0%',
    avg_round_2: 29280,
    app_flat_2: 1,
    char_3: 'march-7th',
    app_rate_3: '25.0%',
    avg_round_3: 29280,
    app_flat_3: 1,
    char_4: 'fu-xuan',
    app_rate_4: '25.0%',
    avg_round_4: 24080,
    app_flat_4: 1,
    char_5: 'kafka',
    app_rate_5: '25.0%',
    avg_round_5: 24880,
    app_flat_5: 1,
    char_6: 'jing-yuan',
    app_rate_6: '12.5%',
    avg_round_6: 0,
    app_flat_6: 0,
    char_7: 'sunday',
    app_rate_7: '12.5%',
    avg_round_7: 0,
    app_flat_7: 0,
    char_8: 'luocha',
    app_rate_8: '12.5%',
    avg_round_8: 0,
    app_flat_8: 0,
    char_9: 'black-swan',
    app_rate_9: '12.5%',
    avg_round_9: 24080,
    app_flat_9: 1,
    char_10: 'gallagher',
    app_rate_10: '12.5%',
    avg_round_10: 24880,
    app_flat_10: 1,
    char_11: 'jiaoqiu',
    app_rate_11: '12.5%',
    avg_round_11: 0,
    app_flat_11: 0,
    char_12: 'ruan-mei',
    app_rate_12: '12.5%',
    avg_round_12: 0,
    app_flat_12: 0,
    char_13: 'lingsha',
    app_rate_13: '12.5%',
    avg_round_13: 0,
    app_flat_13: 0,
    char_14: 'sparkle',
    app_rate_14: '12.5%',
    avg_round_14: 0,
    app_flat_14: 0,
    char_15: 'huohuo',
    app_rate_15: '12.5%',
    avg_round_15: 0,
    app_flat_15: 0,
    char_16: '',
    app_rate_16: '0.00%',
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '',
    app_rate_17: '0.00%',
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '',
    app_rate_18: '0.00%',
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '',
    app_rate_19: '0.00%',
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '',
    app_rate_20: '0.00%',
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '',
    app_rate_21: '0.00%',
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '',
    app_rate_22: '0.00%',
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'trailblazer-preservation',
    app: 0.04,
    char_1: 'acheron',
    app_rate_1: '100.0%',
    avg_round_1: 37960,
    app_flat_1: 1,
    char_2: 'pela',
    app_rate_2: '75.0%',
    avg_round_2: 37960,
    app_flat_2: 1,
    char_3: 'sparkle',
    app_rate_3: '75.0%',
    avg_round_3: 37960,
    app_flat_3: 1,
    char_4: 'kafka',
    app_rate_4: '25.0%',
    avg_round_4: 0,
    app_flat_4: 0,
    char_5: 'black-swan',
    app_rate_5: '25.0%',
    avg_round_5: 0,
    app_flat_5: 0,
    char_6: '',
    app_rate_6: '0.00%',
    avg_round_6: 0.0,
    app_flat_6: 0,
    char_7: '',
    app_rate_7: '0.00%',
    avg_round_7: 0.0,
    app_flat_7: 0,
    char_8: '',
    app_rate_8: '0.00%',
    avg_round_8: 0.0,
    app_flat_8: 0,
    char_9: '',
    app_rate_9: '0.00%',
    avg_round_9: 0.0,
    app_flat_9: 0,
    char_10: '',
    app_rate_10: '0.00%',
    avg_round_10: 0.0,
    app_flat_10: 0,
    char_11: '',
    app_rate_11: '0.00%',
    avg_round_11: 0.0,
    app_flat_11: 0,
    char_12: '',
    app_rate_12: '0.00%',
    avg_round_12: 0.0,
    app_flat_12: 0,
    char_13: '',
    app_rate_13: '0.00%',
    avg_round_13: 0.0,
    app_flat_13: 0,
    char_14: '',
    app_rate_14: '0.00%',
    avg_round_14: 0.0,
    app_flat_14: 0,
    char_15: '',
    app_rate_15: '0.00%',
    avg_round_15: 0.0,
    app_flat_15: 0,
    char_16: '',
    app_rate_16: '0.00%',
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '',
    app_rate_17: '0.00%',
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '',
    app_rate_18: '0.00%',
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '',
    app_rate_19: '0.00%',
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '',
    app_rate_20: '0.00%',
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '',
    app_rate_21: '0.00%',
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '',
    app_rate_22: '0.00%',
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'misha',
    app: 0.03,
    char_1: 'robin',
    app_rate_1: '66.67%',
    avg_round_1: 0,
    app_flat_1: 0,
    char_2: 'herta',
    app_rate_2: '66.67%',
    avg_round_2: 0,
    app_flat_2: 0,
    char_3: 'sparkle',
    app_rate_3: '33.33%',
    avg_round_3: 0,
    app_flat_3: 0,
    char_4: 'sunday',
    app_rate_4: '33.33%',
    avg_round_4: 0,
    app_flat_4: 0,
    char_5: 'huohuo',
    app_rate_5: '33.33%',
    avg_round_5: 0,
    app_flat_5: 0,
    char_6: 'himeko',
    app_rate_6: '33.33%',
    avg_round_6: 0,
    app_flat_6: 0,
    char_7: 'aventurine',
    app_rate_7: '33.33%',
    avg_round_7: 0,
    app_flat_7: 0,
    char_8: '',
    app_rate_8: '0.00%',
    avg_round_8: 0.0,
    app_flat_8: 0,
    char_9: '',
    app_rate_9: '0.00%',
    avg_round_9: 0.0,
    app_flat_9: 0,
    char_10: '',
    app_rate_10: '0.00%',
    avg_round_10: 0.0,
    app_flat_10: 0,
    char_11: '',
    app_rate_11: '0.00%',
    avg_round_11: 0.0,
    app_flat_11: 0,
    char_12: '',
    app_rate_12: '0.00%',
    avg_round_12: 0.0,
    app_flat_12: 0,
    char_13: '',
    app_rate_13: '0.00%',
    avg_round_13: 0.0,
    app_flat_13: 0,
    char_14: '',
    app_rate_14: '0.00%',
    avg_round_14: 0.0,
    app_flat_14: 0,
    char_15: '',
    app_rate_15: '0.00%',
    avg_round_15: 0.0,
    app_flat_15: 0,
    char_16: '',
    app_rate_16: '0.00%',
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '',
    app_rate_17: '0.00%',
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '',
    app_rate_18: '0.00%',
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '',
    app_rate_19: '0.00%',
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '',
    app_rate_20: '0.00%',
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '',
    app_rate_21: '0.00%',
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '',
    app_rate_22: '0.00%',
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'moze',
    app: 0.03,
    char_1: 'robin',
    app_rate_1: '66.67%',
    avg_round_1: 24160,
    app_flat_1: 1,
    char_2: 'aventurine',
    app_rate_2: '66.67%',
    avg_round_2: 24160,
    app_flat_2: 1,
    char_3: 'jing-yuan',
    app_rate_3: '33.33%',
    avg_round_3: 24720,
    app_flat_3: 1,
    char_4: 'sunday',
    app_rate_4: '33.33%',
    avg_round_4: 24720,
    app_flat_4: 1,
    char_5: 'fu-xuan',
    app_rate_5: '33.33%',
    avg_round_5: 24720,
    app_flat_5: 1,
    char_6: 'feixiao',
    app_rate_6: '33.33%',
    avg_round_6: 24160,
    app_flat_6: 1,
    char_7: 'dr-ratio',
    app_rate_7: '33.33%',
    avg_round_7: 0,
    app_flat_7: 0,
    char_8: '',
    app_rate_8: '0.00%',
    avg_round_8: 0.0,
    app_flat_8: 0,
    char_9: '',
    app_rate_9: '0.00%',
    avg_round_9: 0.0,
    app_flat_9: 0,
    char_10: '',
    app_rate_10: '0.00%',
    avg_round_10: 0.0,
    app_flat_10: 0,
    char_11: '',
    app_rate_11: '0.00%',
    avg_round_11: 0.0,
    app_flat_11: 0,
    char_12: '',
    app_rate_12: '0.00%',
    avg_round_12: 0.0,
    app_flat_12: 0,
    char_13: '',
    app_rate_13: '0.00%',
    avg_round_13: 0.0,
    app_flat_13: 0,
    char_14: '',
    app_rate_14: '0.00%',
    avg_round_14: 0.0,
    app_flat_14: 0,
    char_15: '',
    app_rate_15: '0.00%',
    avg_round_15: 0.0,
    app_flat_15: 0,
    char_16: '',
    app_rate_16: '0.00%',
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '',
    app_rate_17: '0.00%',
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '',
    app_rate_18: '0.00%',
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '',
    app_rate_19: '0.00%',
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '',
    app_rate_20: '0.00%',
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '',
    app_rate_21: '0.00%',
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '',
    app_rate_22: '0.00%',
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'dr-ratio',
    app: 0.03,
    char_1: 'aventurine',
    app_rate_1: '66.67%',
    avg_round_1: 0,
    app_flat_1: 0,
    char_2: 'pela',
    app_rate_2: '33.33%',
    avg_round_2: 0,
    app_flat_2: 0,
    char_3: 'tingyun',
    app_rate_3: '33.33%',
    avg_round_3: 0,
    app_flat_3: 0,
    char_4: 'gallagher',
    app_rate_4: '33.33%',
    avg_round_4: 0,
    app_flat_4: 0,
    char_5: 'jade',
    app_rate_5: '33.33%',
    avg_round_5: 0,
    app_flat_5: 0,
    char_6: 'topaz',
    app_rate_6: '33.33%',
    avg_round_6: 0,
    app_flat_6: 0,
    char_7: 'moze',
    app_rate_7: '33.33%',
    avg_round_7: 0,
    app_flat_7: 0,
    char_8: 'robin',
    app_rate_8: '33.33%',
    avg_round_8: 0,
    app_flat_8: 0,
    char_9: '',
    app_rate_9: '0.00%',
    avg_round_9: 0.0,
    app_flat_9: 0,
    char_10: '',
    app_rate_10: '0.00%',
    avg_round_10: 0.0,
    app_flat_10: 0,
    char_11: '',
    app_rate_11: '0.00%',
    avg_round_11: 0.0,
    app_flat_11: 0,
    char_12: '',
    app_rate_12: '0.00%',
    avg_round_12: 0.0,
    app_flat_12: 0,
    char_13: '',
    app_rate_13: '0.00%',
    avg_round_13: 0.0,
    app_flat_13: 0,
    char_14: '',
    app_rate_14: '0.00%',
    avg_round_14: 0.0,
    app_flat_14: 0,
    char_15: '',
    app_rate_15: '0.00%',
    avg_round_15: 0.0,
    app_flat_15: 0,
    char_16: '',
    app_rate_16: '0.00%',
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '',
    app_rate_17: '0.00%',
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '',
    app_rate_18: '0.00%',
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '',
    app_rate_19: '0.00%',
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '',
    app_rate_20: '0.00%',
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '',
    app_rate_21: '0.00%',
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '',
    app_rate_22: '0.00%',
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'yukong',
    app: 0.01,
    char_1: 'jade',
    app_rate_1: '100.0%',
    avg_round_1: 33800,
    app_flat_1: 1,
    char_2: 'tingyun',
    app_rate_2: '100.0%',
    avg_round_2: 33800,
    app_flat_2: 1,
    char_3: 'lingsha',
    app_rate_3: '100.0%',
    avg_round_3: 33800,
    app_flat_3: 1,
    char_4: '',
    app_rate_4: '0.00%',
    avg_round_4: 0.0,
    app_flat_4: 0,
    char_5: '',
    app_rate_5: '0.00%',
    avg_round_5: 0.0,
    app_flat_5: 0,
    char_6: '',
    app_rate_6: '0.00%',
    avg_round_6: 0.0,
    app_flat_6: 0,
    char_7: '',
    app_rate_7: '0.00%',
    avg_round_7: 0.0,
    app_flat_7: 0,
    char_8: '',
    app_rate_8: '0.00%',
    avg_round_8: 0.0,
    app_flat_8: 0,
    char_9: '',
    app_rate_9: '0.00%',
    avg_round_9: 0.0,
    app_flat_9: 0,
    char_10: '',
    app_rate_10: '0.00%',
    avg_round_10: 0.0,
    app_flat_10: 0,
    char_11: '',
    app_rate_11: '0.00%',
    avg_round_11: 0.0,
    app_flat_11: 0,
    char_12: '',
    app_rate_12: '0.00%',
    avg_round_12: 0.0,
    app_flat_12: 0,
    char_13: '',
    app_rate_13: '0.00%',
    avg_round_13: 0.0,
    app_flat_13: 0,
    char_14: '',
    app_rate_14: '0.00%',
    avg_round_14: 0.0,
    app_flat_14: 0,
    char_15: '',
    app_rate_15: '0.00%',
    avg_round_15: 0.0,
    app_flat_15: 0,
    char_16: '',
    app_rate_16: '0.00%',
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '',
    app_rate_17: '0.00%',
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '',
    app_rate_18: '0.00%',
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '',
    app_rate_19: '0.00%',
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '',
    app_rate_20: '0.00%',
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '',
    app_rate_21: '0.00%',
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '',
    app_rate_22: '0.00%',
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'dan-heng',
    app: 0.01,
    char_1: 'the-herta',
    app_rate_1: '100.0%',
    avg_round_1: 0,
    app_flat_1: 0,
    char_2: 'herta',
    app_rate_2: '100.0%',
    avg_round_2: 0,
    app_flat_2: 0,
    char_3: 'huohuo',
    app_rate_3: '100.0%',
    avg_round_3: 0,
    app_flat_3: 0,
    char_4: '',
    app_rate_4: '0.00%',
    avg_round_4: 0.0,
    app_flat_4: 0,
    char_5: '',
    app_rate_5: '0.00%',
    avg_round_5: 0.0,
    app_flat_5: 0,
    char_6: '',
    app_rate_6: '0.00%',
    avg_round_6: 0.0,
    app_flat_6: 0,
    char_7: '',
    app_rate_7: '0.00%',
    avg_round_7: 0.0,
    app_flat_7: 0,
    char_8: '',
    app_rate_8: '0.00%',
    avg_round_8: 0.0,
    app_flat_8: 0,
    char_9: '',
    app_rate_9: '0.00%',
    avg_round_9: 0.0,
    app_flat_9: 0,
    char_10: '',
    app_rate_10: '0.00%',
    avg_round_10: 0.0,
    app_flat_10: 0,
    char_11: '',
    app_rate_11: '0.00%',
    avg_round_11: 0.0,
    app_flat_11: 0,
    char_12: '',
    app_rate_12: '0.00%',
    avg_round_12: 0.0,
    app_flat_12: 0,
    char_13: '',
    app_rate_13: '0.00%',
    avg_round_13: 0.0,
    app_flat_13: 0,
    char_14: '',
    app_rate_14: '0.00%',
    avg_round_14: 0.0,
    app_flat_14: 0,
    char_15: '',
    app_rate_15: '0.00%',
    avg_round_15: 0.0,
    app_flat_15: 0,
    char_16: '',
    app_rate_16: '0.00%',
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '',
    app_rate_17: '0.00%',
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '',
    app_rate_18: '0.00%',
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '',
    app_rate_19: '0.00%',
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '',
    app_rate_20: '0.00%',
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '',
    app_rate_21: '0.00%',
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '',
    app_rate_22: '0.00%',
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'arlan',
    app: 0.01,
    char_1: 'kafka',
    app_rate_1: '100.0%',
    avg_round_1: 0,
    app_flat_1: 0,
    char_2: 'black-swan',
    app_rate_2: '100.0%',
    avg_round_2: 0,
    app_flat_2: 0,
    char_3: 'huohuo',
    app_rate_3: '100.0%',
    avg_round_3: 0,
    app_flat_3: 0,
    char_4: '',
    app_rate_4: '0.00%',
    avg_round_4: 0.0,
    app_flat_4: 0,
    char_5: '',
    app_rate_5: '0.00%',
    avg_round_5: 0.0,
    app_flat_5: 0,
    char_6: '',
    app_rate_6: '0.00%',
    avg_round_6: 0.0,
    app_flat_6: 0,
    char_7: '',
    app_rate_7: '0.00%',
    avg_round_7: 0.0,
    app_flat_7: 0,
    char_8: '',
    app_rate_8: '0.00%',
    avg_round_8: 0.0,
    app_flat_8: 0,
    char_9: '',
    app_rate_9: '0.00%',
    avg_round_9: 0.0,
    app_flat_9: 0,
    char_10: '',
    app_rate_10: '0.00%',
    avg_round_10: 0.0,
    app_flat_10: 0,
    char_11: '',
    app_rate_11: '0.00%',
    avg_round_11: 0.0,
    app_flat_11: 0,
    char_12: '',
    app_rate_12: '0.00%',
    avg_round_12: 0.0,
    app_flat_12: 0,
    char_13: '',
    app_rate_13: '0.00%',
    avg_round_13: 0.0,
    app_flat_13: 0,
    char_14: '',
    app_rate_14: '0.00%',
    avg_round_14: 0.0,
    app_flat_14: 0,
    char_15: '',
    app_rate_15: '0.00%',
    avg_round_15: 0.0,
    app_flat_15: 0,
    char_16: '',
    app_rate_16: '0.00%',
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '',
    app_rate_17: '0.00%',
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '',
    app_rate_18: '0.00%',
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '',
    app_rate_19: '0.00%',
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '',
    app_rate_20: '0.00%',
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '',
    app_rate_21: '0.00%',
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '',
    app_rate_22: '0.00%',
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'trailblazer-destruction',
    app: 0.01,
    char_1: 'tingyun-fugue',
    app_rate_1: '100.0%',
    avg_round_1: 0,
    app_flat_1: 0,
    char_2: 'ruan-mei',
    app_rate_2: '100.0%',
    avg_round_2: 0,
    app_flat_2: 0,
    char_3: 'lingsha',
    app_rate_3: '100.0%',
    avg_round_3: 0,
    app_flat_3: 0,
    char_4: '',
    app_rate_4: '0.00%',
    avg_round_4: 0.0,
    app_flat_4: 0,
    char_5: '',
    app_rate_5: '0.00%',
    avg_round_5: 0.0,
    app_flat_5: 0,
    char_6: '',
    app_rate_6: '0.00%',
    avg_round_6: 0.0,
    app_flat_6: 0,
    char_7: '',
    app_rate_7: '0.00%',
    avg_round_7: 0.0,
    app_flat_7: 0,
    char_8: '',
    app_rate_8: '0.00%',
    avg_round_8: 0.0,
    app_flat_8: 0,
    char_9: '',
    app_rate_9: '0.00%',
    avg_round_9: 0.0,
    app_flat_9: 0,
    char_10: '',
    app_rate_10: '0.00%',
    avg_round_10: 0.0,
    app_flat_10: 0,
    char_11: '',
    app_rate_11: '0.00%',
    avg_round_11: 0.0,
    app_flat_11: 0,
    char_12: '',
    app_rate_12: '0.00%',
    avg_round_12: 0.0,
    app_flat_12: 0,
    char_13: '',
    app_rate_13: '0.00%',
    avg_round_13: 0.0,
    app_flat_13: 0,
    char_14: '',
    app_rate_14: '0.00%',
    avg_round_14: 0.0,
    app_flat_14: 0,
    char_15: '',
    app_rate_15: '0.00%',
    avg_round_15: 0.0,
    app_flat_15: 0,
    char_16: '',
    app_rate_16: '0.00%',
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '',
    app_rate_17: '0.00%',
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '',
    app_rate_18: '0.00%',
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '',
    app_rate_19: '0.00%',
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '',
    app_rate_20: '0.00%',
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '',
    app_rate_21: '0.00%',
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '',
    app_rate_22: '0.00%',
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  }
];
