export const MoCDuos = [
  {
    char: 'ruan-mei',
    app: 82.28,
    char_1: 'firefly',
    app_rate_1: '56.86%',
    avg_round_1: 9.35,
    app_flat_1: 1194,
    char_2: 'trailblazer-harmony',
    app_rate_2: '49.27%',
    avg_round_2: 9.26,
    app_flat_2: 1264,
    char_3: 'tingyun-fugue',
    app_rate_3: '48.29%',
    avg_round_3: 8.7,
    app_flat_3: 1156,
    char_4: 'gallagher',
    app_rate_4: '34.11%',
    avg_round_4: 9.19,
    app_flat_4: 1320,
    char_5: 'lingsha',
    app_rate_5: '31.7%',
    avg_round_5: 8.72,
    app_flat_5: 947,
    char_6: 'rappa',
    app_rate_6: '22.88%',
    avg_round_6: 8.63,
    app_flat_6: 1089,
    char_7: 'kafka',
    app_rate_7: '7.58%',
    avg_round_7: 9.04,
    app_flat_7: 198,
    char_8: 'black-swan',
    app_rate_8: '7.52%',
    avg_round_8: 9.02,
    app_flat_8: 194,
    char_9: 'huohuo',
    app_rate_9: '7.37%',
    avg_round_9: 8.96,
    app_flat_9: 179,
    char_10: 'sunday',
    app_rate_10: '4.84%',
    avg_round_10: 9.15,
    app_flat_10: 79,
    char_11: 'luocha',
    app_rate_11: '4.27%',
    avg_round_11: 9.17,
    app_flat_11: 114,
    char_12: 'aventurine',
    app_rate_12: '3.69%',
    avg_round_12: 9.14,
    app_flat_12: 101,
    char_13: 'imbibitor-lunae',
    app_rate_13: '2.53%',
    avg_round_13: 9.44,
    app_flat_13: 17,
    char_14: 'fu-xuan',
    app_rate_14: '2.36%',
    avg_round_14: 9.7,
    app_flat_14: 45,
    char_15: 'jingliu',
    app_rate_15: '2.29%',
    avg_round_15: 9.29,
    app_flat_15: 41,
    char_16: 'boothill',
    app_rate_16: '2.23%',
    avg_round_16: 9.22,
    app_flat_16: 43,
    char_17: 'sparkle',
    app_rate_17: '2.03%',
    avg_round_17: 9.6,
    app_flat_17: 20,
    char_18: 'acheron',
    app_rate_18: '1.44%',
    avg_round_18: 9.5,
    app_flat_18: 16,
    char_19: 'jing-yuan',
    app_rate_19: '1.32%',
    avg_round_19: 9.0,
    app_flat_19: 32,
    char_20: 'bronya',
    app_rate_20: '1.23%',
    avg_round_20: 8.64,
    app_flat_20: 11,
    char_21: 'jiaoqiu',
    app_rate_21: '1.06%',
    avg_round_21: 9.2,
    app_flat_21: 5,
    char_22: 'jade',
    app_rate_22: '0.61%',
    avg_round_22: 9.37,
    app_flat_22: 19,
    char_23: 'feixiao',
    app_rate_23: '0.42%',
    avg_round_23: 8.5,
    app_flat_23: 6,
    char_24: 'pela',
    app_rate_24: '0.41%',
    avg_round_24: 9.67,
    app_flat_24: 3,
    char_25: 'robin',
    app_rate_25: '0.38%',
    avg_round_25: 8,
    app_flat_25: 1,
    char_26: 'himeko',
    app_rate_26: '0.37%',
    avg_round_26: 8.25,
    app_flat_26: 4,
    char_27: 'herta',
    app_rate_27: '0.36%',
    avg_round_27: 9.62,
    app_flat_27: 13,
    char_28: 'blade',
    app_rate_28: '0.35%',
    avg_round_28: 9.67,
    app_flat_28: 10,
    char_29: 'bailu',
    app_rate_29: '0.3%',
    avg_round_29: 9.5,
    app_flat_29: 8,
    char_30: 'tingyun',
    app_rate_30: '0.19%',
    avg_round_30: 8,
    app_flat_30: 1
  },
  {
    char: 'acheron',
    app: 55.93,
    char_1: 'jiaoqiu',
    app_rate_1: '51.17%',
    avg_round_1: 8.42,
    app_flat_1: 1171,
    char_2: 'aventurine',
    app_rate_2: '41.37%',
    avg_round_2: 8.92,
    app_flat_2: 934,
    char_3: 'pela',
    app_rate_3: '37.69%',
    avg_round_3: 8.83,
    app_flat_3: 1076,
    char_4: 'fu-xuan',
    app_rate_4: '31.84%',
    avg_round_4: 8.83,
    app_flat_4: 573,
    char_5: 'sparkle',
    app_rate_5: '27.88%',
    avg_round_5: 8.97,
    app_flat_5: 144,
    char_6: 'black-swan',
    app_rate_6: '23.91%',
    avg_round_6: 9.23,
    app_flat_6: 601,
    char_7: 'kafka',
    app_rate_7: '16.13%',
    avg_round_7: 9.32,
    app_flat_7: 391,
    char_8: 'silver-wolf',
    app_rate_8: '15.41%',
    avg_round_8: 9.02,
    app_flat_8: 335,
    char_9: 'sunday',
    app_rate_9: '12.55%',
    avg_round_9: 8.5,
    app_flat_9: 146,
    char_10: 'gallagher',
    app_rate_10: '9.17%',
    avg_round_10: 8.83,
    app_flat_10: 251,
    char_11: 'huohuo',
    app_rate_11: '6.36%',
    avg_round_11: 9.05,
    app_flat_11: 111,
    char_12: 'robin',
    app_rate_12: '5.14%',
    avg_round_12: 7.61,
    app_flat_12: 56,
    char_13: 'bronya',
    app_rate_13: '4.72%',
    avg_round_13: 8.5,
    app_flat_13: 6,
    char_14: 'tingyun-fugue',
    app_rate_14: '3.17%',
    avg_round_14: 9.2,
    app_flat_14: 97,
    char_15: 'gepard',
    app_rate_15: '2.89%',
    avg_round_15: 8.59,
    app_flat_15: 66,
    char_16: 'lingsha',
    app_rate_16: '2.73%',
    avg_round_16: 8.26,
    app_flat_16: 59,
    char_17: 'luocha',
    app_rate_17: '2.45%',
    avg_round_17: 9.09,
    app_flat_17: 42,
    char_18: 'ruan-mei',
    app_rate_18: '2.12%',
    avg_round_18: 9.5,
    app_flat_18: 16,
    char_19: 'guinaifen',
    app_rate_19: '0.98%',
    avg_round_19: 9.62,
    app_flat_19: 25,
    char_20: 'welt',
    app_rate_20: '0.47%',
    avg_round_20: 9.83,
    app_flat_20: 10,
    char_21: 'sampo',
    app_rate_21: '0.44%',
    avg_round_21: 9.33,
    app_flat_21: 9,
    char_22: 'bailu',
    app_rate_22: '0.4%',
    avg_round_22: 10.0,
    app_flat_22: 4,
    char_23: 'feixiao',
    app_rate_23: '0.21%',
    avg_round_23: 9,
    app_flat_23: 2,
    char_24: 'march-7th',
    app_rate_24: '0.14%',
    avg_round_24: 9.5,
    app_flat_24: 2,
    char_25: 'lynx',
    app_rate_25: '0.09%',
    avg_round_25: 9,
    app_flat_25: 2,
    char_26: 'himeko',
    app_rate_26: '0.07%',
    avg_round_26: 99.99,
    app_flat_26: 0,
    char_27: 'firefly',
    app_rate_27: '0.07%',
    avg_round_27: 99.99,
    app_flat_27: 0,
    char_28: 'rappa',
    app_rate_28: '0.05%',
    avg_round_28: 99.99,
    app_flat_28: 0,
    char_29: 'yunli',
    app_rate_29: '0.05%',
    avg_round_29: 99.99,
    app_flat_29: 0,
    char_30: 'trailblazer-harmony',
    app_rate_30: '0.05%',
    avg_round_30: 9,
    app_flat_30: 1
  },
  {
    char: 'aventurine',
    app: 51.96,
    char_1: 'robin',
    app_rate_1: '47.16%',
    avg_round_1: 8.91,
    app_flat_1: 928,
    char_2: 'acheron',
    app_rate_2: '44.54%',
    avg_round_2: 8.92,
    app_flat_2: 934,
    char_3: 'feixiao',
    app_rate_3: '32.8%',
    avg_round_3: 8.81,
    app_flat_3: 668,
    char_4: 'jiaoqiu',
    app_rate_4: '27.57%',
    avg_round_4: 8.55,
    app_flat_4: 611,
    char_5: 'sunday',
    app_rate_5: '19.68%',
    avg_round_5: 9.01,
    app_flat_5: 323,
    char_6: 'topaz',
    app_rate_6: '19.21%',
    avg_round_6: 8.72,
    app_flat_6: 290,
    char_7: 'pela',
    app_rate_7: '18.06%',
    avg_round_7: 8.97,
    app_flat_7: 512,
    char_8: 'sparkle',
    app_rate_8: '14.98%',
    avg_round_8: 9.22,
    app_flat_8: 108,
    char_9: 'black-swan',
    app_rate_9: '10.93%',
    avg_round_9: 9.3,
    app_flat_9: 279,
    char_10: 'march-7th-swordmaster',
    app_rate_10: '10.53%',
    avg_round_10: 8.88,
    app_flat_10: 320,
    char_11: 'jing-yuan',
    app_rate_11: '8.81%',
    avg_round_11: 9.21,
    app_flat_11: 181,
    char_12: 'kafka',
    app_rate_12: '6.93%',
    avg_round_12: 9.42,
    app_flat_12: 167,
    char_13: 'ruan-mei',
    app_rate_13: '5.85%',
    avg_round_13: 9.14,
    app_flat_13: 101,
    char_14: 'silver-wolf',
    app_rate_14: '5.49%',
    avg_round_14: 9.12,
    app_flat_14: 134,
    char_15: 'jade',
    app_rate_15: '3.7%',
    avg_round_15: 9.53,
    app_flat_15: 64,
    char_16: 'tingyun-fugue',
    app_rate_16: '2.64%',
    avg_round_16: 8.91,
    app_flat_16: 69,
    char_17: 'bronya',
    app_rate_17: '2.57%',
    avg_round_17: 9.67,
    app_flat_17: 17,
    char_18: 'rappa',
    app_rate_18: '2.15%',
    avg_round_18: 8.78,
    app_flat_18: 64,
    char_19: 'herta',
    app_rate_19: '2.13%',
    avg_round_19: 9.59,
    app_flat_19: 57,
    char_20: 'imbibitor-lunae',
    app_rate_20: '2.11%',
    avg_round_20: 9,
    app_flat_20: 10,
    char_21: 'moze',
    app_rate_21: '1.92%',
    avg_round_21: 8.76,
    app_flat_21: 33,
    char_22: 'trailblazer-harmony',
    app_rate_22: '1.89%',
    avg_round_22: 9.05,
    app_flat_22: 44,
    char_23: 'dr-ratio',
    app_rate_23: '1.81%',
    avg_round_23: 9.25,
    app_flat_23: 14,
    char_24: 'tingyun',
    app_rate_24: '1.4%',
    avg_round_24: 9.11,
    app_flat_24: 18,
    char_25: 'yunli',
    app_rate_25: '1.04%',
    avg_round_25: 8.93,
    app_flat_25: 15,
    char_26: 'jingliu',
    app_rate_26: '0.83%',
    avg_round_26: 8.78,
    app_flat_26: 9,
    char_27: 'firefly',
    app_rate_27: '0.72%',
    avg_round_27: 9.6,
    app_flat_27: 7,
    char_28: 'clara',
    app_rate_28: '0.49%',
    avg_round_28: 9.4,
    app_flat_28: 9,
    char_29: 'guinaifen',
    app_rate_29: '0.47%',
    avg_round_29: 9.4,
    app_flat_29: 10,
    char_30: 'sampo',
    app_rate_30: '0.25%',
    avg_round_30: 9.2,
    app_flat_30: 5
  },
  {
    char: 'firefly',
    app: 50.55,
    char_1: 'ruan-mei',
    app_rate_1: '92.55%',
    avg_round_1: 9.35,
    app_flat_1: 1194,
    char_2: 'trailblazer-harmony',
    app_rate_2: '65.32%',
    avg_round_2: 9.49,
    app_flat_2: 750,
    char_3: 'tingyun-fugue',
    app_rate_3: '58.31%',
    avg_round_3: 9.14,
    app_flat_3: 623,
    char_4: 'gallagher',
    app_rate_4: '39.52%',
    avg_round_4: 9.48,
    app_flat_4: 749,
    char_5: 'lingsha',
    app_rate_5: '38.62%',
    avg_round_5: 9.1,
    app_flat_5: 514,
    char_6: 'rappa',
    app_rate_6: '1.03%',
    avg_round_6: 9.67,
    app_flat_6: 6,
    char_7: 'luocha',
    app_rate_7: '0.83%',
    avg_round_7: 9.71,
    app_flat_7: 13,
    char_8: 'aventurine',
    app_rate_8: '0.74%',
    avg_round_8: 9.6,
    app_flat_8: 7,
    char_9: 'bronya',
    app_rate_9: '0.72%',
    avg_round_9: 99.99,
    app_flat_9: 0,
    char_10: 'huohuo',
    app_rate_10: '0.64%',
    avg_round_10: 8,
    app_flat_10: 2,
    char_11: 'fu-xuan',
    app_rate_11: '0.33%',
    avg_round_11: 10,
    app_flat_11: 2,
    char_12: 'himeko',
    app_rate_12: '0.27%',
    avg_round_12: 10,
    app_flat_12: 2,
    char_13: 'pela',
    app_rate_13: '0.21%',
    avg_round_13: 99.99,
    app_flat_13: 0,
    char_14: 'sunday',
    app_rate_14: '0.19%',
    avg_round_14: 10,
    app_flat_14: 1,
    char_15: 'robin',
    app_rate_15: '0.1%',
    avg_round_15: 99.99,
    app_flat_15: 0,
    char_16: 'acheron',
    app_rate_16: '0.08%',
    avg_round_16: 99.99,
    app_flat_16: 0,
    char_17: 'asta',
    app_rate_17: '0.06%',
    avg_round_17: 99.99,
    app_flat_17: 0,
    char_18: 'march-7th-swordmaster',
    app_rate_18: '0.06%',
    avg_round_18: 99.99,
    app_flat_18: 0,
    char_19: 'welt',
    app_rate_19: '0.04%',
    avg_round_19: 99.99,
    app_flat_19: 0,
    char_20: 'feixiao',
    app_rate_20: '0.04%',
    avg_round_20: 99.99,
    app_flat_20: 0,
    char_21: 'jiaoqiu',
    app_rate_21: '0.04%',
    avg_round_21: 99.99,
    app_flat_21: 0,
    char_22: 'trailblazer-destruction',
    app_rate_22: '0.04%',
    avg_round_22: 99.99,
    app_flat_22: 0,
    char_23: 'serval',
    app_rate_23: '0.02%',
    avg_round_23: 99.99,
    app_flat_23: 0,
    char_24: 'march-7th',
    app_rate_24: '0.02%',
    avg_round_24: 99.99,
    app_flat_24: 0,
    char_25: 'bailu',
    app_rate_25: '0.02%',
    avg_round_25: 99.99,
    app_flat_25: 0,
    char_26: 'black-swan',
    app_rate_26: '0.02%',
    avg_round_26: 99.99,
    app_flat_26: 0,
    char_27: 'gepard',
    app_rate_27: '0.02%',
    avg_round_27: 10,
    app_flat_27: 1,
    char_28: 'natasha',
    app_rate_28: '0.02%',
    avg_round_28: 99.99,
    app_flat_28: 0,
    char_29: 'guinaifen',
    app_rate_29: '0.02%',
    avg_round_29: 99.99,
    app_flat_29: 0,
    char_30: 'jingliu',
    app_rate_30: '0.02%',
    avg_round_30: 99.99,
    app_flat_30: 0
  },
  {
    char: 'tingyun-fugue',
    app: 47.79,
    char_1: 'ruan-mei',
    app_rate_1: '83.14%',
    avg_round_1: 8.7,
    app_flat_1: 1156,
    char_2: 'firefly',
    app_rate_2: '61.67%',
    avg_round_2: 9.14,
    app_flat_2: 623,
    char_3: 'lingsha',
    app_rate_3: '40.09%',
    avg_round_3: 8.42,
    app_flat_3: 706,
    char_4: 'trailblazer-harmony',
    app_rate_4: '35.84%',
    avg_round_4: 8.67,
    app_flat_4: 288,
    char_5: 'rappa',
    app_rate_5: '28.48%',
    avg_round_5: 8.03,
    app_flat_5: 722,
    char_6: 'gallagher',
    app_rate_6: '26.92%',
    avg_round_6: 8.94,
    app_flat_6: 652,
    char_7: 'acheron',
    app_rate_7: '3.71%',
    avg_round_7: 9.2,
    app_flat_7: 97,
    char_8: 'boothill',
    app_rate_8: '3.41%',
    avg_round_8: 9.21,
    app_flat_8: 35,
    char_9: 'aventurine',
    app_rate_9: '2.87%',
    avg_round_9: 8.91,
    app_flat_9: 69,
    char_10: 'huohuo',
    app_rate_10: '1.83%',
    avg_round_10: 8.68,
    app_flat_10: 37,
    char_11: 'luocha',
    app_rate_11: '1.46%',
    avg_round_11: 8.66,
    app_flat_11: 35,
    char_12: 'fu-xuan',
    app_rate_12: '1.42%',
    avg_round_12: 9.6,
    app_flat_12: 29,
    char_13: 'sunday',
    app_rate_13: '1.37%',
    avg_round_13: 9.5,
    app_flat_13: 14,
    char_14: 'pela',
    app_rate_14: '1.23%',
    avg_round_14: 8.9,
    app_flat_14: 39,
    char_15: 'jiaoqiu',
    app_rate_15: '1.15%',
    avg_round_15: 8.53,
    app_flat_15: 38,
    char_16: 'himeko',
    app_rate_16: '1.11%',
    avg_round_16: 9.67,
    app_flat_16: 12,
    char_17: 'bronya',
    app_rate_17: '0.66%',
    avg_round_17: 9,
    app_flat_17: 1,
    char_18: 'sparkle',
    app_rate_18: '0.53%',
    avg_round_18: 8.4,
    app_flat_18: 5,
    char_19: 'silver-wolf',
    app_rate_19: '0.49%',
    avg_round_19: 9.29,
    app_flat_19: 11,
    char_20: 'black-swan',
    app_rate_20: '0.49%',
    avg_round_20: 10.0,
    app_flat_20: 11,
    char_21: 'kafka',
    app_rate_21: '0.35%',
    avg_round_21: 9.17,
    app_flat_21: 6,
    char_22: 'xueyi',
    app_rate_22: '0.33%',
    avg_round_22: 9.5,
    app_flat_22: 2,
    char_23: 'robin',
    app_rate_23: '0.29%',
    avg_round_23: 9,
    app_flat_23: 2,
    char_24: 'gepard',
    app_rate_24: '0.16%',
    avg_round_24: 10.0,
    app_flat_24: 5,
    char_25: 'bailu',
    app_rate_25: '0.08%',
    avg_round_25: 10,
    app_flat_25: 2,
    char_26: 'jingliu',
    app_rate_26: '0.08%',
    avg_round_26: 10,
    app_flat_26: 1,
    char_27: 'march-7th-swordmaster',
    app_rate_27: '0.08%',
    avg_round_27: 9,
    app_flat_27: 2,
    char_28: 'welt',
    app_rate_28: '0.06%',
    avg_round_28: 8,
    app_flat_28: 1,
    char_29: 'feixiao',
    app_rate_29: '0.06%',
    avg_round_29: 99.99,
    app_flat_29: 0,
    char_30: 'jade',
    app_rate_30: '0.06%',
    avg_round_30: 9,
    app_flat_30: 1
  },
  {
    char: 'robin',
    app: 46.68,
    char_1: 'aventurine',
    app_rate_1: '52.5%',
    avg_round_1: 8.91,
    app_flat_1: 928,
    char_2: 'feixiao',
    app_rate_2: '46.01%',
    avg_round_2: 8.79,
    app_flat_2: 816,
    char_3: 'sunday',
    app_rate_3: '28.32%',
    avg_round_3: 8.66,
    app_flat_3: 474,
    char_4: 'topaz',
    app_rate_4: '23.7%',
    avg_round_4: 8.7,
    app_flat_4: 316,
    char_5: 'huohuo',
    app_rate_5: '19.58%',
    avg_round_5: 8.59,
    app_flat_5: 346,
    char_6: 'march-7th-swordmaster',
    app_rate_6: '18.7%',
    avg_round_6: 8.84,
    app_flat_6: 450,
    char_7: 'jing-yuan',
    app_rate_7: '16.79%',
    avg_round_7: 8.84,
    app_flat_7: 318,
    char_8: 'black-swan',
    app_rate_8: '9.08%',
    avg_round_8: 8.85,
    app_flat_8: 133,
    char_9: 'kafka',
    app_rate_9: '8.72%',
    avg_round_9: 8.91,
    app_flat_9: 132,
    char_10: 'fu-xuan',
    app_rate_10: '7.82%',
    avg_round_10: 8.94,
    app_flat_10: 97,
    char_11: 'jade',
    app_rate_11: '6.45%',
    avg_round_11: 9.33,
    app_flat_11: 116,
    char_12: 'acheron',
    app_rate_12: '6.16%',
    avg_round_12: 7.61,
    app_flat_12: 56,
    char_13: 'gallagher',
    app_rate_13: '5.92%',
    avg_round_13: 7.84,
    app_flat_13: 116,
    char_14: 'yunli',
    app_rate_14: '5.69%',
    avg_round_14: 8.42,
    app_flat_14: 111,
    char_15: 'luocha',
    app_rate_15: '5.08%',
    avg_round_15: 9.0,
    app_flat_15: 93,
    char_16: 'jingliu',
    app_rate_16: '4.64%',
    avg_round_16: 7.68,
    app_flat_16: 79,
    char_17: 'jiaoqiu',
    app_rate_17: '4.18%',
    avg_round_17: 7.4,
    app_flat_17: 43,
    char_18: 'sparkle',
    app_rate_18: '4.16%',
    avg_round_18: 8.92,
    app_flat_18: 22,
    char_19: 'lingsha',
    app_rate_19: '3.61%',
    avg_round_19: 8.51,
    app_flat_19: 77,
    char_20: 'herta',
    app_rate_20: '3.49%',
    avg_round_20: 9.52,
    app_flat_20: 92,
    char_21: 'imbibitor-lunae',
    app_rate_21: '2.86%',
    avg_round_21: 9.4,
    app_flat_21: 7,
    char_22: 'bronya',
    app_rate_22: '2.71%',
    avg_round_22: 8.9,
    app_flat_22: 18,
    char_23: 'moze',
    app_rate_23: '2.18%',
    avg_round_23: 8.78,
    app_flat_23: 34,
    char_24: 'tingyun',
    app_rate_24: '1.76%',
    avg_round_24: 8.58,
    app_flat_24: 37,
    char_25: 'dr-ratio',
    app_rate_25: '1.7%',
    avg_round_25: 9.29,
    app_flat_25: 11,
    char_26: 'pela',
    app_rate_26: '1.47%',
    avg_round_26: 8.57,
    app_flat_26: 14,
    char_27: 'clara',
    app_rate_27: '1.37%',
    avg_round_27: 9.5,
    app_flat_27: 26,
    char_28: 'blade',
    app_rate_28: '0.82%',
    avg_round_28: 10.0,
    app_flat_28: 10,
    char_29: 'seele',
    app_rate_29: '0.67%',
    avg_round_29: 7.2,
    app_flat_29: 5,
    char_30: 'ruan-mei',
    app_rate_30: '0.67%',
    avg_round_30: 8,
    app_flat_30: 1
  },
  {
    char: 'trailblazer-harmony',
    app: 46.55,
    char_1: 'ruan-mei',
    app_rate_1: '87.09%',
    avg_round_1: 9.26,
    app_flat_1: 1264,
    char_2: 'firefly',
    app_rate_2: '70.93%',
    avg_round_2: 9.49,
    app_flat_2: 750,
    char_3: 'gallagher',
    app_rate_3: '40.97%',
    avg_round_3: 9.25,
    app_flat_3: 922,
    char_4: 'tingyun-fugue',
    app_rate_4: '36.8%',
    avg_round_4: 8.67,
    app_flat_4: 288,
    char_5: 'lingsha',
    app_rate_5: '26.92%',
    avg_round_5: 9.0,
    app_flat_5: 461,
    char_6: 'rappa',
    app_rate_6: '26.0%',
    avg_round_6: 8.87,
    app_flat_6: 736,
    char_7: 'luocha',
    app_rate_7: '2.36%',
    avg_round_7: 9.47,
    app_flat_7: 68,
    char_8: 'aventurine',
    app_rate_8: '2.11%',
    avg_round_8: 9.05,
    app_flat_8: 44,
    char_9: 'huohuo',
    app_rate_9: '1.56%',
    avg_round_9: 9.38,
    app_flat_9: 39,
    char_10: 'boothill',
    app_rate_10: '1.47%',
    avg_round_10: 9.5,
    app_flat_10: 14,
    char_11: 'bronya',
    app_rate_11: '0.72%',
    avg_round_11: 9.67,
    app_flat_11: 3,
    char_12: 'pela',
    app_rate_12: '0.7%',
    avg_round_12: 9.78,
    app_flat_12: 15,
    char_13: 'sunday',
    app_rate_13: '0.51%',
    avg_round_13: 10.0,
    app_flat_13: 9,
    char_14: 'fu-xuan',
    app_rate_14: '0.46%',
    avg_round_14: 10.0,
    app_flat_14: 8,
    char_15: 'himeko',
    app_rate_15: '0.21%',
    avg_round_15: 9.67,
    app_flat_15: 3,
    char_16: 'march-7th-swordmaster',
    app_rate_16: '0.15%',
    avg_round_16: 8.67,
    app_flat_16: 3,
    char_17: 'xueyi',
    app_rate_17: '0.13%',
    avg_round_17: 10,
    app_flat_17: 1,
    char_18: 'bailu',
    app_rate_18: '0.08%',
    avg_round_18: 10,
    app_flat_18: 1,
    char_19: 'robin',
    app_rate_19: '0.06%',
    avg_round_19: 99.99,
    app_flat_19: 0,
    char_20: 'lynx',
    app_rate_20: '0.06%',
    avg_round_20: 10,
    app_flat_20: 1,
    char_21: 'welt',
    app_rate_21: '0.06%',
    avg_round_21: 99.99,
    app_flat_21: 0,
    char_22: 'imbibitor-lunae',
    app_rate_22: '0.06%',
    avg_round_22: 99.99,
    app_flat_22: 0,
    char_23: 'silver-wolf',
    app_rate_23: '0.06%',
    avg_round_23: 10,
    app_flat_23: 1,
    char_24: 'acheron',
    app_rate_24: '0.06%',
    avg_round_24: 9,
    app_flat_24: 1,
    char_25: 'asta',
    app_rate_25: '0.04%',
    avg_round_25: 99.99,
    app_flat_25: 0,
    char_26: 'jade',
    app_rate_26: '0.04%',
    avg_round_26: 8,
    app_flat_26: 1,
    char_27: 'serval',
    app_rate_27: '0.04%',
    avg_round_27: 99.99,
    app_flat_27: 0,
    char_28: 'natasha',
    app_rate_28: '0.04%',
    avg_round_28: 99.99,
    app_flat_28: 0,
    char_29: 'jiaoqiu',
    app_rate_29: '0.04%',
    avg_round_29: 99.99,
    app_flat_29: 0,
    char_30: 'kafka',
    app_rate_30: '0.04%',
    avg_round_30: 9,
    app_flat_30: 1
  },
  {
    char: 'gallagher',
    app: 38.89,
    char_1: 'ruan-mei',
    app_rate_1: '72.16%',
    avg_round_1: 9.19,
    app_flat_1: 1320,
    char_2: 'firefly',
    app_rate_2: '51.36%',
    avg_round_2: 9.48,
    app_flat_2: 749,
    char_3: 'trailblazer-harmony',
    app_rate_3: '49.04%',
    avg_round_3: 9.25,
    app_flat_3: 922,
    char_4: 'tingyun-fugue',
    app_rate_4: '33.08%',
    avg_round_4: 8.94,
    app_flat_4: 652,
    char_5: 'rappa',
    app_rate_5: '21.28%',
    avg_round_5: 8.7,
    app_flat_5: 621,
    char_6: 'acheron',
    app_rate_6: '13.19%',
    avg_round_6: 8.83,
    app_flat_6: 251,
    char_7: 'jiaoqiu',
    app_rate_7: '7.92%',
    avg_round_7: 8.65,
    app_flat_7: 159,
    char_8: 'black-swan',
    app_rate_8: '7.67%',
    avg_round_8: 8.96,
    app_flat_8: 138,
    char_9: 'robin',
    app_rate_9: '7.11%',
    avg_round_9: 7.84,
    app_flat_9: 116,
    char_10: 'kafka',
    app_rate_10: '6.66%',
    avg_round_10: 8.93,
    app_flat_10: 119,
    char_11: 'sunday',
    app_rate_11: '5.8%',
    avg_round_11: 8.72,
    app_flat_11: 70,
    char_12: 'pela',
    app_rate_12: '5.72%',
    avg_round_12: 8.89,
    app_flat_12: 150,
    char_13: 'sparkle',
    app_rate_13: '2.9%',
    avg_round_13: 9.18,
    app_flat_13: 19,
    char_14: 'feixiao',
    app_rate_14: '2.19%',
    avg_round_14: 8.25,
    app_flat_14: 48,
    char_15: 'jing-yuan',
    app_rate_15: '2.02%',
    avg_round_15: 8.47,
    app_flat_15: 27,
    char_16: 'march-7th-swordmaster',
    app_rate_16: '1.59%',
    avg_round_16: 8.41,
    app_flat_16: 37,
    char_17: 'boothill',
    app_rate_17: '1.59%',
    avg_round_17: 9.47,
    app_flat_17: 27,
    char_18: 'bronya',
    app_rate_18: '1.54%',
    avg_round_18: 7.4,
    app_flat_18: 10,
    char_19: 'silver-wolf',
    app_rate_19: '1.36%',
    avg_round_19: 8.93,
    app_flat_19: 29,
    char_20: 'imbibitor-lunae',
    app_rate_20: '0.96%',
    avg_round_20: 7.67,
    app_flat_20: 3,
    char_21: 'jingliu',
    app_rate_21: '0.88%',
    avg_round_21: 7.75,
    app_flat_21: 12,
    char_22: 'tingyun',
    app_rate_22: '0.63%',
    avg_round_22: 7.86,
    app_flat_22: 7,
    char_23: 'himeko',
    app_rate_23: '0.58%',
    avg_round_23: 9.14,
    app_flat_23: 7,
    char_24: 'sampo',
    app_rate_24: '0.43%',
    avg_round_24: 9.25,
    app_flat_24: 4,
    char_25: 'yunli',
    app_rate_25: '0.4%',
    avg_round_25: 8,
    app_flat_25: 6,
    char_26: 'blade',
    app_rate_26: '0.35%',
    avg_round_26: 9.5,
    app_flat_26: 4,
    char_27: 'jade',
    app_rate_27: '0.28%',
    avg_round_27: 8.25,
    app_flat_27: 4,
    char_28: 'topaz',
    app_rate_28: '0.25%',
    avg_round_28: 8,
    app_flat_28: 3,
    char_29: 'herta',
    app_rate_29: '0.15%',
    avg_round_29: 9.0,
    app_flat_29: 5,
    char_30: 'moze',
    app_rate_30: '0.13%',
    avg_round_30: 9,
    app_flat_30: 1
  },
  {
    char: 'lingsha',
    app: 32.22,
    char_1: 'ruan-mei',
    app_rate_1: '80.95%',
    avg_round_1: 8.72,
    app_flat_1: 947,
    char_2: 'firefly',
    app_rate_2: '60.59%',
    avg_round_2: 9.1,
    app_flat_2: 514,
    char_3: 'tingyun-fugue',
    app_rate_3: '59.46%',
    avg_round_3: 8.42,
    app_flat_3: 706,
    char_4: 'trailblazer-harmony',
    app_rate_4: '38.89%',
    avg_round_4: 9.0,
    app_flat_4: 461,
    char_5: 'rappa',
    app_rate_5: '24.28%',
    avg_round_5: 8.26,
    app_flat_5: 469,
    char_6: 'robin',
    app_rate_6: '5.23%',
    avg_round_6: 8.51,
    app_flat_6: 77,
    char_7: 'acheron',
    app_rate_7: '4.75%',
    avg_round_7: 8.26,
    app_flat_7: 59,
    char_8: 'feixiao',
    app_rate_8: '2.83%',
    avg_round_8: 8.45,
    app_flat_8: 40,
    char_9: 'black-swan',
    app_rate_9: '2.68%',
    avg_round_9: 8.47,
    app_flat_9: 32,
    char_10: 'sunday',
    app_rate_10: '2.56%',
    avg_round_10: 9.19,
    app_flat_10: 32,
    char_11: 'kafka',
    app_rate_11: '2.37%',
    avg_round_11: 8.36,
    app_flat_11: 28,
    char_12: 'jiaoqiu',
    app_rate_12: '2.22%',
    avg_round_12: 7.52,
    app_flat_12: 29,
    char_13: 'march-7th-swordmaster',
    app_rate_13: '1.61%',
    avg_round_13: 8.44,
    app_flat_13: 32,
    char_14: 'jade',
    app_rate_14: '1.58%',
    avg_round_14: 8.62,
    app_flat_14: 25,
    char_15: 'sparkle',
    app_rate_15: '1.46%',
    avg_round_15: 8.38,
    app_flat_15: 8,
    char_16: 'pela',
    app_rate_16: '1.43%',
    avg_round_16: 7.88,
    app_flat_16: 25,
    char_17: 'topaz',
    app_rate_17: '1.19%',
    avg_round_17: 8.08,
    app_flat_17: 12,
    char_18: 'bronya',
    app_rate_18: '0.88%',
    avg_round_18: 8.78,
    app_flat_18: 9,
    char_19: 'boothill',
    app_rate_19: '0.79%',
    avg_round_19: 8,
    app_flat_19: 8,
    char_20: 'jingliu',
    app_rate_20: '0.67%',
    avg_round_20: 9.0,
    app_flat_20: 8,
    char_21: 'silver-wolf',
    app_rate_21: '0.52%',
    avg_round_21: 8.75,
    app_flat_21: 4,
    char_22: 'jing-yuan',
    app_rate_22: '0.46%',
    avg_round_22: 8.88,
    app_flat_22: 8,
    char_23: 'himeko',
    app_rate_23: '0.4%',
    avg_round_23: 10,
    app_flat_23: 1,
    char_24: 'tingyun',
    app_rate_24: '0.37%',
    avg_round_24: 9.25,
    app_flat_24: 4,
    char_25: 'herta',
    app_rate_25: '0.33%',
    avg_round_25: 8.83,
    app_flat_25: 6,
    char_26: 'yunli',
    app_rate_26: '0.21%',
    avg_round_26: 9.33,
    app_flat_26: 3,
    char_27: 'imbibitor-lunae',
    app_rate_27: '0.21%',
    avg_round_27: 10,
    app_flat_27: 1,
    char_28: 'blade',
    app_rate_28: '0.15%',
    avg_round_28: 8.5,
    app_flat_28: 2,
    char_29: 'xueyi',
    app_rate_29: '0.12%',
    avg_round_29: 10,
    app_flat_29: 1,
    char_30: 'asta',
    app_rate_30: '0.12%',
    avg_round_30: 6,
    app_flat_30: 1
  },
  {
    char: 'sunday',
    app: 31.84,
    char_1: 'robin',
    app_rate_1: '41.52%',
    avg_round_1: 8.66,
    app_flat_1: 474,
    char_2: 'jing-yuan',
    app_rate_2: '36.74%',
    avg_round_2: 8.96,
    app_flat_2: 448,
    char_3: 'aventurine',
    app_rate_3: '32.12%',
    avg_round_3: 9.01,
    app_flat_3: 323,
    char_4: 'huohuo',
    app_rate_4: '25.32%',
    avg_round_4: 8.59,
    app_flat_4: 290,
    char_5: 'acheron',
    app_rate_5: '22.05%',
    avg_round_5: 8.5,
    app_flat_5: 146,
    char_6: 'jiaoqiu',
    app_rate_6: '14.91%',
    avg_round_6: 8.44,
    app_flat_6: 113,
    char_7: 'fu-xuan',
    app_rate_7: '13.3%',
    avg_round_7: 8.89,
    app_flat_7: 103,
    char_8: 'sparkle',
    app_rate_8: '13.27%',
    avg_round_8: 9.27,
    app_flat_8: 104,
    char_9: 'luocha',
    app_rate_9: '12.57%',
    avg_round_9: 8.96,
    app_flat_9: 111,
    char_10: 'ruan-mei',
    app_rate_10: '12.5%',
    avg_round_10: 9.15,
    app_flat_10: 79,
    char_11: 'imbibitor-lunae',
    app_rate_11: '12.29%',
    avg_round_11: 9.15,
    app_flat_11: 50,
    char_12: 'jingliu',
    app_rate_12: '11.92%',
    avg_round_12: 8.65,
    app_flat_12: 132,
    char_13: 'gallagher',
    app_rate_13: '7.08%',
    avg_round_13: 8.72,
    app_flat_13: 70,
    char_14: 'pela',
    app_rate_14: '6.62%',
    avg_round_14: 9.05,
    app_flat_14: 37,
    char_15: 'tingyun',
    app_rate_15: '5.88%',
    avg_round_15: 8.71,
    app_flat_15: 65,
    char_16: 'yunli',
    app_rate_16: '4.16%',
    avg_round_16: 8.17,
    app_flat_16: 59,
    char_17: 'lingsha',
    app_rate_17: '2.59%',
    avg_round_17: 9.19,
    app_flat_17: 32,
    char_18: 'feixiao',
    app_rate_18: '2.49%',
    avg_round_18: 9.1,
    app_flat_18: 18,
    char_19: 'bronya',
    app_rate_19: '2.37%',
    avg_round_19: 8.71,
    app_flat_19: 21,
    char_20: 'tingyun-fugue',
    app_rate_20: '2.06%',
    avg_round_20: 9.5,
    app_flat_20: 14,
    char_21: 'jade',
    app_rate_21: '2.0%',
    avg_round_21: 8.92,
    app_flat_21: 23,
    char_22: 'blade',
    app_rate_22: '1.97%',
    avg_round_22: 9.5,
    app_flat_22: 14,
    char_23: 'silver-wolf',
    app_rate_23: '1.57%',
    avg_round_23: 9.5,
    app_flat_23: 6,
    char_24: 'boothill',
    app_rate_24: '1.36%',
    avg_round_24: 99.99,
    app_flat_24: 0,
    char_25: 'black-swan',
    app_rate_25: '1.11%',
    avg_round_25: 9,
    app_flat_25: 3,
    char_26: 'rappa',
    app_rate_26: '0.95%',
    avg_round_26: 9.75,
    app_flat_26: 16,
    char_27: 'seele',
    app_rate_27: '0.92%',
    avg_round_27: 8.0,
    app_flat_27: 7,
    char_28: 'march-7th-swordmaster',
    app_rate_28: '0.89%',
    avg_round_28: 9.5,
    app_flat_28: 2,
    char_29: 'trailblazer-harmony',
    app_rate_29: '0.74%',
    avg_round_29: 10.0,
    app_flat_29: 9,
    char_30: 'gepard',
    app_rate_30: '0.65%',
    avg_round_30: 7.25,
    app_flat_30: 4
  },
  {
    char: 'jiaoqiu',
    app: 30.18,
    char_1: 'acheron',
    app_rate_1: '94.83%',
    avg_round_1: 8.42,
    app_flat_1: 1171,
    char_2: 'aventurine',
    app_rate_2: '47.47%',
    avg_round_2: 8.55,
    app_flat_2: 611,
    char_3: 'sparkle',
    app_rate_3: '27.81%',
    avg_round_3: 8.35,
    app_flat_3: 85,
    char_4: 'pela',
    app_rate_4: '27.71%',
    avg_round_4: 8.33,
    app_flat_4: 633,
    char_5: 'fu-xuan',
    app_rate_5: '24.72%',
    avg_round_5: 7.96,
    app_flat_5: 283,
    char_6: 'sunday',
    app_rate_6: '15.72%',
    avg_round_6: 8.44,
    app_flat_6: 113,
    char_7: 'gallagher',
    app_rate_7: '10.2%',
    avg_round_7: 8.65,
    app_flat_7: 159,
    char_8: 'black-swan',
    app_rate_8: '9.81%',
    avg_round_8: 8.6,
    app_flat_8: 145,
    char_9: 'robin',
    app_rate_9: '6.47%',
    avg_round_9: 7.4,
    app_flat_9: 43,
    char_10: 'huohuo',
    app_rate_10: '5.65%',
    avg_round_10: 8.48,
    app_flat_10: 57,
    char_11: 'silver-wolf',
    app_rate_11: '5.07%',
    avg_round_11: 8.21,
    app_flat_11: 82,
    char_12: 'bronya',
    app_rate_12: '4.29%',
    avg_round_12: 8.2,
    app_flat_12: 5,
    char_13: 'kafka',
    app_rate_13: '3.61%',
    avg_round_13: 9.25,
    app_flat_13: 32,
    char_14: 'luocha',
    app_rate_14: '3.12%',
    avg_round_14: 8.85,
    app_flat_14: 25,
    char_15: 'ruan-mei',
    app_rate_15: '2.89%',
    avg_round_15: 9.2,
    app_flat_15: 5,
    char_16: 'lingsha',
    app_rate_16: '2.37%',
    avg_round_16: 7.52,
    app_flat_16: 29,
    char_17: 'tingyun-fugue',
    app_rate_17: '1.82%',
    avg_round_17: 8.53,
    app_flat_17: 38,
    char_18: 'gepard',
    app_rate_18: '1.36%',
    avg_round_18: 8.27,
    app_flat_18: 22,
    char_19: 'jing-yuan',
    app_rate_19: '0.84%',
    avg_round_19: 10,
    app_flat_19: 3,
    char_20: 'imbibitor-lunae',
    app_rate_20: '0.65%',
    avg_round_20: 9.5,
    app_flat_20: 2,
    char_21: 'dr-ratio',
    app_rate_21: '0.45%',
    avg_round_21: 10,
    app_flat_21: 1,
    char_22: 'feixiao',
    app_rate_22: '0.36%',
    avg_round_22: 9,
    app_flat_22: 3,
    char_23: 'bailu',
    app_rate_23: '0.32%',
    avg_round_23: 10,
    app_flat_23: 1,
    char_24: 'blade',
    app_rate_24: '0.29%',
    avg_round_24: 9,
    app_flat_24: 1,
    char_25: 'sampo',
    app_rate_25: '0.29%',
    avg_round_25: 9.5,
    app_flat_25: 4,
    char_26: 'welt',
    app_rate_26: '0.19%',
    avg_round_26: 9.25,
    app_flat_26: 4,
    char_27: 'lynx',
    app_rate_27: '0.19%',
    avg_round_27: 9,
    app_flat_27: 2,
    char_28: 'guinaifen',
    app_rate_28: '0.16%',
    avg_round_28: 9.67,
    app_flat_28: 3,
    char_29: 'yunli',
    app_rate_29: '0.13%',
    avg_round_29: 9,
    app_flat_29: 1,
    char_30: 'jingliu',
    app_rate_30: '0.13%',
    avg_round_30: 99.99,
    app_flat_30: 0
  },
  {
    char: 'sparkle',
    app: 25.47,
    char_1: 'acheron',
    app_rate_1: '61.22%',
    avg_round_1: 8.97,
    app_flat_1: 144,
    char_2: 'fu-xuan',
    app_rate_2: '33.08%',
    avg_round_2: 9.26,
    app_flat_2: 84,
    char_3: 'jiaoqiu',
    app_rate_3: '32.96%',
    avg_round_3: 8.35,
    app_flat_3: 85,
    char_4: 'aventurine',
    app_rate_4: '30.57%',
    avg_round_4: 9.22,
    app_flat_4: 108,
    char_5: 'imbibitor-lunae',
    app_rate_5: '18.41%',
    avg_round_5: 9.46,
    app_flat_5: 56,
    char_6: 'sunday',
    app_rate_6: '16.6%',
    avg_round_6: 9.27,
    app_flat_6: 104,
    char_7: 'pela',
    app_rate_7: '15.25%',
    avg_round_7: 9.61,
    app_flat_7: 45,
    char_8: 'huohuo',
    app_rate_8: '13.32%',
    avg_round_8: 8.78,
    app_flat_8: 80,
    char_9: 'silver-wolf',
    app_rate_9: '10.47%',
    avg_round_9: 8.25,
    app_flat_9: 4,
    char_10: 'luocha',
    app_rate_10: '8.93%',
    avg_round_10: 9.56,
    app_flat_10: 36,
    char_11: 'robin',
    app_rate_11: '7.62%',
    avg_round_11: 8.92,
    app_flat_11: 22,
    char_12: 'ruan-mei',
    app_rate_12: '6.55%',
    avg_round_12: 9.6,
    app_flat_12: 20,
    char_13: 'tingyun',
    app_rate_13: '5.97%',
    avg_round_13: 8.9,
    app_flat_13: 39,
    char_14: 'jing-yuan',
    app_rate_14: '5.47%',
    avg_round_14: 9.2,
    app_flat_14: 58,
    char_15: 'gallagher',
    app_rate_15: '4.43%',
    avg_round_15: 9.18,
    app_flat_15: 19,
    char_16: 'yunli',
    app_rate_16: '3.47%',
    avg_round_16: 8.33,
    app_flat_16: 21,
    char_17: 'black-swan',
    app_rate_17: '3.31%',
    avg_round_17: 10.0,
    app_flat_17: 10,
    char_18: 'qingque',
    app_rate_18: '2.89%',
    avg_round_18: 9.0,
    app_flat_18: 21,
    char_19: 'lingsha',
    app_rate_19: '1.85%',
    avg_round_19: 8.38,
    app_flat_19: 8,
    char_20: 'jingliu',
    app_rate_20: '1.77%',
    avg_round_20: 9.58,
    app_flat_20: 12,
    char_21: 'seele',
    app_rate_21: '1.66%',
    avg_round_21: 8.5,
    app_flat_21: 2,
    char_22: 'feixiao',
    app_rate_22: '1.66%',
    avg_round_22: 9.67,
    app_flat_22: 6,
    char_23: 'gepard',
    app_rate_23: '1.58%',
    avg_round_23: 9,
    app_flat_23: 6,
    char_24: 'bronya',
    app_rate_24: '1.31%',
    avg_round_24: 10,
    app_flat_24: 2,
    char_25: 'jade',
    app_rate_25: '1.23%',
    avg_round_25: 9.67,
    app_flat_25: 10,
    char_26: 'tingyun-fugue',
    app_rate_26: '1.0%',
    avg_round_26: 8.4,
    app_flat_26: 5,
    char_27: 'march-7th-swordmaster',
    app_rate_27: '0.92%',
    avg_round_27: 9.5,
    app_flat_27: 4,
    char_28: 'kafka',
    app_rate_28: '0.89%',
    avg_round_28: 9.5,
    app_flat_28: 2,
    char_29: 'blade',
    app_rate_29: '0.81%',
    avg_round_29: 9.67,
    app_flat_29: 6,
    char_30: 'clara',
    app_rate_30: '0.77%',
    avg_round_30: 8.71,
    app_flat_30: 7
  },
  {
    char: 'fu-xuan',
    app: 25.22,
    char_1: 'acheron',
    app_rate_1: '70.61%',
    avg_round_1: 8.83,
    app_flat_1: 573,
    char_2: 'sparkle',
    app_rate_2: '33.4%',
    avg_round_2: 9.26,
    app_flat_2: 84,
    char_3: 'pela',
    app_rate_3: '30.17%',
    avg_round_3: 8.6,
    app_flat_3: 331,
    char_4: 'jiaoqiu',
    app_rate_4: '29.59%',
    avg_round_4: 7.96,
    app_flat_4: 283,
    char_5: 'silver-wolf',
    app_rate_5: '18.74%',
    avg_round_5: 8.89,
    app_flat_5: 147,
    char_6: 'black-swan',
    app_rate_6: '17.92%',
    avg_round_6: 9.31,
    app_flat_6: 176,
    char_7: 'sunday',
    app_rate_7: '16.8%',
    avg_round_7: 8.89,
    app_flat_7: 103,
    char_8: 'robin',
    app_rate_8: '14.46%',
    avg_round_8: 8.94,
    app_flat_8: 97,
    char_9: 'kafka',
    app_rate_9: '13.84%',
    avg_round_9: 9.42,
    app_flat_9: 137,
    char_10: 'ruan-mei',
    app_rate_10: '7.7%',
    avg_round_10: 9.7,
    app_flat_10: 45,
    char_11: 'jing-yuan',
    app_rate_11: '6.42%',
    avg_round_11: 8.59,
    app_flat_11: 51,
    char_12: 'feixiao',
    app_rate_12: '5.79%',
    avg_round_12: 8.86,
    app_flat_12: 39,
    char_13: 'march-7th-swordmaster',
    app_rate_13: '4.16%',
    avg_round_13: 9.11,
    app_flat_13: 37,
    char_14: 'bronya',
    app_rate_14: '3.62%',
    avg_round_14: 9.2,
    app_flat_14: 5,
    char_15: 'tingyun-fugue',
    app_rate_15: '2.68%',
    avg_round_15: 9.6,
    app_flat_15: 29,
    char_16: 'jade',
    app_rate_16: '2.6%',
    avg_round_16: 9.58,
    app_flat_16: 22,
    char_17: 'tingyun',
    app_rate_17: '2.33%',
    avg_round_17: 8.44,
    app_flat_17: 18,
    char_18: 'imbibitor-lunae',
    app_rate_18: '2.29%',
    avg_round_18: 10.0,
    app_flat_18: 5,
    char_19: 'qingque',
    app_rate_19: '2.1%',
    avg_round_19: 9.2,
    app_flat_19: 9,
    char_20: 'seele',
    app_rate_20: '1.59%',
    avg_round_20: 9,
    app_flat_20: 2,
    char_21: 'jingliu',
    app_rate_21: '1.59%',
    avg_round_21: 9.67,
    app_flat_21: 6,
    char_22: 'yunli',
    app_rate_22: '1.59%',
    avg_round_22: 8.35,
    app_flat_22: 17,
    char_23: 'topaz',
    app_rate_23: '1.44%',
    avg_round_23: 7.75,
    app_flat_23: 6,
    char_24: 'herta',
    app_rate_24: '1.28%',
    avg_round_24: 9.62,
    app_flat_24: 14,
    char_25: 'blade',
    app_rate_25: '1.17%',
    avg_round_25: 10.0,
    app_flat_25: 8,
    char_26: 'rappa',
    app_rate_26: '1.01%',
    avg_round_26: 10.0,
    app_flat_26: 14,
    char_27: 'guinaifen',
    app_rate_27: '0.86%',
    avg_round_27: 10.0,
    app_flat_27: 8,
    char_28: 'trailblazer-harmony',
    app_rate_28: '0.86%',
    avg_round_28: 10.0,
    app_flat_28: 8,
    char_29: 'clara',
    app_rate_29: '0.74%',
    avg_round_29: 10,
    app_flat_29: 3,
    char_30: 'firefly',
    app_rate_30: '0.66%',
    avg_round_30: 10,
    app_flat_30: 2
  },
  {
    char: 'black-swan',
    app: 24.74,
    char_1: 'kafka',
    app_rate_1: '76.54%',
    avg_round_1: 9.17,
    app_flat_1: 685,
    char_2: 'acheron',
    app_rate_2: '54.06%',
    avg_round_2: 9.23,
    app_flat_2: 601,
    char_3: 'huohuo',
    app_rate_3: '32.1%',
    avg_round_3: 9.04,
    app_flat_3: 234,
    char_4: 'ruan-mei',
    app_rate_4: '25.01%',
    avg_round_4: 9.02,
    app_flat_4: 194,
    char_5: 'aventurine',
    app_rate_5: '22.95%',
    avg_round_5: 9.3,
    app_flat_5: 279,
    char_6: 'fu-xuan',
    app_rate_6: '18.27%',
    avg_round_6: 9.31,
    app_flat_6: 176,
    char_7: 'robin',
    app_rate_7: '17.12%',
    avg_round_7: 8.85,
    app_flat_7: 133,
    char_8: 'gallagher',
    app_rate_8: '12.05%',
    avg_round_8: 8.96,
    app_flat_8: 138,
    char_9: 'jiaoqiu',
    app_rate_9: '11.97%',
    avg_round_9: 8.6,
    app_flat_9: 145,
    char_10: 'luocha',
    app_rate_10: '6.34%',
    avg_round_10: 8.77,
    app_flat_10: 43,
    char_11: 'pela',
    app_rate_11: '5.03%',
    avg_round_11: 9.35,
    app_flat_11: 80,
    char_12: 'lingsha',
    app_rate_12: '3.49%',
    avg_round_12: 8.47,
    app_flat_12: 32,
    char_13: 'sparkle',
    app_rate_13: '3.41%',
    avg_round_13: 10.0,
    app_flat_13: 10,
    char_14: 'gepard',
    app_rate_14: '2.06%',
    avg_round_14: 8.69,
    app_flat_14: 26,
    char_15: 'bailu',
    app_rate_15: '1.78%',
    avg_round_15: 9.29,
    app_flat_15: 13,
    char_16: 'sunday',
    app_rate_16: '1.43%',
    avg_round_16: 9,
    app_flat_16: 3,
    char_17: 'tingyun-fugue',
    app_rate_17: '0.95%',
    avg_round_17: 10.0,
    app_flat_17: 11,
    char_18: 'guinaifen',
    app_rate_18: '0.95%',
    avg_round_18: 9,
    app_flat_18: 4,
    char_19: 'silver-wolf',
    app_rate_19: '0.95%',
    avg_round_19: 9,
    app_flat_19: 8,
    char_20: 'sampo',
    app_rate_20: '0.91%',
    avg_round_20: 9,
    app_flat_20: 2,
    char_21: 'bronya',
    app_rate_21: '0.75%',
    avg_round_21: 9.5,
    app_flat_21: 2,
    char_22: 'asta',
    app_rate_22: '0.55%',
    avg_round_22: 8.33,
    app_flat_22: 3,
    char_23: 'tingyun',
    app_rate_23: '0.52%',
    avg_round_23: 9.33,
    app_flat_23: 3,
    char_24: 'lynx',
    app_rate_24: '0.16%',
    avg_round_24: 10,
    app_flat_24: 1,
    char_25: 'natasha',
    app_rate_25: '0.08%',
    avg_round_25: 99.99,
    app_flat_25: 0,
    char_26: 'jing-yuan',
    app_rate_26: '0.08%',
    avg_round_26: 99.99,
    app_flat_26: 0,
    char_27: 'himeko',
    app_rate_27: '0.08%',
    avg_round_27: 99.99,
    app_flat_27: 0,
    char_28: 'feixiao',
    app_rate_28: '0.04%',
    avg_round_28: 99.99,
    app_flat_28: 0,
    char_29: 'yunli',
    app_rate_29: '0.04%',
    avg_round_29: 99.99,
    app_flat_29: 0,
    char_30: 'moze',
    app_rate_30: '0.04%',
    avg_round_30: 99.99,
    app_flat_30: 0
  },
  {
    char: 'huohuo',
    app: 22.65,
    char_1: 'robin',
    app_rate_1: '40.35%',
    avg_round_1: 8.59,
    app_flat_1: 346,
    char_2: 'sunday',
    app_rate_2: '35.58%',
    avg_round_2: 8.59,
    app_flat_2: 290,
    char_3: 'black-swan',
    app_rate_3: '35.06%',
    avg_round_3: 9.04,
    app_flat_3: 234,
    char_4: 'kafka',
    app_rate_4: '34.16%',
    avg_round_4: 9.06,
    app_flat_4: 220,
    char_5: 'ruan-mei',
    app_rate_5: '26.75%',
    avg_round_5: 8.96,
    app_flat_5: 179,
    char_6: 'jing-yuan',
    app_rate_6: '16.15%',
    avg_round_6: 8.76,
    app_flat_6: 156,
    char_7: 'acheron',
    app_rate_7: '15.71%',
    avg_round_7: 9.05,
    app_flat_7: 111,
    char_8: 'sparkle',
    app_rate_8: '14.98%',
    avg_round_8: 8.78,
    app_flat_8: 80,
    char_9: 'yunli',
    app_rate_9: '8.79%',
    avg_round_9: 7.84,
    app_flat_9: 91,
    char_10: 'jingliu',
    app_rate_10: '8.74%',
    avg_round_10: 7.98,
    app_flat_10: 59,
    char_11: 'imbibitor-lunae',
    app_rate_11: '8.53%',
    avg_round_11: 8.92,
    app_flat_11: 25,
    char_12: 'tingyun',
    app_rate_12: '8.1%',
    avg_round_12: 8.62,
    app_flat_12: 82,
    char_13: 'jiaoqiu',
    app_rate_13: '7.53%',
    avg_round_13: 8.48,
    app_flat_13: 57,
    char_14: 'rappa',
    app_rate_14: '4.55%',
    avg_round_14: 9.12,
    app_flat_14: 62,
    char_15: 'tingyun-fugue',
    app_rate_15: '3.85%',
    avg_round_15: 8.68,
    app_flat_15: 37,
    char_16: 'feixiao',
    app_rate_16: '3.77%',
    avg_round_16: 9.06,
    app_flat_16: 33,
    char_17: 'pela',
    app_rate_17: '3.64%',
    avg_round_17: 8.89,
    app_flat_17: 34,
    char_18: 'trailblazer-harmony',
    app_rate_18: '3.2%',
    avg_round_18: 9.38,
    app_flat_18: 39,
    char_19: 'jade',
    app_rate_19: '2.99%',
    avg_round_19: 9.53,
    app_flat_19: 31,
    char_20: 'march-7th-swordmaster',
    app_rate_20: '2.99%',
    avg_round_20: 9.0,
    app_flat_20: 31,
    char_21: 'bronya',
    app_rate_21: '2.51%',
    avg_round_21: 9.75,
    app_flat_21: 16,
    char_22: 'blade',
    app_rate_22: '1.86%',
    avg_round_22: 9.75,
    app_flat_22: 16,
    char_23: 'firefly',
    app_rate_23: '1.43%',
    avg_round_23: 8,
    app_flat_23: 2,
    char_24: 'silver-wolf',
    app_rate_24: '1.39%',
    avg_round_24: 9.62,
    app_flat_24: 8,
    char_25: 'herta',
    app_rate_25: '1.34%',
    avg_round_25: 9.5,
    app_flat_25: 20,
    char_26: 'topaz',
    app_rate_26: '1.17%',
    avg_round_26: 8.86,
    app_flat_26: 7,
    char_27: 'guinaifen',
    app_rate_27: '0.74%',
    avg_round_27: 9,
    app_flat_27: 4,
    char_28: 'clara',
    app_rate_28: '0.69%',
    avg_round_28: 9,
    app_flat_28: 10,
    char_29: 'sampo',
    app_rate_29: '0.48%',
    avg_round_29: 9,
    app_flat_29: 2,
    char_30: 'serval',
    app_rate_30: '0.48%',
    avg_round_30: 9.25,
    app_flat_30: 4
  },
  {
    char: 'feixiao',
    app: 22.49,
    char_1: 'robin',
    app_rate_1: '95.47%',
    avg_round_1: 8.79,
    app_flat_1: 816,
    char_2: 'aventurine',
    app_rate_2: '75.76%',
    avg_round_2: 8.81,
    app_flat_2: 668,
    char_3: 'topaz',
    app_rate_3: '46.95%',
    avg_round_3: 8.71,
    app_flat_3: 307,
    char_4: 'march-7th-swordmaster',
    app_rate_4: '38.19%',
    avg_round_4: 8.86,
    app_flat_4: 442,
    char_5: 'fu-xuan',
    app_rate_5: '6.5%',
    avg_round_5: 8.86,
    app_flat_5: 39,
    char_6: 'jade',
    app_rate_6: '4.8%',
    avg_round_6: 8.86,
    app_flat_6: 11,
    char_7: 'moze',
    app_rate_7: '4.53%',
    avg_round_7: 8.76,
    app_flat_7: 33,
    char_8: 'lingsha',
    app_rate_8: '4.05%',
    avg_round_8: 8.45,
    app_flat_8: 40,
    char_9: 'gallagher',
    app_rate_9: '3.79%',
    avg_round_9: 8.25,
    app_flat_9: 48,
    char_10: 'huohuo',
    app_rate_10: '3.79%',
    avg_round_10: 9.06,
    app_flat_10: 33,
    char_11: 'bronya',
    app_rate_11: '3.66%',
    avg_round_11: 8.62,
    app_flat_11: 14,
    char_12: 'sunday',
    app_rate_12: '3.53%',
    avg_round_12: 9.1,
    app_flat_12: 18,
    char_13: 'sparkle',
    app_rate_13: '1.87%',
    avg_round_13: 9.67,
    app_flat_13: 6,
    char_14: 'yunli',
    app_rate_14: '1.53%',
    avg_round_14: 8.5,
    app_flat_14: 14,
    char_15: 'ruan-mei',
    app_rate_15: '1.53%',
    avg_round_15: 8.5,
    app_flat_15: 6,
    char_16: 'luocha',
    app_rate_16: '1.18%',
    avg_round_16: 9.75,
    app_flat_16: 8,
    char_17: 'acheron',
    app_rate_17: '0.52%',
    avg_round_17: 9,
    app_flat_17: 2,
    char_18: 'jiaoqiu',
    app_rate_18: '0.48%',
    avg_round_18: 9,
    app_flat_18: 3,
    char_19: 'herta',
    app_rate_19: '0.44%',
    avg_round_19: 9,
    app_flat_19: 2,
    char_20: 'bailu',
    app_rate_20: '0.31%',
    avg_round_20: 9,
    app_flat_20: 3,
    char_21: 'clara',
    app_rate_21: '0.22%',
    avg_round_21: 9.5,
    app_flat_21: 2,
    char_22: 'kafka',
    app_rate_22: '0.13%',
    avg_round_22: 99.99,
    app_flat_22: 0,
    char_23: 'tingyun-fugue',
    app_rate_23: '0.13%',
    avg_round_23: 99.99,
    app_flat_23: 0,
    char_24: 'firefly',
    app_rate_24: '0.09%',
    avg_round_24: 99.99,
    app_flat_24: 0,
    char_25: 'tingyun',
    app_rate_25: '0.09%',
    avg_round_25: 99.99,
    app_flat_25: 0,
    char_26: 'jing-yuan',
    app_rate_26: '0.09%',
    avg_round_26: 9.5,
    app_flat_26: 2,
    char_27: 'dr-ratio',
    app_rate_27: '0.04%',
    avg_round_27: 99.99,
    app_flat_27: 0,
    char_28: 'black-swan',
    app_rate_28: '0.04%',
    avg_round_28: 99.99,
    app_flat_28: 0,
    char_29: 'serval',
    app_rate_29: '0.04%',
    avg_round_29: 99.99,
    app_flat_29: 0,
    char_30: 'march-7th',
    app_rate_30: '0.04%',
    avg_round_30: 99.99,
    app_flat_30: 0
  },
  {
    char: 'pela',
    app: 22.4,
    char_1: 'acheron',
    app_rate_1: '94.13%',
    avg_round_1: 8.83,
    app_flat_1: 1076,
    char_2: 'aventurine',
    app_rate_2: '41.9%',
    avg_round_2: 8.97,
    app_flat_2: 512,
    char_3: 'jiaoqiu',
    app_rate_3: '37.35%',
    avg_round_3: 8.33,
    app_flat_3: 633,
    char_4: 'fu-xuan',
    app_rate_4: '33.98%',
    avg_round_4: 8.6,
    app_flat_4: 331,
    char_5: 'sparkle',
    app_rate_5: '17.34%',
    avg_round_5: 9.61,
    app_flat_5: 45,
    char_6: 'silver-wolf',
    app_rate_6: '15.63%',
    avg_round_6: 9.22,
    app_flat_6: 224,
    char_7: 'gallagher',
    app_rate_7: '9.94%',
    avg_round_7: 8.89,
    app_flat_7: 150,
    char_8: 'sunday',
    app_rate_8: '9.41%',
    avg_round_8: 9.05,
    app_flat_8: 37,
    char_9: 'black-swan',
    app_rate_9: '5.56%',
    avg_round_9: 9.35,
    app_flat_9: 80,
    char_10: 'bronya',
    app_rate_10: '4.29%',
    avg_round_10: 9.5,
    app_flat_10: 2,
    char_11: 'gepard',
    app_rate_11: '3.72%',
    avg_round_11: 8.57,
    app_flat_11: 40,
    char_12: 'huohuo',
    app_rate_12: '3.68%',
    avg_round_12: 8.89,
    app_flat_12: 34,
    char_13: 'robin',
    app_rate_13: '3.06%',
    avg_round_13: 8.57,
    app_flat_13: 14,
    char_14: 'tingyun-fugue',
    app_rate_14: '2.63%',
    avg_round_14: 8.9,
    app_flat_14: 39,
    char_15: 'luocha',
    app_rate_15: '2.58%',
    avg_round_15: 9.33,
    app_flat_15: 27,
    char_16: 'lingsha',
    app_rate_16: '2.06%',
    avg_round_16: 7.88,
    app_flat_16: 25,
    char_17: 'rappa',
    app_rate_17: '1.58%',
    avg_round_17: 9.31,
    app_flat_17: 23,
    char_18: 'ruan-mei',
    app_rate_18: '1.49%',
    avg_round_18: 9.67,
    app_flat_18: 3,
    char_19: 'trailblazer-harmony',
    app_rate_19: '1.44%',
    avg_round_19: 9.78,
    app_flat_19: 15,
    char_20: 'kafka',
    app_rate_20: '1.36%',
    avg_round_20: 9.4,
    app_flat_20: 9,
    char_21: 'guinaifen',
    app_rate_21: '1.36%',
    avg_round_21: 9.25,
    app_flat_21: 16,
    char_22: 'jingliu',
    app_rate_22: '1.18%',
    avg_round_22: 9.75,
    app_flat_22: 8,
    char_23: 'imbibitor-lunae',
    app_rate_23: '0.7%',
    avg_round_23: 9.5,
    app_flat_23: 4,
    char_24: 'firefly',
    app_rate_24: '0.48%',
    avg_round_24: 99.99,
    app_flat_24: 0,
    char_25: 'dr-ratio',
    app_rate_25: '0.44%',
    avg_round_25: 10.0,
    app_flat_25: 4,
    char_26: 'welt',
    app_rate_26: '0.39%',
    avg_round_26: 10,
    app_flat_26: 5,
    char_27: 'tingyun',
    app_rate_27: '0.35%',
    avg_round_27: 99.99,
    app_flat_27: 0,
    char_28: 'yunli',
    app_rate_28: '0.26%',
    avg_round_28: 9,
    app_flat_28: 1,
    char_29: 'jing-yuan',
    app_rate_29: '0.26%',
    avg_round_29: 9.33,
    app_flat_29: 3,
    char_30: 'jade',
    app_rate_30: '0.22%',
    avg_round_30: 8.5,
    app_flat_30: 2
  },
  {
    char: 'rappa',
    app: 21.94,
    char_1: 'ruan-mei',
    app_rate_1: '85.83%',
    avg_round_1: 8.63,
    app_flat_1: 1089,
    char_2: 'tingyun-fugue',
    app_rate_2: '62.05%',
    avg_round_2: 8.03,
    app_flat_2: 722,
    char_3: 'trailblazer-harmony',
    app_rate_3: '55.16%',
    avg_round_3: 8.87,
    app_flat_3: 736,
    char_4: 'gallagher',
    app_rate_4: '37.73%',
    avg_round_4: 8.7,
    app_flat_4: 621,
    char_5: 'lingsha',
    app_rate_5: '35.67%',
    avg_round_5: 8.26,
    app_flat_5: 469,
    char_6: 'aventurine',
    app_rate_6: '5.1%',
    avg_round_6: 8.78,
    app_flat_6: 64,
    char_7: 'huohuo',
    app_rate_7: '4.69%',
    avg_round_7: 9.12,
    app_flat_7: 62,
    char_8: 'luocha',
    app_rate_8: '4.65%',
    avg_round_8: 9.22,
    app_flat_8: 71,
    char_9: 'firefly',
    app_rate_9: '2.37%',
    avg_round_9: 9.67,
    app_flat_9: 6,
    char_10: 'pela',
    app_rate_10: '1.61%',
    avg_round_10: 9.31,
    app_flat_10: 23,
    char_11: 'sunday',
    app_rate_11: '1.39%',
    avg_round_11: 9.75,
    app_flat_11: 16,
    char_12: 'fu-xuan',
    app_rate_12: '1.16%',
    avg_round_12: 10.0,
    app_flat_12: 14,
    char_13: 'silver-wolf',
    app_rate_13: '0.31%',
    avg_round_13: 9.5,
    app_flat_13: 4,
    char_14: 'robin',
    app_rate_14: '0.31%',
    avg_round_14: 9.67,
    app_flat_14: 3,
    char_15: 'himeko',
    app_rate_15: '0.27%',
    avg_round_15: 9.67,
    app_flat_15: 3,
    char_16: 'bailu',
    app_rate_16: '0.22%',
    avg_round_16: 10,
    app_flat_16: 2,
    char_17: 'bronya',
    app_rate_17: '0.22%',
    avg_round_17: 9.5,
    app_flat_17: 2,
    char_18: 'acheron',
    app_rate_18: '0.13%',
    avg_round_18: 99.99,
    app_flat_18: 0,
    char_19: 'jade',
    app_rate_19: '0.13%',
    avg_round_19: 9,
    app_flat_19: 2,
    char_20: 'sparkle',
    app_rate_20: '0.13%',
    avg_round_20: 8,
    app_flat_20: 1,
    char_21: 'boothill',
    app_rate_21: '0.09%',
    avg_round_21: 99.99,
    app_flat_21: 0,
    char_22: 'lynx',
    app_rate_22: '0.09%',
    avg_round_22: 10,
    app_flat_22: 1,
    char_23: 'jiaoqiu',
    app_rate_23: '0.09%',
    avg_round_23: 10,
    app_flat_23: 1,
    char_24: 'gepard',
    app_rate_24: '0.09%',
    avg_round_24: 7,
    app_flat_24: 2,
    char_25: 'march-7th-swordmaster',
    app_rate_25: '0.09%',
    avg_round_25: 8,
    app_flat_25: 1,
    char_26: 'welt',
    app_rate_26: '0.04%',
    avg_round_26: 8,
    app_flat_26: 1,
    char_27: 'jingliu',
    app_rate_27: '0.04%',
    avg_round_27: 99.99,
    app_flat_27: 0,
    char_28: 'natasha',
    app_rate_28: '0.04%',
    avg_round_28: 99.99,
    app_flat_28: 0,
    char_29: 'misha',
    app_rate_29: '0.04%',
    avg_round_29: 99.99,
    app_flat_29: 0,
    char_30: 'yunli',
    app_rate_30: '0.04%',
    avg_round_30: 10,
    app_flat_30: 1
  },
  {
    char: 'kafka',
    app: 20.58,
    char_1: 'black-swan',
    app_rate_1: '92.0%',
    avg_round_1: 9.17,
    app_flat_1: 685,
    char_2: 'acheron',
    app_rate_2: '43.83%',
    avg_round_2: 9.32,
    app_flat_2: 391,
    char_3: 'huohuo',
    app_rate_3: '37.59%',
    avg_round_3: 9.06,
    app_flat_3: 220,
    char_4: 'ruan-mei',
    app_rate_4: '30.3%',
    avg_round_4: 9.04,
    app_flat_4: 198,
    char_5: 'robin',
    app_rate_5: '19.77%',
    avg_round_5: 8.91,
    app_flat_5: 132,
    char_6: 'aventurine',
    app_rate_6: '17.48%',
    avg_round_6: 9.42,
    app_flat_6: 167,
    char_7: 'fu-xuan',
    app_rate_7: '16.96%',
    avg_round_7: 9.42,
    app_flat_7: 137,
    char_8: 'gallagher',
    app_rate_8: '12.58%',
    avg_round_8: 8.93,
    app_flat_8: 119,
    char_9: 'luocha',
    app_rate_9: '6.86%',
    avg_round_9: 8.74,
    app_flat_9: 39,
    char_10: 'jiaoqiu',
    app_rate_10: '5.29%',
    avg_round_10: 9.25,
    app_flat_10: 32,
    char_11: 'lingsha',
    app_rate_11: '3.72%',
    avg_round_11: 8.36,
    app_flat_11: 28,
    char_12: 'bailu',
    app_rate_12: '2.0%',
    avg_round_12: 9.29,
    app_flat_12: 13,
    char_13: 'gepard',
    app_rate_13: '1.72%',
    avg_round_13: 8.85,
    app_flat_13: 13,
    char_14: 'sampo',
    app_rate_14: '1.62%',
    avg_round_14: 9,
    app_flat_14: 6,
    char_15: 'pela',
    app_rate_15: '1.48%',
    avg_round_15: 9.4,
    app_flat_15: 9,
    char_16: 'guinaifen',
    app_rate_16: '1.38%',
    avg_round_16: 10.0,
    app_flat_16: 4,
    char_17: 'sparkle',
    app_rate_17: '1.1%',
    avg_round_17: 9.5,
    app_flat_17: 2,
    char_18: 'tingyun-fugue',
    app_rate_18: '0.81%',
    avg_round_18: 9.17,
    app_flat_18: 6,
    char_19: 'asta',
    app_rate_19: '0.67%',
    avg_round_19: 8.33,
    app_flat_19: 3,
    char_20: 'tingyun',
    app_rate_20: '0.62%',
    avg_round_20: 9.33,
    app_flat_20: 3,
    char_21: 'sunday',
    app_rate_21: '0.52%',
    avg_round_21: 10,
    app_flat_21: 1,
    char_22: 'silver-wolf',
    app_rate_22: '0.48%',
    avg_round_22: 99.99,
    app_flat_22: 0,
    char_23: 'bronya',
    app_rate_23: '0.33%',
    avg_round_23: 10,
    app_flat_23: 1,
    char_24: 'lynx',
    app_rate_24: '0.19%',
    avg_round_24: 10,
    app_flat_24: 1,
    char_25: 'feixiao',
    app_rate_25: '0.14%',
    avg_round_25: 99.99,
    app_flat_25: 0,
    char_26: 'natasha',
    app_rate_26: '0.1%',
    avg_round_26: 99.99,
    app_flat_26: 0,
    char_27: 'jade',
    app_rate_27: '0.1%',
    avg_round_27: 99.99,
    app_flat_27: 0,
    char_28: 'trailblazer-harmony',
    app_rate_28: '0.1%',
    avg_round_28: 9,
    app_flat_28: 1,
    char_29: 'yunli',
    app_rate_29: '0.05%',
    avg_round_29: 99.99,
    app_flat_29: 0,
    char_30: 'jing-yuan',
    app_rate_30: '0.05%',
    avg_round_30: 99.99,
    app_flat_30: 0
  },
  {
    char: 'jing-yuan',
    app: 11.89,
    char_1: 'sunday',
    app_rate_1: '98.35%',
    avg_round_1: 8.96,
    app_flat_1: 448,
    char_2: 'robin',
    app_rate_2: '65.87%',
    avg_round_2: 8.84,
    app_flat_2: 318,
    char_3: 'aventurine',
    app_rate_3: '38.5%',
    avg_round_3: 9.21,
    app_flat_3: 181,
    char_4: 'huohuo',
    app_rate_4: '30.75%',
    avg_round_4: 8.76,
    app_flat_4: 156,
    char_5: 'fu-xuan',
    app_rate_5: '13.6%',
    avg_round_5: 8.59,
    app_flat_5: 51,
    char_6: 'sparkle',
    app_rate_6: '11.71%',
    avg_round_6: 9.2,
    app_flat_6: 58,
    char_7: 'ruan-mei',
    app_rate_7: '9.15%',
    avg_round_7: 9.0,
    app_flat_7: 32,
    char_8: 'tingyun',
    app_rate_8: '9.07%',
    avg_round_8: 8.89,
    app_flat_8: 38,
    char_9: 'luocha',
    app_rate_9: '7.58%',
    avg_round_9: 9.25,
    app_flat_9: 30,
    char_10: 'gallagher',
    app_rate_10: '6.6%',
    avg_round_10: 8.47,
    app_flat_10: 27,
    char_11: 'jiaoqiu',
    app_rate_11: '2.14%',
    avg_round_11: 10,
    app_flat_11: 3,
    char_12: 'bronya',
    app_rate_12: '1.73%',
    avg_round_12: 9.29,
    app_flat_12: 7,
    char_13: 'lingsha',
    app_rate_13: '1.24%',
    avg_round_13: 8.88,
    app_flat_13: 8,
    char_14: 'jade',
    app_rate_14: '0.91%',
    avg_round_14: 9.67,
    app_flat_14: 3,
    char_15: 'pela',
    app_rate_15: '0.49%',
    avg_round_15: 9.33,
    app_flat_15: 3,
    char_16: 'bailu',
    app_rate_16: '0.49%',
    avg_round_16: 10,
    app_flat_16: 3,
    char_17: 'hanya',
    app_rate_17: '0.49%',
    avg_round_17: 10,
    app_flat_17: 3,
    char_18: 'topaz',
    app_rate_18: '0.25%',
    avg_round_18: 9,
    app_flat_18: 1,
    char_19: 'black-swan',
    app_rate_19: '0.16%',
    avg_round_19: 99.99,
    app_flat_19: 0,
    char_20: 'gepard',
    app_rate_20: '0.16%',
    avg_round_20: 7,
    app_flat_20: 1,
    char_21: 'tingyun-fugue',
    app_rate_21: '0.16%',
    avg_round_21: 99.99,
    app_flat_21: 0,
    char_22: 'feixiao',
    app_rate_22: '0.16%',
    avg_round_22: 9.5,
    app_flat_22: 2,
    char_23: 'kafka',
    app_rate_23: '0.08%',
    avg_round_23: 99.99,
    app_flat_23: 0,
    char_24: 'march-7th-swordmaster',
    app_rate_24: '0.08%',
    avg_round_24: 99.99,
    app_flat_24: 0,
    char_25: 'blade',
    app_rate_25: '0.08%',
    avg_round_25: 99.99,
    app_flat_25: 0,
    char_26: 'asta',
    app_rate_26: '0.08%',
    avg_round_26: 99.99,
    app_flat_26: 0,
    char_27: 'lynx',
    app_rate_27: '0.08%',
    avg_round_27: 9,
    app_flat_27: 1,
    char_28: '',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'topaz',
    app: 11.49,
    char_1: 'robin',
    app_rate_1: '96.25%',
    avg_round_1: 8.7,
    app_flat_1: 316,
    char_2: 'feixiao',
    app_rate_2: '91.89%',
    avg_round_2: 8.71,
    app_flat_2: 307,
    char_3: 'aventurine',
    app_rate_3: '86.86%',
    avg_round_3: 8.72,
    app_flat_3: 290,
    char_4: 'dr-ratio',
    app_rate_4: '4.35%',
    avg_round_4: 9.0,
    app_flat_4: 4,
    char_5: 'lingsha',
    app_rate_5: '3.33%',
    avg_round_5: 8.08,
    app_flat_5: 12,
    char_6: 'fu-xuan',
    app_rate_6: '3.16%',
    avg_round_6: 7.75,
    app_flat_6: 6,
    char_7: 'huohuo',
    app_rate_7: '2.3%',
    avg_round_7: 8.86,
    app_flat_7: 7,
    char_8: 'jade',
    app_rate_8: '1.71%',
    avg_round_8: 8,
    app_flat_8: 1,
    char_9: 'yunli',
    app_rate_9: '1.62%',
    avg_round_9: 8.33,
    app_flat_9: 6,
    char_10: 'bronya',
    app_rate_10: '1.54%',
    avg_round_10: 10,
    app_flat_10: 1,
    char_11: 'ruan-mei',
    app_rate_11: '1.28%',
    avg_round_11: 6,
    app_flat_11: 1,
    char_12: 'sparkle',
    app_rate_12: '1.28%',
    avg_round_12: 99.99,
    app_flat_12: 0,
    char_13: 'sunday',
    app_rate_13: '1.11%',
    avg_round_13: 8,
    app_flat_13: 1,
    char_14: 'luocha',
    app_rate_14: '1.11%',
    avg_round_14: 10,
    app_flat_14: 1,
    char_15: 'gallagher',
    app_rate_15: '0.85%',
    avg_round_15: 8,
    app_flat_15: 3,
    char_16: 'jing-yuan',
    app_rate_16: '0.26%',
    avg_round_16: 9,
    app_flat_16: 1,
    char_17: 'march-7th-swordmaster',
    app_rate_17: '0.26%',
    avg_round_17: 99.99,
    app_flat_17: 0,
    char_18: 'jiaoqiu',
    app_rate_18: '0.17%',
    avg_round_18: 99.99,
    app_flat_18: 0,
    char_19: 'clara',
    app_rate_19: '0.17%',
    avg_round_19: 99.99,
    app_flat_19: 0,
    char_20: 'bailu',
    app_rate_20: '0.17%',
    avg_round_20: 99.99,
    app_flat_20: 0,
    char_21: 'acheron',
    app_rate_21: '0.09%',
    avg_round_21: 99.99,
    app_flat_21: 0,
    char_22: 'kafka',
    app_rate_22: '0.09%',
    avg_round_22: 99.99,
    app_flat_22: 0,
    char_23: 'moze',
    app_rate_23: '0.09%',
    avg_round_23: 99.99,
    app_flat_23: 0,
    char_24: 'imbibitor-lunae',
    app_rate_24: '0.09%',
    avg_round_24: 99.99,
    app_flat_24: 0,
    char_25: '',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'luocha',
    app: 10.12,
    char_1: 'sunday',
    app_rate_1: '39.53%',
    avg_round_1: 8.96,
    app_flat_1: 111,
    char_2: 'ruan-mei',
    app_rate_2: '34.69%',
    avg_round_2: 9.17,
    app_flat_2: 114,
    char_3: 'imbibitor-lunae',
    app_rate_3: '25.1%',
    avg_round_3: 9.56,
    app_flat_3: 34,
    char_4: 'robin',
    app_rate_4: '23.45%',
    avg_round_4: 9.0,
    app_flat_4: 93,
    char_5: 'sparkle',
    app_rate_5: '22.48%',
    avg_round_5: 9.56,
    app_flat_5: 36,
    char_6: 'black-swan',
    app_rate_6: '15.5%',
    avg_round_6: 8.77,
    app_flat_6: 43,
    char_7: 'jingliu',
    app_rate_7: '13.95%',
    avg_round_7: 8.77,
    app_flat_7: 52,
    char_8: 'kafka',
    app_rate_8: '13.95%',
    avg_round_8: 8.74,
    app_flat_8: 39,
    char_9: 'acheron',
    app_rate_9: '13.57%',
    avg_round_9: 9.09,
    app_flat_9: 42,
    char_10: 'trailblazer-harmony',
    app_rate_10: '10.85%',
    avg_round_10: 9.47,
    app_flat_10: 68,
    char_11: 'rappa',
    app_rate_11: '10.08%',
    avg_round_11: 9.22,
    app_flat_11: 71,
    char_12: 'bronya',
    app_rate_12: '9.69%',
    avg_round_12: 9.25,
    app_flat_12: 22,
    char_13: 'jiaoqiu',
    app_rate_13: '9.3%',
    avg_round_13: 8.85,
    app_flat_13: 25,
    char_14: 'jing-yuan',
    app_rate_14: '8.91%',
    avg_round_14: 9.25,
    app_flat_14: 30,
    char_15: 'tingyun-fugue',
    app_rate_15: '6.88%',
    avg_round_15: 8.66,
    app_flat_15: 35,
    char_16: 'tingyun',
    app_rate_16: '6.49%',
    avg_round_16: 9.75,
    app_flat_16: 16,
    char_17: 'pela',
    app_rate_17: '5.72%',
    avg_round_17: 9.33,
    app_flat_17: 27,
    char_18: 'jade',
    app_rate_18: '4.94%',
    avg_round_18: 9.85,
    app_flat_18: 23,
    char_19: 'firefly',
    app_rate_19: '4.17%',
    avg_round_19: 9.71,
    app_flat_19: 13,
    char_20: 'blade',
    app_rate_20: '3.88%',
    avg_round_20: 10.0,
    app_flat_20: 9,
    char_21: 'feixiao',
    app_rate_21: '2.62%',
    avg_round_21: 9.75,
    app_flat_21: 8,
    char_22: 'yunli',
    app_rate_22: '2.42%',
    avg_round_22: 8.92,
    app_flat_22: 13,
    char_23: 'herta',
    app_rate_23: '2.03%',
    avg_round_23: 9.71,
    app_flat_23: 13,
    char_24: 'boothill',
    app_rate_24: '1.74%',
    avg_round_24: 9,
    app_flat_24: 10,
    char_25: 'march-7th-swordmaster',
    app_rate_25: '1.65%',
    avg_round_25: 9.29,
    app_flat_25: 7,
    char_26: 'topaz',
    app_rate_26: '1.26%',
    avg_round_26: 10,
    app_flat_26: 1,
    char_27: 'clara',
    app_rate_27: '0.97%',
    avg_round_27: 7.83,
    app_flat_27: 6,
    char_28: 'silver-wolf',
    app_rate_28: '0.78%',
    avg_round_28: 8.5,
    app_flat_28: 2,
    char_29: 'seele',
    app_rate_29: '0.48%',
    avg_round_29: 8.5,
    app_flat_29: 2,
    char_30: 'himeko',
    app_rate_30: '0.48%',
    avg_round_30: 8.5,
    app_flat_30: 2
  },
  {
    char: 'march-7th-swordmaster',
    app: 9.31,
    char_1: 'robin',
    app_rate_1: '93.78%',
    avg_round_1: 8.84,
    app_flat_1: 450,
    char_2: 'feixiao',
    app_rate_2: '92.31%',
    avg_round_2: 8.86,
    app_flat_2: 442,
    char_3: 'aventurine',
    app_rate_3: '58.8%',
    avg_round_3: 8.88,
    app_flat_3: 320,
    char_4: 'fu-xuan',
    app_rate_4: '11.28%',
    avg_round_4: 9.11,
    app_flat_4: 37,
    char_5: 'huohuo',
    app_rate_5: '7.27%',
    avg_round_5: 9.0,
    app_flat_5: 31,
    char_6: 'gallagher',
    app_rate_6: '6.64%',
    avg_round_6: 8.41,
    app_flat_6: 37,
    char_7: 'lingsha',
    app_rate_7: '5.58%',
    avg_round_7: 8.44,
    app_flat_7: 32,
    char_8: 'bronya',
    app_rate_8: '5.27%',
    avg_round_8: 9.25,
    app_flat_8: 4,
    char_9: 'sunday',
    app_rate_9: '3.06%',
    avg_round_9: 9.5,
    app_flat_9: 2,
    char_10: 'sparkle',
    app_rate_10: '2.53%',
    avg_round_10: 9.5,
    app_flat_10: 4,
    char_11: 'yunli',
    app_rate_11: '2.42%',
    avg_round_11: 7.75,
    app_flat_11: 8,
    char_12: 'clara',
    app_rate_12: '2.0%',
    avg_round_12: 9.25,
    app_flat_12: 8,
    char_13: 'luocha',
    app_rate_13: '1.79%',
    avg_round_13: 9.29,
    app_flat_13: 7,
    char_14: 'ruan-mei',
    app_rate_14: '1.48%',
    avg_round_14: 9.5,
    app_flat_14: 4,
    char_15: 'trailblazer-harmony',
    app_rate_15: '0.74%',
    avg_round_15: 8.67,
    app_flat_15: 3,
    char_16: 'dr-ratio',
    app_rate_16: '0.53%',
    avg_round_16: 6,
    app_flat_16: 1,
    char_17: 'bailu',
    app_rate_17: '0.53%',
    avg_round_17: 9,
    app_flat_17: 3,
    char_18: 'serval',
    app_rate_18: '0.42%',
    avg_round_18: 9,
    app_flat_18: 2,
    char_19: 'tingyun-fugue',
    app_rate_19: '0.42%',
    avg_round_19: 9,
    app_flat_19: 2,
    char_20: 'moze',
    app_rate_20: '0.32%',
    avg_round_20: 99.99,
    app_flat_20: 0,
    char_21: 'firefly',
    app_rate_21: '0.32%',
    avg_round_21: 99.99,
    app_flat_21: 0,
    char_22: 'tingyun',
    app_rate_22: '0.32%',
    avg_round_22: 99.99,
    app_flat_22: 0,
    char_23: 'topaz',
    app_rate_23: '0.32%',
    avg_round_23: 99.99,
    app_flat_23: 0,
    char_24: 'jade',
    app_rate_24: '0.21%',
    avg_round_24: 4,
    app_flat_24: 1,
    char_25: 'imbibitor-lunae',
    app_rate_25: '0.21%',
    avg_round_25: 99.99,
    app_flat_25: 0,
    char_26: 'blade',
    app_rate_26: '0.21%',
    avg_round_26: 99.99,
    app_flat_26: 0,
    char_27: 'herta',
    app_rate_27: '0.21%',
    avg_round_27: 10,
    app_flat_27: 2,
    char_28: 'rappa',
    app_rate_28: '0.21%',
    avg_round_28: 8,
    app_flat_28: 1,
    char_29: 'seele',
    app_rate_29: '0.11%',
    avg_round_29: 99.99,
    app_flat_29: 0,
    char_30: 'jing-yuan',
    app_rate_30: '0.11%',
    avg_round_30: 99.99,
    app_flat_30: 0
  },
  {
    char: 'silver-wolf',
    app: 9.21,
    char_1: 'acheron',
    app_rate_1: '93.61%',
    avg_round_1: 9.02,
    app_flat_1: 335,
    char_2: 'fu-xuan',
    app_rate_2: '51.33%',
    avg_round_2: 8.89,
    app_flat_2: 147,
    char_3: 'pela',
    app_rate_3: '38.02%',
    avg_round_3: 9.22,
    app_flat_3: 224,
    char_4: 'aventurine',
    app_rate_4: '30.99%',
    avg_round_4: 9.12,
    app_flat_4: 134,
    char_5: 'sparkle',
    app_rate_5: '28.97%',
    avg_round_5: 8.25,
    app_flat_5: 4,
    char_6: 'jiaoqiu',
    app_rate_6: '16.61%',
    avg_round_6: 8.21,
    app_flat_6: 82,
    char_7: 'gallagher',
    app_rate_7: '5.75%',
    avg_round_7: 8.93,
    app_flat_7: 29,
    char_8: 'sunday',
    app_rate_8: '5.43%',
    avg_round_8: 9.5,
    app_flat_8: 6,
    char_9: 'gepard',
    app_rate_9: '3.51%',
    avg_round_9: 8.73,
    app_flat_9: 15,
    char_10: 'huohuo',
    app_rate_10: '3.41%',
    avg_round_10: 9.62,
    app_flat_10: 8,
    char_11: 'tingyun-fugue',
    app_rate_11: '2.56%',
    avg_round_11: 9.29,
    app_flat_11: 11,
    char_12: 'bronya',
    app_rate_12: '2.56%',
    avg_round_12: 99.99,
    app_flat_12: 0,
    char_13: 'black-swan',
    app_rate_13: '2.56%',
    avg_round_13: 9,
    app_flat_13: 8,
    char_14: 'qingque',
    app_rate_14: '2.34%',
    avg_round_14: 99.99,
    app_flat_14: 0,
    char_15: 'robin',
    app_rate_15: '1.92%',
    avg_round_15: 10,
    app_flat_15: 2,
    char_16: 'lingsha',
    app_rate_16: '1.81%',
    avg_round_16: 8.75,
    app_flat_16: 4,
    char_17: 'seele',
    app_rate_17: '1.81%',
    avg_round_17: 99.99,
    app_flat_17: 0,
    char_18: 'kafka',
    app_rate_18: '1.06%',
    avg_round_18: 99.99,
    app_flat_18: 0,
    char_19: 'dr-ratio',
    app_rate_19: '0.96%',
    avg_round_19: 9.25,
    app_flat_19: 4,
    char_20: 'luocha',
    app_rate_20: '0.85%',
    avg_round_20: 8.5,
    app_flat_20: 2,
    char_21: 'rappa',
    app_rate_21: '0.75%',
    avg_round_21: 9.5,
    app_flat_21: 4,
    char_22: 'ruan-mei',
    app_rate_22: '0.75%',
    avg_round_22: 5,
    app_flat_22: 1,
    char_23: 'welt',
    app_rate_23: '0.32%',
    avg_round_23: 9,
    app_flat_23: 1,
    char_24: 'guinaifen',
    app_rate_24: '0.32%',
    avg_round_24: 9.5,
    app_flat_24: 2,
    char_25: 'trailblazer-harmony',
    app_rate_25: '0.32%',
    avg_round_25: 10,
    app_flat_25: 1,
    char_26: 'march-7th',
    app_rate_26: '0.32%',
    avg_round_26: 9,
    app_flat_26: 1,
    char_27: 'jade',
    app_rate_27: '0.21%',
    avg_round_27: 99.99,
    app_flat_27: 0,
    char_28: 'tingyun',
    app_rate_28: '0.21%',
    avg_round_28: 8,
    app_flat_28: 1,
    char_29: 'lynx',
    app_rate_29: '0.21%',
    avg_round_29: 10,
    app_flat_29: 1,
    char_30: 'bailu',
    app_rate_30: '0.21%',
    avg_round_30: 10,
    app_flat_30: 2
  },
  {
    char: 'imbibitor-lunae',
    app: 6.85,
    char_1: 'sparkle',
    app_rate_1: '68.38%',
    avg_round_1: 9.46,
    app_flat_1: 56,
    char_2: 'sunday',
    app_rate_2: '57.08%',
    avg_round_2: 9.15,
    app_flat_2: 50,
    char_3: 'luocha',
    app_rate_3: '37.05%',
    avg_round_3: 9.56,
    app_flat_3: 34,
    char_4: 'ruan-mei',
    app_rate_4: '30.33%',
    avg_round_4: 9.44,
    app_flat_4: 17,
    char_5: 'huohuo',
    app_rate_5: '28.18%',
    avg_round_5: 8.92,
    app_flat_5: 25,
    char_6: 'robin',
    app_rate_6: '19.46%',
    avg_round_6: 9.4,
    app_flat_6: 7,
    char_7: 'tingyun',
    app_rate_7: '18.17%',
    avg_round_7: 9.6,
    app_flat_7: 20,
    char_8: 'aventurine',
    app_rate_8: '16.02%',
    avg_round_8: 9,
    app_flat_8: 10,
    char_9: 'fu-xuan',
    app_rate_9: '8.44%',
    avg_round_9: 10.0,
    app_flat_9: 5,
    char_10: 'gallagher',
    app_rate_10: '5.44%',
    avg_round_10: 7.67,
    app_flat_10: 3,
    char_11: 'jiaoqiu',
    app_rate_11: '2.86%',
    avg_round_11: 9.5,
    app_flat_11: 2,
    char_12: 'pela',
    app_rate_12: '2.29%',
    avg_round_12: 9.5,
    app_flat_12: 4,
    char_13: 'bronya',
    app_rate_13: '1.43%',
    avg_round_13: 99.99,
    app_flat_13: 0,
    char_14: 'lingsha',
    app_rate_14: '1.0%',
    avg_round_14: 10,
    app_flat_14: 1,
    char_15: 'bailu',
    app_rate_15: '0.72%',
    avg_round_15: 99.99,
    app_flat_15: 0,
    char_16: 'trailblazer-harmony',
    app_rate_16: '0.43%',
    avg_round_16: 99.99,
    app_flat_16: 0,
    char_17: 'lynx',
    app_rate_17: '0.43%',
    avg_round_17: 99.99,
    app_flat_17: 0,
    char_18: 'hanya',
    app_rate_18: '0.43%',
    avg_round_18: 99.99,
    app_flat_18: 0,
    char_19: 'march-7th-swordmaster',
    app_rate_19: '0.29%',
    avg_round_19: 99.99,
    app_flat_19: 0,
    char_20: 'clara',
    app_rate_20: '0.14%',
    avg_round_20: 99.99,
    app_flat_20: 0,
    char_21: 'tingyun-fugue',
    app_rate_21: '0.14%',
    avg_round_21: 99.99,
    app_flat_21: 0,
    char_22: 'himeko',
    app_rate_22: '0.14%',
    avg_round_22: 99.99,
    app_flat_22: 0,
    char_23: 'herta',
    app_rate_23: '0.14%',
    avg_round_23: 99.99,
    app_flat_23: 0,
    char_24: 'jingliu',
    app_rate_24: '0.14%',
    avg_round_24: 99.99,
    app_flat_24: 0,
    char_25: 'dan-heng',
    app_rate_25: '0.14%',
    avg_round_25: 99.99,
    app_flat_25: 0,
    char_26: 'jade',
    app_rate_26: '0.14%',
    avg_round_26: 99.99,
    app_flat_26: 0,
    char_27: 'gepard',
    app_rate_27: '0.14%',
    avg_round_27: 99.99,
    app_flat_27: 0,
    char_28: 'topaz',
    app_rate_28: '0.14%',
    avg_round_28: 99.99,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'bronya',
    app: 6.25,
    char_1: 'acheron',
    app_rate_1: '42.23%',
    avg_round_1: 8.5,
    app_flat_1: 6,
    char_2: 'aventurine',
    app_rate_2: '21.35%',
    avg_round_2: 9.67,
    app_flat_2: 17,
    char_3: 'jiaoqiu',
    app_rate_3: '20.72%',
    avg_round_3: 8.2,
    app_flat_3: 5,
    char_4: 'robin',
    app_rate_4: '20.25%',
    avg_round_4: 8.9,
    app_flat_4: 18,
    char_5: 'jingliu',
    app_rate_5: '16.33%',
    avg_round_5: 8.92,
    app_flat_5: 22,
    char_6: 'ruan-mei',
    app_rate_6: '16.17%',
    avg_round_6: 8.64,
    app_flat_6: 11,
    char_7: 'luocha',
    app_rate_7: '15.7%',
    avg_round_7: 9.25,
    app_flat_7: 22,
    char_8: 'pela',
    app_rate_8: '15.38%',
    avg_round_8: 9.5,
    app_flat_8: 2,
    char_9: 'fu-xuan',
    app_rate_9: '14.6%',
    avg_round_9: 9.2,
    app_flat_9: 5,
    char_10: 'feixiao',
    app_rate_10: '13.19%',
    avg_round_10: 8.62,
    app_flat_10: 14,
    char_11: 'sunday',
    app_rate_11: '12.09%',
    avg_round_11: 8.71,
    app_flat_11: 21,
    char_12: 'gallagher',
    app_rate_12: '9.58%',
    avg_round_12: 7.4,
    app_flat_12: 10,
    char_13: 'blade',
    app_rate_13: '9.11%',
    avg_round_13: 9.89,
    app_flat_13: 15,
    char_14: 'huohuo',
    app_rate_14: '9.11%',
    avg_round_14: 9.75,
    app_flat_14: 16,
    char_15: 'march-7th-swordmaster',
    app_rate_15: '7.85%',
    avg_round_15: 9.25,
    app_flat_15: 4,
    char_16: 'firefly',
    app_rate_16: '5.81%',
    avg_round_16: 99.99,
    app_flat_16: 0,
    char_17: 'sparkle',
    app_rate_17: '5.34%',
    avg_round_17: 10,
    app_flat_17: 2,
    char_18: 'trailblazer-harmony',
    app_rate_18: '5.34%',
    avg_round_18: 9.67,
    app_flat_18: 3,
    char_19: 'tingyun-fugue',
    app_rate_19: '5.02%',
    avg_round_19: 9,
    app_flat_19: 1,
    char_20: 'lingsha',
    app_rate_20: '4.55%',
    avg_round_20: 8.78,
    app_flat_20: 9,
    char_21: 'jade',
    app_rate_21: '4.24%',
    avg_round_21: 9.71,
    app_flat_21: 13,
    char_22: 'boothill',
    app_rate_22: '3.77%',
    avg_round_22: 9.33,
    app_flat_22: 3,
    char_23: 'silver-wolf',
    app_rate_23: '3.77%',
    avg_round_23: 99.99,
    app_flat_23: 0,
    char_24: 'jing-yuan',
    app_rate_24: '3.3%',
    avg_round_24: 9.29,
    app_flat_24: 7,
    char_25: 'black-swan',
    app_rate_25: '2.98%',
    avg_round_25: 9.5,
    app_flat_25: 2,
    char_26: 'topaz',
    app_rate_26: '2.83%',
    avg_round_26: 10,
    app_flat_26: 1,
    char_27: 'imbibitor-lunae',
    app_rate_27: '1.57%',
    avg_round_27: 99.99,
    app_flat_27: 0,
    char_28: 'gepard',
    app_rate_28: '1.26%',
    avg_round_28: 99.99,
    app_flat_28: 0,
    char_29: 'kafka',
    app_rate_29: '1.1%',
    avg_round_29: 10,
    app_flat_29: 1,
    char_30: 'lynx',
    app_rate_30: '0.94%',
    avg_round_30: 99.99,
    app_flat_30: 0
  },
  {
    char: 'jingliu',
    app: 4.97,
    char_1: 'sunday',
    app_rate_1: '76.33%',
    avg_round_1: 8.65,
    app_flat_1: 132,
    char_2: 'robin',
    app_rate_2: '43.59%',
    avg_round_2: 7.68,
    app_flat_2: 79,
    char_3: 'huohuo',
    app_rate_3: '39.84%',
    avg_round_3: 7.98,
    app_flat_3: 59,
    char_4: 'ruan-mei',
    app_rate_4: '37.87%',
    avg_round_4: 9.29,
    app_flat_4: 41,
    char_5: 'luocha',
    app_rate_5: '28.4%',
    avg_round_5: 8.77,
    app_flat_5: 52,
    char_6: 'bronya',
    app_rate_6: '20.51%',
    avg_round_6: 8.92,
    app_flat_6: 22,
    char_7: 'sparkle',
    app_rate_7: '9.07%',
    avg_round_7: 9.58,
    app_flat_7: 12,
    char_8: 'aventurine',
    app_rate_8: '8.68%',
    avg_round_8: 8.78,
    app_flat_8: 9,
    char_9: 'fu-xuan',
    app_rate_9: '8.09%',
    avg_round_9: 9.67,
    app_flat_9: 6,
    char_10: 'gallagher',
    app_rate_10: '6.9%',
    avg_round_10: 7.75,
    app_flat_10: 12,
    char_11: 'pela',
    app_rate_11: '5.33%',
    avg_round_11: 9.75,
    app_flat_11: 8,
    char_12: 'lingsha',
    app_rate_12: '4.34%',
    avg_round_12: 9.0,
    app_flat_12: 8,
    char_13: 'tingyun',
    app_rate_13: '3.75%',
    avg_round_13: 9.0,
    app_flat_13: 4,
    char_14: 'jade',
    app_rate_14: '1.38%',
    avg_round_14: 99.99,
    app_flat_14: 0,
    char_15: 'blade',
    app_rate_15: '1.38%',
    avg_round_15: 10,
    app_flat_15: 1,
    char_16: 'lynx',
    app_rate_16: '1.18%',
    avg_round_16: 9.5,
    app_flat_16: 2,
    char_17: 'jiaoqiu',
    app_rate_17: '0.79%',
    avg_round_17: 99.99,
    app_flat_17: 0,
    char_18: 'tingyun-fugue',
    app_rate_18: '0.79%',
    avg_round_18: 10,
    app_flat_18: 1,
    char_19: 'bailu',
    app_rate_19: '0.59%',
    avg_round_19: 8.5,
    app_flat_19: 2,
    char_20: 'rappa',
    app_rate_20: '0.2%',
    avg_round_20: 99.99,
    app_flat_20: 0,
    char_21: 'imbibitor-lunae',
    app_rate_21: '0.2%',
    avg_round_21: 99.99,
    app_flat_21: 0,
    char_22: 'gepard',
    app_rate_22: '0.2%',
    avg_round_22: 99.99,
    app_flat_22: 0,
    char_23: 'firefly',
    app_rate_23: '0.2%',
    avg_round_23: 99.99,
    app_flat_23: 0,
    char_24: 'trailblazer-harmony',
    app_rate_24: '0.2%',
    avg_round_24: 99.99,
    app_flat_24: 0,
    char_25: '',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'jade',
    app: 4.53,
    char_1: 'robin',
    app_rate_1: '66.45%',
    avg_round_1: 9.33,
    app_flat_1: 116,
    char_2: 'aventurine',
    app_rate_2: '42.42%',
    avg_round_2: 9.53,
    app_flat_2: 64,
    char_3: 'herta',
    app_rate_3: '38.96%',
    avg_round_3: 9.51,
    app_flat_3: 101,
    char_4: 'feixiao',
    app_rate_4: '23.81%',
    avg_round_4: 8.86,
    app_flat_4: 11,
    char_5: 'huohuo',
    app_rate_5: '14.94%',
    avg_round_5: 9.53,
    app_flat_5: 31,
    char_6: 'fu-xuan',
    app_rate_6: '14.5%',
    avg_round_6: 9.58,
    app_flat_6: 22,
    char_7: 'sunday',
    app_rate_7: '14.07%',
    avg_round_7: 8.92,
    app_flat_7: 23,
    char_8: 'blade',
    app_rate_8: '12.99%',
    avg_round_8: 9.92,
    app_flat_8: 22,
    char_9: 'lingsha',
    app_rate_9: '11.26%',
    avg_round_9: 8.62,
    app_flat_9: 25,
    char_10: 'ruan-mei',
    app_rate_10: '11.04%',
    avg_round_10: 9.37,
    app_flat_10: 19,
    char_11: 'luocha',
    app_rate_11: '11.04%',
    avg_round_11: 9.85,
    app_flat_11: 23,
    char_12: 'sparkle',
    app_rate_12: '6.93%',
    avg_round_12: 9.67,
    app_flat_12: 10,
    char_13: 'bronya',
    app_rate_13: '5.84%',
    avg_round_13: 9.71,
    app_flat_13: 13,
    char_14: 'topaz',
    app_rate_14: '4.33%',
    avg_round_14: 8,
    app_flat_14: 1,
    char_15: 'gallagher',
    app_rate_15: '2.38%',
    avg_round_15: 8.25,
    app_flat_15: 4,
    char_16: 'jing-yuan',
    app_rate_16: '2.38%',
    avg_round_16: 9.67,
    app_flat_16: 3,
    char_17: 'yunli',
    app_rate_17: '2.16%',
    avg_round_17: 9.5,
    app_flat_17: 8,
    char_18: 'jingliu',
    app_rate_18: '1.52%',
    avg_round_18: 99.99,
    app_flat_18: 0,
    char_19: 'lynx',
    app_rate_19: '1.3%',
    avg_round_19: 9.5,
    app_flat_19: 2,
    char_20: 'pela',
    app_rate_20: '1.08%',
    avg_round_20: 8.5,
    app_flat_20: 2,
    char_21: 'dr-ratio',
    app_rate_21: '1.08%',
    avg_round_21: 99.99,
    app_flat_21: 0,
    char_22: 'tingyun',
    app_rate_22: '1.08%',
    avg_round_22: 9.33,
    app_flat_22: 3,
    char_23: 'clara',
    app_rate_23: '0.87%',
    avg_round_23: 9,
    app_flat_23: 1,
    char_24: 'jiaoqiu',
    app_rate_24: '0.87%',
    avg_round_24: 8,
    app_flat_24: 2,
    char_25: 'rappa',
    app_rate_25: '0.65%',
    avg_round_25: 9,
    app_flat_25: 2,
    char_26: 'tingyun-fugue',
    app_rate_26: '0.65%',
    avg_round_26: 9,
    app_flat_26: 1,
    char_27: 'seele',
    app_rate_27: '0.43%',
    avg_round_27: 99.99,
    app_flat_27: 0,
    char_28: 'himeko',
    app_rate_28: '0.43%',
    avg_round_28: 99.99,
    app_flat_28: 0,
    char_29: 'silver-wolf',
    app_rate_29: '0.43%',
    avg_round_29: 99.99,
    app_flat_29: 0,
    char_30: 'trailblazer-harmony',
    app_rate_30: '0.43%',
    avg_round_30: 8,
    app_flat_30: 1
  },
  {
    char: 'tingyun',
    app: 4.49,
    char_1: 'sunday',
    app_rate_1: '41.7%',
    avg_round_1: 8.71,
    app_flat_1: 65,
    char_2: 'huohuo',
    app_rate_2: '40.83%',
    avg_round_2: 8.62,
    app_flat_2: 82,
    char_3: 'sparkle',
    app_rate_3: '33.84%',
    avg_round_3: 8.9,
    app_flat_3: 39,
    char_4: 'imbibitor-lunae',
    app_rate_4: '27.73%',
    avg_round_4: 9.6,
    app_flat_4: 20,
    char_5: 'yunli',
    app_rate_5: '27.07%',
    avg_round_5: 8.28,
    app_flat_5: 57,
    char_6: 'jing-yuan',
    app_rate_6: '24.02%',
    avg_round_6: 8.89,
    app_flat_6: 38,
    char_7: 'robin',
    app_rate_7: '18.34%',
    avg_round_7: 8.58,
    app_flat_7: 37,
    char_8: 'aventurine',
    app_rate_8: '16.16%',
    avg_round_8: 9.11,
    app_flat_8: 18,
    char_9: 'luocha',
    app_rate_9: '14.63%',
    avg_round_9: 9.75,
    app_flat_9: 16,
    char_10: 'fu-xuan',
    app_rate_10: '13.1%',
    avg_round_10: 8.44,
    app_flat_10: 18,
    char_11: 'gallagher',
    app_rate_11: '5.46%',
    avg_round_11: 7.86,
    app_flat_11: 7,
    char_12: 'jingliu',
    app_rate_12: '4.15%',
    avg_round_12: 9.0,
    app_flat_12: 4,
    char_13: 'seele',
    app_rate_13: '3.71%',
    avg_round_13: 7.5,
    app_flat_13: 4,
    char_14: 'ruan-mei',
    app_rate_14: '3.49%',
    avg_round_14: 8,
    app_flat_14: 1,
    char_15: 'kafka',
    app_rate_15: '2.84%',
    avg_round_15: 9.33,
    app_flat_15: 3,
    char_16: 'black-swan',
    app_rate_16: '2.84%',
    avg_round_16: 9.33,
    app_flat_16: 3,
    char_17: 'lingsha',
    app_rate_17: '2.62%',
    avg_round_17: 9.25,
    app_flat_17: 4,
    char_18: 'qingque',
    app_rate_18: '2.18%',
    avg_round_18: 8.17,
    app_flat_18: 6,
    char_19: 'pela',
    app_rate_19: '1.75%',
    avg_round_19: 99.99,
    app_flat_19: 0,
    char_20: 'argenti',
    app_rate_20: '1.75%',
    avg_round_20: 9.5,
    app_flat_20: 4,
    char_21: 'clara',
    app_rate_21: '1.75%',
    avg_round_21: 9,
    app_flat_21: 5,
    char_22: 'dr-ratio',
    app_rate_22: '1.09%',
    avg_round_22: 8,
    app_flat_22: 1,
    char_23: 'serval',
    app_rate_23: '1.09%',
    avg_round_23: 8,
    app_flat_23: 3,
    char_24: 'bailu',
    app_rate_24: '1.09%',
    avg_round_24: 10,
    app_flat_24: 2,
    char_25: 'jade',
    app_rate_25: '1.09%',
    avg_round_25: 9.33,
    app_flat_25: 3,
    char_26: 'bronya',
    app_rate_26: '0.66%',
    avg_round_26: 8,
    app_flat_26: 1,
    char_27: 'jiaoqiu',
    app_rate_27: '0.66%',
    avg_round_27: 99.99,
    app_flat_27: 0,
    char_28: 'march-7th-swordmaster',
    app_rate_28: '0.66%',
    avg_round_28: 99.99,
    app_flat_28: 0,
    char_29: 'tingyun-fugue',
    app_rate_29: '0.66%',
    avg_round_29: 99.99,
    app_flat_29: 0,
    char_30: 'silver-wolf',
    app_rate_30: '0.44%',
    avg_round_30: 8,
    app_flat_30: 1
  },
  {
    char: 'yunli',
    app: 3.59,
    char_1: 'robin',
    app_rate_1: '74.04%',
    avg_round_1: 8.42,
    app_flat_1: 111,
    char_2: 'huohuo',
    app_rate_2: '55.46%',
    avg_round_2: 7.84,
    app_flat_2: 91,
    char_3: 'sunday',
    app_rate_3: '36.89%',
    avg_round_3: 8.17,
    app_flat_3: 59,
    char_4: 'tingyun',
    app_rate_4: '33.88%',
    avg_round_4: 8.28,
    app_flat_4: 57,
    char_5: 'sparkle',
    app_rate_5: '24.59%',
    avg_round_5: 8.33,
    app_flat_5: 21,
    char_6: 'aventurine',
    app_rate_6: '15.03%',
    avg_round_6: 8.93,
    app_flat_6: 15,
    char_7: 'fu-xuan',
    app_rate_7: '11.2%',
    avg_round_7: 8.35,
    app_flat_7: 17,
    char_8: 'feixiao',
    app_rate_8: '9.56%',
    avg_round_8: 8.5,
    app_flat_8: 14,
    char_9: 'luocha',
    app_rate_9: '6.83%',
    avg_round_9: 8.92,
    app_flat_9: 13,
    char_10: 'march-7th-swordmaster',
    app_rate_10: '6.28%',
    avg_round_10: 7.75,
    app_flat_10: 8,
    char_11: 'topaz',
    app_rate_11: '5.19%',
    avg_round_11: 8.33,
    app_flat_11: 6,
    char_12: 'gallagher',
    app_rate_12: '4.37%',
    avg_round_12: 8,
    app_flat_12: 6,
    char_13: 'ruan-mei',
    app_rate_13: '3.55%',
    avg_round_13: 7.33,
    app_flat_13: 3,
    char_14: 'jade',
    app_rate_14: '2.73%',
    avg_round_14: 9.5,
    app_flat_14: 8,
    char_15: 'lingsha',
    app_rate_15: '1.91%',
    avg_round_15: 9.33,
    app_flat_15: 3,
    char_16: 'pela',
    app_rate_16: '1.64%',
    avg_round_16: 9,
    app_flat_16: 1,
    char_17: 'bailu',
    app_rate_17: '1.37%',
    avg_round_17: 10,
    app_flat_17: 2,
    char_18: 'clara',
    app_rate_18: '1.37%',
    avg_round_18: 9.33,
    app_flat_18: 3,
    char_19: 'jiaoqiu',
    app_rate_19: '1.09%',
    avg_round_19: 9,
    app_flat_19: 1,
    char_20: 'acheron',
    app_rate_20: '0.82%',
    avg_round_20: 99.99,
    app_flat_20: 0,
    char_21: 'tingyun-fugue',
    app_rate_21: '0.27%',
    avg_round_21: 99.99,
    app_flat_21: 0,
    char_22: 'bronya',
    app_rate_22: '0.27%',
    avg_round_22: 10,
    app_flat_22: 1,
    char_23: 'kafka',
    app_rate_23: '0.27%',
    avg_round_23: 99.99,
    app_flat_23: 0,
    char_24: 'black-swan',
    app_rate_24: '0.27%',
    avg_round_24: 99.99,
    app_flat_24: 0,
    char_25: 'lynx',
    app_rate_25: '0.27%',
    avg_round_25: 99.99,
    app_flat_25: 0,
    char_26: 'rappa',
    app_rate_26: '0.27%',
    avg_round_26: 10,
    app_flat_26: 1,
    char_27: 'silver-wolf',
    app_rate_27: '0.27%',
    avg_round_27: 99.99,
    app_flat_27: 0,
    char_28: 'herta',
    app_rate_28: '0.27%',
    avg_round_28: 99.99,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'herta',
    app: 2.14,
    char_1: 'jade',
    app_rate_1: '82.57%',
    avg_round_1: 9.51,
    app_flat_1: 101,
    char_2: 'robin',
    app_rate_2: '76.15%',
    avg_round_2: 9.52,
    app_flat_2: 92,
    char_3: 'aventurine',
    app_rate_3: '51.83%',
    avg_round_3: 9.59,
    app_flat_3: 57,
    char_4: 'fu-xuan',
    app_rate_4: '15.14%',
    avg_round_4: 9.62,
    app_flat_4: 14,
    char_5: 'huohuo',
    app_rate_5: '14.22%',
    avg_round_5: 9.5,
    app_flat_5: 20,
    char_6: 'ruan-mei',
    app_rate_6: '13.76%',
    avg_round_6: 9.62,
    app_flat_6: 13,
    char_7: 'luocha',
    app_rate_7: '9.63%',
    avg_round_7: 9.71,
    app_flat_7: 13,
    char_8: 'sparkle',
    app_rate_8: '8.26%',
    avg_round_8: 9.67,
    app_flat_8: 5,
    char_9: 'sunday',
    app_rate_9: '8.26%',
    avg_round_9: 9.6,
    app_flat_9: 9,
    char_10: 'lingsha',
    app_rate_10: '5.05%',
    avg_round_10: 8.83,
    app_flat_10: 6,
    char_11: 'feixiao',
    app_rate_11: '4.59%',
    avg_round_11: 9,
    app_flat_11: 2,
    char_12: 'gallagher',
    app_rate_12: '2.75%',
    avg_round_12: 9.0,
    app_flat_12: 5,
    char_13: 'clara',
    app_rate_13: '1.38%',
    avg_round_13: 10,
    app_flat_13: 1,
    char_14: 'tingyun-fugue',
    app_rate_14: '0.92%',
    avg_round_14: 8,
    app_flat_14: 1,
    char_15: 'march-7th-swordmaster',
    app_rate_15: '0.92%',
    avg_round_15: 10,
    app_flat_15: 2,
    char_16: 'jiaoqiu',
    app_rate_16: '0.92%',
    avg_round_16: 9,
    app_flat_16: 1,
    char_17: 'imbibitor-lunae',
    app_rate_17: '0.46%',
    avg_round_17: 99.99,
    app_flat_17: 0,
    char_18: 'tingyun',
    app_rate_18: '0.46%',
    avg_round_18: 99.99,
    app_flat_18: 0,
    char_19: 'lynx',
    app_rate_19: '0.46%',
    avg_round_19: 99.99,
    app_flat_19: 0,
    char_20: 'bronya',
    app_rate_20: '0.46%',
    avg_round_20: 8,
    app_flat_20: 1,
    char_21: 'acheron',
    app_rate_21: '0.46%',
    avg_round_21: 9,
    app_flat_21: 1,
    char_22: 'rappa',
    app_rate_22: '0.46%',
    avg_round_22: 8,
    app_flat_22: 1,
    char_23: 'bailu',
    app_rate_23: '0.46%',
    avg_round_23: 99.99,
    app_flat_23: 0,
    char_24: 'yunli',
    app_rate_24: '0.46%',
    avg_round_24: 99.99,
    app_flat_24: 0,
    char_25: '',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'boothill',
    app: 2.05,
    char_1: 'ruan-mei',
    app_rate_1: '89.47%',
    avg_round_1: 9.22,
    app_flat_1: 43,
    char_2: 'tingyun-fugue',
    app_rate_2: '79.43%',
    avg_round_2: 9.21,
    app_flat_2: 35,
    char_3: 'trailblazer-harmony',
    app_rate_3: '33.49%',
    avg_round_3: 9.5,
    app_flat_3: 14,
    char_4: 'gallagher',
    app_rate_4: '30.14%',
    avg_round_4: 9.47,
    app_flat_4: 27,
    char_5: 'sunday',
    app_rate_5: '21.05%',
    avg_round_5: 99.99,
    app_flat_5: 0,
    char_6: 'lingsha',
    app_rate_6: '12.44%',
    avg_round_6: 8,
    app_flat_6: 8,
    char_7: 'bronya',
    app_rate_7: '11.48%',
    avg_round_7: 9.33,
    app_flat_7: 3,
    char_8: 'luocha',
    app_rate_8: '8.61%',
    avg_round_8: 9,
    app_flat_8: 10,
    char_9: 'aventurine',
    app_rate_9: '4.78%',
    avg_round_9: 7,
    app_flat_9: 2,
    char_10: 'huohuo',
    app_rate_10: '3.35%',
    avg_round_10: 99.99,
    app_flat_10: 0,
    char_11: 'fu-xuan',
    app_rate_11: '2.39%',
    avg_round_11: 10,
    app_flat_11: 1,
    char_12: 'pela',
    app_rate_12: '1.44%',
    avg_round_12: 9,
    app_flat_12: 1,
    char_13: 'rappa',
    app_rate_13: '0.96%',
    avg_round_13: 99.99,
    app_flat_13: 0,
    char_14: 'acheron',
    app_rate_14: '0.48%',
    avg_round_14: 99.99,
    app_flat_14: 0,
    char_15: 'lynx',
    app_rate_15: '0.48%',
    avg_round_15: 99.99,
    app_flat_15: 0,
    char_16: '',
    app_rate_16: '0.00%',
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '',
    app_rate_17: '0.00%',
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '',
    app_rate_18: '0.00%',
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '',
    app_rate_19: '0.00%',
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '',
    app_rate_20: '0.00%',
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '',
    app_rate_21: '0.00%',
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '',
    app_rate_22: '0.00%',
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'gepard',
    app: 1.74,
    char_1: 'acheron',
    app_rate_1: '93.22%',
    avg_round_1: 8.59,
    app_flat_1: 66,
    char_2: 'pela',
    app_rate_2: '48.02%',
    avg_round_2: 8.57,
    app_flat_2: 40,
    char_3: 'black-swan',
    app_rate_3: '29.38%',
    avg_round_3: 8.69,
    app_flat_3: 26,
    char_4: 'jiaoqiu',
    app_rate_4: '23.73%',
    avg_round_4: 8.27,
    app_flat_4: 22,
    char_5: 'sparkle',
    app_rate_5: '23.16%',
    avg_round_5: 9,
    app_flat_5: 6,
    char_6: 'kafka',
    app_rate_6: '20.34%',
    avg_round_6: 8.85,
    app_flat_6: 13,
    char_7: 'silver-wolf',
    app_rate_7: '18.64%',
    avg_round_7: 8.73,
    app_flat_7: 15,
    char_8: 'sunday',
    app_rate_8: '11.86%',
    avg_round_8: 7.25,
    app_flat_8: 4,
    char_9: 'guinaifen',
    app_rate_9: '6.21%',
    avg_round_9: 7.75,
    app_flat_9: 4,
    char_10: 'tingyun-fugue',
    app_rate_10: '4.52%',
    avg_round_10: 10.0,
    app_flat_10: 5,
    char_11: 'bronya',
    app_rate_11: '4.52%',
    avg_round_11: 99.99,
    app_flat_11: 0,
    char_12: 'robin',
    app_rate_12: '3.39%',
    avg_round_12: 7,
    app_flat_12: 1,
    char_13: 'ruan-mei',
    app_rate_13: '3.39%',
    avg_round_13: 8,
    app_flat_13: 3,
    char_14: 'luocha',
    app_rate_14: '1.13%',
    avg_round_14: 99.99,
    app_flat_14: 0,
    char_15: 'jing-yuan',
    app_rate_15: '1.13%',
    avg_round_15: 7,
    app_flat_15: 1,
    char_16: 'rappa',
    app_rate_16: '1.13%',
    avg_round_16: 7,
    app_flat_16: 2,
    char_17: 'sampo',
    app_rate_17: '1.13%',
    avg_round_17: 99.99,
    app_flat_17: 0,
    char_18: 'qingque',
    app_rate_18: '0.56%',
    avg_round_18: 99.99,
    app_flat_18: 0,
    char_19: 'firefly',
    app_rate_19: '0.56%',
    avg_round_19: 10,
    app_flat_19: 1,
    char_20: 'jade',
    app_rate_20: '0.56%',
    avg_round_20: 99.99,
    app_flat_20: 0,
    char_21: 'march-7th-swordmaster',
    app_rate_21: '0.56%',
    avg_round_21: 99.99,
    app_flat_21: 0,
    char_22: 'trailblazer-harmony',
    app_rate_22: '0.56%',
    avg_round_22: 6,
    app_flat_22: 1,
    char_23: 'jingliu',
    app_rate_23: '0.56%',
    avg_round_23: 99.99,
    app_flat_23: 0,
    char_24: 'lynx',
    app_rate_24: '0.56%',
    avg_round_24: 99.99,
    app_flat_24: 0,
    char_25: 'imbibitor-lunae',
    app_rate_25: '0.56%',
    avg_round_25: 99.99,
    app_flat_25: 0,
    char_26: 'fu-xuan',
    app_rate_26: '0.56%',
    avg_round_26: 99.99,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'blade',
    app: 1.44,
    char_1: 'sunday',
    app_rate_1: '43.54%',
    avg_round_1: 9.5,
    app_flat_1: 14,
    char_2: 'jade',
    app_rate_2: '40.82%',
    avg_round_2: 9.92,
    app_flat_2: 22,
    char_3: 'bronya',
    app_rate_3: '39.46%',
    avg_round_3: 9.89,
    app_flat_3: 15,
    char_4: 'huohuo',
    app_rate_4: '29.25%',
    avg_round_4: 9.75,
    app_flat_4: 16,
    char_5: 'luocha',
    app_rate_5: '27.21%',
    avg_round_5: 10.0,
    app_flat_5: 9,
    char_6: 'robin',
    app_rate_6: '26.53%',
    avg_round_6: 10.0,
    app_flat_6: 10,
    char_7: 'fu-xuan',
    app_rate_7: '20.41%',
    avg_round_7: 10.0,
    app_flat_7: 8,
    char_8: 'ruan-mei',
    app_rate_8: '19.73%',
    avg_round_8: 9.67,
    app_flat_8: 10,
    char_9: 'sparkle',
    app_rate_9: '14.29%',
    avg_round_9: 9.67,
    app_flat_9: 6,
    char_10: 'gallagher',
    app_rate_10: '9.52%',
    avg_round_10: 9.5,
    app_flat_10: 4,
    char_11: 'lynx',
    app_rate_11: '6.8%',
    avg_round_11: 9.5,
    app_flat_11: 2,
    char_12: 'jiaoqiu',
    app_rate_12: '6.12%',
    avg_round_12: 9,
    app_flat_12: 1,
    char_13: 'jingliu',
    app_rate_13: '4.76%',
    avg_round_13: 10,
    app_flat_13: 1,
    char_14: 'lingsha',
    app_rate_14: '3.4%',
    avg_round_14: 8.5,
    app_flat_14: 2,
    char_15: 'march-7th-swordmaster',
    app_rate_15: '1.36%',
    avg_round_15: 99.99,
    app_flat_15: 0,
    char_16: 'welt',
    app_rate_16: '0.68%',
    avg_round_16: 99.99,
    app_flat_16: 0,
    char_17: 'natasha',
    app_rate_17: '0.68%',
    avg_round_17: 99.99,
    app_flat_17: 0,
    char_18: 'jing-yuan',
    app_rate_18: '0.68%',
    avg_round_18: 99.99,
    app_flat_18: 0,
    char_19: 'himeko',
    app_rate_19: '0.68%',
    avg_round_19: 99.99,
    app_flat_19: 0,
    char_20: 'bailu',
    app_rate_20: '0.68%',
    avg_round_20: 99.99,
    app_flat_20: 0,
    char_21: 'tingyun',
    app_rate_21: '0.68%',
    avg_round_21: 9,
    app_flat_21: 1,
    char_22: 'moze',
    app_rate_22: '0.68%',
    avg_round_22: 10,
    app_flat_22: 1,
    char_23: 'aventurine',
    app_rate_23: '0.68%',
    avg_round_23: 99.99,
    app_flat_23: 0,
    char_24: 'serval',
    app_rate_24: '0.68%',
    avg_round_24: 10,
    app_flat_24: 1,
    char_25: 'pela',
    app_rate_25: '0.68%',
    avg_round_25: 99.99,
    app_flat_25: 0,
    char_26: '',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'moze',
    app: 1.13,
    char_1: 'feixiao',
    app_rate_1: '90.43%',
    avg_round_1: 8.76,
    app_flat_1: 33,
    char_2: 'robin',
    app_rate_2: '90.43%',
    avg_round_2: 8.78,
    app_flat_2: 34,
    char_3: 'aventurine',
    app_rate_3: '88.7%',
    avg_round_3: 8.76,
    app_flat_3: 33,
    char_4: 'dr-ratio',
    app_rate_4: '5.22%',
    avg_round_4: 10,
    app_flat_4: 1,
    char_5: 'fu-xuan',
    app_rate_5: '4.35%',
    avg_round_5: 99.99,
    app_flat_5: 0,
    char_6: 'gallagher',
    app_rate_6: '4.35%',
    avg_round_6: 9,
    app_flat_6: 1,
    char_7: 'jiaoqiu',
    app_rate_7: '3.48%',
    avg_round_7: 99.99,
    app_flat_7: 0,
    char_8: 'march-7th-swordmaster',
    app_rate_8: '2.61%',
    avg_round_8: 99.99,
    app_flat_8: 0,
    char_9: 'sparkle',
    app_rate_9: '1.74%',
    avg_round_9: 99.99,
    app_flat_9: 0,
    char_10: 'sunday',
    app_rate_10: '1.74%',
    avg_round_10: 10,
    app_flat_10: 1,
    char_11: 'black-swan',
    app_rate_11: '0.87%',
    avg_round_11: 99.99,
    app_flat_11: 0,
    char_12: 'ruan-mei',
    app_rate_12: '0.87%',
    avg_round_12: 99.99,
    app_flat_12: 0,
    char_13: 'march-7th',
    app_rate_13: '0.87%',
    avg_round_13: 99.99,
    app_flat_13: 0,
    char_14: 'jade',
    app_rate_14: '0.87%',
    avg_round_14: 99.99,
    app_flat_14: 0,
    char_15: 'topaz',
    app_rate_15: '0.87%',
    avg_round_15: 99.99,
    app_flat_15: 0,
    char_16: 'blade',
    app_rate_16: '0.87%',
    avg_round_16: 10,
    app_flat_16: 1,
    char_17: 'lingsha',
    app_rate_17: '0.87%',
    avg_round_17: 10,
    app_flat_17: 1,
    char_18: 'clara',
    app_rate_18: '0.87%',
    avg_round_18: 99.99,
    app_flat_18: 0,
    char_19: '',
    app_rate_19: '0.00%',
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '',
    app_rate_20: '0.00%',
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '',
    app_rate_21: '0.00%',
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '',
    app_rate_22: '0.00%',
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'dr-ratio',
    app: 1.06,
    char_1: 'aventurine',
    app_rate_1: '88.89%',
    avg_round_1: 9.25,
    app_flat_1: 14,
    char_2: 'robin',
    app_rate_2: '75.0%',
    avg_round_2: 9.29,
    app_flat_2: 11,
    char_3: 'topaz',
    app_rate_3: '47.22%',
    avg_round_3: 9.0,
    app_flat_3: 4,
    char_4: 'sunday',
    app_rate_4: '15.74%',
    avg_round_4: 9.33,
    app_flat_4: 5,
    char_5: 'jiaoqiu',
    app_rate_5: '12.96%',
    avg_round_5: 10,
    app_flat_5: 1,
    char_6: 'pela',
    app_rate_6: '9.26%',
    avg_round_6: 10.0,
    app_flat_6: 4,
    char_7: 'silver-wolf',
    app_rate_7: '8.33%',
    avg_round_7: 9.25,
    app_flat_7: 4,
    char_8: 'ruan-mei',
    app_rate_8: '6.48%',
    avg_round_8: 99.99,
    app_flat_8: 0,
    char_9: 'moze',
    app_rate_9: '5.56%',
    avg_round_9: 10,
    app_flat_9: 1,
    char_10: 'march-7th-swordmaster',
    app_rate_10: '4.63%',
    avg_round_10: 6,
    app_flat_10: 1,
    char_11: 'tingyun',
    app_rate_11: '4.63%',
    avg_round_11: 8,
    app_flat_11: 1,
    char_12: 'jade',
    app_rate_12: '4.63%',
    avg_round_12: 99.99,
    app_flat_12: 0,
    char_13: 'gallagher',
    app_rate_13: '2.78%',
    avg_round_13: 99.99,
    app_flat_13: 0,
    char_14: 'sparkle',
    app_rate_14: '2.78%',
    avg_round_14: 99.99,
    app_flat_14: 0,
    char_15: 'huohuo',
    app_rate_15: '1.85%',
    avg_round_15: 10,
    app_flat_15: 1,
    char_16: 'fu-xuan',
    app_rate_16: '1.85%',
    avg_round_16: 99.99,
    app_flat_16: 0,
    char_17: 'luocha',
    app_rate_17: '1.85%',
    avg_round_17: 99.99,
    app_flat_17: 0,
    char_18: 'feixiao',
    app_rate_18: '0.93%',
    avg_round_18: 99.99,
    app_flat_18: 0,
    char_19: 'natasha',
    app_rate_19: '0.93%',
    avg_round_19: 99.99,
    app_flat_19: 0,
    char_20: 'bronya',
    app_rate_20: '0.93%',
    avg_round_20: 99.99,
    app_flat_20: 0,
    char_21: 'lingsha',
    app_rate_21: '0.93%',
    avg_round_21: 99.99,
    app_flat_21: 0,
    char_22: 'clara',
    app_rate_22: '0.93%',
    avg_round_22: 99.99,
    app_flat_22: 0,
    char_23: 'bailu',
    app_rate_23: '0.93%',
    avg_round_23: 10,
    app_flat_23: 1,
    char_24: '',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'bailu',
    app: 0.89,
    char_1: 'black-swan',
    app_rate_1: '49.45%',
    avg_round_1: 9.29,
    app_flat_1: 13,
    char_2: 'kafka',
    app_rate_2: '46.15%',
    avg_round_2: 9.29,
    app_flat_2: 13,
    char_3: 'robin',
    app_rate_3: '29.67%',
    avg_round_3: 9.38,
    app_flat_3: 14,
    char_4: 'ruan-mei',
    app_rate_4: '27.47%',
    avg_round_4: 9.5,
    app_flat_4: 8,
    char_5: 'acheron',
    app_rate_5: '25.27%',
    avg_round_5: 10.0,
    app_flat_5: 4,
    char_6: 'sunday',
    app_rate_6: '17.58%',
    avg_round_6: 10.0,
    app_flat_6: 8,
    char_7: 'sparkle',
    app_rate_7: '13.19%',
    avg_round_7: 8,
    app_flat_7: 2,
    char_8: 'jiaoqiu',
    app_rate_8: '10.99%',
    avg_round_8: 10,
    app_flat_8: 1,
    char_9: 'feixiao',
    app_rate_9: '7.69%',
    avg_round_9: 9,
    app_flat_9: 3,
    char_10: 'jing-yuan',
    app_rate_10: '6.59%',
    avg_round_10: 10,
    app_flat_10: 3,
    char_11: 'yunli',
    app_rate_11: '5.49%',
    avg_round_11: 10,
    app_flat_11: 2,
    char_12: 'imbibitor-lunae',
    app_rate_12: '5.49%',
    avg_round_12: 99.99,
    app_flat_12: 0,
    char_13: 'rappa',
    app_rate_13: '5.49%',
    avg_round_13: 10,
    app_flat_13: 2,
    char_14: 'march-7th-swordmaster',
    app_rate_14: '5.49%',
    avg_round_14: 9,
    app_flat_14: 3,
    char_15: 'tingyun',
    app_rate_15: '5.49%',
    avg_round_15: 10,
    app_flat_15: 2,
    char_16: 'bronya',
    app_rate_16: '5.49%',
    avg_round_16: 99.99,
    app_flat_16: 0,
    char_17: 'trailblazer-harmony',
    app_rate_17: '4.4%',
    avg_round_17: 10,
    app_flat_17: 1,
    char_18: 'tingyun-fugue',
    app_rate_18: '4.4%',
    avg_round_18: 10,
    app_flat_18: 2,
    char_19: 'jingliu',
    app_rate_19: '3.3%',
    avg_round_19: 8.5,
    app_flat_19: 2,
    char_20: 'serval',
    app_rate_20: '3.3%',
    avg_round_20: 6,
    app_flat_20: 1,
    char_21: 'pela',
    app_rate_21: '3.3%',
    avg_round_21: 10,
    app_flat_21: 1,
    char_22: 'silver-wolf',
    app_rate_22: '2.2%',
    avg_round_22: 10,
    app_flat_22: 2,
    char_23: 'topaz',
    app_rate_23: '2.2%',
    avg_round_23: 99.99,
    app_flat_23: 0,
    char_24: 'jade',
    app_rate_24: '2.2%',
    avg_round_24: 99.99,
    app_flat_24: 0,
    char_25: 'firefly',
    app_rate_25: '1.1%',
    avg_round_25: 99.99,
    app_flat_25: 0,
    char_26: 'sampo',
    app_rate_26: '1.1%',
    avg_round_26: 99.99,
    app_flat_26: 0,
    char_27: 'asta',
    app_rate_27: '1.1%',
    avg_round_27: 9,
    app_flat_27: 1,
    char_28: 'qingque',
    app_rate_28: '1.1%',
    avg_round_28: 6,
    app_flat_28: 1,
    char_29: 'blade',
    app_rate_29: '1.1%',
    avg_round_29: 99.99,
    app_flat_29: 0,
    char_30: 'herta',
    app_rate_30: '1.1%',
    avg_round_30: 99.99,
    app_flat_30: 0
  },
  {
    char: 'guinaifen',
    app: 0.8,
    char_1: 'acheron',
    app_rate_1: '68.29%',
    avg_round_1: 9.62,
    app_flat_1: 25,
    char_2: 'pela',
    app_rate_2: '37.8%',
    avg_round_2: 9.25,
    app_flat_2: 16,
    char_3: 'kafka',
    app_rate_3: '35.37%',
    avg_round_3: 10.0,
    app_flat_3: 4,
    char_4: 'aventurine',
    app_rate_4: '30.49%',
    avg_round_4: 9.4,
    app_flat_4: 10,
    char_5: 'black-swan',
    app_rate_5: '29.27%',
    avg_round_5: 9,
    app_flat_5: 4,
    char_6: 'fu-xuan',
    app_rate_6: '26.83%',
    avg_round_6: 10.0,
    app_flat_6: 8,
    char_7: 'huohuo',
    app_rate_7: '20.73%',
    avg_round_7: 9,
    app_flat_7: 4,
    char_8: 'gepard',
    app_rate_8: '13.41%',
    avg_round_8: 7.75,
    app_flat_8: 4,
    char_9: 'sparkle',
    app_rate_9: '7.32%',
    avg_round_9: 99.99,
    app_flat_9: 0,
    char_10: 'jiaoqiu',
    app_rate_10: '6.1%',
    avg_round_10: 9.67,
    app_flat_10: 3,
    char_11: 'ruan-mei',
    app_rate_11: '3.66%',
    avg_round_11: 8,
    app_flat_11: 1,
    char_12: 'silver-wolf',
    app_rate_12: '3.66%',
    avg_round_12: 9.5,
    app_flat_12: 2,
    char_13: 'sunday',
    app_rate_13: '3.66%',
    avg_round_13: 99.99,
    app_flat_13: 0,
    char_14: 'luocha',
    app_rate_14: '2.44%',
    avg_round_14: 99.99,
    app_flat_14: 0,
    char_15: 'gallagher',
    app_rate_15: '2.44%',
    avg_round_15: 8,
    app_flat_15: 2,
    char_16: 'lingsha',
    app_rate_16: '2.44%',
    avg_round_16: 99.99,
    app_flat_16: 0,
    char_17: 'tingyun-fugue',
    app_rate_17: '2.44%',
    avg_round_17: 10,
    app_flat_17: 1,
    char_18: 'lynx',
    app_rate_18: '1.22%',
    avg_round_18: 99.99,
    app_flat_18: 0,
    char_19: 'sampo',
    app_rate_19: '1.22%',
    avg_round_19: 99.99,
    app_flat_19: 0,
    char_20: 'firefly',
    app_rate_20: '1.22%',
    avg_round_20: 99.99,
    app_flat_20: 0,
    char_21: '',
    app_rate_21: '0.00%',
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '',
    app_rate_22: '0.00%',
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'qingque',
    app: 0.76,
    char_1: 'sparkle',
    app_rate_1: '96.15%',
    avg_round_1: 9.0,
    app_flat_1: 21,
    char_2: 'fu-xuan',
    app_rate_2: '69.23%',
    avg_round_2: 9.2,
    app_flat_2: 9,
    char_3: 'silver-wolf',
    app_rate_3: '28.21%',
    avg_round_3: 99.99,
    app_flat_3: 0,
    char_4: 'sunday',
    app_rate_4: '25.64%',
    avg_round_4: 9.4,
    app_flat_4: 7,
    char_5: 'robin',
    app_rate_5: '19.23%',
    avg_round_5: 7.8,
    app_flat_5: 5,
    char_6: 'aventurine',
    app_rate_6: '14.1%',
    avg_round_6: 9.6,
    app_flat_6: 7,
    char_7: 'tingyun',
    app_rate_7: '12.82%',
    avg_round_7: 8.17,
    app_flat_7: 6,
    char_8: 'ruan-mei',
    app_rate_8: '12.82%',
    avg_round_8: 9.67,
    app_flat_8: 3,
    char_9: 'huohuo',
    app_rate_9: '10.26%',
    avg_round_9: 7,
    app_flat_9: 3,
    char_10: 'pela',
    app_rate_10: '2.56%',
    avg_round_10: 99.99,
    app_flat_10: 0,
    char_11: 'bronya',
    app_rate_11: '2.56%',
    avg_round_11: 99.99,
    app_flat_11: 0,
    char_12: 'luocha',
    app_rate_12: '1.28%',
    avg_round_12: 99.99,
    app_flat_12: 0,
    char_13: 'gallagher',
    app_rate_13: '1.28%',
    avg_round_13: 9,
    app_flat_13: 1,
    char_14: 'gepard',
    app_rate_14: '1.28%',
    avg_round_14: 99.99,
    app_flat_14: 0,
    char_15: 'lingsha',
    app_rate_15: '1.28%',
    avg_round_15: 99.99,
    app_flat_15: 0,
    char_16: 'bailu',
    app_rate_16: '1.28%',
    avg_round_16: 6,
    app_flat_16: 1,
    char_17: '',
    app_rate_17: '0.00%',
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '',
    app_rate_18: '0.00%',
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '',
    app_rate_19: '0.00%',
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '',
    app_rate_20: '0.00%',
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '',
    app_rate_21: '0.00%',
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '',
    app_rate_22: '0.00%',
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'clara',
    app: 0.76,
    char_1: 'robin',
    app_rate_1: '83.33%',
    avg_round_1: 9.5,
    app_flat_1: 26,
    char_2: 'aventurine',
    app_rate_2: '33.33%',
    avg_round_2: 9.4,
    app_flat_2: 9,
    char_3: 'sparkle',
    app_rate_3: '25.64%',
    avg_round_3: 8.71,
    app_flat_3: 7,
    char_4: 'fu-xuan',
    app_rate_4: '24.36%',
    avg_round_4: 10,
    app_flat_4: 3,
    char_5: 'march-7th-swordmaster',
    app_rate_5: '24.36%',
    avg_round_5: 9.25,
    app_flat_5: 8,
    char_6: 'huohuo',
    app_rate_6: '20.51%',
    avg_round_6: 9,
    app_flat_6: 10,
    char_7: 'sunday',
    app_rate_7: '19.23%',
    avg_round_7: 8.12,
    app_flat_7: 8,
    char_8: 'luocha',
    app_rate_8: '12.82%',
    avg_round_8: 7.83,
    app_flat_8: 6,
    char_9: 'tingyun',
    app_rate_9: '10.26%',
    avg_round_9: 9,
    app_flat_9: 5,
    char_10: 'yunli',
    app_rate_10: '6.41%',
    avg_round_10: 9.33,
    app_flat_10: 3,
    char_11: 'feixiao',
    app_rate_11: '6.41%',
    avg_round_11: 9.5,
    app_flat_11: 2,
    char_12: 'jade',
    app_rate_12: '5.13%',
    avg_round_12: 9,
    app_flat_12: 1,
    char_13: 'ruan-mei',
    app_rate_13: '5.13%',
    avg_round_13: 10,
    app_flat_13: 1,
    char_14: 'lingsha',
    app_rate_14: '5.13%',
    avg_round_14: 9,
    app_flat_14: 2,
    char_15: 'herta',
    app_rate_15: '3.85%',
    avg_round_15: 10,
    app_flat_15: 1,
    char_16: 'gallagher',
    app_rate_16: '2.56%',
    avg_round_16: 10,
    app_flat_16: 1,
    char_17: 'topaz',
    app_rate_17: '2.56%',
    avg_round_17: 99.99,
    app_flat_17: 0,
    char_18: 'acheron',
    app_rate_18: '2.56%',
    avg_round_18: 99.99,
    app_flat_18: 0,
    char_19: 'imbibitor-lunae',
    app_rate_19: '1.28%',
    avg_round_19: 99.99,
    app_flat_19: 0,
    char_20: 'black-swan',
    app_rate_20: '1.28%',
    avg_round_20: 99.99,
    app_flat_20: 0,
    char_21: 'lynx',
    app_rate_21: '1.28%',
    avg_round_21: 99.99,
    app_flat_21: 0,
    char_22: 'dr-ratio',
    app_rate_22: '1.28%',
    avg_round_22: 99.99,
    app_flat_22: 0,
    char_23: 'moze',
    app_rate_23: '1.28%',
    avg_round_23: 99.99,
    app_flat_23: 0,
    char_24: '',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'seele',
    app: 0.69,
    char_1: 'sparkle',
    app_rate_1: '61.43%',
    avg_round_1: 8.5,
    app_flat_1: 2,
    char_2: 'fu-xuan',
    app_rate_2: '58.57%',
    avg_round_2: 9,
    app_flat_2: 2,
    char_3: 'robin',
    app_rate_3: '45.71%',
    avg_round_3: 7.2,
    app_flat_3: 5,
    char_4: 'sunday',
    app_rate_4: '42.86%',
    avg_round_4: 8.0,
    app_flat_4: 7,
    char_5: 'silver-wolf',
    app_rate_5: '24.29%',
    avg_round_5: 99.99,
    app_flat_5: 0,
    char_6: 'tingyun',
    app_rate_6: '24.29%',
    avg_round_6: 7.5,
    app_flat_6: 4,
    char_7: 'huohuo',
    app_rate_7: '14.29%',
    avg_round_7: 6.67,
    app_flat_7: 3,
    char_8: 'luocha',
    app_rate_8: '7.14%',
    avg_round_8: 8.5,
    app_flat_8: 2,
    char_9: 'ruan-mei',
    app_rate_9: '4.29%',
    avg_round_9: 99.99,
    app_flat_9: 0,
    char_10: 'aventurine',
    app_rate_10: '4.29%',
    avg_round_10: 10,
    app_flat_10: 1,
    char_11: 'pela',
    app_rate_11: '2.86%',
    avg_round_11: 99.99,
    app_flat_11: 0,
    char_12: 'jade',
    app_rate_12: '2.86%',
    avg_round_12: 99.99,
    app_flat_12: 0,
    char_13: 'gallagher',
    app_rate_13: '2.86%',
    avg_round_13: 3,
    app_flat_13: 1,
    char_14: 'bronya',
    app_rate_14: '2.86%',
    avg_round_14: 99.99,
    app_flat_14: 0,
    char_15: 'march-7th-swordmaster',
    app_rate_15: '1.43%',
    avg_round_15: 99.99,
    app_flat_15: 0,
    char_16: '',
    app_rate_16: '0.00%',
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '',
    app_rate_17: '0.00%',
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '',
    app_rate_18: '0.00%',
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '',
    app_rate_19: '0.00%',
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '',
    app_rate_20: '0.00%',
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '',
    app_rate_21: '0.00%',
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '',
    app_rate_22: '0.00%',
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'himeko',
    app: 0.66,
    char_1: 'tingyun-fugue',
    app_rate_1: '80.6%',
    avg_round_1: 9.67,
    app_flat_1: 12,
    char_2: 'ruan-mei',
    app_rate_2: '46.27%',
    avg_round_2: 8.25,
    app_flat_2: 4,
    char_3: 'gallagher',
    app_rate_3: '34.33%',
    avg_round_3: 9.14,
    app_flat_3: 7,
    char_4: 'firefly',
    app_rate_4: '20.9%',
    avg_round_4: 10,
    app_flat_4: 2,
    char_5: 'lingsha',
    app_rate_5: '19.4%',
    avg_round_5: 10,
    app_flat_5: 1,
    char_6: 'aventurine',
    app_rate_6: '17.91%',
    avg_round_6: 10,
    app_flat_6: 1,
    char_7: 'trailblazer-harmony',
    app_rate_7: '14.93%',
    avg_round_7: 9.67,
    app_flat_7: 3,
    char_8: 'rappa',
    app_rate_8: '8.96%',
    avg_round_8: 9.67,
    app_flat_8: 3,
    char_9: 'fu-xuan',
    app_rate_9: '8.96%',
    avg_round_9: 10,
    app_flat_9: 1,
    char_10: 'robin',
    app_rate_10: '7.46%',
    avg_round_10: 99.99,
    app_flat_10: 0,
    char_11: 'luocha',
    app_rate_11: '7.46%',
    avg_round_11: 8.5,
    app_flat_11: 2,
    char_12: 'acheron',
    app_rate_12: '5.97%',
    avg_round_12: 99.99,
    app_flat_12: 0,
    char_13: 'pela',
    app_rate_13: '4.48%',
    avg_round_13: 99.99,
    app_flat_13: 0,
    char_14: 'jade',
    app_rate_14: '2.99%',
    avg_round_14: 99.99,
    app_flat_14: 0,
    char_15: 'asta',
    app_rate_15: '2.99%',
    avg_round_15: 99.99,
    app_flat_15: 0,
    char_16: 'black-swan',
    app_rate_16: '2.99%',
    avg_round_16: 99.99,
    app_flat_16: 0,
    char_17: 'imbibitor-lunae',
    app_rate_17: '1.49%',
    avg_round_17: 99.99,
    app_flat_17: 0,
    char_18: 'xueyi',
    app_rate_18: '1.49%',
    avg_round_18: 99.99,
    app_flat_18: 0,
    char_19: 'kafka',
    app_rate_19: '1.49%',
    avg_round_19: 99.99,
    app_flat_19: 0,
    char_20: 'blade',
    app_rate_20: '1.49%',
    avg_round_20: 99.99,
    app_flat_20: 0,
    char_21: 'lynx',
    app_rate_21: '1.49%',
    avg_round_21: 99.99,
    app_flat_21: 0,
    char_22: 'jiaoqiu',
    app_rate_22: '1.49%',
    avg_round_22: 99.99,
    app_flat_22: 0,
    char_23: 'sparkle',
    app_rate_23: '1.49%',
    avg_round_23: 99.99,
    app_flat_23: 0,
    char_24: 'tingyun',
    app_rate_24: '1.49%',
    avg_round_24: 99.99,
    app_flat_24: 0,
    char_25: 'serval',
    app_rate_25: '1.49%',
    avg_round_25: 99.99,
    app_flat_25: 0,
    char_26: '',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'sampo',
    app: 0.58,
    char_1: 'kafka',
    app_rate_1: '57.63%',
    avg_round_1: 9,
    app_flat_1: 6,
    char_2: 'acheron',
    app_rate_2: '42.37%',
    avg_round_2: 9.33,
    app_flat_2: 9,
    char_3: 'black-swan',
    app_rate_3: '38.98%',
    avg_round_3: 9,
    app_flat_3: 2,
    char_4: 'gallagher',
    app_rate_4: '28.81%',
    avg_round_4: 9.25,
    app_flat_4: 4,
    char_5: 'aventurine',
    app_rate_5: '22.03%',
    avg_round_5: 9.2,
    app_flat_5: 5,
    char_6: 'ruan-mei',
    app_rate_6: '20.34%',
    avg_round_6: 8.75,
    app_flat_6: 4,
    char_7: 'huohuo',
    app_rate_7: '18.64%',
    avg_round_7: 9,
    app_flat_7: 2,
    char_8: 'jiaoqiu',
    app_rate_8: '15.25%',
    avg_round_8: 9.5,
    app_flat_8: 4,
    char_9: 'fu-xuan',
    app_rate_9: '13.56%',
    avg_round_9: 9,
    app_flat_9: 2,
    char_10: 'robin',
    app_rate_10: '6.78%',
    avg_round_10: 10,
    app_flat_10: 2,
    char_11: 'lingsha',
    app_rate_11: '5.08%',
    avg_round_11: 10,
    app_flat_11: 1,
    char_12: 'pela',
    app_rate_12: '5.08%',
    avg_round_12: 9.5,
    app_flat_12: 2,
    char_13: 'sunday',
    app_rate_13: '3.39%',
    avg_round_13: 10,
    app_flat_13: 1,
    char_14: 'luocha',
    app_rate_14: '3.39%',
    avg_round_14: 99.99,
    app_flat_14: 0,
    char_15: 'sparkle',
    app_rate_15: '3.39%',
    avg_round_15: 99.99,
    app_flat_15: 0,
    char_16: 'gepard',
    app_rate_16: '3.39%',
    avg_round_16: 99.99,
    app_flat_16: 0,
    char_17: 'lynx',
    app_rate_17: '1.69%',
    avg_round_17: 99.99,
    app_flat_17: 0,
    char_18: 'bailu',
    app_rate_18: '1.69%',
    avg_round_18: 99.99,
    app_flat_18: 0,
    char_19: 'tingyun-fugue',
    app_rate_19: '1.69%',
    avg_round_19: 99.99,
    app_flat_19: 0,
    char_20: 'trailblazer-harmony',
    app_rate_20: '1.69%',
    avg_round_20: 99.99,
    app_flat_20: 0,
    char_21: 'guinaifen',
    app_rate_21: '1.69%',
    avg_round_21: 99.99,
    app_flat_21: 0,
    char_22: 'bronya',
    app_rate_22: '1.69%',
    avg_round_22: 99.99,
    app_flat_22: 0,
    char_23: 'march-7th',
    app_rate_23: '1.69%',
    avg_round_23: 10,
    app_flat_23: 1,
    char_24: '',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'welt',
    app: 0.38,
    char_1: 'acheron',
    app_rate_1: '69.23%',
    avg_round_1: 9.83,
    app_flat_1: 10,
    char_2: 'fu-xuan',
    app_rate_2: '30.77%',
    avg_round_2: 10,
    app_flat_2: 1,
    char_3: 'aventurine',
    app_rate_3: '28.21%',
    avg_round_3: 9.6,
    app_flat_3: 5,
    char_4: 'pela',
    app_rate_4: '23.08%',
    avg_round_4: 10,
    app_flat_4: 5,
    char_5: 'sparkle',
    app_rate_5: '23.08%',
    avg_round_5: 99.99,
    app_flat_5: 0,
    char_6: 'ruan-mei',
    app_rate_6: '17.95%',
    avg_round_6: 99.99,
    app_flat_6: 0,
    char_7: 'sunday',
    app_rate_7: '17.95%',
    avg_round_7: 99.99,
    app_flat_7: 0,
    char_8: 'jiaoqiu',
    app_rate_8: '15.38%',
    avg_round_8: 9.25,
    app_flat_8: 4,
    char_9: 'robin',
    app_rate_9: '15.38%',
    avg_round_9: 99.99,
    app_flat_9: 0,
    char_10: 'gallagher',
    app_rate_10: '10.26%',
    avg_round_10: 10,
    app_flat_10: 3,
    char_11: 'tingyun-fugue',
    app_rate_11: '7.69%',
    avg_round_11: 8,
    app_flat_11: 1,
    char_12: 'silver-wolf',
    app_rate_12: '7.69%',
    avg_round_12: 9,
    app_flat_12: 1,
    char_13: 'luocha',
    app_rate_13: '7.69%',
    avg_round_13: 8,
    app_flat_13: 1,
    char_14: 'trailblazer-harmony',
    app_rate_14: '7.69%',
    avg_round_14: 99.99,
    app_flat_14: 0,
    char_15: 'firefly',
    app_rate_15: '5.13%',
    avg_round_15: 99.99,
    app_flat_15: 0,
    char_16: 'rappa',
    app_rate_16: '2.56%',
    avg_round_16: 8,
    app_flat_16: 1,
    char_17: 'huohuo',
    app_rate_17: '2.56%',
    avg_round_17: 8,
    app_flat_17: 1,
    char_18: 'lingsha',
    app_rate_18: '2.56%',
    avg_round_18: 99.99,
    app_flat_18: 0,
    char_19: 'blade',
    app_rate_19: '2.56%',
    avg_round_19: 99.99,
    app_flat_19: 0,
    char_20: 'jade',
    app_rate_20: '2.56%',
    avg_round_20: 99.99,
    app_flat_20: 0,
    char_21: '',
    app_rate_21: '0.00%',
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '',
    app_rate_22: '0.00%',
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'lynx',
    app: 0.36,
    char_1: 'ruan-mei',
    app_rate_1: '32.43%',
    avg_round_1: 10,
    app_flat_1: 3,
    char_2: 'sunday',
    app_rate_2: '32.43%',
    avg_round_2: 9.0,
    app_flat_2: 4,
    char_3: 'blade',
    app_rate_3: '27.03%',
    avg_round_3: 9.5,
    app_flat_3: 2,
    char_4: 'sparkle',
    app_rate_4: '24.32%',
    avg_round_4: 9.0,
    app_flat_4: 4,
    char_5: 'jingliu',
    app_rate_5: '16.22%',
    avg_round_5: 9.5,
    app_flat_5: 2,
    char_6: 'robin',
    app_rate_6: '16.22%',
    avg_round_6: 9,
    app_flat_6: 1,
    char_7: 'bronya',
    app_rate_7: '16.22%',
    avg_round_7: 99.99,
    app_flat_7: 0,
    char_8: 'jiaoqiu',
    app_rate_8: '16.22%',
    avg_round_8: 9,
    app_flat_8: 2,
    char_9: 'jade',
    app_rate_9: '16.22%',
    avg_round_9: 9.5,
    app_flat_9: 2,
    char_10: 'acheron',
    app_rate_10: '13.51%',
    avg_round_10: 9,
    app_flat_10: 2,
    char_11: 'kafka',
    app_rate_11: '10.81%',
    avg_round_11: 10,
    app_flat_11: 1,
    char_12: 'black-swan',
    app_rate_12: '10.81%',
    avg_round_12: 10,
    app_flat_12: 1,
    char_13: 'trailblazer-harmony',
    app_rate_13: '8.11%',
    avg_round_13: 10,
    app_flat_13: 1,
    char_14: 'imbibitor-lunae',
    app_rate_14: '8.11%',
    avg_round_14: 99.99,
    app_flat_14: 0,
    char_15: 'rappa',
    app_rate_15: '5.41%',
    avg_round_15: 10,
    app_flat_15: 1,
    char_16: 'pela',
    app_rate_16: '5.41%',
    avg_round_16: 8,
    app_flat_16: 1,
    char_17: 'silver-wolf',
    app_rate_17: '5.41%',
    avg_round_17: 10,
    app_flat_17: 1,
    char_18: 'tingyun',
    app_rate_18: '5.41%',
    avg_round_18: 9,
    app_flat_18: 1,
    char_19: 'sampo',
    app_rate_19: '2.7%',
    avg_round_19: 99.99,
    app_flat_19: 0,
    char_20: 'yunli',
    app_rate_20: '2.7%',
    avg_round_20: 99.99,
    app_flat_20: 0,
    char_21: 'guinaifen',
    app_rate_21: '2.7%',
    avg_round_21: 99.99,
    app_flat_21: 0,
    char_22: 'herta',
    app_rate_22: '2.7%',
    avg_round_22: 99.99,
    app_flat_22: 0,
    char_23: 'himeko',
    app_rate_23: '2.7%',
    avg_round_23: 99.99,
    app_flat_23: 0,
    char_24: 'tingyun-fugue',
    app_rate_24: '2.7%',
    avg_round_24: 99.99,
    app_flat_24: 0,
    char_25: 'gepard',
    app_rate_25: '2.7%',
    avg_round_25: 99.99,
    app_flat_25: 0,
    char_26: 'clara',
    app_rate_26: '2.7%',
    avg_round_26: 99.99,
    app_flat_26: 0,
    char_27: 'march-7th-swordmaster',
    app_rate_27: '2.7%',
    avg_round_27: 99.99,
    app_flat_27: 0,
    char_28: 'jing-yuan',
    app_rate_28: '2.7%',
    avg_round_28: 9,
    app_flat_28: 1,
    char_29: 'boothill',
    app_rate_29: '2.7%',
    avg_round_29: 99.99,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'serval',
    app: 0.26,
    char_1: 'sunday',
    app_rate_1: '48.15%',
    avg_round_1: 8.5,
    app_flat_1: 6,
    char_2: 'huohuo',
    app_rate_2: '40.74%',
    avg_round_2: 9.25,
    app_flat_2: 4,
    char_3: 'robin',
    app_rate_3: '37.04%',
    avg_round_3: 8.25,
    app_flat_3: 4,
    char_4: 'ruan-mei',
    app_rate_4: '29.63%',
    avg_round_4: 10,
    app_flat_4: 2,
    char_5: 'tingyun',
    app_rate_5: '18.52%',
    avg_round_5: 8,
    app_flat_5: 3,
    char_6: 'march-7th-swordmaster',
    app_rate_6: '14.81%',
    avg_round_6: 9,
    app_flat_6: 2,
    char_7: 'gallagher',
    app_rate_7: '14.81%',
    avg_round_7: 8,
    app_flat_7: 2,
    char_8: 'sparkle',
    app_rate_8: '14.81%',
    avg_round_8: 99.99,
    app_flat_8: 0,
    char_9: 'aventurine',
    app_rate_9: '14.81%',
    avg_round_9: 10,
    app_flat_9: 1,
    char_10: 'bronya',
    app_rate_10: '11.11%',
    avg_round_10: 9,
    app_flat_10: 2,
    char_11: 'fu-xuan',
    app_rate_11: '11.11%',
    avg_round_11: 10,
    app_flat_11: 1,
    char_12: 'bailu',
    app_rate_12: '11.11%',
    avg_round_12: 6,
    app_flat_12: 1,
    char_13: 'trailblazer-harmony',
    app_rate_13: '7.41%',
    avg_round_13: 99.99,
    app_flat_13: 0,
    char_14: 'feixiao',
    app_rate_14: '3.7%',
    avg_round_14: 99.99,
    app_flat_14: 0,
    char_15: 'firefly',
    app_rate_15: '3.7%',
    avg_round_15: 99.99,
    app_flat_15: 0,
    char_16: 'lingsha',
    app_rate_16: '3.7%',
    avg_round_16: 8,
    app_flat_16: 1,
    char_17: 'tingyun-fugue',
    app_rate_17: '3.7%',
    avg_round_17: 99.99,
    app_flat_17: 0,
    char_18: 'black-swan',
    app_rate_18: '3.7%',
    avg_round_18: 99.99,
    app_flat_18: 0,
    char_19: 'blade',
    app_rate_19: '3.7%',
    avg_round_19: 10,
    app_flat_19: 1,
    char_20: 'himeko',
    app_rate_20: '3.7%',
    avg_round_20: 99.99,
    app_flat_20: 0,
    char_21: '',
    app_rate_21: '0.00%',
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '',
    app_rate_22: '0.00%',
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'asta',
    app: 0.22,
    char_1: 'kafka',
    app_rate_1: '63.64%',
    avg_round_1: 8.33,
    app_flat_1: 3,
    char_2: 'black-swan',
    app_rate_2: '63.64%',
    avg_round_2: 8.33,
    app_flat_2: 3,
    char_3: 'huohuo',
    app_rate_3: '36.36%',
    avg_round_3: 10,
    app_flat_3: 1,
    char_4: 'lingsha',
    app_rate_4: '18.18%',
    avg_round_4: 6,
    app_flat_4: 1,
    char_5: 'gallagher',
    app_rate_5: '18.18%',
    avg_round_5: 99.99,
    app_flat_5: 0,
    char_6: 'firefly',
    app_rate_6: '13.64%',
    avg_round_6: 99.99,
    app_flat_6: 0,
    char_7: 'aventurine',
    app_rate_7: '13.64%',
    avg_round_7: 99.99,
    app_flat_7: 0,
    char_8: 'tingyun-fugue',
    app_rate_8: '13.64%',
    avg_round_8: 99.99,
    app_flat_8: 0,
    char_9: 'trailblazer-harmony',
    app_rate_9: '9.09%',
    avg_round_9: 99.99,
    app_flat_9: 0,
    char_10: 'himeko',
    app_rate_10: '9.09%',
    avg_round_10: 99.99,
    app_flat_10: 0,
    char_11: 'ruan-mei',
    app_rate_11: '9.09%',
    avg_round_11: 99.99,
    app_flat_11: 0,
    char_12: 'bailu',
    app_rate_12: '4.55%',
    avg_round_12: 9,
    app_flat_12: 1,
    char_13: 'luocha',
    app_rate_13: '4.55%',
    avg_round_13: 99.99,
    app_flat_13: 0,
    char_14: 'jing-yuan',
    app_rate_14: '4.55%',
    avg_round_14: 99.99,
    app_flat_14: 0,
    char_15: 'sunday',
    app_rate_15: '4.55%',
    avg_round_15: 99.99,
    app_flat_15: 0,
    char_16: 'rappa',
    app_rate_16: '4.55%',
    avg_round_16: 99.99,
    app_flat_16: 0,
    char_17: 'acheron',
    app_rate_17: '4.55%',
    avg_round_17: 99.99,
    app_flat_17: 0,
    char_18: 'jiaoqiu',
    app_rate_18: '4.55%',
    avg_round_18: 99.99,
    app_flat_18: 0,
    char_19: '',
    app_rate_19: '0.00%',
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '',
    app_rate_20: '0.00%',
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '',
    app_rate_21: '0.00%',
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '',
    app_rate_22: '0.00%',
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'argenti',
    app: 0.21,
    char_1: 'sunday',
    app_rate_1: '71.43%',
    avg_round_1: 9,
    app_flat_1: 6,
    char_2: 'robin',
    app_rate_2: '47.62%',
    avg_round_2: 8.8,
    app_flat_2: 5,
    char_3: 'huohuo',
    app_rate_3: '42.86%',
    avg_round_3: 8.67,
    app_flat_3: 6,
    char_4: 'tingyun',
    app_rate_4: '38.1%',
    avg_round_4: 9.5,
    app_flat_4: 4,
    char_5: 'aventurine',
    app_rate_5: '33.33%',
    avg_round_5: 9.33,
    app_flat_5: 3,
    char_6: 'sparkle',
    app_rate_6: '19.05%',
    avg_round_6: 8.67,
    app_flat_6: 3,
    char_7: 'luocha',
    app_rate_7: '14.29%',
    avg_round_7: 99.99,
    app_flat_7: 0,
    char_8: 'jade',
    app_rate_8: '9.52%',
    avg_round_8: 99.99,
    app_flat_8: 0,
    char_9: 'jiaoqiu',
    app_rate_9: '4.76%',
    avg_round_9: 99.99,
    app_flat_9: 0,
    char_10: 'bronya',
    app_rate_10: '4.76%',
    avg_round_10: 99.99,
    app_flat_10: 0,
    char_11: 'gallagher',
    app_rate_11: '4.76%',
    avg_round_11: 99.99,
    app_flat_11: 0,
    char_12: 'ruan-mei',
    app_rate_12: '4.76%',
    avg_round_12: 99.99,
    app_flat_12: 0,
    char_13: 'fu-xuan',
    app_rate_13: '4.76%',
    avg_round_13: 99.99,
    app_flat_13: 0,
    char_14: '',
    app_rate_14: '0.00%',
    avg_round_14: 0.0,
    app_flat_14: 0,
    char_15: '',
    app_rate_15: '0.00%',
    avg_round_15: 0.0,
    app_flat_15: 0,
    char_16: '',
    app_rate_16: '0.00%',
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '',
    app_rate_17: '0.00%',
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '',
    app_rate_18: '0.00%',
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '',
    app_rate_19: '0.00%',
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '',
    app_rate_20: '0.00%',
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '',
    app_rate_21: '0.00%',
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '',
    app_rate_22: '0.00%',
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'xueyi',
    app: 0.19,
    char_1: 'tingyun-fugue',
    app_rate_1: '84.21%',
    avg_round_1: 9.5,
    app_flat_1: 2,
    char_2: 'ruan-mei',
    app_rate_2: '63.16%',
    avg_round_2: 9.67,
    app_flat_2: 3,
    char_3: 'trailblazer-harmony',
    app_rate_3: '31.58%',
    avg_round_3: 10,
    app_flat_3: 1,
    char_4: 'lingsha',
    app_rate_4: '21.05%',
    avg_round_4: 10,
    app_flat_4: 1,
    char_5: 'gallagher',
    app_rate_5: '21.05%',
    avg_round_5: 9.5,
    app_flat_5: 2,
    char_6: 'sparkle',
    app_rate_6: '15.79%',
    avg_round_6: 99.99,
    app_flat_6: 0,
    char_7: 'fu-xuan',
    app_rate_7: '10.53%',
    avg_round_7: 99.99,
    app_flat_7: 0,
    char_8: 'jade',
    app_rate_8: '10.53%',
    avg_round_8: 10,
    app_flat_8: 1,
    char_9: 'luocha',
    app_rate_9: '10.53%',
    avg_round_9: 10,
    app_flat_9: 1,
    char_10: 'sunday',
    app_rate_10: '5.26%',
    avg_round_10: 99.99,
    app_flat_10: 0,
    char_11: 'huohuo',
    app_rate_11: '5.26%',
    avg_round_11: 99.99,
    app_flat_11: 0,
    char_12: 'himeko',
    app_rate_12: '5.26%',
    avg_round_12: 99.99,
    app_flat_12: 0,
    char_13: 'pela',
    app_rate_13: '5.26%',
    avg_round_13: 99.99,
    app_flat_13: 0,
    char_14: 'robin',
    app_rate_14: '5.26%',
    avg_round_14: 10,
    app_flat_14: 1,
    char_15: 'aventurine',
    app_rate_15: '5.26%',
    avg_round_15: 99.99,
    app_flat_15: 0,
    char_16: '',
    app_rate_16: '0.00%',
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '',
    app_rate_17: '0.00%',
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '',
    app_rate_18: '0.00%',
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '',
    app_rate_19: '0.00%',
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '',
    app_rate_20: '0.00%',
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '',
    app_rate_21: '0.00%',
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '',
    app_rate_22: '0.00%',
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'march-7th',
    app: 0.1,
    char_1: 'acheron',
    app_rate_1: '80.0%',
    avg_round_1: 9.5,
    app_flat_1: 2,
    char_2: 'pela',
    app_rate_2: '50.0%',
    avg_round_2: 9.5,
    app_flat_2: 2,
    char_3: 'sparkle',
    app_rate_3: '30.0%',
    avg_round_3: 99.99,
    app_flat_3: 0,
    char_4: 'silver-wolf',
    app_rate_4: '30.0%',
    avg_round_4: 9,
    app_flat_4: 1,
    char_5: 'jiaoqiu',
    app_rate_5: '20.0%',
    avg_round_5: 99.99,
    app_flat_5: 0,
    char_6: 'firefly',
    app_rate_6: '10.0%',
    avg_round_6: 99.99,
    app_flat_6: 0,
    char_7: 'tingyun-fugue',
    app_rate_7: '10.0%',
    avg_round_7: 99.99,
    app_flat_7: 0,
    char_8: 'ruan-mei',
    app_rate_8: '10.0%',
    avg_round_8: 99.99,
    app_flat_8: 0,
    char_9: 'feixiao',
    app_rate_9: '10.0%',
    avg_round_9: 99.99,
    app_flat_9: 0,
    char_10: 'moze',
    app_rate_10: '10.0%',
    avg_round_10: 99.99,
    app_flat_10: 0,
    char_11: 'robin',
    app_rate_11: '10.0%',
    avg_round_11: 99.99,
    app_flat_11: 0,
    char_12: 'bronya',
    app_rate_12: '10.0%',
    avg_round_12: 99.99,
    app_flat_12: 0,
    char_13: 'sunday',
    app_rate_13: '10.0%',
    avg_round_13: 99.99,
    app_flat_13: 0,
    char_14: 'sampo',
    app_rate_14: '10.0%',
    avg_round_14: 10,
    app_flat_14: 1,
    char_15: '',
    app_rate_15: '0.00%',
    avg_round_15: 0.0,
    app_flat_15: 0,
    char_16: '',
    app_rate_16: '0.00%',
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '',
    app_rate_17: '0.00%',
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '',
    app_rate_18: '0.00%',
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '',
    app_rate_19: '0.00%',
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '',
    app_rate_20: '0.00%',
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '',
    app_rate_21: '0.00%',
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '',
    app_rate_22: '0.00%',
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'hanya',
    app: 0.09,
    char_1: 'sunday',
    app_rate_1: '77.78%',
    avg_round_1: 10,
    app_flat_1: 3,
    char_2: 'jing-yuan',
    app_rate_2: '66.67%',
    avg_round_2: 10,
    app_flat_2: 3,
    char_3: 'imbibitor-lunae',
    app_rate_3: '33.33%',
    avg_round_3: 99.99,
    app_flat_3: 0,
    char_4: 'huohuo',
    app_rate_4: '33.33%',
    avg_round_4: 10,
    app_flat_4: 2,
    char_5: 'luocha',
    app_rate_5: '33.33%',
    avg_round_5: 99.99,
    app_flat_5: 0,
    char_6: 'fu-xuan',
    app_rate_6: '22.22%',
    avg_round_6: 10,
    app_flat_6: 1,
    char_7: 'tingyun',
    app_rate_7: '11.11%',
    avg_round_7: 99.99,
    app_flat_7: 0,
    char_8: 'sparkle',
    app_rate_8: '11.11%',
    avg_round_8: 99.99,
    app_flat_8: 0,
    char_9: 'gallagher',
    app_rate_9: '11.11%',
    avg_round_9: 99.99,
    app_flat_9: 0,
    char_10: '',
    app_rate_10: '0.00%',
    avg_round_10: 0.0,
    app_flat_10: 0,
    char_11: '',
    app_rate_11: '0.00%',
    avg_round_11: 0.0,
    app_flat_11: 0,
    char_12: '',
    app_rate_12: '0.00%',
    avg_round_12: 0.0,
    app_flat_12: 0,
    char_13: '',
    app_rate_13: '0.00%',
    avg_round_13: 0.0,
    app_flat_13: 0,
    char_14: '',
    app_rate_14: '0.00%',
    avg_round_14: 0.0,
    app_flat_14: 0,
    char_15: '',
    app_rate_15: '0.00%',
    avg_round_15: 0.0,
    app_flat_15: 0,
    char_16: '',
    app_rate_16: '0.00%',
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '',
    app_rate_17: '0.00%',
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '',
    app_rate_18: '0.00%',
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '',
    app_rate_19: '0.00%',
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '',
    app_rate_20: '0.00%',
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '',
    app_rate_21: '0.00%',
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '',
    app_rate_22: '0.00%',
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'natasha',
    app: 0.06,
    char_1: 'kafka',
    app_rate_1: '33.33%',
    avg_round_1: 99.99,
    app_flat_1: 0,
    char_2: 'black-swan',
    app_rate_2: '33.33%',
    avg_round_2: 99.99,
    app_flat_2: 0,
    char_3: 'trailblazer-harmony',
    app_rate_3: '33.33%',
    avg_round_3: 99.99,
    app_flat_3: 0,
    char_4: 'tingyun',
    app_rate_4: '33.33%',
    avg_round_4: 99.99,
    app_flat_4: 0,
    char_5: 'acheron',
    app_rate_5: '16.67%',
    avg_round_5: 99.99,
    app_flat_5: 0,
    char_6: 'rappa',
    app_rate_6: '16.67%',
    avg_round_6: 99.99,
    app_flat_6: 0,
    char_7: 'ruan-mei',
    app_rate_7: '16.67%',
    avg_round_7: 99.99,
    app_flat_7: 0,
    char_8: 'dr-ratio',
    app_rate_8: '16.67%',
    avg_round_8: 99.99,
    app_flat_8: 0,
    char_9: 'pela',
    app_rate_9: '16.67%',
    avg_round_9: 99.99,
    app_flat_9: 0,
    char_10: 'blade',
    app_rate_10: '16.67%',
    avg_round_10: 99.99,
    app_flat_10: 0,
    char_11: 'bronya',
    app_rate_11: '16.67%',
    avg_round_11: 99.99,
    app_flat_11: 0,
    char_12: 'sunday',
    app_rate_12: '16.67%',
    avg_round_12: 99.99,
    app_flat_12: 0,
    char_13: 'firefly',
    app_rate_13: '16.67%',
    avg_round_13: 99.99,
    app_flat_13: 0,
    char_14: 'robin',
    app_rate_14: '16.67%',
    avg_round_14: 99.99,
    app_flat_14: 0,
    char_15: '',
    app_rate_15: '0.00%',
    avg_round_15: 0.0,
    app_flat_15: 0,
    char_16: '',
    app_rate_16: '0.00%',
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '',
    app_rate_17: '0.00%',
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '',
    app_rate_18: '0.00%',
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '',
    app_rate_19: '0.00%',
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '',
    app_rate_20: '0.00%',
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '',
    app_rate_21: '0.00%',
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '',
    app_rate_22: '0.00%',
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'misha',
    app: 0.05,
    char_1: 'robin',
    app_rate_1: '60.0%',
    avg_round_1: 9,
    app_flat_1: 1,
    char_2: 'sunday',
    app_rate_2: '60.0%',
    avg_round_2: 9,
    app_flat_2: 1,
    char_3: 'luocha',
    app_rate_3: '40.0%',
    avg_round_3: 99.99,
    app_flat_3: 0,
    char_4: 'bronya',
    app_rate_4: '40.0%',
    avg_round_4: 99.99,
    app_flat_4: 0,
    char_5: 'huohuo',
    app_rate_5: '20.0%',
    avg_round_5: 9,
    app_flat_5: 1,
    char_6: 'rappa',
    app_rate_6: '20.0%',
    avg_round_6: 99.99,
    app_flat_6: 0,
    char_7: 'trailblazer-harmony',
    app_rate_7: '20.0%',
    avg_round_7: 99.99,
    app_flat_7: 0,
    char_8: 'ruan-mei',
    app_rate_8: '20.0%',
    avg_round_8: 99.99,
    app_flat_8: 0,
    char_9: 'sparkle',
    app_rate_9: '20.0%',
    avg_round_9: 99.99,
    app_flat_9: 0,
    char_10: '',
    app_rate_10: '0.00%',
    avg_round_10: 0.0,
    app_flat_10: 0,
    char_11: '',
    app_rate_11: '0.00%',
    avg_round_11: 0.0,
    app_flat_11: 0,
    char_12: '',
    app_rate_12: '0.00%',
    avg_round_12: 0.0,
    app_flat_12: 0,
    char_13: '',
    app_rate_13: '0.00%',
    avg_round_13: 0.0,
    app_flat_13: 0,
    char_14: '',
    app_rate_14: '0.00%',
    avg_round_14: 0.0,
    app_flat_14: 0,
    char_15: '',
    app_rate_15: '0.00%',
    avg_round_15: 0.0,
    app_flat_15: 0,
    char_16: '',
    app_rate_16: '0.00%',
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '',
    app_rate_17: '0.00%',
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '',
    app_rate_18: '0.00%',
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '',
    app_rate_19: '0.00%',
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '',
    app_rate_20: '0.00%',
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '',
    app_rate_21: '0.00%',
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '',
    app_rate_22: '0.00%',
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'yanqing',
    app: 0.05,
    char_1: 'sunday',
    app_rate_1: '60.0%',
    avg_round_1: 99.99,
    app_flat_1: 0,
    char_2: 'aventurine',
    app_rate_2: '60.0%',
    avg_round_2: 99.99,
    app_flat_2: 0,
    char_3: 'robin',
    app_rate_3: '60.0%',
    avg_round_3: 99.99,
    app_flat_3: 0,
    char_4: 'march-7th-swordmaster',
    app_rate_4: '20.0%',
    avg_round_4: 99.99,
    app_flat_4: 0,
    char_5: 'acheron',
    app_rate_5: '20.0%',
    avg_round_5: 99.99,
    app_flat_5: 0,
    char_6: 'jiaoqiu',
    app_rate_6: '20.0%',
    avg_round_6: 99.99,
    app_flat_6: 0,
    char_7: 'sparkle',
    app_rate_7: '20.0%',
    avg_round_7: 99.99,
    app_flat_7: 0,
    char_8: 'ruan-mei',
    app_rate_8: '20.0%',
    avg_round_8: 99.99,
    app_flat_8: 0,
    char_9: 'huohuo',
    app_rate_9: '20.0%',
    avg_round_9: 99.99,
    app_flat_9: 0,
    char_10: '',
    app_rate_10: '0.00%',
    avg_round_10: 0.0,
    app_flat_10: 0,
    char_11: '',
    app_rate_11: '0.00%',
    avg_round_11: 0.0,
    app_flat_11: 0,
    char_12: '',
    app_rate_12: '0.00%',
    avg_round_12: 0.0,
    app_flat_12: 0,
    char_13: '',
    app_rate_13: '0.00%',
    avg_round_13: 0.0,
    app_flat_13: 0,
    char_14: '',
    app_rate_14: '0.00%',
    avg_round_14: 0.0,
    app_flat_14: 0,
    char_15: '',
    app_rate_15: '0.00%',
    avg_round_15: 0.0,
    app_flat_15: 0,
    char_16: '',
    app_rate_16: '0.00%',
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '',
    app_rate_17: '0.00%',
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '',
    app_rate_18: '0.00%',
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '',
    app_rate_19: '0.00%',
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '',
    app_rate_20: '0.00%',
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '',
    app_rate_21: '0.00%',
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '',
    app_rate_22: '0.00%',
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'arlan',
    app: 0.03,
    char_1: 'sparkle',
    app_rate_1: '100.0%',
    avg_round_1: 99.99,
    app_flat_1: 0,
    char_2: 'robin',
    app_rate_2: '33.33%',
    avg_round_2: 99.99,
    app_flat_2: 0,
    char_3: 'sunday',
    app_rate_3: '33.33%',
    avg_round_3: 99.99,
    app_flat_3: 0,
    char_4: 'acheron',
    app_rate_4: '33.33%',
    avg_round_4: 99.99,
    app_flat_4: 0,
    char_5: 'jiaoqiu',
    app_rate_5: '33.33%',
    avg_round_5: 99.99,
    app_flat_5: 0,
    char_6: 'tingyun',
    app_rate_6: '33.33%',
    avg_round_6: 99.99,
    app_flat_6: 0,
    char_7: 'fu-xuan',
    app_rate_7: '33.33%',
    avg_round_7: 99.99,
    app_flat_7: 0,
    char_8: '',
    app_rate_8: '0.00%',
    avg_round_8: 0.0,
    app_flat_8: 0,
    char_9: '',
    app_rate_9: '0.00%',
    avg_round_9: 0.0,
    app_flat_9: 0,
    char_10: '',
    app_rate_10: '0.00%',
    avg_round_10: 0.0,
    app_flat_10: 0,
    char_11: '',
    app_rate_11: '0.00%',
    avg_round_11: 0.0,
    app_flat_11: 0,
    char_12: '',
    app_rate_12: '0.00%',
    avg_round_12: 0.0,
    app_flat_12: 0,
    char_13: '',
    app_rate_13: '0.00%',
    avg_round_13: 0.0,
    app_flat_13: 0,
    char_14: '',
    app_rate_14: '0.00%',
    avg_round_14: 0.0,
    app_flat_14: 0,
    char_15: '',
    app_rate_15: '0.00%',
    avg_round_15: 0.0,
    app_flat_15: 0,
    char_16: '',
    app_rate_16: '0.00%',
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '',
    app_rate_17: '0.00%',
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '',
    app_rate_18: '0.00%',
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '',
    app_rate_19: '0.00%',
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '',
    app_rate_20: '0.00%',
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '',
    app_rate_21: '0.00%',
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '',
    app_rate_22: '0.00%',
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'trailblazer-destruction',
    app: 0.03,
    char_1: 'tingyun-fugue',
    app_rate_1: '100.0%',
    avg_round_1: 99.99,
    app_flat_1: 0,
    char_2: 'ruan-mei',
    app_rate_2: '100.0%',
    avg_round_2: 99.99,
    app_flat_2: 0,
    char_3: 'firefly',
    app_rate_3: '66.67%',
    avg_round_3: 99.99,
    app_flat_3: 0,
    char_4: 'lingsha',
    app_rate_4: '33.33%',
    avg_round_4: 99.99,
    app_flat_4: 0,
    char_5: '',
    app_rate_5: '0.00%',
    avg_round_5: 0.0,
    app_flat_5: 0,
    char_6: '',
    app_rate_6: '0.00%',
    avg_round_6: 0.0,
    app_flat_6: 0,
    char_7: '',
    app_rate_7: '0.00%',
    avg_round_7: 0.0,
    app_flat_7: 0,
    char_8: '',
    app_rate_8: '0.00%',
    avg_round_8: 0.0,
    app_flat_8: 0,
    char_9: '',
    app_rate_9: '0.00%',
    avg_round_9: 0.0,
    app_flat_9: 0,
    char_10: '',
    app_rate_10: '0.00%',
    avg_round_10: 0.0,
    app_flat_10: 0,
    char_11: '',
    app_rate_11: '0.00%',
    avg_round_11: 0.0,
    app_flat_11: 0,
    char_12: '',
    app_rate_12: '0.00%',
    avg_round_12: 0.0,
    app_flat_12: 0,
    char_13: '',
    app_rate_13: '0.00%',
    avg_round_13: 0.0,
    app_flat_13: 0,
    char_14: '',
    app_rate_14: '0.00%',
    avg_round_14: 0.0,
    app_flat_14: 0,
    char_15: '',
    app_rate_15: '0.00%',
    avg_round_15: 0.0,
    app_flat_15: 0,
    char_16: '',
    app_rate_16: '0.00%',
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '',
    app_rate_17: '0.00%',
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '',
    app_rate_18: '0.00%',
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '',
    app_rate_19: '0.00%',
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '',
    app_rate_20: '0.00%',
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '',
    app_rate_21: '0.00%',
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '',
    app_rate_22: '0.00%',
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'dan-heng',
    app: 0.02,
    char_1: 'robin',
    app_rate_1: '100.0%',
    avg_round_1: 99.99,
    app_flat_1: 0,
    char_2: 'march-7th-swordmaster',
    app_rate_2: '50.0%',
    avg_round_2: 99.99,
    app_flat_2: 0,
    char_3: 'huohuo',
    app_rate_3: '50.0%',
    avg_round_3: 99.99,
    app_flat_3: 0,
    char_4: 'imbibitor-lunae',
    app_rate_4: '50.0%',
    avg_round_4: 99.99,
    app_flat_4: 0,
    char_5: 'sunday',
    app_rate_5: '50.0%',
    avg_round_5: 99.99,
    app_flat_5: 0,
    char_6: '',
    app_rate_6: '0.00%',
    avg_round_6: 0.0,
    app_flat_6: 0,
    char_7: '',
    app_rate_7: '0.00%',
    avg_round_7: 0.0,
    app_flat_7: 0,
    char_8: '',
    app_rate_8: '0.00%',
    avg_round_8: 0.0,
    app_flat_8: 0,
    char_9: '',
    app_rate_9: '0.00%',
    avg_round_9: 0.0,
    app_flat_9: 0,
    char_10: '',
    app_rate_10: '0.00%',
    avg_round_10: 0.0,
    app_flat_10: 0,
    char_11: '',
    app_rate_11: '0.00%',
    avg_round_11: 0.0,
    app_flat_11: 0,
    char_12: '',
    app_rate_12: '0.00%',
    avg_round_12: 0.0,
    app_flat_12: 0,
    char_13: '',
    app_rate_13: '0.00%',
    avg_round_13: 0.0,
    app_flat_13: 0,
    char_14: '',
    app_rate_14: '0.00%',
    avg_round_14: 0.0,
    app_flat_14: 0,
    char_15: '',
    app_rate_15: '0.00%',
    avg_round_15: 0.0,
    app_flat_15: 0,
    char_16: '',
    app_rate_16: '0.00%',
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '',
    app_rate_17: '0.00%',
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '',
    app_rate_18: '0.00%',
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '',
    app_rate_19: '0.00%',
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '',
    app_rate_20: '0.00%',
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '',
    app_rate_21: '0.00%',
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '',
    app_rate_22: '0.00%',
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'hook',
    app: 0.01,
    char_1: 'tingyun-fugue',
    app_rate_1: '100.0%',
    avg_round_1: 10,
    app_flat_1: 1,
    char_2: 'ruan-mei',
    app_rate_2: '100.0%',
    avg_round_2: 10,
    app_flat_2: 1,
    char_3: 'lingsha',
    app_rate_3: '100.0%',
    avg_round_3: 10,
    app_flat_3: 1,
    char_4: '',
    app_rate_4: '0.00%',
    avg_round_4: 0.0,
    app_flat_4: 0,
    char_5: '',
    app_rate_5: '0.00%',
    avg_round_5: 0.0,
    app_flat_5: 0,
    char_6: '',
    app_rate_6: '0.00%',
    avg_round_6: 0.0,
    app_flat_6: 0,
    char_7: '',
    app_rate_7: '0.00%',
    avg_round_7: 0.0,
    app_flat_7: 0,
    char_8: '',
    app_rate_8: '0.00%',
    avg_round_8: 0.0,
    app_flat_8: 0,
    char_9: '',
    app_rate_9: '0.00%',
    avg_round_9: 0.0,
    app_flat_9: 0,
    char_10: '',
    app_rate_10: '0.00%',
    avg_round_10: 0.0,
    app_flat_10: 0,
    char_11: '',
    app_rate_11: '0.00%',
    avg_round_11: 0.0,
    app_flat_11: 0,
    char_12: '',
    app_rate_12: '0.00%',
    avg_round_12: 0.0,
    app_flat_12: 0,
    char_13: '',
    app_rate_13: '0.00%',
    avg_round_13: 0.0,
    app_flat_13: 0,
    char_14: '',
    app_rate_14: '0.00%',
    avg_round_14: 0.0,
    app_flat_14: 0,
    char_15: '',
    app_rate_15: '0.00%',
    avg_round_15: 0.0,
    app_flat_15: 0,
    char_16: '',
    app_rate_16: '0.00%',
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '',
    app_rate_17: '0.00%',
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '',
    app_rate_18: '0.00%',
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '',
    app_rate_19: '0.00%',
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '',
    app_rate_20: '0.00%',
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '',
    app_rate_21: '0.00%',
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '',
    app_rate_22: '0.00%',
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'sushang',
    app: 0.01,
    char_1: 'robin',
    app_rate_1: '100.0%',
    avg_round_1: 99.99,
    app_flat_1: 0,
    char_2: 'sunday',
    app_rate_2: '100.0%',
    avg_round_2: 99.99,
    app_flat_2: 0,
    char_3: 'lingsha',
    app_rate_3: '100.0%',
    avg_round_3: 99.99,
    app_flat_3: 0,
    char_4: '',
    app_rate_4: '0.00%',
    avg_round_4: 0.0,
    app_flat_4: 0,
    char_5: '',
    app_rate_5: '0.00%',
    avg_round_5: 0.0,
    app_flat_5: 0,
    char_6: '',
    app_rate_6: '0.00%',
    avg_round_6: 0.0,
    app_flat_6: 0,
    char_7: '',
    app_rate_7: '0.00%',
    avg_round_7: 0.0,
    app_flat_7: 0,
    char_8: '',
    app_rate_8: '0.00%',
    avg_round_8: 0.0,
    app_flat_8: 0,
    char_9: '',
    app_rate_9: '0.00%',
    avg_round_9: 0.0,
    app_flat_9: 0,
    char_10: '',
    app_rate_10: '0.00%',
    avg_round_10: 0.0,
    app_flat_10: 0,
    char_11: '',
    app_rate_11: '0.00%',
    avg_round_11: 0.0,
    app_flat_11: 0,
    char_12: '',
    app_rate_12: '0.00%',
    avg_round_12: 0.0,
    app_flat_12: 0,
    char_13: '',
    app_rate_13: '0.00%',
    avg_round_13: 0.0,
    app_flat_13: 0,
    char_14: '',
    app_rate_14: '0.00%',
    avg_round_14: 0.0,
    app_flat_14: 0,
    char_15: '',
    app_rate_15: '0.00%',
    avg_round_15: 0.0,
    app_flat_15: 0,
    char_16: '',
    app_rate_16: '0.00%',
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '',
    app_rate_17: '0.00%',
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '',
    app_rate_18: '0.00%',
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '',
    app_rate_19: '0.00%',
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '',
    app_rate_20: '0.00%',
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '',
    app_rate_21: '0.00%',
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '',
    app_rate_22: '0.00%',
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'trailblazer-preservation',
    app: 0.01,
    char_1: 'acheron',
    app_rate_1: '100.0%',
    avg_round_1: 99.99,
    app_flat_1: 0,
    char_2: 'pela',
    app_rate_2: '100.0%',
    avg_round_2: 99.99,
    app_flat_2: 0,
    char_3: 'silver-wolf',
    app_rate_3: '100.0%',
    avg_round_3: 99.99,
    app_flat_3: 0,
    char_4: '',
    app_rate_4: '0.00%',
    avg_round_4: 0.0,
    app_flat_4: 0,
    char_5: '',
    app_rate_5: '0.00%',
    avg_round_5: 0.0,
    app_flat_5: 0,
    char_6: '',
    app_rate_6: '0.00%',
    avg_round_6: 0.0,
    app_flat_6: 0,
    char_7: '',
    app_rate_7: '0.00%',
    avg_round_7: 0.0,
    app_flat_7: 0,
    char_8: '',
    app_rate_8: '0.00%',
    avg_round_8: 0.0,
    app_flat_8: 0,
    char_9: '',
    app_rate_9: '0.00%',
    avg_round_9: 0.0,
    app_flat_9: 0,
    char_10: '',
    app_rate_10: '0.00%',
    avg_round_10: 0.0,
    app_flat_10: 0,
    char_11: '',
    app_rate_11: '0.00%',
    avg_round_11: 0.0,
    app_flat_11: 0,
    char_12: '',
    app_rate_12: '0.00%',
    avg_round_12: 0.0,
    app_flat_12: 0,
    char_13: '',
    app_rate_13: '0.00%',
    avg_round_13: 0.0,
    app_flat_13: 0,
    char_14: '',
    app_rate_14: '0.00%',
    avg_round_14: 0.0,
    app_flat_14: 0,
    char_15: '',
    app_rate_15: '0.00%',
    avg_round_15: 0.0,
    app_flat_15: 0,
    char_16: '',
    app_rate_16: '0.00%',
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '',
    app_rate_17: '0.00%',
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '',
    app_rate_18: '0.00%',
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '',
    app_rate_19: '0.00%',
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '',
    app_rate_20: '0.00%',
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '',
    app_rate_21: '0.00%',
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '',
    app_rate_22: '0.00%',
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  }
];
