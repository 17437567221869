import React from 'react';

export const AglaeaSpeedTuning: React.FC = () => {
  return (
    <>
      <p>
        Aglaea has many Speed setups available to her - all impacting her
        performance in a meaningful way. Choosing one and ensuring it works is
        important to achieving a strong performance with her. Which Speed
        breakpoint to go for on Aglaea will depend on her Light Cone, the
        teammates she can be played with and the relics she has available. Below
        are some of the fundamental and basic setups you can run - note you can
        optimize much further past this.
      </p>
      <h5>With Sunday</h5>
      <p>
        Sunday is Aglaea's best and most commonly used teammate. There are many
        speed break points the two can play around but two of the most
        accessible and common ones are:
      </p>
      <h6>Slow Aglaea</h6>
      <p>Run Aglaea and Sunday at the following speeds:</p>
      <ul>
        <li>
          Aglaea: <strong>102</strong> (Base SPD)
        </li>
        <li>
          Sunday: <strong>160</strong>
        </li>
      </ul>
      <p>
        This setup relies on Sunday to assist Aglaea and Garmentmaker with
        gaining her initial momentum but allows her to invest all her sub-stats
        and her Boots main stat into pure offense whilst still maintaining a
        high action count when it matters most (during her Ultimate) thanks to
        Sunday's high speed breakpoint catching her up when needed. The downside
        of this Speed setup is the high gear requirements placed on Sunday -
        getting 160 Speed on him isn't easy and is needed in order to make this
        set-up work smoothly. Additionally outside of Ultimate, without her
        Speed stacks, Aglaea is completely reliant on Sunday to grant her
        actions and assist her with recovering her energy.
      </p>
      <ul>
        <li>
          <strong className="green">Pros:</strong>
        </li>
        <ul>
          <li>
            Aglaea reaches ~190-200 SPD during combat and Sunday will allow her
            to take action again after her turn.
          </li>
          <li>Easy to build - can focus into CRIT sub stats.</li>
          <li>
            Sunday being fast leads to better buff uptime and more Ultimates.
          </li>
        </ul>
        <li>
          <strong className="red">Cons:</strong>
        </li>
        <ul>
          <li>
            Without her Ultimate, Aglaea will return to her base SPD can and it
            will take some time for her to get back into her Ultimate state.
          </li>
          <li>
            Requires Sunday to build an absurd amount of SPD, which may not be
            compatible with other DPS (and it also sacrifices his CRIT DMG
            buff).
          </li>
        </ul>
      </ul>
      <h6>Fast Aglaea</h6>
      <p>Run Aglaea and Sunday at the following speeds:</p>
      <ul>
        <li>
          Aglaea: <strong>163</strong> (Signature Light Cone, Pre-combat & 4-Pc
          Hero) | <strong>174</strong> (Non-Signature Light Cone - Not
          recommended)
        </li>
        <li>
          Sunday: <strong>135</strong>
        </li>
      </ul>
      <p>
        This setup focuses on granting Aglaea additional actions and greater
        agency outside her Ultimates at the cost of much higher investment in
        Speed and a semi-mandatory requirement that she has her Signature
        equipped (or additional external Speed sources). With this Speed setup,
        you'll ramp-up Aglaea Ultimate more quickly, have a lesser reliance on
        Sunday's action advance and gain more actions inside her Ultimate damage
        window. But as a result of Sunday's lower Speed and Aglaea's more
        frequent actions, you'll also have lower uptime on team buffs - namely
        Sunday's - resulting in a bit of a trade off. Despite the additional
        actions, this Speed setup it's not a clear cut winner due to the buff
        downtime and tight gearing it enforces. The Fast Aglaea setup has many
        variants and optimizations available based on team members, gear,
        encounters and playstyles - many of these feature adjustments to Aglaea
        and Sunday's speed (e.g. running Sunday at 160 Speed alongside Aglaea).
        As the most speed focused character in the game, the sky is the limit
        for min maxing past the basics we have here.
      </p>
      <ul>
        <li>
          <strong className="green">Pros:</strong>
        </li>
        <ul>
          <li>
            Aglaea reaches 270 SPD during combat, allowing her to take 2 turns
            in between Sunday's actions.
          </li>
          <li>Highest possible amount of turns on Aglaea.</li>
          <li>
            Aglaea's turns are synced to Robin's 90 SPD Concerto countdown,
            allowing Robin to re-enter her Ultimate state without wasting any
            Action Value on Aglaea.
          </li>
        </ul>
        <li>
          <strong className="red">Cons:</strong>
        </li>
        <ul>
          <li>
            Requires signature Light Cone to make 160+ SPD reachable, while also
            being extremely stat-hungry - requiring a lot of SPD subs.
          </li>
          <li>
            The SPD tuning is only effective when Aglaea has 6 SPD Boost stacks;
            when the stacks are reset, she may require some ramp up time before
            the SPD tuning is achieved again.
          </li>
          <li>
            Some stackable buffs such as Sunday's Signature Light Cone and 4-Pc
            Sacerdos' Relived Ordeal will often reset when Aglaea takes too many
            turns.
          </li>
        </ul>
      </ul>
      <h5>Without Sunday</h5>
      <p>
        When playing without Sunday, things change. Aglaea and Garmentmaker will
        be more reliant on their own Speed stat for ramping up and achieving
        actions during Ultimate. Other advancement characters like Bronya, Robin
        and Remembrance MC can be used as lesser substitutes on a case by case
        basis but Speed tuning will need to be tweaked accordingly. As a general
        rule adhering to the usual 134/160 speed breakpoints is a good starting
        point or fall-back until you're able to fine tune your available Aglaea
        teams action order precisely.
      </p>
    </>
  );
};
