export const ApoCharacterData = [
  {
    char: 'acheron',
    name: 'Acheron',
    rarity: 'Limited 5*',
    current_app_rate: 30.93,
    prev_app_rate: 56.18,
    round_eidolons: 3512,
    current_avg_round: 3343,
    prev_avg_round: 3411
  },
  {
    char: 'argenti',
    name: 'Argenti',
    rarity: 'Limited 5*',
    current_app_rate: 6.28,
    prev_app_rate: 0.63,
    round_eidolons: 3425,
    current_avg_round: 3321,
    prev_avg_round: 3263
  },
  {
    char: 'arlan',
    name: 'Arlan',
    rarity: '4*',
    current_app_rate: 0,
    prev_app_rate: 0,
    round_eidolons: 0,
    current_avg_round: 0,
    prev_avg_round: 0
  },
  {
    char: 'asta',
    name: 'Asta',
    rarity: '4*',
    current_app_rate: 0.3,
    prev_app_rate: 0.39,
    round_eidolons: 3520,
    current_avg_round: 0,
    prev_avg_round: 3326
  },
  {
    char: 'aventurine',
    name: 'Aventurine',
    rarity: 'Limited 5*',
    current_app_rate: 53.5,
    prev_app_rate: 60.14,
    round_eidolons: 3531,
    current_avg_round: 3434,
    prev_avg_round: 3419
  },
  {
    char: 'bailu',
    name: 'Bailu',
    rarity: 'Standard 5*',
    current_app_rate: 0.57,
    prev_app_rate: 0.61,
    round_eidolons: 3403,
    current_avg_round: 3292,
    prev_avg_round: 3308
  },
  {
    char: 'black-swan',
    name: 'Black Swan',
    rarity: 'Limited 5*',
    current_app_rate: 13.05,
    prev_app_rate: 13.79,
    round_eidolons: 3430,
    current_avg_round: 3307,
    prev_avg_round: 3290
  },
  {
    char: 'blade',
    name: 'Blade',
    rarity: 'Limited 5*',
    current_app_rate: 0.58,
    prev_app_rate: 0.74,
    round_eidolons: 3368,
    current_avg_round: 3221,
    prev_avg_round: 3191
  },
  {
    char: 'boothill',
    name: 'Boothill',
    rarity: 'Limited 5*',
    current_app_rate: 1.43,
    prev_app_rate: 3.24,
    round_eidolons: 3400,
    current_avg_round: 3228,
    prev_avg_round: 3459
  },
  {
    char: 'bronya',
    name: 'Bronya',
    rarity: 'Standard 5*',
    current_app_rate: 4.2,
    prev_app_rate: 6.48,
    round_eidolons: 3540,
    current_avg_round: 3361,
    prev_avg_round: 3369
  },
  {
    char: 'clara',
    name: 'Clara',
    rarity: 'Standard 5*',
    current_app_rate: 1.76,
    prev_app_rate: 0.41,
    round_eidolons: 3248,
    current_avg_round: 3152,
    prev_avg_round: 0
  },
  {
    char: 'dan-heng',
    name: 'Dan Heng',
    rarity: '4*',
    current_app_rate: 0.02,
    prev_app_rate: 0,
    round_eidolons: 0,
    current_avg_round: 0,
    prev_avg_round: 0
  },
  {
    char: 'dr-ratio',
    name: 'Dr. Ratio',
    rarity: 'Limited 5*',
    current_app_rate: 0.47,
    prev_app_rate: 0.51,
    round_eidolons: 3437,
    current_avg_round: 0,
    prev_avg_round: 0
  },
  {
    char: 'feixiao',
    name: 'Feixiao',
    rarity: 'Limited 5*',
    current_app_rate: 15.42,
    prev_app_rate: 24.34,
    round_eidolons: 3608,
    current_avg_round: 3492,
    prev_avg_round: 3461
  },
  {
    char: 'firefly',
    name: 'Firefly',
    rarity: 'Limited 5*',
    current_app_rate: 43.35,
    prev_app_rate: 61.69,
    round_eidolons: 3533,
    current_avg_round: 3361,
    prev_avg_round: 3534
  },
  {
    char: 'fu-xuan',
    name: 'Fu Xuan',
    rarity: 'Limited 5*',
    current_app_rate: 22.67,
    prev_app_rate: 22.13,
    round_eidolons: 3457,
    current_avg_round: 3382,
    prev_avg_round: 3345
  },
  {
    char: 'gallagher',
    name: 'Gallagher',
    rarity: '4*',
    current_app_rate: 36.95,
    prev_app_rate: 56.52,
    round_eidolons: 3457,
    current_avg_round: 3374,
    prev_avg_round: 3494
  },
  {
    char: 'gepard',
    name: 'Gepard',
    rarity: 'Standard 5*',
    current_app_rate: 0.9,
    prev_app_rate: 0.79,
    round_eidolons: 3411,
    current_avg_round: 3302,
    prev_avg_round: 3378
  },
  {
    char: 'guinaifen',
    name: 'Guinaifen',
    rarity: '4*',
    current_app_rate: 0.38,
    prev_app_rate: 2.12,
    round_eidolons: 0,
    current_avg_round: 0,
    prev_avg_round: 3373
  },
  {
    char: 'hanya',
    name: 'Hanya',
    rarity: '4*',
    current_app_rate: 0.07,
    prev_app_rate: 0.02,
    round_eidolons: 0,
    current_avg_round: 0,
    prev_avg_round: 0
  },
  {
    char: 'herta',
    name: 'Herta',
    rarity: '4*',
    current_app_rate: 33.61,
    prev_app_rate: 1.23,
    round_eidolons: 3591,
    current_avg_round: 3469,
    prev_avg_round: 3164
  },
  {
    char: 'himeko',
    name: 'Himeko',
    rarity: 'Standard 5*',
    current_app_rate: 1.4,
    prev_app_rate: 1.62,
    round_eidolons: 3449,
    current_avg_round: 3329,
    prev_avg_round: 3244
  },
  {
    char: 'hook',
    name: 'Hook',
    rarity: '4*',
    current_app_rate: 0.03,
    prev_app_rate: 0.02,
    round_eidolons: 0,
    current_avg_round: 0,
    prev_avg_round: 0
  },
  {
    char: 'huohuo',
    name: 'Huohuo',
    rarity: 'Limited 5*',
    current_app_rate: 22.44,
    prev_app_rate: 14.08,
    round_eidolons: 3473,
    current_avg_round: 3410,
    prev_avg_round: 3327
  },
  {
    char: 'imbibitor-lunae',
    name: 'Imbibitor Lunae',
    rarity: 'Limited 5*',
    current_app_rate: 4.3,
    prev_app_rate: 2.55,
    round_eidolons: 3464,
    current_avg_round: 3277,
    prev_avg_round: 3222
  },
  {
    char: 'jade',
    name: 'Jade',
    rarity: 'Limited 5*',
    current_app_rate: 24.18,
    prev_app_rate: 5.36,
    round_eidolons: 3494,
    current_avg_round: 3335,
    prev_avg_round: 3324
  },
  {
    char: 'jiaoqiu',
    name: 'Jiaoqiu',
    rarity: 'Limited 5*',
    current_app_rate: 19.71,
    prev_app_rate: 27.92,
    round_eidolons: 3567,
    current_avg_round: 3367,
    prev_avg_round: 3491
  },
  {
    char: 'jing-yuan',
    name: 'Jing Yuan',
    rarity: 'Limited 5*',
    current_app_rate: 5.59,
    prev_app_rate: 20.96,
    round_eidolons: 3471,
    current_avg_round: 3354,
    prev_avg_round: 3371
  },
  {
    char: 'jingliu',
    name: 'Jingliu',
    rarity: 'Limited 5*',
    current_app_rate: 1.13,
    prev_app_rate: 0.81,
    round_eidolons: 3320,
    current_avg_round: 3166,
    prev_avg_round: 3127
  },
  {
    char: 'kafka',
    name: 'Kafka',
    rarity: 'Limited 5*',
    current_app_rate: 11.55,
    prev_app_rate: 14.64,
    round_eidolons: 3432,
    current_avg_round: 3310,
    prev_avg_round: 3298
  },
  {
    char: 'lingsha',
    name: 'Lingsha',
    rarity: 'Limited 5*',
    current_app_rate: 40.25,
    prev_app_rate: 35.17,
    round_eidolons: 3573,
    current_avg_round: 3449,
    prev_avg_round: 3548
  },
  {
    char: 'luka',
    name: 'Luka',
    rarity: '4*',
    current_app_rate: 0.02,
    prev_app_rate: 0.01,
    round_eidolons: 0,
    current_avg_round: 0,
    prev_avg_round: 0
  },
  {
    char: 'luocha',
    name: 'Luocha',
    rarity: 'Limited 5*',
    current_app_rate: 9.57,
    prev_app_rate: 7.95,
    round_eidolons: 3422,
    current_avg_round: 3363,
    prev_avg_round: 3338
  },
  {
    char: 'lynx',
    name: 'Lynx',
    rarity: '4*',
    current_app_rate: 0.47,
    prev_app_rate: 0.41,
    round_eidolons: 3412,
    current_avg_round: 3266,
    prev_avg_round: 3314
  },
  {
    char: 'march-7th',
    name: 'March 7th',
    rarity: '4*',
    current_app_rate: 0.17,
    prev_app_rate: 0.06,
    round_eidolons: 0,
    current_avg_round: 0,
    prev_avg_round: 0
  },
  {
    char: 'march-7th-swordmaster',
    name: 'March 7th - Hunt',
    rarity: '4*',
    current_app_rate: 8.38,
    prev_app_rate: 9.29,
    round_eidolons: 3533,
    current_avg_round: 3334,
    prev_avg_round: 3296
  },
  {
    char: 'misha',
    name: 'Misha',
    rarity: '4*',
    current_app_rate: 0.01,
    prev_app_rate: 0.02,
    round_eidolons: 0,
    current_avg_round: 0,
    prev_avg_round: 0
  },
  {
    char: 'moze',
    name: 'Moze',
    rarity: '4*',
    current_app_rate: 0.87,
    prev_app_rate: 1.88,
    round_eidolons: 3558,
    current_avg_round: 3431,
    prev_avg_round: 3428
  },
  {
    char: 'natasha',
    name: 'Natasha',
    rarity: '4*',
    current_app_rate: 0.05,
    prev_app_rate: 0.04,
    round_eidolons: 0,
    current_avg_round: 0,
    prev_avg_round: 0
  },
  {
    char: 'pela',
    name: 'Pela',
    rarity: '4*',
    current_app_rate: 11.86,
    prev_app_rate: 30.53,
    round_eidolons: 3447,
    current_avg_round: 3354,
    prev_avg_round: 3430
  },
  {
    char: 'qingque',
    name: 'Qingque',
    rarity: '4*',
    current_app_rate: 0.16,
    prev_app_rate: 1.45,
    round_eidolons: 0,
    current_avg_round: 0,
    prev_avg_round: 3271
  },
  {
    char: 'rappa',
    name: 'Rappa',
    rarity: 'Limited 5*',
    current_app_rate: 16.2,
    prev_app_rate: 14.41,
    round_eidolons: 3570,
    current_avg_round: 3445,
    prev_avg_round: 3453
  },
  {
    char: 'robin',
    name: 'Robin',
    rarity: 'Limited 5*',
    current_app_rate: 49.89,
    prev_app_rate: 45.08,
    round_eidolons: 3546,
    current_avg_round: 3402,
    prev_avg_round: 3407
  },
  {
    char: 'ruan-mei',
    name: 'Ruan Mei',
    rarity: 'Limited 5*',
    current_app_rate: 72.9,
    prev_app_rate: 84.81,
    round_eidolons: 3524,
    current_avg_round: 3391,
    prev_avg_round: 3514
  },
  {
    char: 'sampo',
    name: 'Sampo',
    rarity: '4*',
    current_app_rate: 0.25,
    prev_app_rate: 0.19,
    round_eidolons: 0,
    current_avg_round: 0,
    prev_avg_round: 0
  },
  {
    char: 'seele',
    name: 'Seele',
    rarity: 'Limited 5*',
    current_app_rate: 0.17,
    prev_app_rate: 2.14,
    round_eidolons: 3276,
    current_avg_round: 0,
    prev_avg_round: 3230
  },
  {
    char: 'serval',
    name: 'Serval',
    rarity: '4*',
    current_app_rate: 5.05,
    prev_app_rate: 0.73,
    round_eidolons: 3594,
    current_avg_round: 3451,
    prev_avg_round: 3281
  },
  {
    char: 'silver-wolf',
    name: 'Silver Wolf',
    rarity: 'Limited 5*',
    current_app_rate: 3.16,
    prev_app_rate: 9.07,
    round_eidolons: 3432,
    current_avg_round: 3339,
    prev_avg_round: 3355
  },
  {
    char: 'sparkle',
    name: 'Sparkle',
    rarity: 'Limited 5*',
    current_app_rate: 17.33,
    prev_app_rate: 22.82,
    round_eidolons: 3511,
    current_avg_round: 3296,
    prev_avg_round: 3302
  },
  {
    char: 'sunday',
    name: 'Sunday',
    rarity: 'Limited 5*',
    current_app_rate: 34.66,
    prev_app_rate: 35.63,
    round_eidolons: 3501,
    current_avg_round: 3403,
    prev_avg_round: 3371
  },
  {
    char: 'sushang',
    name: 'Sushang',
    rarity: '4*',
    current_app_rate: 0.03,
    prev_app_rate: 0.01,
    round_eidolons: 0,
    current_avg_round: 0,
    prev_avg_round: 0
  },
  {
    char: 'the-herta',
    name: 'The Herta',
    rarity: 'Limited 5*',
    current_app_rate: 60.63,
    prev_app_rate: 60.63,
    round_eidolons: 3606,
    current_avg_round: 3475,
    prev_avg_round: 3475
  },
  {
    char: 'tingyun',
    name: 'Tingyun',
    rarity: '4*',
    current_app_rate: 3.43,
    prev_app_rate: 3.82,
    round_eidolons: 3426,
    current_avg_round: 3275,
    prev_avg_round: 3280
  },
  {
    char: 'tingyun-fugue',
    name: 'Tingyun - Fugue',
    rarity: 'Limited 5*',
    current_app_rate: 43.03,
    prev_app_rate: 43.03,
    round_eidolons: 3567,
    current_avg_round: 3423,
    prev_avg_round: 3423
  },
  {
    char: 'topaz',
    name: 'Topaz & Numby',
    rarity: 'Limited 5*',
    current_app_rate: 7.32,
    prev_app_rate: 13.28,
    round_eidolons: 3614,
    current_avg_round: 3489,
    prev_avg_round: 3466
  },
  {
    char: 'trailblazer-destruction',
    name: 'Trailblazer (Destruction)',
    rarity: '4*',
    current_app_rate: 0,
    prev_app_rate: 0,
    round_eidolons: 0,
    current_avg_round: 0,
    prev_avg_round: 0
  },
  {
    char: 'trailblazer-harmony',
    name: 'Trailblazer (Harmony)',
    rarity: '4*',
    current_app_rate: 28.77,
    prev_app_rate: 77.26,
    round_eidolons: 3507,
    current_avg_round: 3359,
    prev_avg_round: 3518
  },
  {
    char: 'trailblazer-preservation',
    name: 'Trailblazer (Preservation)',
    rarity: '4*',
    current_app_rate: 0.04,
    prev_app_rate: 0,
    round_eidolons: 0,
    current_avg_round: 0,
    prev_avg_round: 0
  },
  {
    char: 'trailblazer-remembrance',
    name: 'Trailblazer (Remembrance)',
    rarity: '4*',
    current_app_rate: 23.26,
    prev_app_rate: 23.26,
    round_eidolons: 3596,
    current_avg_round: 3517,
    prev_avg_round: 3517
  },
  {
    char: 'welt',
    name: 'Welt',
    rarity: 'Standard 5*',
    current_app_rate: 0.23,
    prev_app_rate: 1.73,
    round_eidolons: 0,
    current_avg_round: 0,
    prev_avg_round: 3296
  },
  {
    char: 'xueyi',
    name: 'Xueyi',
    rarity: '4*',
    current_app_rate: 0.04,
    prev_app_rate: 0.61,
    round_eidolons: 0,
    current_avg_round: 0,
    prev_avg_round: 3208
  },
  {
    char: 'yanqing',
    name: 'Yanqing',
    rarity: 'Standard 5*',
    current_app_rate: 0.02,
    prev_app_rate: 0,
    round_eidolons: 0,
    current_avg_round: 0,
    prev_avg_round: 0
  },
  {
    char: 'yukong',
    name: 'Yukong',
    rarity: '4*',
    current_app_rate: 0.03,
    prev_app_rate: 0.01,
    round_eidolons: 0,
    current_avg_round: 0,
    prev_avg_round: 0
  },
  {
    char: 'yunli',
    name: 'Yunli',
    rarity: 'Limited 5*',
    current_app_rate: 4.89,
    prev_app_rate: 1.58,
    round_eidolons: 3462,
    current_avg_round: 3314,
    prev_avg_round: 3125
  }
];
