export const ApoDuos = [
  {
    char: 'ruan-mei',
    app: 72.9,
    char_1: 'firefly',
    app_rate_1: '56.38%',
    avg_round_1: 3362,
    app_flat_1: 1071,
    char_2: 'tingyun-fugue',
    app_rate_2: '52.46%',
    avg_round_2: 3431,
    app_flat_2: 1176,
    char_3: 'lingsha',
    app_rate_3: '39.27%',
    avg_round_3: 3446,
    app_flat_3: 929,
    char_4: 'trailblazer-harmony',
    app_rate_4: '34.99%',
    avg_round_4: 3357,
    app_flat_4: 908,
    char_5: 'gallagher',
    app_rate_5: '34.15%',
    avg_round_5: 3363,
    app_flat_5: 1129,
    char_6: 'rappa',
    app_rate_6: '19.71%',
    avg_round_6: 3450,
    app_flat_6: 826,
    char_7: 'kafka',
    app_rate_7: '7.58%',
    avg_round_7: 3316,
    app_flat_7: 137,
    char_8: 'black-swan',
    app_rate_8: '7.51%',
    avg_round_8: 3317,
    app_flat_8: 135,
    char_9: 'huohuo',
    app_rate_9: '5.3%',
    avg_round_9: 3325,
    app_flat_9: 99,
    char_10: 'the-herta',
    app_rate_10: '4.69%',
    avg_round_10: 3428,
    app_flat_10: 142,
    char_11: 'aventurine',
    app_rate_11: '4.32%',
    avg_round_11: 3366,
    app_flat_11: 118,
    char_12: 'sunday',
    app_rate_12: '3.42%',
    avg_round_12: 3269,
    app_flat_12: 42,
    char_13: 'luocha',
    app_rate_13: '3.21%',
    avg_round_13: 3289,
    app_flat_13: 64,
    char_14: 'herta',
    app_rate_14: '2.94%',
    avg_round_14: 3383,
    app_flat_14: 90,
    char_15: 'fu-xuan',
    app_rate_15: '2.51%',
    avg_round_15: 3357,
    app_flat_15: 47,
    char_16: 'acheron',
    app_rate_16: '2.16%',
    avg_round_16: 3338,
    app_flat_16: 10,
    char_17: 'jade',
    app_rate_17: '2.03%',
    avg_round_17: 3421,
    app_flat_17: 58,
    char_18: 'imbibitor-lunae',
    app_rate_18: '1.85%',
    avg_round_18: 3186,
    app_flat_18: 7,
    char_19: 'boothill',
    app_rate_19: '1.84%',
    avg_round_19: 3228,
    app_flat_19: 36,
    char_20: 'sparkle',
    app_rate_20: '1.35%',
    avg_round_20: 3094,
    app_flat_20: 1,
    char_21: 'jiaoqiu',
    app_rate_21: '1.26%',
    avg_round_21: 3268,
    app_flat_21: 4,
    char_22: 'himeko',
    app_rate_22: '1.25%',
    avg_round_22: 3294,
    app_flat_22: 32,
    char_23: 'march-7th-swordmaster',
    app_rate_23: '1.02%',
    avg_round_23: 3441,
    app_flat_23: 27,
    char_24: 'jing-yuan',
    app_rate_24: '1.01%',
    avg_round_24: 3280,
    app_flat_24: 16,
    char_25: 'feixiao',
    app_rate_25: '0.99%',
    avg_round_25: 3490,
    app_flat_25: 18,
    char_26: 'bronya',
    app_rate_26: '0.98%',
    avg_round_26: 3135,
    app_flat_26: 5,
    char_27: 'trailblazer-remembrance',
    app_rate_27: '0.8%',
    avg_round_27: 3217,
    app_flat_27: 1,
    char_28: 'argenti',
    app_rate_28: '0.78%',
    avg_round_28: 3383,
    app_flat_28: 15,
    char_29: 'robin',
    app_rate_29: '0.71%',
    avg_round_29: 3356,
    app_flat_29: 1,
    char_30: 'jingliu',
    app_rate_30: '0.62%',
    avg_round_30: 3204,
    app_flat_30: 5
  },
  {
    char: 'the-herta',
    app: 60.63,
    char_1: 'herta',
    app_rate_1: '51.58%',
    avg_round_1: 3479,
    app_flat_1: 1986,
    char_2: 'aventurine',
    app_rate_2: '39.27%',
    avg_round_2: 3501,
    app_flat_2: 1539,
    char_3: 'jade',
    app_rate_3: '34.69%',
    avg_round_3: 3544,
    app_flat_3: 1098,
    char_4: 'robin',
    app_rate_4: '33.14%',
    avg_round_4: 3481,
    app_flat_4: 1085,
    char_5: 'trailblazer-remembrance',
    app_rate_5: '31.64%',
    avg_round_5: 3540,
    app_flat_5: 1265,
    char_6: 'sunday',
    app_rate_6: '24.9%',
    avg_round_6: 3492,
    app_flat_6: 897,
    char_7: 'fu-xuan',
    app_rate_7: '19.05%',
    avg_round_7: 3456,
    app_flat_7: 611,
    char_8: 'huohuo',
    app_rate_8: '17.03%',
    avg_round_8: 3520,
    app_flat_8: 591,
    char_9: 'lingsha',
    app_rate_9: '11.29%',
    avg_round_9: 3525,
    app_flat_9: 402,
    char_10: 'serval',
    app_rate_10: '8.2%',
    avg_round_10: 3451,
    app_flat_10: 298,
    char_11: 'luocha',
    app_rate_11: '6.1%',
    avg_round_11: 3484,
    app_flat_11: 228,
    char_12: 'ruan-mei',
    app_rate_12: '5.64%',
    avg_round_12: 3428,
    app_flat_12: 142,
    char_13: 'argenti',
    app_rate_13: '5.17%',
    avg_round_13: 3500,
    app_flat_13: 178,
    char_14: 'sparkle',
    app_rate_14: '4.01%',
    avg_round_14: 3389,
    app_flat_14: 89,
    char_15: 'gallagher',
    app_rate_15: '3.94%',
    avg_round_15: 3501,
    app_flat_15: 168,
    char_16: 'pela',
    app_rate_16: '1.35%',
    avg_round_16: 3357,
    app_flat_16: 45,
    char_17: 'rappa',
    app_rate_17: '0.47%',
    avg_round_17: 3379,
    app_flat_17: 14,
    char_18: 'bronya',
    app_rate_18: '0.37%',
    avg_round_18: 3429,
    app_flat_18: 13,
    char_19: 'himeko',
    app_rate_19: '0.34%',
    avg_round_19: 3399,
    app_flat_19: 13,
    char_20: 'yunli',
    app_rate_20: '0.34%',
    avg_round_20: 3338,
    app_flat_20: 8,
    char_21: 'gepard',
    app_rate_21: '0.27%',
    avg_round_21: 3364,
    app_flat_21: 11,
    char_22: 'bailu',
    app_rate_22: '0.22%',
    avg_round_22: 3418,
    app_flat_22: 8,
    char_23: 'lynx',
    app_rate_23: '0.19%',
    avg_round_23: 3386,
    app_flat_23: 8,
    char_24: 'tingyun',
    app_rate_24: '0.15%',
    avg_round_24: 3402,
    app_flat_24: 2,
    char_25: 'march-7th',
    app_rate_25: '0.12%',
    avg_round_25: 3612,
    app_flat_25: 4,
    char_26: 'tingyun-fugue',
    app_rate_26: '0.08%',
    avg_round_26: 3345,
    app_flat_26: 3,
    char_27: 'silver-wolf',
    app_rate_27: '0.07%',
    avg_round_27: 3310,
    app_flat_27: 2,
    char_28: 'jing-yuan',
    app_rate_28: '0.07%',
    avg_round_28: 0,
    app_flat_28: 0,
    char_29: 'clara',
    app_rate_29: '0.05%',
    avg_round_29: 0,
    app_flat_29: 0,
    char_30: 'hanya',
    app_rate_30: '0.03%',
    avg_round_30: 0,
    app_flat_30: 0
  },
  {
    char: 'aventurine',
    app: 53.5,
    char_1: 'the-herta',
    app_rate_1: '44.5%',
    avg_round_1: 3501,
    app_flat_1: 1539,
    char_2: 'robin',
    app_rate_2: '44.33%',
    avg_round_2: 3465,
    app_flat_2: 1143,
    char_3: 'herta',
    app_rate_3: '25.03%',
    avg_round_3: 3471,
    app_flat_3: 911,
    char_4: 'sunday',
    app_rate_4: '22.66%',
    avg_round_4: 3460,
    app_flat_4: 561,
    char_5: 'acheron',
    app_rate_5: '22.49%',
    avg_round_5: 3364,
    app_flat_5: 358,
    char_6: 'jade',
    app_rate_6: '19.55%',
    avg_round_6: 3541,
    app_flat_6: 545,
    char_7: 'feixiao',
    app_rate_7: '17.47%',
    avg_round_7: 3505,
    app_flat_7: 408,
    char_8: 'jiaoqiu',
    app_rate_8: '15.43%',
    avg_round_8: 3391,
    app_flat_8: 266,
    char_9: 'trailblazer-remembrance',
    app_rate_9: '11.8%',
    avg_round_9: 3548,
    app_flat_9: 443,
    char_10: 'topaz',
    app_rate_10: '10.56%',
    avg_round_10: 3514,
    app_flat_10: 213,
    char_11: 'pela',
    app_rate_11: '9.76%',
    avg_round_11: 3374,
    app_flat_11: 249,
    char_12: 'sparkle',
    app_rate_12: '9.13%',
    avg_round_12: 3319,
    app_flat_12: 60,
    char_13: 'march-7th-swordmaster',
    app_rate_13: '6.41%',
    avg_round_13: 3518,
    app_flat_13: 189,
    char_14: 'ruan-mei',
    app_rate_14: '5.88%',
    avg_round_14: 3366,
    app_flat_14: 118,
    char_15: 'black-swan',
    app_rate_15: '4.35%',
    avg_round_15: 3312,
    app_flat_15: 88,
    char_16: 'argenti',
    app_rate_16: '4.35%',
    avg_round_16: 3421,
    app_flat_16: 123,
    char_17: 'jing-yuan',
    app_rate_17: '3.23%',
    avg_round_17: 3358,
    app_flat_17: 59,
    char_18: 'serval',
    app_rate_18: '3.21%',
    avg_round_18: 3452,
    app_flat_18: 121,
    char_19: 'kafka',
    app_rate_19: '3.11%',
    avg_round_19: 3328,
    app_flat_19: 57,
    char_20: 'silver-wolf',
    app_rate_20: '1.95%',
    avg_round_20: 3366,
    app_flat_20: 34,
    char_21: 'bronya',
    app_rate_21: '1.81%',
    avg_round_21: 3423,
    app_flat_21: 30,
    char_22: 'tingyun-fugue',
    app_rate_22: '1.79%',
    avg_round_22: 3343,
    app_flat_22: 36,
    char_23: 'yunli',
    app_rate_23: '1.74%',
    avg_round_23: 3308,
    app_flat_23: 41,
    char_24: 'imbibitor-lunae',
    app_rate_24: '1.49%',
    avg_round_24: 3209,
    app_flat_24: 6,
    char_25: 'moze',
    app_rate_25: '1.3%',
    avg_round_25: 3424,
    app_flat_25: 29,
    char_26: 'clara',
    app_rate_26: '1.18%',
    avg_round_26: 3187,
    app_flat_26: 21,
    char_27: 'tingyun',
    app_rate_27: '1.13%',
    avg_round_27: 3279,
    app_flat_27: 23,
    char_28: 'rappa',
    app_rate_28: '0.97%',
    avg_round_28: 3331,
    app_flat_28: 28,
    char_29: 'trailblazer-harmony',
    app_rate_29: '0.78%',
    avg_round_29: 3288,
    app_flat_29: 18,
    char_30: 'dr-ratio',
    app_rate_30: '0.71%',
    avg_round_30: 3237,
    app_flat_30: 9
  },
  {
    char: 'robin',
    app: 49.89,
    char_1: 'aventurine',
    app_rate_1: '47.54%',
    avg_round_1: 3465,
    app_flat_1: 1143,
    char_2: 'the-herta',
    app_rate_2: '40.27%',
    avg_round_2: 3481,
    app_flat_2: 1085,
    char_3: 'feixiao',
    app_rate_3: '27.42%',
    avg_round_3: 3505,
    app_flat_3: 569,
    char_4: 'jade',
    app_rate_4: '23.55%',
    avg_round_4: 3522,
    app_flat_4: 573,
    char_5: 'herta',
    app_rate_5: '22.34%',
    avg_round_5: 3417,
    app_flat_5: 618,
    char_6: 'sunday',
    app_rate_6: '15.68%',
    avg_round_6: 3345,
    app_flat_6: 239,
    char_7: 'huohuo',
    app_rate_7: '14.37%',
    avg_round_7: 3399,
    app_flat_7: 305,
    char_8: 'march-7th-swordmaster',
    app_rate_8: '13.8%',
    avg_round_8: 3513,
    app_flat_8: 334,
    char_9: 'topaz',
    app_rate_9: '13.14%',
    avg_round_9: 3494,
    app_flat_9: 232,
    char_10: 'fu-xuan',
    app_rate_10: '11.12%',
    avg_round_10: 3397,
    app_flat_10: 250,
    char_11: 'lingsha',
    app_rate_11: '7.76%',
    avg_round_11: 3531,
    app_flat_11: 186,
    char_12: 'yunli',
    app_rate_12: '7.29%',
    avg_round_12: 3318,
    app_flat_12: 175,
    char_13: 'gallagher',
    app_rate_13: '7.19%',
    avg_round_13: 3471,
    app_flat_13: 163,
    char_14: 'jing-yuan',
    app_rate_14: '6.96%',
    avg_round_14: 3383,
    app_flat_14: 120,
    char_15: 'luocha',
    app_rate_15: '4.57%',
    avg_round_15: 3393,
    app_flat_15: 105,
    char_16: 'acheron',
    app_rate_16: '4.16%',
    avg_round_16: 3346,
    app_flat_16: 27,
    char_17: 'argenti',
    app_rate_17: '3.54%',
    avg_round_17: 3399,
    app_flat_17: 68,
    char_18: 'trailblazer-remembrance',
    app_rate_18: '3.3%',
    avg_round_18: 3347,
    app_flat_18: 49,
    char_19: 'jiaoqiu',
    app_rate_19: '2.95%',
    avg_round_19: 3391,
    app_flat_19: 21,
    char_20: 'sparkle',
    app_rate_20: '2.52%',
    avg_round_20: 3281,
    app_flat_20: 18,
    char_21: 'clara',
    app_rate_21: '2.48%',
    avg_round_21: 3164,
    app_flat_21: 41,
    char_22: 'black-swan',
    app_rate_22: '2.33%',
    avg_round_22: 3248,
    app_flat_22: 16,
    char_23: 'kafka',
    app_rate_23: '2.09%',
    avg_round_23: 3265,
    app_flat_23: 15,
    char_24: 'bronya',
    app_rate_24: '1.99%',
    avg_round_24: 3603,
    app_flat_24: 8,
    char_25: 'imbibitor-lunae',
    app_rate_25: '1.76%',
    avg_round_25: 3286,
    app_flat_25: 5,
    char_26: 'tingyun',
    app_rate_26: '1.52%',
    avg_round_26: 3274,
    app_flat_26: 35,
    char_27: 'serval',
    app_rate_27: '1.23%',
    avg_round_27: 3334,
    app_flat_27: 28,
    char_28: 'moze',
    app_rate_28: '1.19%',
    avg_round_28: 3436,
    app_flat_28: 26,
    char_29: 'ruan-mei',
    app_rate_29: '1.04%',
    avg_round_29: 3356,
    app_flat_29: 1,
    char_30: 'pela',
    app_rate_30: '0.94%',
    avg_round_30: 3249,
    app_flat_30: 7
  },
  {
    char: 'firefly',
    app: 43.35,
    char_1: 'ruan-mei',
    app_rate_1: '94.82%',
    avg_round_1: 3362,
    app_flat_1: 1071,
    char_2: 'tingyun-fugue',
    app_rate_2: '63.38%',
    avg_round_2: 3377,
    app_flat_2: 583,
    char_3: 'trailblazer-harmony',
    app_rate_3: '48.66%',
    avg_round_3: 3342,
    app_flat_3: 585,
    char_4: 'lingsha',
    app_rate_4: '47.6%',
    avg_round_4: 3402,
    app_flat_4: 477,
    char_5: 'gallagher',
    app_rate_5: '39.56%',
    avg_round_5: 3331,
    app_flat_5: 643,
    char_6: 'rappa',
    app_rate_6: '1.3%',
    avg_round_6: 3365,
    app_flat_6: 7,
    char_7: 'bronya',
    app_rate_7: '0.85%',
    avg_round_7: 2864,
    app_flat_7: 1,
    char_8: 'himeko',
    app_rate_8: '0.68%',
    avg_round_8: 3312,
    app_flat_8: 2,
    char_9: 'aventurine',
    app_rate_9: '0.66%',
    avg_round_9: 3149,
    app_flat_9: 3,
    char_10: 'huohuo',
    app_rate_10: '0.61%',
    avg_round_10: 3304,
    app_flat_10: 1,
    char_11: 'luocha',
    app_rate_11: '0.4%',
    avg_round_11: 3256,
    app_flat_11: 1,
    char_12: 'march-7th-swordmaster',
    app_rate_12: '0.19%',
    avg_round_12: 3207,
    app_flat_12: 2,
    char_13: 'trailblazer-remembrance',
    app_rate_13: '0.14%',
    avg_round_13: 0,
    app_flat_13: 0,
    char_14: 'fu-xuan',
    app_rate_14: '0.14%',
    avg_round_14: 0,
    app_flat_14: 0,
    char_15: 'robin',
    app_rate_15: '0.14%',
    avg_round_15: 3144,
    app_flat_15: 1,
    char_16: 'sunday',
    app_rate_16: '0.14%',
    avg_round_16: 0,
    app_flat_16: 0,
    char_17: 'pela',
    app_rate_17: '0.14%',
    avg_round_17: 0,
    app_flat_17: 0,
    char_18: 'asta',
    app_rate_18: '0.09%',
    avg_round_18: 0,
    app_flat_18: 0,
    char_19: 'bailu',
    app_rate_19: '0.07%',
    avg_round_19: 3153,
    app_flat_19: 1,
    char_20: 'boothill',
    app_rate_20: '0.07%',
    avg_round_20: 0,
    app_flat_20: 0,
    char_21: 'acheron',
    app_rate_21: '0.05%',
    avg_round_21: 0,
    app_flat_21: 0,
    char_22: 'silver-wolf',
    app_rate_22: '0.05%',
    avg_round_22: 0,
    app_flat_22: 0,
    char_23: 'jiaoqiu',
    app_rate_23: '0.05%',
    avg_round_23: 0,
    app_flat_23: 0,
    char_24: 'feixiao',
    app_rate_24: '0.05%',
    avg_round_24: 0,
    app_flat_24: 0,
    char_25: 'lynx',
    app_rate_25: '0.02%',
    avg_round_25: 0,
    app_flat_25: 0,
    char_26: 'march-7th',
    app_rate_26: '0.02%',
    avg_round_26: 0,
    app_flat_26: 0,
    char_27: 'sparkle',
    app_rate_27: '0.02%',
    avg_round_27: 0,
    app_flat_27: 0,
    char_28: 'guinaifen',
    app_rate_28: '0.02%',
    avg_round_28: 0,
    app_flat_28: 0,
    char_29: 'clara',
    app_rate_29: '0.02%',
    avg_round_29: 0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'tingyun-fugue',
    app: 43.03,
    char_1: 'ruan-mei',
    app_rate_1: '88.87%',
    avg_round_1: 3431,
    app_flat_1: 1176,
    char_2: 'firefly',
    app_rate_2: '63.85%',
    avg_round_2: 3377,
    app_flat_2: 583,
    char_3: 'lingsha',
    app_rate_3: '52.98%',
    avg_round_3: 3457,
    app_flat_3: 712,
    char_4: 'gallagher',
    app_rate_4: '28.72%',
    avg_round_4: 3394,
    app_flat_4: 557,
    char_5: 'rappa',
    app_rate_5: '27.06%',
    avg_round_5: 3476,
    app_flat_5: 631,
    char_6: 'trailblazer-harmony',
    app_rate_6: '18.78%',
    avg_round_6: 3405,
    app_flat_6: 169,
    char_7: 'acheron',
    app_rate_7: '2.78%',
    avg_round_7: 3347,
    app_flat_7: 46,
    char_8: 'aventurine',
    app_rate_8: '2.23%',
    avg_round_8: 3343,
    app_flat_8: 36,
    char_9: 'boothill',
    app_rate_9: '2.14%',
    avg_round_9: 3263,
    app_flat_9: 15,
    char_10: 'himeko',
    app_rate_10: '2.04%',
    avg_round_10: 3345,
    app_flat_10: 29,
    char_11: 'huohuo',
    app_rate_11: '1.5%',
    avg_round_11: 3338,
    app_flat_11: 25,
    char_12: 'jiaoqiu',
    app_rate_12: '1.23%',
    avg_round_12: 3359,
    app_flat_12: 31,
    char_13: 'luocha',
    app_rate_13: '1.16%',
    avg_round_13: 3321,
    app_flat_13: 15,
    char_14: 'sunday',
    app_rate_14: '1.14%',
    avg_round_14: 3344,
    app_flat_14: 8,
    char_15: 'fu-xuan',
    app_rate_15: '0.76%',
    avg_round_15: 3311,
    app_flat_15: 13,
    char_16: 'bronya',
    app_rate_16: '0.74%',
    avg_round_16: 0,
    app_flat_16: 0,
    char_17: 'sparkle',
    app_rate_17: '0.52%',
    avg_round_17: 3233,
    app_flat_17: 1,
    char_18: 'pela',
    app_rate_18: '0.52%',
    avg_round_18: 3313,
    app_flat_18: 8,
    char_19: 'black-swan',
    app_rate_19: '0.45%',
    avg_round_19: 3286,
    app_flat_19: 6,
    char_20: 'trailblazer-remembrance',
    app_rate_20: '0.28%',
    avg_round_20: 0,
    app_flat_20: 0,
    char_21: 'kafka',
    app_rate_21: '0.26%',
    avg_round_21: 3244,
    app_flat_21: 2,
    char_22: 'march-7th-swordmaster',
    app_rate_22: '0.21%',
    avg_round_22: 3404,
    app_flat_22: 3,
    char_23: 'jing-yuan',
    app_rate_23: '0.17%',
    avg_round_23: 3092,
    app_flat_23: 1,
    char_24: 'argenti',
    app_rate_24: '0.14%',
    avg_round_24: 0,
    app_flat_24: 0,
    char_25: 'feixiao',
    app_rate_25: '0.14%',
    avg_round_25: 3436,
    app_flat_25: 2,
    char_26: 'the-herta',
    app_rate_26: '0.12%',
    avg_round_26: 3345,
    app_flat_26: 3,
    char_27: 'robin',
    app_rate_27: '0.12%',
    avg_round_27: 3294,
    app_flat_27: 2,
    char_28: 'jade',
    app_rate_28: '0.09%',
    avg_round_28: 3406,
    app_flat_28: 2,
    char_29: 'bailu',
    app_rate_29: '0.09%',
    avg_round_29: 3265,
    app_flat_29: 2,
    char_30: 'asta',
    app_rate_30: '0.09%',
    avg_round_30: 3175,
    app_flat_30: 1
  },
  {
    char: 'lingsha',
    app: 40.25,
    char_1: 'ruan-mei',
    app_rate_1: '71.14%',
    avg_round_1: 3446,
    app_flat_1: 929,
    char_2: 'tingyun-fugue',
    app_rate_2: '56.65%',
    avg_round_2: 3457,
    app_flat_2: 712,
    char_3: 'firefly',
    app_rate_3: '51.27%',
    avg_round_3: 3402,
    app_flat_3: 477,
    char_4: 'trailblazer-harmony',
    app_rate_4: '22.99%',
    avg_round_4: 3412,
    app_flat_4: 338,
    char_5: 'rappa',
    app_rate_5: '17.11%',
    avg_round_5: 3501,
    app_flat_5: 386,
    char_6: 'the-herta',
    app_rate_6: '17.01%',
    avg_round_6: 3525,
    app_flat_6: 402,
    char_7: 'robin',
    app_rate_7: '9.62%',
    avg_round_7: 3531,
    app_flat_7: 186,
    char_8: 'jade',
    app_rate_8: '8.15%',
    avg_round_8: 3570,
    app_flat_8: 159,
    char_9: 'herta',
    app_rate_9: '7.69%',
    avg_round_9: 3501,
    app_flat_9: 207,
    char_10: 'trailblazer-remembrance',
    app_rate_10: '6.04%',
    avg_round_10: 3553,
    app_flat_10: 161,
    char_11: 'sunday',
    app_rate_11: '5.41%',
    avg_round_11: 3451,
    app_flat_11: 115,
    char_12: 'acheron',
    app_rate_12: '3.68%',
    avg_round_12: 3331,
    app_flat_12: 50,
    char_13: 'black-swan',
    app_rate_13: '3.15%',
    avg_round_13: 3340,
    app_flat_13: 41,
    char_14: 'feixiao',
    app_rate_14: '2.87%',
    avg_round_14: 3529,
    app_flat_14: 54,
    char_15: 'kafka',
    app_rate_15: '2.84%',
    avg_round_15: 3351,
    app_flat_15: 33,
    char_16: 'jiaoqiu',
    app_rate_16: '1.98%',
    avg_round_16: 3332,
    app_flat_16: 27,
    char_17: 'march-7th-swordmaster',
    app_rate_17: '1.88%',
    avg_round_17: 3531,
    app_flat_17: 47,
    char_18: 'sparkle',
    app_rate_18: '1.6%',
    avg_round_18: 3313,
    app_flat_18: 13,
    char_19: 'serval',
    app_rate_19: '1.37%',
    avg_round_19: 3466,
    app_flat_19: 38,
    char_20: 'topaz',
    app_rate_20: '1.19%',
    avg_round_20: 3478,
    app_flat_20: 15,
    char_21: 'himeko',
    app_rate_21: '1.17%',
    avg_round_21: 3372,
    app_flat_21: 19,
    char_22: 'pela',
    app_rate_22: '0.91%',
    avg_round_22: 3358,
    app_flat_22: 22,
    char_23: 'boothill',
    app_rate_23: '0.81%',
    avg_round_23: 3378,
    app_flat_23: 7,
    char_24: 'argenti',
    app_rate_24: '0.63%',
    avg_round_24: 3529,
    app_flat_24: 14,
    char_25: 'bronya',
    app_rate_25: '0.51%',
    avg_round_25: 3507,
    app_flat_25: 6,
    char_26: 'jing-yuan',
    app_rate_26: '0.41%',
    avg_round_26: 3329,
    app_flat_26: 9,
    char_27: 'silver-wolf',
    app_rate_27: '0.23%',
    avg_round_27: 3353,
    app_flat_27: 3,
    char_28: 'yunli',
    app_rate_28: '0.2%',
    avg_round_28: 3286,
    app_flat_28: 5,
    char_29: 'blade',
    app_rate_29: '0.2%',
    avg_round_29: 3373,
    app_flat_29: 3,
    char_30: 'moze',
    app_rate_30: '0.18%',
    avg_round_30: 3440,
    app_flat_30: 4
  },
  {
    char: 'gallagher',
    app: 36.95,
    char_1: 'ruan-mei',
    app_rate_1: '67.38%',
    avg_round_1: 3363,
    app_flat_1: 1129,
    char_2: 'firefly',
    app_rate_2: '46.42%',
    avg_round_2: 3331,
    app_flat_2: 643,
    char_3: 'trailblazer-harmony',
    app_rate_3: '36.02%',
    avg_round_3: 3340,
    app_flat_3: 648,
    char_4: 'tingyun-fugue',
    app_rate_4: '33.45%',
    avg_round_4: 3394,
    app_flat_4: 557,
    char_5: 'rappa',
    app_rate_5: '16.73%',
    avg_round_5: 3424,
    app_flat_5: 438,
    char_6: 'acheron',
    app_rate_6: '12.97%',
    avg_round_6: 3345,
    app_flat_6: 189,
    char_7: 'robin',
    app_rate_7: '9.7%',
    avg_round_7: 3471,
    app_flat_7: 163,
    char_8: 'jiaoqiu',
    app_rate_8: '8.71%',
    avg_round_8: 3359,
    app_flat_8: 144,
    char_9: 'black-swan',
    app_rate_9: '7.77%',
    avg_round_9: 3327,
    app_flat_9: 118,
    char_10: 'sunday',
    app_rate_10: '7.6%',
    avg_round_10: 3382,
    app_flat_10: 120,
    char_11: 'kafka',
    app_rate_11: '7.24%',
    avg_round_11: 3329,
    app_flat_11: 108,
    char_12: 'the-herta',
    app_rate_12: '6.47%',
    avg_round_12: 3501,
    app_flat_12: 168,
    char_13: 'feixiao',
    app_rate_13: '4.87%',
    avg_round_13: 3507,
    app_flat_13: 109,
    char_14: 'trailblazer-remembrance',
    app_rate_14: '4.48%',
    avg_round_14: 3517,
    app_flat_14: 121,
    char_15: 'herta',
    app_rate_15: '4.06%',
    avg_round_15: 3496,
    app_flat_15: 113,
    char_16: 'pela',
    app_rate_16: '3.95%',
    avg_round_16: 3359,
    app_flat_16: 80,
    char_17: 'sparkle',
    app_rate_17: '3.46%',
    avg_round_17: 3303,
    app_flat_17: 16,
    char_18: 'march-7th-swordmaster',
    app_rate_18: '3.43%',
    avg_round_18: 3493,
    app_flat_18: 88,
    char_19: 'jing-yuan',
    app_rate_19: '2.85%',
    avg_round_19: 3382,
    app_flat_19: 43,
    char_20: 'bronya',
    app_rate_20: '1.33%',
    avg_round_20: 3440,
    app_flat_20: 13,
    char_21: 'topaz',
    app_rate_21: '1.3%',
    avg_round_21: 3463,
    app_flat_21: 20,
    char_22: 'imbibitor-lunae',
    app_rate_22: '1.19%',
    avg_round_22: 3371,
    app_flat_22: 6,
    char_23: 'boothill',
    app_rate_23: '1.11%',
    avg_round_23: 3201,
    app_flat_23: 13,
    char_24: 'himeko',
    app_rate_24: '1.08%',
    avg_round_24: 3240,
    app_flat_24: 19,
    char_25: 'jade',
    app_rate_25: '1.0%',
    avg_round_25: 3536,
    app_flat_25: 21,
    char_26: 'serval',
    app_rate_26: '0.97%',
    avg_round_26: 3475,
    app_flat_26: 18,
    char_27: 'silver-wolf',
    app_rate_27: '0.91%',
    avg_round_27: 3381,
    app_flat_27: 16,
    char_28: 'argenti',
    app_rate_28: '0.86%',
    avg_round_28: 3535,
    app_flat_28: 21,
    char_29: 'tingyun',
    app_rate_29: '0.53%',
    avg_round_29: 3359,
    app_flat_29: 5,
    char_30: 'guinaifen',
    app_rate_30: '0.41%',
    avg_round_30: 3206,
    app_flat_30: 6
  },
  {
    char: 'sunday',
    app: 34.66,
    char_1: 'the-herta',
    app_rate_1: '43.56%',
    avg_round_1: 3492,
    app_flat_1: 897,
    char_2: 'aventurine',
    app_rate_2: '34.98%',
    avg_round_2: 3460,
    app_flat_2: 561,
    char_3: 'herta',
    app_rate_3: '22.9%',
    avg_round_3: 3478,
    app_flat_3: 496,
    char_4: 'robin',
    app_rate_4: '22.58%',
    avg_round_4: 3345,
    app_flat_4: 239,
    char_5: 'huohuo',
    app_rate_5: '19.86%',
    avg_round_5: 3410,
    app_flat_5: 286,
    char_6: 'jing-yuan',
    app_rate_6: '15.97%',
    avg_round_6: 3354,
    app_flat_6: 178,
    char_7: 'jade',
    app_rate_7: '13.44%',
    avg_round_7: 3551,
    app_flat_7: 236,
    char_8: 'fu-xuan',
    app_rate_8: '12.91%',
    avg_round_8: 3419,
    app_flat_8: 168,
    char_9: 'acheron',
    app_rate_9: '12.14%',
    avg_round_9: 3296,
    app_flat_9: 51,
    char_10: 'luocha',
    app_rate_10: '9.55%',
    avg_round_10: 3395,
    app_flat_10: 128,
    char_11: 'jiaoqiu',
    app_rate_11: '9.43%',
    avg_round_11: 3304,
    app_flat_11: 42,
    char_12: 'argenti',
    app_rate_12: '9.11%',
    avg_round_12: 3397,
    app_flat_12: 128,
    char_13: 'gallagher',
    app_rate_13: '8.1%',
    avg_round_13: 3382,
    app_flat_13: 120,
    char_14: 'ruan-mei',
    app_rate_14: '7.19%',
    avg_round_14: 3269,
    app_flat_14: 42,
    char_15: 'imbibitor-lunae',
    app_rate_15: '7.13%',
    avg_round_15: 3303,
    app_flat_15: 28,
    char_16: 'sparkle',
    app_rate_16: '6.63%',
    avg_round_16: 3283,
    app_flat_16: 48,
    char_17: 'lingsha',
    app_rate_17: '6.28%',
    avg_round_17: 3451,
    app_flat_17: 115,
    char_18: 'yunli',
    app_rate_18: '5.36%',
    avg_round_18: 3311,
    app_flat_18: 88,
    char_19: 'serval',
    app_rate_19: '4.98%',
    avg_round_19: 3455,
    app_flat_19: 111,
    char_20: 'trailblazer-remembrance',
    app_rate_20: '4.75%',
    avg_round_20: 3285,
    app_flat_20: 57,
    char_21: 'tingyun',
    app_rate_21: '3.83%',
    avg_round_21: 3313,
    app_flat_21: 32,
    char_22: 'pela',
    app_rate_22: '3.21%',
    avg_round_22: 3222,
    app_flat_22: 11,
    char_23: 'jingliu',
    app_rate_23: '2.45%',
    avg_round_23: 3166,
    app_flat_23: 16,
    char_24: 'feixiao',
    app_rate_24: '1.92%',
    avg_round_24: 3447,
    app_flat_24: 14,
    char_25: 'clara',
    app_rate_25: '1.62%',
    avg_round_25: 3154,
    app_flat_25: 21,
    char_26: 'tingyun-fugue',
    app_rate_26: '1.41%',
    avg_round_26: 3344,
    app_flat_26: 8,
    char_27: 'march-7th-swordmaster',
    app_rate_27: '1.33%',
    avg_round_27: 3486,
    app_flat_27: 8,
    char_28: 'bronya',
    app_rate_28: '1.03%',
    avg_round_28: 3284,
    app_flat_28: 7,
    char_29: 'blade',
    app_rate_29: '0.85%',
    avg_round_29: 3208,
    app_flat_29: 9,
    char_30: 'boothill',
    app_rate_30: '0.62%',
    avg_round_30: 3167,
    app_flat_30: 3
  },
  {
    char: 'herta',
    app: 33.61,
    char_1: 'the-herta',
    app_rate_1: '93.07%',
    avg_round_1: 3479,
    app_flat_1: 1986,
    char_2: 'aventurine',
    app_rate_2: '39.85%',
    avg_round_2: 3471,
    app_flat_2: 911,
    char_3: 'robin',
    app_rate_3: '33.16%',
    avg_round_3: 3417,
    app_flat_3: 618,
    char_4: 'trailblazer-remembrance',
    app_rate_4: '31.7%',
    avg_round_4: 3534,
    app_flat_4: 755,
    char_5: 'sunday',
    app_rate_5: '23.62%',
    avg_round_5: 3478,
    app_flat_5: 496,
    char_6: 'fu-xuan',
    app_rate_6: '20.85%',
    avg_round_6: 3420,
    app_flat_6: 386,
    char_7: 'huohuo',
    app_rate_7: '15.68%',
    avg_round_7: 3507,
    app_flat_7: 319,
    char_8: 'lingsha',
    app_rate_8: '9.21%',
    avg_round_8: 3501,
    app_flat_8: 207,
    char_9: 'jade',
    app_rate_9: '6.63%',
    avg_round_9: 3277,
    app_flat_9: 107,
    char_10: 'ruan-mei',
    app_rate_10: '6.38%',
    avg_round_10: 3383,
    app_flat_10: 90,
    char_11: 'luocha',
    app_rate_11: '5.71%',
    avg_round_11: 3455,
    app_flat_11: 122,
    char_12: 'gallagher',
    app_rate_12: '4.47%',
    avg_round_12: 3496,
    app_flat_12: 113,
    char_13: 'sparkle',
    app_rate_13: '4.32%',
    avg_round_13: 3355,
    app_flat_13: 60,
    char_14: 'pela',
    app_rate_14: '1.61%',
    avg_round_14: 3332,
    app_flat_14: 29,
    char_15: 'yunli',
    app_rate_15: '0.64%',
    avg_round_15: 3280,
    app_flat_15: 6,
    char_16: 'gepard',
    app_rate_16: '0.43%',
    avg_round_16: 3356,
    app_flat_16: 10,
    char_17: 'bronya',
    app_rate_17: '0.36%',
    avg_round_17: 3425,
    app_flat_17: 9,
    char_18: 'bailu',
    app_rate_18: '0.24%',
    avg_round_18: 3393,
    app_flat_18: 4,
    char_19: 'tingyun',
    app_rate_19: '0.24%',
    avg_round_19: 3426,
    app_flat_19: 1,
    char_20: 'lynx',
    app_rate_20: '0.18%',
    avg_round_20: 3388,
    app_flat_20: 5,
    char_21: 'clara',
    app_rate_21: '0.18%',
    avg_round_21: 0,
    app_flat_21: 0,
    char_22: 'march-7th',
    app_rate_22: '0.15%',
    avg_round_22: 3612,
    app_flat_22: 4,
    char_23: 'acheron',
    app_rate_23: '0.15%',
    avg_round_23: 0,
    app_flat_23: 0,
    char_24: 'feixiao',
    app_rate_24: '0.15%',
    avg_round_24: 3161,
    app_flat_24: 1,
    char_25: 'argenti',
    app_rate_25: '0.12%',
    avg_round_25: 3382,
    app_flat_25: 2,
    char_26: 'jiaoqiu',
    app_rate_26: '0.12%',
    avg_round_26: 0,
    app_flat_26: 0,
    char_27: 'tingyun-fugue',
    app_rate_27: '0.12%',
    avg_round_27: 0,
    app_flat_27: 0,
    char_28: 'silver-wolf',
    app_rate_28: '0.09%',
    avg_round_28: 3180,
    app_flat_28: 1,
    char_29: 'serval',
    app_rate_29: '0.06%',
    avg_round_29: 0,
    app_flat_29: 0,
    char_30: 'himeko',
    app_rate_30: '0.06%',
    avg_round_30: 0,
    app_flat_30: 0
  },
  {
    char: 'acheron',
    app: 30.93,
    char_1: 'jiaoqiu',
    app_rate_1: '59.64%',
    avg_round_1: 3365,
    app_flat_1: 581,
    char_2: 'aventurine',
    app_rate_2: '38.9%',
    avg_round_2: 3364,
    app_flat_2: 358,
    char_3: 'pela',
    app_rate_3: '34.35%',
    avg_round_3: 3357,
    app_flat_3: 452,
    char_4: 'sparkle',
    app_rate_4: '29.46%',
    avg_round_4: 3305,
    app_flat_4: 39,
    char_5: 'fu-xuan',
    app_rate_5: '22.39%',
    avg_round_5: 3301,
    app_flat_5: 148,
    char_6: 'black-swan',
    app_rate_6: '20.28%',
    avg_round_6: 3300,
    app_flat_6: 206,
    char_7: 'gallagher',
    app_rate_7: '15.49%',
    avg_round_7: 3345,
    app_flat_7: 189,
    char_8: 'kafka',
    app_rate_8: '15.19%',
    avg_round_8: 3305,
    app_flat_8: 141,
    char_9: 'sunday',
    app_rate_9: '13.61%',
    avg_round_9: 3296,
    app_flat_9: 51,
    char_10: 'silver-wolf',
    app_rate_10: '9.58%',
    avg_round_10: 3335,
    app_flat_10: 77,
    char_11: 'robin',
    app_rate_11: '6.7%',
    avg_round_11: 3346,
    app_flat_11: 27,
    char_12: 'ruan-mei',
    app_rate_12: '5.09%',
    avg_round_12: 3338,
    app_flat_12: 10,
    char_13: 'bronya',
    app_rate_13: '4.89%',
    avg_round_13: 3384,
    app_flat_13: 2,
    char_14: 'lingsha',
    app_rate_14: '4.79%',
    avg_round_14: 3331,
    app_flat_14: 50,
    char_15: 'huohuo',
    app_rate_15: '4.49%',
    avg_round_15: 3325,
    app_flat_15: 40,
    char_16: 'tingyun-fugue',
    app_rate_16: '3.86%',
    avg_round_16: 3347,
    app_flat_16: 46,
    char_17: 'luocha',
    app_rate_17: '2.71%',
    avg_round_17: 3293,
    app_flat_17: 26,
    char_18: 'trailblazer-remembrance',
    app_rate_18: '2.64%',
    avg_round_18: 3206,
    app_flat_18: 8,
    char_19: 'gepard',
    app_rate_19: '2.05%',
    avg_round_19: 3298,
    app_flat_19: 15,
    char_20: 'guinaifen',
    app_rate_20: '0.69%',
    avg_round_20: 3233,
    app_flat_20: 8,
    char_21: 'welt',
    app_rate_21: '0.53%',
    avg_round_21: 3143,
    app_flat_21: 5,
    char_22: 'bailu',
    app_rate_22: '0.46%',
    avg_round_22: 3173,
    app_flat_22: 1,
    char_23: 'feixiao',
    app_rate_23: '0.3%',
    avg_round_23: 0,
    app_flat_23: 0,
    char_24: 'sampo',
    app_rate_24: '0.23%',
    avg_round_24: 3247,
    app_flat_24: 5,
    char_25: 'lynx',
    app_rate_25: '0.2%',
    avg_round_25: 3280,
    app_flat_25: 2,
    char_26: 'march-7th',
    app_rate_26: '0.2%',
    avg_round_26: 3245,
    app_flat_26: 1,
    char_27: 'herta',
    app_rate_27: '0.17%',
    avg_round_27: 0,
    app_flat_27: 0,
    char_28: 'trailblazer-preservation',
    app_rate_28: '0.13%',
    avg_round_28: 0,
    app_flat_28: 0,
    char_29: 'yunli',
    app_rate_29: '0.1%',
    avg_round_29: 0,
    app_flat_29: 0,
    char_30: 'tingyun',
    app_rate_30: '0.1%',
    avg_round_30: 0,
    app_flat_30: 0
  },
  {
    char: 'trailblazer-harmony',
    app: 28.77,
    char_1: 'ruan-mei',
    app_rate_1: '88.64%',
    avg_round_1: 3357,
    app_flat_1: 908,
    char_2: 'firefly',
    app_rate_2: '73.3%',
    avg_round_2: 3342,
    app_flat_2: 585,
    char_3: 'gallagher',
    app_rate_3: '46.25%',
    avg_round_3: 3340,
    app_flat_3: 648,
    char_4: 'lingsha',
    app_rate_4: '32.16%',
    avg_round_4: 3412,
    app_flat_4: 338,
    char_5: 'tingyun-fugue',
    app_rate_5: '28.08%',
    avg_round_5: 3405,
    app_flat_5: 169,
    char_6: 'rappa',
    app_rate_6: '20.34%',
    avg_round_6: 3383,
    app_flat_6: 360,
    char_7: 'luocha',
    app_rate_7: '1.63%',
    avg_round_7: 3285,
    app_flat_7: 20,
    char_8: 'aventurine',
    app_rate_8: '1.46%',
    avg_round_8: 3288,
    app_flat_8: 18,
    char_9: 'boothill',
    app_rate_9: '1.24%',
    avg_round_9: 3213,
    app_flat_9: 13,
    char_10: 'himeko',
    app_rate_10: '1.24%',
    avg_round_10: 3248,
    app_flat_10: 12,
    char_11: 'huohuo',
    app_rate_11: '1.14%',
    avg_round_11: 3250,
    app_flat_11: 12,
    char_12: 'march-7th-swordmaster',
    app_rate_12: '1.1%',
    avg_round_12: 3390,
    app_flat_12: 18,
    char_13: 'bronya',
    app_rate_13: '0.71%',
    avg_round_13: 2910,
    app_flat_13: 2,
    char_14: 'fu-xuan',
    app_rate_14: '0.53%',
    avg_round_14: 3191,
    app_flat_14: 3,
    char_15: 'sunday',
    app_rate_15: '0.5%',
    avg_round_15: 3324,
    app_flat_15: 3,
    char_16: 'pela',
    app_rate_16: '0.28%',
    avg_round_16: 3166,
    app_flat_16: 3,
    char_17: 'robin',
    app_rate_17: '0.14%',
    avg_round_17: 0,
    app_flat_17: 0,
    char_18: 'asta',
    app_rate_18: '0.14%',
    avg_round_18: 3175,
    app_flat_18: 1,
    char_19: 'argenti',
    app_rate_19: '0.11%',
    avg_round_19: 0,
    app_flat_19: 0,
    char_20: 'jiaoqiu',
    app_rate_20: '0.11%',
    avg_round_20: 0,
    app_flat_20: 0,
    char_21: 'serval',
    app_rate_21: '0.11%',
    avg_round_21: 3356,
    app_flat_21: 2,
    char_22: 'gepard',
    app_rate_22: '0.11%',
    avg_round_22: 3036,
    app_flat_22: 2,
    char_23: 'kafka',
    app_rate_23: '0.07%',
    avg_round_23: 3275,
    app_flat_23: 1,
    char_24: 'blade',
    app_rate_24: '0.07%',
    avg_round_24: 3281,
    app_flat_24: 2,
    char_25: 'acheron',
    app_rate_25: '0.07%',
    avg_round_25: 3324,
    app_flat_25: 1,
    char_26: 'black-swan',
    app_rate_26: '0.07%',
    avg_round_26: 0,
    app_flat_26: 0,
    char_27: 'lynx',
    app_rate_27: '0.07%',
    avg_round_27: 0,
    app_flat_27: 0,
    char_28: 'bailu',
    app_rate_28: '0.07%',
    avg_round_28: 0,
    app_flat_28: 0,
    char_29: 'the-herta',
    app_rate_29: '0.04%',
    avg_round_29: 0,
    app_flat_29: 0,
    char_30: 'silver-wolf',
    app_rate_30: '0.04%',
    avg_round_30: 0,
    app_flat_30: 0
  },
  {
    char: 'jade',
    app: 24.18,
    char_1: 'the-herta',
    app_rate_1: '86.99%',
    avg_round_1: 3544,
    app_flat_1: 1098,
    char_2: 'robin',
    app_rate_2: '48.58%',
    avg_round_2: 3522,
    app_flat_2: 573,
    char_3: 'aventurine',
    app_rate_3: '43.26%',
    avg_round_3: 3541,
    app_flat_3: 545,
    char_4: 'trailblazer-remembrance',
    app_rate_4: '21.29%',
    avg_round_4: 3598,
    app_flat_4: 288,
    char_5: 'sunday',
    app_rate_5: '19.26%',
    avg_round_5: 3551,
    app_flat_5: 236,
    char_6: 'fu-xuan',
    app_rate_6: '17.36%',
    avg_round_6: 3488,
    app_flat_6: 199,
    char_7: 'huohuo',
    app_rate_7: '15.59%',
    avg_round_7: 3558,
    app_flat_7: 193,
    char_8: 'lingsha',
    app_rate_8: '13.56%',
    avg_round_8: 3570,
    app_flat_8: 159,
    char_9: 'herta',
    app_rate_9: '9.21%',
    avg_round_9: 3277,
    app_flat_9: 107,
    char_10: 'luocha',
    app_rate_10: '6.21%',
    avg_round_10: 3518,
    app_flat_10: 87,
    char_11: 'ruan-mei',
    app_rate_11: '6.13%',
    avg_round_11: 3421,
    app_flat_11: 58,
    char_12: 'sparkle',
    app_rate_12: '3.68%',
    avg_round_12: 3478,
    app_flat_12: 25,
    char_13: 'gallagher',
    app_rate_13: '1.52%',
    avg_round_13: 3536,
    app_flat_13: 21,
    char_14: 'argenti',
    app_rate_14: '1.48%',
    avg_round_14: 3292,
    app_flat_14: 9,
    char_15: 'yunli',
    app_rate_15: '0.89%',
    avg_round_15: 3362,
    app_flat_15: 10,
    char_16: 'feixiao',
    app_rate_16: '0.84%',
    avg_round_16: 0,
    app_flat_16: 0,
    char_17: 'pela',
    app_rate_17: '0.76%',
    avg_round_17: 3428,
    app_flat_17: 9,
    char_18: 'clara',
    app_rate_18: '0.59%',
    avg_round_18: 2977,
    app_flat_18: 1,
    char_19: 'bronya',
    app_rate_19: '0.55%',
    avg_round_19: 3406,
    app_flat_19: 4,
    char_20: 'blade',
    app_rate_20: '0.3%',
    avg_round_20: 0,
    app_flat_20: 0,
    char_21: 'lynx',
    app_rate_21: '0.3%',
    avg_round_21: 3296,
    app_flat_21: 2,
    char_22: 'bailu',
    app_rate_22: '0.25%',
    avg_round_22: 3400,
    app_flat_22: 4,
    char_23: 'tingyun-fugue',
    app_rate_23: '0.17%',
    avg_round_23: 3406,
    app_flat_23: 2,
    char_24: 'jing-yuan',
    app_rate_24: '0.17%',
    avg_round_24: 0,
    app_flat_24: 0,
    char_25: 'acheron',
    app_rate_25: '0.13%',
    avg_round_25: 0,
    app_flat_25: 0,
    char_26: 'gepard',
    app_rate_26: '0.13%',
    avg_round_26: 3507,
    app_flat_26: 1,
    char_27: 'qingque',
    app_rate_27: '0.13%',
    avg_round_27: 0,
    app_flat_27: 0,
    char_28: 'rappa',
    app_rate_28: '0.08%',
    avg_round_28: 0,
    app_flat_28: 0,
    char_29: 'march-7th-swordmaster',
    app_rate_29: '0.08%',
    avg_round_29: 0,
    app_flat_29: 0,
    char_30: 'jiaoqiu',
    app_rate_30: '0.08%',
    avg_round_30: 0,
    app_flat_30: 0
  },
  {
    char: 'trailblazer-remembrance',
    app: 23.26,
    char_1: 'the-herta',
    app_rate_1: '82.48%',
    avg_round_1: 3540,
    app_flat_1: 1265,
    char_2: 'herta',
    app_rate_2: '45.81%',
    avg_round_2: 3534,
    app_flat_2: 755,
    char_3: 'aventurine',
    app_rate_3: '27.14%',
    avg_round_3: 3548,
    app_flat_3: 443,
    char_4: 'huohuo',
    app_rate_4: '22.88%',
    avg_round_4: 3545,
    app_flat_4: 349,
    char_5: 'jade',
    app_rate_5: '22.13%',
    avg_round_5: 3598,
    app_flat_5: 288,
    char_6: 'fu-xuan',
    app_rate_6: '16.07%',
    avg_round_6: 3525,
    app_flat_6: 224,
    char_7: 'lingsha',
    app_rate_7: '10.45%',
    avg_round_7: 3553,
    app_flat_7: 161,
    char_8: 'serval',
    app_rate_8: '9.84%',
    avg_round_8: 3481,
    app_flat_8: 145,
    char_9: 'argenti',
    app_rate_9: '8.39%',
    avg_round_9: 3483,
    app_flat_9: 112,
    char_10: 'gallagher',
    app_rate_10: '7.11%',
    avg_round_10: 3517,
    app_flat_10: 121,
    char_11: 'sunday',
    app_rate_11: '7.07%',
    avg_round_11: 3285,
    app_flat_11: 57,
    char_12: 'robin',
    app_rate_12: '7.07%',
    avg_round_12: 3347,
    app_flat_12: 49,
    char_13: 'luocha',
    app_rate_13: '6.5%',
    avg_round_13: 3481,
    app_flat_13: 89,
    char_14: 'yunli',
    app_rate_14: '4.57%',
    avg_round_14: 3350,
    app_flat_14: 43,
    char_15: 'acheron',
    app_rate_15: '3.51%',
    avg_round_15: 3206,
    app_flat_15: 8,
    char_16: 'ruan-mei',
    app_rate_16: '2.5%',
    avg_round_16: 3217,
    app_flat_16: 1,
    char_17: 'jiaoqiu',
    app_rate_17: '2.24%',
    avg_round_17: 3247,
    app_flat_17: 5,
    char_18: 'sparkle',
    app_rate_18: '1.98%',
    avg_round_18: 3145,
    app_flat_18: 7,
    char_19: 'pela',
    app_rate_19: '1.62%',
    avg_round_19: 3197,
    app_flat_19: 1,
    char_20: 'clara',
    app_rate_20: '1.45%',
    avg_round_20: 3170,
    app_flat_20: 16,
    char_21: 'tingyun',
    app_rate_21: '1.23%',
    avg_round_21: 3360,
    app_flat_21: 8,
    char_22: 'imbibitor-lunae',
    app_rate_22: '1.05%',
    avg_round_22: 3308,
    app_flat_22: 3,
    char_23: 'jing-yuan',
    app_rate_23: '0.83%',
    avg_round_23: 3266,
    app_flat_23: 7,
    char_24: 'feixiao',
    app_rate_24: '0.75%',
    avg_round_24: 3462,
    app_flat_24: 11,
    char_25: 'tingyun-fugue',
    app_rate_25: '0.53%',
    avg_round_25: 0,
    app_flat_25: 0,
    char_26: 'jingliu',
    app_rate_26: '0.53%',
    avg_round_26: 3050,
    app_flat_26: 2,
    char_27: 'blade',
    app_rate_27: '0.4%',
    avg_round_27: 3102,
    app_flat_27: 2,
    char_28: 'black-swan',
    app_rate_28: '0.4%',
    avg_round_28: 0,
    app_flat_28: 0,
    char_29: 'lynx',
    app_rate_29: '0.4%',
    avg_round_29: 3286,
    app_flat_29: 5,
    char_30: 'march-7th-swordmaster',
    app_rate_30: '0.35%',
    avg_round_30: 3464,
    app_flat_30: 4
  },
  {
    char: 'fu-xuan',
    app: 22.67,
    char_1: 'the-herta',
    app_rate_1: '50.97%',
    avg_round_1: 3456,
    app_flat_1: 611,
    char_2: 'herta',
    app_rate_2: '30.91%',
    avg_round_2: 3420,
    app_flat_2: 386,
    char_3: 'acheron',
    app_rate_3: '30.55%',
    avg_round_3: 3301,
    app_flat_3: 148,
    char_4: 'robin',
    app_rate_4: '24.47%',
    avg_round_4: 3397,
    app_flat_4: 250,
    char_5: 'sparkle',
    app_rate_5: '23.57%',
    avg_round_5: 3341,
    app_flat_5: 63,
    char_6: 'sunday',
    app_rate_6: '19.74%',
    avg_round_6: 3419,
    app_flat_6: 168,
    char_7: 'jade',
    app_rate_7: '18.52%',
    avg_round_7: 3488,
    app_flat_7: 199,
    char_8: 'jiaoqiu',
    app_rate_8: '16.63%',
    avg_round_8: 3330,
    app_flat_8: 106,
    char_9: 'trailblazer-remembrance',
    app_rate_9: '16.49%',
    avg_round_9: 3525,
    app_flat_9: 224,
    char_10: 'pela',
    app_rate_10: '13.25%',
    avg_round_10: 3318,
    app_flat_10: 114,
    char_11: 'ruan-mei',
    app_rate_11: '8.07%',
    avg_round_11: 3357,
    app_flat_11: 47,
    char_12: 'silver-wolf',
    app_rate_12: '5.68%',
    avg_round_12: 3273,
    app_flat_12: 23,
    char_13: 'black-swan',
    app_rate_13: '4.82%',
    avg_round_13: 3213,
    app_flat_13: 28,
    char_14: 'serval',
    app_rate_14: '3.92%',
    avg_round_14: 3423,
    app_flat_14: 42,
    char_15: 'argenti',
    app_rate_15: '3.52%',
    avg_round_15: 3380,
    app_flat_15: 31,
    char_16: 'kafka',
    app_rate_16: '3.38%',
    avg_round_16: 3231,
    app_flat_16: 19,
    char_17: 'feixiao',
    app_rate_17: '3.15%',
    avg_round_17: 3442,
    app_flat_17: 33,
    char_18: 'yunli',
    app_rate_18: '2.79%',
    avg_round_18: 3311,
    app_flat_18: 24,
    char_19: 'march-7th-swordmaster',
    app_rate_19: '2.66%',
    avg_round_19: 3433,
    app_flat_19: 30,
    char_20: 'bronya',
    app_rate_20: '2.07%',
    avg_round_20: 3357,
    app_flat_20: 5,
    char_21: 'tingyun',
    app_rate_21: '1.85%',
    avg_round_21: 3262,
    app_flat_21: 10,
    char_22: 'imbibitor-lunae',
    app_rate_22: '1.8%',
    avg_round_22: 3246,
    app_flat_22: 2,
    char_23: 'jing-yuan',
    app_rate_23: '1.76%',
    avg_round_23: 3341,
    app_flat_23: 8,
    char_24: 'clara',
    app_rate_24: '1.67%',
    avg_round_24: 3197,
    app_flat_24: 3,
    char_25: 'tingyun-fugue',
    app_rate_25: '1.44%',
    avg_round_25: 3311,
    app_flat_25: 13,
    char_26: 'rappa',
    app_rate_26: '1.22%',
    avg_round_26: 3327,
    app_flat_26: 12,
    char_27: 'jingliu',
    app_rate_27: '0.72%',
    avg_round_27: 3196,
    app_flat_27: 1,
    char_28: 'trailblazer-harmony',
    app_rate_28: '0.68%',
    avg_round_28: 3191,
    app_flat_28: 3,
    char_29: 'seele',
    app_rate_29: '0.63%',
    avg_round_29: 0,
    app_flat_29: 0,
    char_30: 'topaz',
    app_rate_30: '0.59%',
    avg_round_30: 3433,
    app_flat_30: 6
  },
  {
    char: 'huohuo',
    app: 22.44,
    char_1: 'the-herta',
    app_rate_1: '46.02%',
    avg_round_1: 3520,
    app_flat_1: 591,
    char_2: 'robin',
    app_rate_2: '31.95%',
    avg_round_2: 3399,
    app_flat_2: 305,
    char_3: 'sunday',
    app_rate_3: '30.68%',
    avg_round_3: 3410,
    app_flat_3: 286,
    char_4: 'trailblazer-remembrance',
    app_rate_4: '23.71%',
    avg_round_4: 3545,
    app_flat_4: 349,
    char_5: 'herta',
    app_rate_5: '23.49%',
    avg_round_5: 3507,
    app_flat_5: 319,
    char_6: 'ruan-mei',
    app_rate_6: '17.21%',
    avg_round_6: 3325,
    app_flat_6: 99,
    char_7: 'jade',
    app_rate_7: '16.8%',
    avg_round_7: 3558,
    app_flat_7: 193,
    char_8: 'black-swan',
    app_rate_8: '14.47%',
    avg_round_8: 3284,
    app_flat_8: 65,
    char_9: 'kafka',
    app_rate_9: '14.2%',
    avg_round_9: 3279,
    app_flat_9: 64,
    char_10: 'yunli',
    app_rate_10: '10.42%',
    avg_round_10: 3329,
    app_flat_10: 119,
    char_11: 'sparkle',
    app_rate_11: '9.97%',
    avg_round_11: 3308,
    app_flat_11: 61,
    char_12: 'argenti',
    app_rate_12: '7.42%',
    avg_round_12: 3416,
    app_flat_12: 77,
    char_13: 'acheron',
    app_rate_13: '6.19%',
    avg_round_13: 3325,
    app_flat_13: 40,
    char_14: 'tingyun',
    app_rate_14: '5.28%',
    avg_round_14: 3284,
    app_flat_14: 51,
    char_15: 'imbibitor-lunae',
    app_rate_15: '5.05%',
    avg_round_15: 3330,
    app_flat_15: 11,
    char_16: 'serval',
    app_rate_16: '5.01%',
    avg_round_16: 3466,
    app_flat_16: 59,
    char_17: 'jing-yuan',
    app_rate_17: '4.92%',
    avg_round_17: 3369,
    app_flat_17: 38,
    char_18: 'jiaoqiu',
    app_rate_18: '3.55%',
    avg_round_18: 3333,
    app_flat_18: 21,
    char_19: 'rappa',
    app_rate_19: '3.19%',
    avg_round_19: 3325,
    app_flat_19: 38,
    char_20: 'march-7th-swordmaster',
    app_rate_20: '2.91%',
    avg_round_20: 3385,
    app_flat_20: 41,
    char_21: 'tingyun-fugue',
    app_rate_21: '2.87%',
    avg_round_21: 3338,
    app_flat_21: 25,
    char_22: 'feixiao',
    app_rate_22: '2.37%',
    avg_round_22: 3455,
    app_flat_22: 27,
    char_23: 'jingliu',
    app_rate_23: '2.18%',
    avg_round_23: 3159,
    app_flat_23: 9,
    char_24: 'clara',
    app_rate_24: '1.87%',
    avg_round_24: 3131,
    app_flat_24: 16,
    char_25: 'pela',
    app_rate_25: '1.64%',
    avg_round_25: 3362,
    app_flat_25: 19,
    char_26: 'trailblazer-harmony',
    app_rate_26: '1.46%',
    avg_round_26: 3250,
    app_flat_26: 12,
    char_27: 'firefly',
    app_rate_27: '1.18%',
    avg_round_27: 3304,
    app_flat_27: 1,
    char_28: 'bronya',
    app_rate_28: '1.09%',
    avg_round_28: 3248,
    app_flat_28: 7,
    char_29: 'topaz',
    app_rate_29: '0.59%',
    avg_round_29: 3326,
    app_flat_29: 4,
    char_30: 'blade',
    app_rate_30: '0.55%',
    avg_round_30: 3179,
    app_flat_30: 5
  },
  {
    char: 'jiaoqiu',
    app: 19.71,
    char_1: 'acheron',
    app_rate_1: '93.58%',
    avg_round_1: 3365,
    app_flat_1: 581,
    char_2: 'aventurine',
    app_rate_2: '41.87%',
    avg_round_2: 3391,
    app_flat_2: 266,
    char_3: 'sparkle',
    app_rate_3: '29.38%',
    avg_round_3: 3354,
    app_flat_3: 28,
    char_4: 'pela',
    app_rate_4: '27.05%',
    avg_round_4: 3380,
    app_flat_4: 358,
    char_5: 'fu-xuan',
    app_rate_5: '19.12%',
    avg_round_5: 3330,
    app_flat_5: 106,
    char_6: 'sunday',
    app_rate_6: '16.58%',
    avg_round_6: 3304,
    app_flat_6: 42,
    char_7: 'gallagher',
    app_rate_7: '16.32%',
    avg_round_7: 3359,
    app_flat_7: 144,
    char_8: 'black-swan',
    app_rate_8: '7.82%',
    avg_round_8: 3320,
    app_flat_8: 54,
    char_9: 'robin',
    app_rate_9: '7.46%',
    avg_round_9: 3391,
    app_flat_9: 21,
    char_10: 'kafka',
    app_rate_10: '4.82%',
    avg_round_10: 3301,
    app_flat_10: 17,
    char_11: 'ruan-mei',
    app_rate_11: '4.66%',
    avg_round_11: 3268,
    app_flat_11: 4,
    char_12: 'bronya',
    app_rate_12: '4.04%',
    avg_round_12: 3384,
    app_flat_12: 2,
    char_13: 'huohuo',
    app_rate_13: '4.04%',
    avg_round_13: 3333,
    app_flat_13: 21,
    char_14: 'lingsha',
    app_rate_14: '4.04%',
    avg_round_14: 3332,
    app_flat_14: 27,
    char_15: 'luocha',
    app_rate_15: '3.47%',
    avg_round_15: 3300,
    app_flat_15: 21,
    char_16: 'silver-wolf',
    app_rate_16: '3.06%',
    avg_round_16: 3407,
    app_flat_16: 30,
    char_17: 'tingyun-fugue',
    app_rate_17: '2.69%',
    avg_round_17: 3359,
    app_flat_17: 31,
    char_18: 'trailblazer-remembrance',
    app_rate_18: '2.64%',
    avg_round_18: 3247,
    app_flat_18: 5,
    char_19: 'gepard',
    app_rate_19: '1.3%',
    avg_round_19: 3328,
    app_flat_19: 7,
    char_20: 'jing-yuan',
    app_rate_20: '1.3%',
    avg_round_20: 3391,
    app_flat_20: 1,
    char_21: 'imbibitor-lunae',
    app_rate_21: '0.88%',
    avg_round_21: 3322,
    app_flat_21: 1,
    char_22: 'feixiao',
    app_rate_22: '0.52%',
    avg_round_22: 3505,
    app_flat_22: 1,
    char_23: 'guinaifen',
    app_rate_23: '0.31%',
    avg_round_23: 3216,
    app_flat_23: 3,
    char_24: 'sampo',
    app_rate_24: '0.31%',
    avg_round_24: 3260,
    app_flat_24: 4,
    char_25: 'blade',
    app_rate_25: '0.26%',
    avg_round_25: 3183,
    app_flat_25: 1,
    char_26: 'bailu',
    app_rate_26: '0.26%',
    avg_round_26: 0,
    app_flat_26: 0,
    char_27: 'dr-ratio',
    app_rate_27: '0.21%',
    avg_round_27: 0,
    app_flat_27: 0,
    char_28: 'lynx',
    app_rate_28: '0.21%',
    avg_round_28: 3280,
    app_flat_28: 2,
    char_29: 'tingyun',
    app_rate_29: '0.21%',
    avg_round_29: 0,
    app_flat_29: 0,
    char_30: 'herta',
    app_rate_30: '0.21%',
    avg_round_30: 0,
    app_flat_30: 0
  },
  {
    char: 'sparkle',
    app: 17.33,
    char_1: 'acheron',
    app_rate_1: '52.56%',
    avg_round_1: 3305,
    app_flat_1: 39,
    char_2: 'jiaoqiu',
    app_rate_2: '33.41%',
    avg_round_2: 3354,
    app_flat_2: 28,
    char_3: 'fu-xuan',
    app_rate_3: '30.82%',
    avg_round_3: 3341,
    app_flat_3: 63,
    char_4: 'aventurine',
    app_rate_4: '28.17%',
    avg_round_4: 3319,
    app_flat_4: 60,
    char_5: 'imbibitor-lunae',
    app_rate_5: '15.79%',
    avg_round_5: 3276,
    app_flat_5: 24,
    char_6: 'the-herta',
    app_rate_6: '14.02%',
    avg_round_6: 3389,
    app_flat_6: 89,
    char_7: 'sunday',
    app_rate_7: '13.26%',
    avg_round_7: 3283,
    app_flat_7: 48,
    char_8: 'huohuo',
    app_rate_8: '12.91%',
    avg_round_8: 3308,
    app_flat_8: 61,
    char_9: 'pela',
    app_rate_9: '10.78%',
    avg_round_9: 3231,
    app_flat_9: 8,
    char_10: 'herta',
    app_rate_10: '8.37%',
    avg_round_10: 3355,
    app_flat_10: 60,
    char_11: 'gallagher',
    app_rate_11: '7.37%',
    avg_round_11: 3303,
    app_flat_11: 16,
    char_12: 'robin',
    app_rate_12: '7.25%',
    avg_round_12: 3281,
    app_flat_12: 18,
    char_13: 'luocha',
    app_rate_13: '6.89%',
    avg_round_13: 3236,
    app_flat_13: 14,
    char_14: 'silver-wolf',
    app_rate_14: '6.78%',
    avg_round_14: 3088,
    app_flat_14: 2,
    char_15: 'ruan-mei',
    app_rate_15: '5.66%',
    avg_round_15: 3094,
    app_flat_15: 1,
    char_16: 'tingyun',
    app_rate_16: '5.6%',
    avg_round_16: 3189,
    app_flat_16: 18,
    char_17: 'jade',
    app_rate_17: '5.13%',
    avg_round_17: 3478,
    app_flat_17: 25,
    char_18: 'yunli',
    app_rate_18: '4.71%',
    avg_round_18: 3283,
    app_flat_18: 22,
    char_19: 'argenti',
    app_rate_19: '4.01%',
    avg_round_19: 3224,
    app_flat_19: 26,
    char_20: 'lingsha',
    app_rate_20: '3.71%',
    avg_round_20: 3313,
    app_flat_20: 13,
    char_21: 'trailblazer-remembrance',
    app_rate_21: '2.65%',
    avg_round_21: 3145,
    app_flat_21: 7,
    char_22: 'clara',
    app_rate_22: '2.59%',
    avg_round_22: 3161,
    app_flat_22: 8,
    char_23: 'jing-yuan',
    app_rate_23: '2.47%',
    avg_round_23: 3304,
    app_flat_23: 13,
    char_24: 'black-swan',
    app_rate_24: '1.94%',
    avg_round_24: 0,
    app_flat_24: 0,
    char_25: 'feixiao',
    app_rate_25: '1.47%',
    avg_round_25: 3343,
    app_flat_25: 7,
    char_26: 'gepard',
    app_rate_26: '1.36%',
    avg_round_26: 3220,
    app_flat_26: 3,
    char_27: 'tingyun-fugue',
    app_rate_27: '1.3%',
    avg_round_27: 3233,
    app_flat_27: 1,
    char_28: 'march-7th-swordmaster',
    app_rate_28: '1.06%',
    avg_round_28: 3261,
    app_flat_28: 5,
    char_29: 'bronya',
    app_rate_29: '1.0%',
    avg_round_29: 3232,
    app_flat_29: 2,
    char_30: 'serval',
    app_rate_30: '0.94%',
    avg_round_30: 3314,
    app_flat_30: 4
  },
  {
    char: 'rappa',
    app: 16.2,
    char_1: 'ruan-mei',
    app_rate_1: '88.71%',
    avg_round_1: 3450,
    app_flat_1: 826,
    char_2: 'tingyun-fugue',
    app_rate_2: '71.88%',
    avg_round_2: 3476,
    app_flat_2: 631,
    char_3: 'lingsha',
    app_rate_3: '42.5%',
    avg_round_3: 3501,
    app_flat_3: 386,
    char_4: 'gallagher',
    app_rate_4: '38.15%',
    avg_round_4: 3424,
    app_flat_4: 438,
    char_5: 'trailblazer-harmony',
    app_rate_5: '36.13%',
    avg_round_5: 3383,
    app_flat_5: 360,
    char_6: 'huohuo',
    app_rate_6: '4.41%',
    avg_round_6: 3325,
    app_flat_6: 38,
    char_7: 'firefly',
    app_rate_7: '3.47%',
    avg_round_7: 3365,
    app_flat_7: 7,
    char_8: 'luocha',
    app_rate_8: '3.22%',
    avg_round_8: 3312,
    app_flat_8: 27,
    char_9: 'aventurine',
    app_rate_9: '3.22%',
    avg_round_9: 3331,
    app_flat_9: 28,
    char_10: 'the-herta',
    app_rate_10: '1.77%',
    avg_round_10: 3379,
    app_flat_10: 14,
    char_11: 'fu-xuan',
    app_rate_11: '1.7%',
    avg_round_11: 3327,
    app_flat_11: 12,
    char_12: 'sunday',
    app_rate_12: '1.13%',
    avg_round_12: 3370,
    app_flat_12: 8,
    char_13: 'pela',
    app_rate_13: '1.01%',
    avg_round_13: 3314,
    app_flat_13: 8,
    char_14: 'robin',
    app_rate_14: '0.63%',
    avg_round_14: 3346,
    app_flat_14: 2,
    char_15: 'trailblazer-remembrance',
    app_rate_15: '0.32%',
    avg_round_15: 3470,
    app_flat_15: 3,
    char_16: 'tingyun',
    app_rate_16: '0.19%',
    avg_round_16: 3325,
    app_flat_16: 1,
    char_17: 'jade',
    app_rate_17: '0.13%',
    avg_round_17: 0,
    app_flat_17: 0,
    char_18: 'march-7th-swordmaster',
    app_rate_18: '0.13%',
    avg_round_18: 3489,
    app_flat_18: 1,
    char_19: 'bronya',
    app_rate_19: '0.13%',
    avg_round_19: 0,
    app_flat_19: 0,
    char_20: 'bailu',
    app_rate_20: '0.13%',
    avg_round_20: 3377,
    app_flat_20: 1,
    char_21: 'sparkle',
    app_rate_21: '0.13%',
    avg_round_21: 0,
    app_flat_21: 0,
    char_22: 'himeko',
    app_rate_22: '0.13%',
    avg_round_22: 3375,
    app_flat_22: 2,
    char_23: 'herta',
    app_rate_23: '0.13%',
    avg_round_23: 0,
    app_flat_23: 0,
    char_24: 'gepard',
    app_rate_24: '0.13%',
    avg_round_24: 3117,
    app_flat_24: 1,
    char_25: 'serval',
    app_rate_25: '0.13%',
    avg_round_25: 3320,
    app_flat_25: 1,
    char_26: 'asta',
    app_rate_26: '0.06%',
    avg_round_26: 0,
    app_flat_26: 0,
    char_27: 'welt',
    app_rate_27: '0.06%',
    avg_round_27: 0,
    app_flat_27: 0,
    char_28: 'natasha',
    app_rate_28: '0.06%',
    avg_round_28: 3379,
    app_flat_28: 1,
    char_29: 'acheron',
    app_rate_29: '0.06%',
    avg_round_29: 0,
    app_flat_29: 0,
    char_30: 'march-7th',
    app_rate_30: '0.06%',
    avg_round_30: 0,
    app_flat_30: 0
  },
  {
    char: 'feixiao',
    app: 15.42,
    char_1: 'robin',
    app_rate_1: '88.68%',
    avg_round_1: 3505,
    app_flat_1: 569,
    char_2: 'aventurine',
    app_rate_2: '60.6%',
    avg_round_2: 3505,
    app_flat_2: 408,
    char_3: 'march-7th-swordmaster',
    app_rate_3: '46.82%',
    avg_round_3: 3521,
    app_flat_3: 352,
    char_4: 'topaz',
    app_rate_4: '44.5%',
    avg_round_4: 3497,
    app_flat_4: 253,
    char_5: 'gallagher',
    app_rate_5: '11.66%',
    avg_round_5: 3507,
    app_flat_5: 109,
    char_6: 'lingsha',
    app_rate_6: '7.48%',
    avg_round_6: 3529,
    app_flat_6: 54,
    char_7: 'bronya',
    app_rate_7: '7.22%',
    avg_round_7: 3507,
    app_flat_7: 33,
    char_8: 'moze',
    app_rate_8: '5.23%',
    avg_round_8: 3431,
    app_flat_8: 34,
    char_9: 'ruan-mei',
    app_rate_9: '4.7%',
    avg_round_9: 3490,
    app_flat_9: 18,
    char_10: 'fu-xuan',
    app_rate_10: '4.64%',
    avg_round_10: 3442,
    app_flat_10: 33,
    char_11: 'sunday',
    app_rate_11: '4.3%',
    avg_round_11: 3447,
    app_flat_11: 14,
    char_12: 'huohuo',
    app_rate_12: '3.44%',
    avg_round_12: 3455,
    app_flat_12: 27,
    char_13: 'luocha',
    app_rate_13: '2.12%',
    avg_round_13: 3420,
    app_flat_13: 18,
    char_14: 'sparkle',
    app_rate_14: '1.66%',
    avg_round_14: 3343,
    app_flat_14: 7,
    char_15: 'jade',
    app_rate_15: '1.32%',
    avg_round_15: 0,
    app_flat_15: 0,
    char_16: 'trailblazer-remembrance',
    app_rate_16: '1.13%',
    avg_round_16: 3462,
    app_flat_16: 11,
    char_17: 'jiaoqiu',
    app_rate_17: '0.66%',
    avg_round_17: 3505,
    app_flat_17: 1,
    char_18: 'acheron',
    app_rate_18: '0.6%',
    avg_round_18: 0,
    app_flat_18: 0,
    char_19: 'clara',
    app_rate_19: '0.53%',
    avg_round_19: 3214,
    app_flat_19: 2,
    char_20: 'tingyun-fugue',
    app_rate_20: '0.4%',
    avg_round_20: 3436,
    app_flat_20: 2,
    char_21: 'yunli',
    app_rate_21: '0.4%',
    avg_round_21: 3340,
    app_flat_21: 2,
    char_22: 'herta',
    app_rate_22: '0.33%',
    avg_round_22: 3161,
    app_flat_22: 1,
    char_23: 'himeko',
    app_rate_23: '0.26%',
    avg_round_23: 3506,
    app_flat_23: 1,
    char_24: 'blade',
    app_rate_24: '0.26%',
    avg_round_24: 3292,
    app_flat_24: 2,
    char_25: 'lynx',
    app_rate_25: '0.13%',
    avg_round_25: 3491,
    app_flat_25: 1,
    char_26: 'gepard',
    app_rate_26: '0.13%',
    avg_round_26: 3225,
    app_flat_26: 1,
    char_27: 'bailu',
    app_rate_27: '0.13%',
    avg_round_27: 3582,
    app_flat_27: 1,
    char_28: 'jing-yuan',
    app_rate_28: '0.13%',
    avg_round_28: 0,
    app_flat_28: 0,
    char_29: 'firefly',
    app_rate_29: '0.13%',
    avg_round_29: 0,
    app_flat_29: 0,
    char_30: 'march-7th',
    app_rate_30: '0.07%',
    avg_round_30: 0,
    app_flat_30: 0
  },
  {
    char: 'black-swan',
    app: 13.05,
    char_1: 'kafka',
    app_rate_1: '82.86%',
    avg_round_1: 3308,
    app_flat_1: 289,
    char_2: 'acheron',
    app_rate_2: '48.04%',
    avg_round_2: 3300,
    app_flat_2: 206,
    char_3: 'ruan-mei',
    app_rate_3: '41.94%',
    avg_round_3: 3317,
    app_flat_3: 135,
    char_4: 'huohuo',
    app_rate_4: '24.88%',
    avg_round_4: 3284,
    app_flat_4: 65,
    char_5: 'gallagher',
    app_rate_5: '21.99%',
    avg_round_5: 3327,
    app_flat_5: 118,
    char_6: 'aventurine',
    app_rate_6: '17.84%',
    avg_round_6: 3312,
    app_flat_6: 88,
    char_7: 'jiaoqiu',
    app_rate_7: '11.82%',
    avg_round_7: 3320,
    app_flat_7: 54,
    char_8: 'lingsha',
    app_rate_8: '9.7%',
    avg_round_8: 3340,
    app_flat_8: 41,
    char_9: 'robin',
    app_rate_9: '8.92%',
    avg_round_9: 3248,
    app_flat_9: 16,
    char_10: 'fu-xuan',
    app_rate_10: '8.37%',
    avg_round_10: 3213,
    app_flat_10: 28,
    char_11: 'luocha',
    app_rate_11: '7.67%',
    avg_round_11: 3250,
    app_flat_11: 20,
    char_12: 'pela',
    app_rate_12: '2.66%',
    avg_round_12: 3256,
    app_flat_12: 22,
    char_13: 'sparkle',
    app_rate_13: '2.58%',
    avg_round_13: 0,
    app_flat_13: 0,
    char_14: 'tingyun-fugue',
    app_rate_14: '1.49%',
    avg_round_14: 3286,
    app_flat_14: 6,
    char_15: 'asta',
    app_rate_15: '1.25%',
    avg_round_15: 3370,
    app_flat_15: 3,
    char_16: 'guinaifen',
    app_rate_16: '1.25%',
    avg_round_16: 3164,
    app_flat_16: 3,
    char_17: 'bailu',
    app_rate_17: '1.17%',
    avg_round_17: 3274,
    app_flat_17: 2,
    char_18: 'sunday',
    app_rate_18: '1.17%',
    avg_round_18: 0,
    app_flat_18: 0,
    char_19: 'gepard',
    app_rate_19: '1.1%',
    avg_round_19: 3302,
    app_flat_19: 6,
    char_20: 'sampo',
    app_rate_20: '0.78%',
    avg_round_20: 0,
    app_flat_20: 0,
    char_21: 'trailblazer-remembrance',
    app_rate_21: '0.7%',
    avg_round_21: 0,
    app_flat_21: 0,
    char_22: 'bronya',
    app_rate_22: '0.55%',
    avg_round_22: 0,
    app_flat_22: 0,
    char_23: 'silver-wolf',
    app_rate_23: '0.23%',
    avg_round_23: 3085,
    app_flat_23: 1,
    char_24: 'tingyun',
    app_rate_24: '0.16%',
    avg_round_24: 0,
    app_flat_24: 0,
    char_25: 'trailblazer-harmony',
    app_rate_25: '0.16%',
    avg_round_25: 0,
    app_flat_25: 0,
    char_26: 'march-7th-swordmaster',
    app_rate_26: '0.16%',
    avg_round_26: 0,
    app_flat_26: 0,
    char_27: 'natasha',
    app_rate_27: '0.08%',
    avg_round_27: 0,
    app_flat_27: 0,
    char_28: 'himeko',
    app_rate_28: '0.08%',
    avg_round_28: 0,
    app_flat_28: 0,
    char_29: 'topaz',
    app_rate_29: '0.08%',
    avg_round_29: 0,
    app_flat_29: 0,
    char_30: 'feixiao',
    app_rate_30: '0.08%',
    avg_round_30: 0,
    app_flat_30: 0
  },
  {
    char: 'pela',
    app: 11.86,
    char_1: 'acheron',
    app_rate_1: '89.58%',
    avg_round_1: 3357,
    app_flat_1: 452,
    char_2: 'jiaoqiu',
    app_rate_2: '44.96%',
    avg_round_2: 3380,
    app_flat_2: 358,
    char_3: 'aventurine',
    app_rate_3: '44.01%',
    avg_round_3: 3374,
    app_flat_3: 249,
    char_4: 'fu-xuan',
    app_rate_4: '25.32%',
    avg_round_4: 3318,
    app_flat_4: 114,
    char_5: 'sparkle',
    app_rate_5: '15.76%',
    avg_round_5: 3231,
    app_flat_5: 8,
    char_6: 'gallagher',
    app_rate_6: '12.32%',
    avg_round_6: 3359,
    app_flat_6: 80,
    char_7: 'sunday',
    app_rate_7: '9.39%',
    avg_round_7: 3222,
    app_flat_7: 11,
    char_8: 'silver-wolf',
    app_rate_8: '8.1%',
    avg_round_8: 3330,
    app_flat_8: 41,
    char_9: 'the-herta',
    app_rate_9: '6.89%',
    avg_round_9: 3357,
    app_flat_9: 45,
    char_10: 'herta',
    app_rate_10: '4.57%',
    avg_round_10: 3332,
    app_flat_10: 29,
    char_11: 'bronya',
    app_rate_11: '4.13%',
    avg_round_11: 0,
    app_flat_11: 0,
    char_12: 'robin',
    app_rate_12: '3.96%',
    avg_round_12: 3249,
    app_flat_12: 7,
    char_13: 'trailblazer-remembrance',
    app_rate_13: '3.19%',
    avg_round_13: 3197,
    app_flat_13: 1,
    char_14: 'huohuo',
    app_rate_14: '3.1%',
    avg_round_14: 3362,
    app_flat_14: 19,
    char_15: 'lingsha',
    app_rate_15: '3.1%',
    avg_round_15: 3358,
    app_flat_15: 22,
    char_16: 'black-swan',
    app_rate_16: '2.93%',
    avg_round_16: 3256,
    app_flat_16: 22,
    char_17: 'ruan-mei',
    app_rate_17: '2.67%',
    avg_round_17: 0,
    app_flat_17: 0,
    char_18: 'luocha',
    app_rate_18: '2.5%',
    avg_round_18: 3306,
    app_flat_18: 14,
    char_19: 'gepard',
    app_rate_19: '2.5%',
    avg_round_19: 3287,
    app_flat_19: 8,
    char_20: 'tingyun-fugue',
    app_rate_20: '1.89%',
    avg_round_20: 3313,
    app_flat_20: 8,
    char_21: 'jade',
    app_rate_21: '1.55%',
    avg_round_21: 3428,
    app_flat_21: 9,
    char_22: 'rappa',
    app_rate_22: '1.38%',
    avg_round_22: 3314,
    app_flat_22: 8,
    char_23: 'trailblazer-harmony',
    app_rate_23: '0.69%',
    avg_round_23: 3166,
    app_flat_23: 3,
    char_24: 'yunli',
    app_rate_24: '0.6%',
    avg_round_24: 3130,
    app_flat_24: 2,
    char_25: 'serval',
    app_rate_25: '0.52%',
    avg_round_25: 3330,
    app_flat_25: 3,
    char_26: 'firefly',
    app_rate_26: '0.52%',
    avg_round_26: 0,
    app_flat_26: 0,
    char_27: 'welt',
    app_rate_27: '0.52%',
    avg_round_27: 3162,
    app_flat_27: 1,
    char_28: 'jingliu',
    app_rate_28: '0.43%',
    avg_round_28: 3147,
    app_flat_28: 2,
    char_29: 'imbibitor-lunae',
    app_rate_29: '0.43%',
    avg_round_29: 0,
    app_flat_29: 0,
    char_30: 'kafka',
    app_rate_30: '0.43%',
    avg_round_30: 0,
    app_flat_30: 0
  },
  {
    char: 'kafka',
    app: 11.55,
    char_1: 'black-swan',
    app_rate_1: '93.63%',
    avg_round_1: 3308,
    app_flat_1: 289,
    char_2: 'ruan-mei',
    app_rate_2: '47.83%',
    avg_round_2: 3316,
    app_flat_2: 137,
    char_3: 'acheron',
    app_rate_3: '40.67%',
    avg_round_3: 3305,
    app_flat_3: 141,
    char_4: 'huohuo',
    app_rate_4: '27.59%',
    avg_round_4: 3279,
    app_flat_4: 64,
    char_5: 'gallagher',
    app_rate_5: '23.17%',
    avg_round_5: 3329,
    app_flat_5: 108,
    char_6: 'aventurine',
    app_rate_6: '14.41%',
    avg_round_6: 3328,
    app_flat_6: 57,
    char_7: 'lingsha',
    app_rate_7: '9.9%',
    avg_round_7: 3351,
    app_flat_7: 33,
    char_8: 'robin',
    app_rate_8: '9.02%',
    avg_round_8: 3265,
    app_flat_8: 15,
    char_9: 'luocha',
    app_rate_9: '8.4%',
    avg_round_9: 3251,
    app_flat_9: 20,
    char_10: 'jiaoqiu',
    app_rate_10: '8.22%',
    avg_round_10: 3301,
    app_flat_10: 17,
    char_11: 'fu-xuan',
    app_rate_11: '6.63%',
    avg_round_11: 3231,
    app_flat_11: 19,
    char_12: 'asta',
    app_rate_12: '1.41%',
    avg_round_12: 3370,
    app_flat_12: 3,
    char_13: 'guinaifen',
    app_rate_13: '1.41%',
    avg_round_13: 3077,
    app_flat_13: 3,
    char_14: 'bailu',
    app_rate_14: '1.33%',
    avg_round_14: 3274,
    app_flat_14: 2,
    char_15: 'sampo',
    app_rate_15: '1.24%',
    avg_round_15: 3226,
    app_flat_15: 4,
    char_16: 'tingyun-fugue',
    app_rate_16: '0.97%',
    avg_round_16: 3244,
    app_flat_16: 2,
    char_17: 'gepard',
    app_rate_17: '0.88%',
    avg_round_17: 3315,
    app_flat_17: 4,
    char_18: 'sparkle',
    app_rate_18: '0.71%',
    avg_round_18: 0,
    app_flat_18: 0,
    char_19: 'trailblazer-remembrance',
    app_rate_19: '0.44%',
    avg_round_19: 0,
    app_flat_19: 0,
    char_20: 'pela',
    app_rate_20: '0.44%',
    avg_round_20: 0,
    app_flat_20: 0,
    char_21: 'sunday',
    app_rate_21: '0.35%',
    avg_round_21: 3241,
    app_flat_21: 1,
    char_22: 'bronya',
    app_rate_22: '0.35%',
    avg_round_22: 0,
    app_flat_22: 0,
    char_23: 'trailblazer-harmony',
    app_rate_23: '0.18%',
    avg_round_23: 3275,
    app_flat_23: 1,
    char_24: 'tingyun',
    app_rate_24: '0.18%',
    avg_round_24: 0,
    app_flat_24: 0,
    char_25: 'silver-wolf',
    app_rate_25: '0.18%',
    avg_round_25: 3163,
    app_flat_25: 1,
    char_26: 'natasha',
    app_rate_26: '0.09%',
    avg_round_26: 0,
    app_flat_26: 0,
    char_27: 'himeko',
    app_rate_27: '0.09%',
    avg_round_27: 0,
    app_flat_27: 0,
    char_28: 'march-7th-swordmaster',
    app_rate_28: '0.09%',
    avg_round_28: 0,
    app_flat_28: 0,
    char_29: 'boothill',
    app_rate_29: '0.09%',
    avg_round_29: 0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'luocha',
    app: 9.57,
    char_1: 'the-herta',
    app_rate_1: '38.63%',
    avg_round_1: 3484,
    app_flat_1: 228,
    char_2: 'sunday',
    app_rate_2: '34.58%',
    avg_round_2: 3395,
    app_flat_2: 128,
    char_3: 'ruan-mei',
    app_rate_3: '24.44%',
    avg_round_3: 3289,
    app_flat_3: 64,
    char_4: 'robin',
    app_rate_4: '23.8%',
    avg_round_4: 3393,
    app_flat_4: 105,
    char_5: 'herta',
    app_rate_5: '20.06%',
    avg_round_5: 3455,
    app_flat_5: 122,
    char_6: 'trailblazer-remembrance',
    app_rate_6: '15.8%',
    avg_round_6: 3481,
    app_flat_6: 89,
    char_7: 'jade',
    app_rate_7: '15.69%',
    avg_round_7: 3518,
    app_flat_7: 87,
    char_8: 'sparkle',
    app_rate_8: '12.49%',
    avg_round_8: 3236,
    app_flat_8: 14,
    char_9: 'imbibitor-lunae',
    app_rate_9: '11.74%',
    avg_round_9: 3213,
    app_flat_9: 11,
    char_10: 'black-swan',
    app_rate_10: '10.46%',
    avg_round_10: 3250,
    app_flat_10: 20,
    char_11: 'kafka',
    app_rate_11: '10.14%',
    avg_round_11: 3251,
    app_flat_11: 20,
    char_12: 'acheron',
    app_rate_12: '8.75%',
    avg_round_12: 3293,
    app_flat_12: 26,
    char_13: 'argenti',
    app_rate_13: '7.15%',
    avg_round_13: 3372,
    app_flat_13: 21,
    char_14: 'jiaoqiu',
    app_rate_14: '7.15%',
    avg_round_14: 3300,
    app_flat_14: 21,
    char_15: 'jing-yuan',
    app_rate_15: '6.19%',
    avg_round_15: 3309,
    app_flat_15: 18,
    char_16: 'rappa',
    app_rate_16: '5.44%',
    avg_round_16: 3312,
    app_flat_16: 27,
    char_17: 'tingyun-fugue',
    app_rate_17: '5.23%',
    avg_round_17: 3321,
    app_flat_17: 15,
    char_18: 'trailblazer-harmony',
    app_rate_18: '4.91%',
    avg_round_18: 3285,
    app_flat_18: 20,
    char_19: 'tingyun',
    app_rate_19: '4.59%',
    avg_round_19: 3193,
    app_flat_19: 10,
    char_20: 'serval',
    app_rate_20: '3.52%',
    avg_round_20: 3401,
    app_flat_20: 22,
    char_21: 'feixiao',
    app_rate_21: '3.42%',
    avg_round_21: 3420,
    app_flat_21: 18,
    char_22: 'yunli',
    app_rate_22: '3.31%',
    avg_round_22: 3269,
    app_flat_22: 16,
    char_23: 'pela',
    app_rate_23: '3.09%',
    avg_round_23: 3306,
    app_flat_23: 14,
    char_24: 'bronya',
    app_rate_24: '2.77%',
    avg_round_24: 3268,
    app_flat_24: 6,
    char_25: 'jingliu',
    app_rate_25: '2.67%',
    avg_round_25: 3262,
    app_flat_25: 1,
    char_26: 'march-7th-swordmaster',
    app_rate_26: '2.56%',
    avg_round_26: 3415,
    app_flat_26: 11,
    char_27: 'clara',
    app_rate_27: '2.13%',
    avg_round_27: 3106,
    app_flat_27: 8,
    char_28: 'firefly',
    app_rate_28: '1.81%',
    avg_round_28: 3256,
    app_flat_28: 1,
    char_29: 'boothill',
    app_rate_29: '1.6%',
    avg_round_29: 3163,
    app_flat_29: 4,
    char_30: 'topaz',
    app_rate_30: '1.49%',
    avg_round_30: 3434,
    app_flat_30: 6
  },
  {
    char: 'march-7th-swordmaster',
    app: 8.38,
    char_1: 'feixiao',
    app_rate_1: '86.22%',
    avg_round_1: 3521,
    app_flat_1: 352,
    char_2: 'robin',
    app_rate_2: '82.2%',
    avg_round_2: 3513,
    app_flat_2: 334,
    char_3: 'aventurine',
    app_rate_3: '40.98%',
    avg_round_3: 3518,
    app_flat_3: 189,
    char_4: 'gallagher',
    app_rate_4: '15.12%',
    avg_round_4: 3493,
    app_flat_4: 88,
    char_5: 'bronya',
    app_rate_5: '9.51%',
    avg_round_5: 3485,
    app_flat_5: 16,
    char_6: 'lingsha',
    app_rate_6: '9.02%',
    avg_round_6: 3531,
    app_flat_6: 47,
    char_7: 'ruan-mei',
    app_rate_7: '8.9%',
    avg_round_7: 3441,
    app_flat_7: 27,
    char_8: 'huohuo',
    app_rate_8: '7.8%',
    avg_round_8: 3385,
    app_flat_8: 41,
    char_9: 'fu-xuan',
    app_rate_9: '7.2%',
    avg_round_9: 3433,
    app_flat_9: 30,
    char_10: 'sunday',
    app_rate_10: '5.49%',
    avg_round_10: 3486,
    app_flat_10: 8,
    char_11: 'yunli',
    app_rate_11: '4.27%',
    avg_round_11: 3291,
    app_flat_11: 24,
    char_12: 'trailblazer-harmony',
    app_rate_12: '3.78%',
    avg_round_12: 3390,
    app_flat_12: 18,
    char_13: 'luocha',
    app_rate_13: '2.93%',
    avg_round_13: 3415,
    app_flat_13: 11,
    char_14: 'topaz',
    app_rate_14: '2.8%',
    avg_round_14: 3424,
    app_flat_14: 8,
    char_15: 'clara',
    app_rate_15: '2.32%',
    avg_round_15: 3132,
    app_flat_15: 11,
    char_16: 'sparkle',
    app_rate_16: '2.2%',
    avg_round_16: 3261,
    app_flat_16: 5,
    char_17: 'moze',
    app_rate_17: '1.22%',
    avg_round_17: 3400,
    app_flat_17: 5,
    char_18: 'tingyun-fugue',
    app_rate_18: '1.1%',
    avg_round_18: 3404,
    app_flat_18: 3,
    char_19: 'trailblazer-remembrance',
    app_rate_19: '0.98%',
    avg_round_19: 3464,
    app_flat_19: 4,
    char_20: 'imbibitor-lunae',
    app_rate_20: '0.98%',
    avg_round_20: 3313,
    app_flat_20: 1,
    char_21: 'firefly',
    app_rate_21: '0.98%',
    avg_round_21: 3207,
    app_flat_21: 2,
    char_22: 'dr-ratio',
    app_rate_22: '0.61%',
    avg_round_22: 3100,
    app_flat_22: 2,
    char_23: 'lynx',
    app_rate_23: '0.49%',
    avg_round_23: 3293,
    app_flat_23: 3,
    char_24: 'blade',
    app_rate_24: '0.37%',
    avg_round_24: 3184,
    app_flat_24: 2,
    char_25: 'jade',
    app_rate_25: '0.24%',
    avg_round_25: 0,
    app_flat_25: 0,
    char_26: 'rappa',
    app_rate_26: '0.24%',
    avg_round_26: 3489,
    app_flat_26: 1,
    char_27: 'dan-heng',
    app_rate_27: '0.24%',
    avg_round_27: 0,
    app_flat_27: 0,
    char_28: 'jing-yuan',
    app_rate_28: '0.24%',
    avg_round_28: 0,
    app_flat_28: 0,
    char_29: 'black-swan',
    app_rate_29: '0.24%',
    avg_round_29: 0,
    app_flat_29: 0,
    char_30: 'himeko',
    app_rate_30: '0.24%',
    avg_round_30: 0,
    app_flat_30: 0
  },
  {
    char: 'topaz',
    app: 7.32,
    char_1: 'feixiao',
    app_rate_1: '93.72%',
    avg_round_1: 3497,
    app_flat_1: 253,
    char_2: 'robin',
    app_rate_2: '89.54%',
    avg_round_2: 3494,
    app_flat_2: 232,
    char_3: 'aventurine',
    app_rate_3: '77.13%',
    avg_round_3: 3514,
    app_flat_3: 213,
    char_4: 'lingsha',
    app_rate_4: '6.56%',
    avg_round_4: 3478,
    app_flat_4: 15,
    char_5: 'gallagher',
    app_rate_5: '6.56%',
    avg_round_5: 3463,
    app_flat_5: 20,
    char_6: 'dr-ratio',
    app_rate_6: '3.91%',
    avg_round_6: 3226,
    app_flat_6: 6,
    char_7: 'ruan-mei',
    app_rate_7: '3.35%',
    avg_round_7: 3518,
    app_flat_7: 5,
    char_8: 'march-7th-swordmaster',
    app_rate_8: '3.21%',
    avg_round_8: 3424,
    app_flat_8: 8,
    char_9: 'sunday',
    app_rate_9: '2.37%',
    avg_round_9: 3263,
    app_flat_9: 4,
    char_10: 'luocha',
    app_rate_10: '1.95%',
    avg_round_10: 3434,
    app_flat_10: 6,
    char_11: 'fu-xuan',
    app_rate_11: '1.81%',
    avg_round_11: 3433,
    app_flat_11: 6,
    char_12: 'bronya',
    app_rate_12: '1.81%',
    avg_round_12: 3521,
    app_flat_12: 8,
    char_13: 'huohuo',
    app_rate_13: '1.81%',
    avg_round_13: 3326,
    app_flat_13: 4,
    char_14: 'sparkle',
    app_rate_14: '1.53%',
    avg_round_14: 3400,
    app_flat_14: 2,
    char_15: 'yunli',
    app_rate_15: '1.12%',
    avg_round_15: 3189,
    app_flat_15: 1,
    char_16: 'trailblazer-remembrance',
    app_rate_16: '0.84%',
    avg_round_16: 3387,
    app_flat_16: 5,
    char_17: 'moze',
    app_rate_17: '0.7%',
    avg_round_17: 3378,
    app_flat_17: 2,
    char_18: 'jiaoqiu',
    app_rate_18: '0.42%',
    avg_round_18: 0,
    app_flat_18: 0,
    char_19: 'jing-yuan',
    app_rate_19: '0.28%',
    avg_round_19: 3232,
    app_flat_19: 1,
    char_20: 'tingyun-fugue',
    app_rate_20: '0.14%',
    avg_round_20: 0,
    app_flat_20: 0,
    char_21: 'asta',
    app_rate_21: '0.14%',
    avg_round_21: 0,
    app_flat_21: 0,
    char_22: 'march-7th',
    app_rate_22: '0.14%',
    avg_round_22: 0,
    app_flat_22: 0,
    char_23: 'acheron',
    app_rate_23: '0.14%',
    avg_round_23: 0,
    app_flat_23: 0,
    char_24: 'jade',
    app_rate_24: '0.14%',
    avg_round_24: 0,
    app_flat_24: 0,
    char_25: 'clara',
    app_rate_25: '0.14%',
    avg_round_25: 3242,
    app_flat_25: 1,
    char_26: 'black-swan',
    app_rate_26: '0.14%',
    avg_round_26: 0,
    app_flat_26: 0,
    char_27: 'guinaifen',
    app_rate_27: '0.14%',
    avg_round_27: 0,
    app_flat_27: 0,
    char_28: 'argenti',
    app_rate_28: '0.14%',
    avg_round_28: 0,
    app_flat_28: 0,
    char_29: 'yukong',
    app_rate_29: '0.14%',
    avg_round_29: 0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'argenti',
    app: 6.28,
    char_1: 'sunday',
    app_rate_1: '50.24%',
    avg_round_1: 3397,
    app_flat_1: 128,
    char_2: 'the-herta',
    app_rate_2: '49.92%',
    avg_round_2: 3500,
    app_flat_2: 178,
    char_3: 'aventurine',
    app_rate_3: '37.07%',
    avg_round_3: 3421,
    app_flat_3: 123,
    char_4: 'trailblazer-remembrance',
    app_rate_4: '31.06%',
    avg_round_4: 3483,
    app_flat_4: 112,
    char_5: 'robin',
    app_rate_5: '28.13%',
    avg_round_5: 3399,
    app_flat_5: 68,
    char_6: 'huohuo',
    app_rate_6: '26.5%',
    avg_round_6: 3416,
    app_flat_6: 77,
    char_7: 'fu-xuan',
    app_rate_7: '12.68%',
    avg_round_7: 3380,
    app_flat_7: 31,
    char_8: 'sparkle',
    app_rate_8: '11.06%',
    avg_round_8: 3224,
    app_flat_8: 26,
    char_9: 'luocha',
    app_rate_9: '10.89%',
    avg_round_9: 3372,
    app_flat_9: 21,
    char_10: 'tingyun',
    app_rate_10: '10.89%',
    avg_round_10: 3246,
    app_flat_10: 26,
    char_11: 'ruan-mei',
    app_rate_11: '9.11%',
    avg_round_11: 3383,
    app_flat_11: 15,
    char_12: 'jade',
    app_rate_12: '5.69%',
    avg_round_12: 3292,
    app_flat_12: 9,
    char_13: 'gallagher',
    app_rate_13: '5.04%',
    avg_round_13: 3535,
    app_flat_13: 21,
    char_14: 'lingsha',
    app_rate_14: '4.07%',
    avg_round_14: 3529,
    app_flat_14: 14,
    char_15: 'bronya',
    app_rate_15: '2.44%',
    avg_round_15: 3178,
    app_flat_15: 8,
    char_16: 'tingyun-fugue',
    app_rate_16: '0.98%',
    avg_round_16: 0,
    app_flat_16: 0,
    char_17: 'pela',
    app_rate_17: '0.81%',
    avg_round_17: 3389,
    app_flat_17: 3,
    char_18: 'herta',
    app_rate_18: '0.65%',
    avg_round_18: 3382,
    app_flat_18: 2,
    char_19: 'trailblazer-harmony',
    app_rate_19: '0.49%',
    avg_round_19: 0,
    app_flat_19: 0,
    char_20: 'clara',
    app_rate_20: '0.33%',
    avg_round_20: 0,
    app_flat_20: 0,
    char_21: 'acheron',
    app_rate_21: '0.33%',
    avg_round_21: 0,
    app_flat_21: 0,
    char_22: 'jiaoqiu',
    app_rate_22: '0.33%',
    avg_round_22: 0,
    app_flat_22: 0,
    char_23: 'lynx',
    app_rate_23: '0.16%',
    avg_round_23: 3556,
    app_flat_23: 1,
    char_24: 'welt',
    app_rate_24: '0.16%',
    avg_round_24: 0,
    app_flat_24: 0,
    char_25: 'gepard',
    app_rate_25: '0.16%',
    avg_round_25: 0,
    app_flat_25: 0,
    char_26: 'yunli',
    app_rate_26: '0.16%',
    avg_round_26: 0,
    app_flat_26: 0,
    char_27: 'topaz',
    app_rate_27: '0.16%',
    avg_round_27: 0,
    app_flat_27: 0,
    char_28: 'jing-yuan',
    app_rate_28: '0.16%',
    avg_round_28: 0,
    app_flat_28: 0,
    char_29: 'boothill',
    app_rate_29: '0.16%',
    avg_round_29: 3199,
    app_flat_29: 1,
    char_30: 'bailu',
    app_rate_30: '0.16%',
    avg_round_30: 0,
    app_flat_30: 0
  },
  {
    char: 'jing-yuan',
    app: 5.59,
    char_1: 'sunday',
    app_rate_1: '99.09%',
    avg_round_1: 3354,
    app_flat_1: 178,
    char_2: 'robin',
    app_rate_2: '62.16%',
    avg_round_2: 3383,
    app_flat_2: 120,
    char_3: 'aventurine',
    app_rate_3: '30.9%',
    avg_round_3: 3358,
    app_flat_3: 59,
    char_4: 'huohuo',
    app_rate_4: '19.74%',
    avg_round_4: 3369,
    app_flat_4: 38,
    char_5: 'gallagher',
    app_rate_5: '18.83%',
    avg_round_5: 3382,
    app_flat_5: 43,
    char_6: 'ruan-mei',
    app_rate_6: '13.16%',
    avg_round_6: 3280,
    app_flat_6: 16,
    char_7: 'luocha',
    app_rate_7: '10.6%',
    avg_round_7: 3309,
    app_flat_7: 18,
    char_8: 'tingyun',
    app_rate_8: '10.05%',
    avg_round_8: 3323,
    app_flat_8: 15,
    char_9: 'sparkle',
    app_rate_9: '7.68%',
    avg_round_9: 3304,
    app_flat_9: 13,
    char_10: 'fu-xuan',
    app_rate_10: '7.13%',
    avg_round_10: 3341,
    app_flat_10: 8,
    char_11: 'jiaoqiu',
    app_rate_11: '4.57%',
    avg_round_11: 3391,
    app_flat_11: 1,
    char_12: 'trailblazer-remembrance',
    app_rate_12: '3.47%',
    avg_round_12: 3266,
    app_flat_12: 7,
    char_13: 'lingsha',
    app_rate_13: '2.93%',
    avg_round_13: 3329,
    app_flat_13: 9,
    char_14: 'bronya',
    app_rate_14: '2.38%',
    avg_round_14: 3334,
    app_flat_14: 4,
    char_15: 'tingyun-fugue',
    app_rate_15: '1.28%',
    avg_round_15: 3092,
    app_flat_15: 1,
    char_16: 'bailu',
    app_rate_16: '0.91%',
    avg_round_16: 3281,
    app_flat_16: 3,
    char_17: 'jade',
    app_rate_17: '0.73%',
    avg_round_17: 0,
    app_flat_17: 0,
    char_18: 'the-herta',
    app_rate_18: '0.73%',
    avg_round_18: 0,
    app_flat_18: 0,
    char_19: 'pela',
    app_rate_19: '0.37%',
    avg_round_19: 0,
    app_flat_19: 0,
    char_20: 'imbibitor-lunae',
    app_rate_20: '0.37%',
    avg_round_20: 0,
    app_flat_20: 0,
    char_21: 'moze',
    app_rate_21: '0.37%',
    avg_round_21: 3242,
    app_flat_21: 1,
    char_22: 'topaz',
    app_rate_22: '0.37%',
    avg_round_22: 3232,
    app_flat_22: 1,
    char_23: 'march-7th-swordmaster',
    app_rate_23: '0.37%',
    avg_round_23: 0,
    app_flat_23: 0,
    char_24: 'hanya',
    app_rate_24: '0.37%',
    avg_round_24: 0,
    app_flat_24: 0,
    char_25: 'feixiao',
    app_rate_25: '0.37%',
    avg_round_25: 0,
    app_flat_25: 0,
    char_26: 'asta',
    app_rate_26: '0.18%',
    avg_round_26: 0,
    app_flat_26: 0,
    char_27: 'silver-wolf',
    app_rate_27: '0.18%',
    avg_round_27: 0,
    app_flat_27: 0,
    char_28: 'lynx',
    app_rate_28: '0.18%',
    avg_round_28: 3154,
    app_flat_28: 1,
    char_29: 'argenti',
    app_rate_29: '0.18%',
    avg_round_29: 0,
    app_flat_29: 0,
    char_30: 'trailblazer-harmony',
    app_rate_30: '0.18%',
    avg_round_30: 3161,
    app_flat_30: 1
  },
  {
    char: 'serval',
    app: 5.05,
    char_1: 'the-herta',
    app_rate_1: '98.58%',
    avg_round_1: 3451,
    app_flat_1: 298,
    char_2: 'trailblazer-remembrance',
    app_rate_2: '45.34%',
    avg_round_2: 3481,
    app_flat_2: 145,
    char_3: 'sunday',
    app_rate_3: '34.21%',
    avg_round_3: 3455,
    app_flat_3: 111,
    char_4: 'aventurine',
    app_rate_4: '34.01%',
    avg_round_4: 3452,
    app_flat_4: 121,
    char_5: 'huohuo',
    app_rate_5: '22.27%',
    avg_round_5: 3466,
    app_flat_5: 59,
    char_6: 'fu-xuan',
    app_rate_6: '17.61%',
    avg_round_6: 3423,
    app_flat_6: 42,
    char_7: 'robin',
    app_rate_7: '12.15%',
    avg_round_7: 3334,
    app_flat_7: 28,
    char_8: 'lingsha',
    app_rate_8: '10.93%',
    avg_round_8: 3466,
    app_flat_8: 38,
    char_9: 'gallagher',
    app_rate_9: '7.09%',
    avg_round_9: 3475,
    app_flat_9: 18,
    char_10: 'luocha',
    app_rate_10: '6.68%',
    avg_round_10: 3401,
    app_flat_10: 22,
    char_11: 'sparkle',
    app_rate_11: '3.24%',
    avg_round_11: 3314,
    app_flat_11: 4,
    char_12: 'ruan-mei',
    app_rate_12: '2.63%',
    avg_round_12: 3393,
    app_flat_12: 5,
    char_13: 'pela',
    app_rate_13: '1.21%',
    avg_round_13: 3330,
    app_flat_13: 3,
    char_14: 'bronya',
    app_rate_14: '0.61%',
    avg_round_14: 3326,
    app_flat_14: 1,
    char_15: 'trailblazer-harmony',
    app_rate_15: '0.61%',
    avg_round_15: 3356,
    app_flat_15: 2,
    char_16: 'tingyun',
    app_rate_16: '0.61%',
    avg_round_16: 3379,
    app_flat_16: 1,
    char_17: 'herta',
    app_rate_17: '0.4%',
    avg_round_17: 0,
    app_flat_17: 0,
    char_18: 'rappa',
    app_rate_18: '0.4%',
    avg_round_18: 3320,
    app_flat_18: 1,
    char_19: 'hanya',
    app_rate_19: '0.2%',
    avg_round_19: 0,
    app_flat_19: 0,
    char_20: 'march-7th',
    app_rate_20: '0.2%',
    avg_round_20: 0,
    app_flat_20: 0,
    char_21: 'jade',
    app_rate_21: '0.2%',
    avg_round_21: 0,
    app_flat_21: 0,
    char_22: 'yunli',
    app_rate_22: '0.2%',
    avg_round_22: 3264,
    app_flat_22: 1,
    char_23: 'tingyun-fugue',
    app_rate_23: '0.2%',
    avg_round_23: 0,
    app_flat_23: 0,
    char_24: 'march-7th-swordmaster',
    app_rate_24: '0.2%',
    avg_round_24: 0,
    app_flat_24: 0,
    char_25: 'silver-wolf',
    app_rate_25: '0.2%',
    avg_round_25: 0,
    app_flat_25: 0,
    char_26: '',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'yunli',
    app: 4.89,
    char_1: 'robin',
    app_rate_1: '74.32%',
    avg_round_1: 3318,
    app_flat_1: 175,
    char_2: 'huohuo',
    app_rate_2: '47.81%',
    avg_round_2: 3329,
    app_flat_2: 119,
    char_3: 'sunday',
    app_rate_3: '38.0%',
    avg_round_3: 3311,
    app_flat_3: 88,
    char_4: 'tingyun',
    app_rate_4: '22.76%',
    avg_round_4: 3292,
    app_flat_4: 48,
    char_5: 'trailblazer-remembrance',
    app_rate_5: '21.71%',
    avg_round_5: 3350,
    app_flat_5: 43,
    char_6: 'aventurine',
    app_rate_6: '19.0%',
    avg_round_6: 3308,
    app_flat_6: 41,
    char_7: 'sparkle',
    app_rate_7: '16.7%',
    avg_round_7: 3283,
    app_flat_7: 22,
    char_8: 'fu-xuan',
    app_rate_8: '12.94%',
    avg_round_8: 3311,
    app_flat_8: 24,
    char_9: 'march-7th-swordmaster',
    app_rate_9: '7.31%',
    avg_round_9: 3291,
    app_flat_9: 24,
    char_10: 'luocha',
    app_rate_10: '6.47%',
    avg_round_10: 3269,
    app_flat_10: 16,
    char_11: 'jade',
    app_rate_11: '4.38%',
    avg_round_11: 3362,
    app_flat_11: 10,
    char_12: 'herta',
    app_rate_12: '4.38%',
    avg_round_12: 3280,
    app_flat_12: 6,
    char_13: 'the-herta',
    app_rate_13: '4.18%',
    avg_round_13: 3338,
    app_flat_13: 8,
    char_14: 'lynx',
    app_rate_14: '3.34%',
    avg_round_14: 3207,
    app_flat_14: 7,
    char_15: 'ruan-mei',
    app_rate_15: '3.13%',
    avg_round_15: 3271,
    app_flat_15: 4,
    char_16: 'clara',
    app_rate_16: '2.09%',
    avg_round_16: 3178,
    app_flat_16: 1,
    char_17: 'gallagher',
    app_rate_17: '1.88%',
    avg_round_17: 3271,
    app_flat_17: 5,
    char_18: 'topaz',
    app_rate_18: '1.67%',
    avg_round_18: 3189,
    app_flat_18: 1,
    char_19: 'lingsha',
    app_rate_19: '1.67%',
    avg_round_19: 3286,
    app_flat_19: 5,
    char_20: 'pela',
    app_rate_20: '1.46%',
    avg_round_20: 3130,
    app_flat_20: 2,
    char_21: 'feixiao',
    app_rate_21: '1.25%',
    avg_round_21: 3340,
    app_flat_21: 2,
    char_22: 'acheron',
    app_rate_22: '0.63%',
    avg_round_22: 0,
    app_flat_22: 0,
    char_23: 'jiaoqiu',
    app_rate_23: '0.63%',
    avg_round_23: 3227,
    app_flat_23: 1,
    char_24: 'imbibitor-lunae',
    app_rate_24: '0.42%',
    avg_round_24: 0,
    app_flat_24: 0,
    char_25: 'bailu',
    app_rate_25: '0.42%',
    avg_round_25: 3268,
    app_flat_25: 1,
    char_26: 'tingyun-fugue',
    app_rate_26: '0.21%',
    avg_round_26: 0,
    app_flat_26: 0,
    char_27: 'argenti',
    app_rate_27: '0.21%',
    avg_round_27: 0,
    app_flat_27: 0,
    char_28: 'bronya',
    app_rate_28: '0.21%',
    avg_round_28: 0,
    app_flat_28: 0,
    char_29: 'natasha',
    app_rate_29: '0.21%',
    avg_round_29: 0,
    app_flat_29: 0,
    char_30: 'rappa',
    app_rate_30: '0.21%',
    avg_round_30: 0,
    app_flat_30: 0
  },
  {
    char: 'imbibitor-lunae',
    app: 4.3,
    char_1: 'sparkle',
    app_rate_1: '63.66%',
    avg_round_1: 3276,
    app_flat_1: 24,
    char_2: 'sunday',
    app_rate_2: '57.48%',
    avg_round_2: 3303,
    app_flat_2: 28,
    char_3: 'ruan-mei',
    app_rate_3: '31.35%',
    avg_round_3: 3186,
    app_flat_3: 7,
    char_4: 'huohuo',
    app_rate_4: '26.37%',
    avg_round_4: 3330,
    app_flat_4: 11,
    char_5: 'luocha',
    app_rate_5: '26.13%',
    avg_round_5: 3213,
    app_flat_5: 11,
    char_6: 'robin',
    app_rate_6: '20.43%',
    avg_round_6: 3286,
    app_flat_6: 5,
    char_7: 'aventurine',
    app_rate_7: '18.53%',
    avg_round_7: 3209,
    app_flat_7: 6,
    char_8: 'tingyun',
    app_rate_8: '16.39%',
    avg_round_8: 3198,
    app_flat_8: 5,
    char_9: 'gallagher',
    app_rate_9: '10.21%',
    avg_round_9: 3371,
    app_flat_9: 6,
    char_10: 'fu-xuan',
    app_rate_10: '9.5%',
    avg_round_10: 3246,
    app_flat_10: 2,
    char_11: 'trailblazer-remembrance',
    app_rate_11: '5.7%',
    avg_round_11: 3308,
    app_flat_11: 3,
    char_12: 'jiaoqiu',
    app_rate_12: '4.04%',
    avg_round_12: 3322,
    app_flat_12: 1,
    char_13: 'march-7th-swordmaster',
    app_rate_13: '1.9%',
    avg_round_13: 3313,
    app_flat_13: 1,
    char_14: 'lingsha',
    app_rate_14: '1.66%',
    avg_round_14: 0,
    app_flat_14: 0,
    char_15: 'pela',
    app_rate_15: '1.19%',
    avg_round_15: 0,
    app_flat_15: 0,
    char_16: 'bronya',
    app_rate_16: '0.95%',
    avg_round_16: 0,
    app_flat_16: 0,
    char_17: 'bailu',
    app_rate_17: '0.95%',
    avg_round_17: 0,
    app_flat_17: 0,
    char_18: 'yunli',
    app_rate_18: '0.48%',
    avg_round_18: 0,
    app_flat_18: 0,
    char_19: 'tingyun-fugue',
    app_rate_19: '0.48%',
    avg_round_19: 0,
    app_flat_19: 0,
    char_20: 'jing-yuan',
    app_rate_20: '0.48%',
    avg_round_20: 0,
    app_flat_20: 0,
    char_21: 'hanya',
    app_rate_21: '0.48%',
    avg_round_21: 0,
    app_flat_21: 0,
    char_22: 'jade',
    app_rate_22: '0.24%',
    avg_round_22: 0,
    app_flat_22: 0,
    char_23: 'himeko',
    app_rate_23: '0.24%',
    avg_round_23: 0,
    app_flat_23: 0,
    char_24: 'moze',
    app_rate_24: '0.24%',
    avg_round_24: 0,
    app_flat_24: 0,
    char_25: 'gepard',
    app_rate_25: '0.24%',
    avg_round_25: 3219,
    app_flat_25: 1,
    char_26: 'yukong',
    app_rate_26: '0.24%',
    avg_round_26: 0,
    app_flat_26: 0,
    char_27: 'clara',
    app_rate_27: '0.24%',
    avg_round_27: 0,
    app_flat_27: 0,
    char_28: 'blade',
    app_rate_28: '0.24%',
    avg_round_28: 0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'bronya',
    app: 4.2,
    char_1: 'acheron',
    app_rate_1: '36.01%',
    avg_round_1: 3384,
    app_flat_1: 2,
    char_2: 'feixiao',
    app_rate_2: '26.52%',
    avg_round_2: 3507,
    app_flat_2: 33,
    char_3: 'robin',
    app_rate_3: '23.6%',
    avg_round_3: 3603,
    app_flat_3: 8,
    char_4: 'aventurine',
    app_rate_4: '23.11%',
    avg_round_4: 3423,
    app_flat_4: 30,
    char_5: 'jiaoqiu',
    app_rate_5: '18.98%',
    avg_round_5: 3384,
    app_flat_5: 2,
    char_6: 'march-7th-swordmaster',
    app_rate_6: '18.98%',
    avg_round_6: 3485,
    app_flat_6: 16,
    char_7: 'ruan-mei',
    app_rate_7: '17.03%',
    avg_round_7: 3135,
    app_flat_7: 5,
    char_8: 'pela',
    app_rate_8: '11.68%',
    avg_round_8: 0,
    app_flat_8: 0,
    char_9: 'gallagher',
    app_rate_9: '11.68%',
    avg_round_9: 3440,
    app_flat_9: 13,
    char_10: 'fu-xuan',
    app_rate_10: '11.19%',
    avg_round_10: 3357,
    app_flat_10: 5,
    char_11: 'firefly',
    app_rate_11: '8.76%',
    avg_round_11: 2864,
    app_flat_11: 1,
    char_12: 'sunday',
    app_rate_12: '8.52%',
    avg_round_12: 3284,
    app_flat_12: 7,
    char_13: 'tingyun-fugue',
    app_rate_13: '7.54%',
    avg_round_13: 0,
    app_flat_13: 0,
    char_14: 'luocha',
    app_rate_14: '6.33%',
    avg_round_14: 3268,
    app_flat_14: 6,
    char_15: 'huohuo',
    app_rate_15: '5.84%',
    avg_round_15: 3248,
    app_flat_15: 7,
    char_16: 'the-herta',
    app_rate_16: '5.35%',
    avg_round_16: 3429,
    app_flat_16: 13,
    char_17: 'jingliu',
    app_rate_17: '5.35%',
    avg_round_17: 0,
    app_flat_17: 0,
    char_18: 'trailblazer-harmony',
    app_rate_18: '4.87%',
    avg_round_18: 2910,
    app_flat_18: 2,
    char_19: 'lingsha',
    app_rate_19: '4.87%',
    avg_round_19: 3507,
    app_flat_19: 6,
    char_20: 'blade',
    app_rate_20: '4.62%',
    avg_round_20: 3176,
    app_flat_20: 3,
    char_21: 'sparkle',
    app_rate_21: '4.14%',
    avg_round_21: 3232,
    app_flat_21: 2,
    char_22: 'argenti',
    app_rate_22: '3.65%',
    avg_round_22: 3178,
    app_flat_22: 8,
    char_23: 'boothill',
    app_rate_23: '3.41%',
    avg_round_23: 3193,
    app_flat_23: 3,
    char_24: 'jing-yuan',
    app_rate_24: '3.16%',
    avg_round_24: 3334,
    app_flat_24: 4,
    char_25: 'topaz',
    app_rate_25: '3.16%',
    avg_round_25: 3521,
    app_flat_25: 8,
    char_26: 'jade',
    app_rate_26: '3.16%',
    avg_round_26: 3406,
    app_flat_26: 4,
    char_27: 'herta',
    app_rate_27: '2.92%',
    avg_round_27: 3425,
    app_flat_27: 9,
    char_28: 'silver-wolf',
    app_rate_28: '2.19%',
    avg_round_28: 0,
    app_flat_28: 0,
    char_29: 'black-swan',
    app_rate_29: '1.7%',
    avg_round_29: 0,
    app_flat_29: 0,
    char_30: 'gepard',
    app_rate_30: '1.46%',
    avg_round_30: 0,
    app_flat_30: 0
  },
  {
    char: 'tingyun',
    app: 3.43,
    char_1: 'sunday',
    app_rate_1: '38.69%',
    avg_round_1: 3313,
    app_flat_1: 32,
    char_2: 'huohuo',
    app_rate_2: '34.52%',
    avg_round_2: 3284,
    app_flat_2: 51,
    char_3: 'yunli',
    app_rate_3: '32.44%',
    avg_round_3: 3292,
    app_flat_3: 48,
    char_4: 'sparkle',
    app_rate_4: '28.27%',
    avg_round_4: 3189,
    app_flat_4: 18,
    char_5: 'robin',
    app_rate_5: '22.02%',
    avg_round_5: 3274,
    app_flat_5: 35,
    char_6: 'imbibitor-lunae',
    app_rate_6: '20.54%',
    avg_round_6: 3198,
    app_flat_6: 5,
    char_7: 'argenti',
    app_rate_7: '19.94%',
    avg_round_7: 3246,
    app_flat_7: 26,
    char_8: 'aventurine',
    app_rate_8: '17.56%',
    avg_round_8: 3279,
    app_flat_8: 23,
    char_9: 'jing-yuan',
    app_rate_9: '16.37%',
    avg_round_9: 3323,
    app_flat_9: 15,
    char_10: 'luocha',
    app_rate_10: '12.8%',
    avg_round_10: 3193,
    app_flat_10: 10,
    char_11: 'fu-xuan',
    app_rate_11: '12.2%',
    avg_round_11: 3262,
    app_flat_11: 10,
    char_12: 'trailblazer-remembrance',
    app_rate_12: '8.33%',
    avg_round_12: 3360,
    app_flat_12: 8,
    char_13: 'ruan-mei',
    app_rate_13: '7.44%',
    avg_round_13: 3265,
    app_flat_13: 1,
    char_14: 'gallagher',
    app_rate_14: '5.65%',
    avg_round_14: 3359,
    app_flat_14: 5,
    char_15: 'clara',
    app_rate_15: '2.68%',
    avg_round_15: 3056,
    app_flat_15: 3,
    char_16: 'the-herta',
    app_rate_16: '2.68%',
    avg_round_16: 3402,
    app_flat_16: 2,
    char_17: 'herta',
    app_rate_17: '2.38%',
    avg_round_17: 3426,
    app_flat_17: 1,
    char_18: 'lingsha',
    app_rate_18: '2.08%',
    avg_round_18: 3325,
    app_flat_18: 1,
    char_19: 'lynx',
    app_rate_19: '1.19%',
    avg_round_19: 0,
    app_flat_19: 0,
    char_20: 'jiaoqiu',
    app_rate_20: '1.19%',
    avg_round_20: 0,
    app_flat_20: 0,
    char_21: 'bronya',
    app_rate_21: '1.19%',
    avg_round_21: 3089,
    app_flat_21: 3,
    char_22: 'acheron',
    app_rate_22: '0.89%',
    avg_round_22: 0,
    app_flat_22: 0,
    char_23: 'rappa',
    app_rate_23: '0.89%',
    avg_round_23: 3325,
    app_flat_23: 1,
    char_24: 'bailu',
    app_rate_24: '0.89%',
    avg_round_24: 0,
    app_flat_24: 0,
    char_25: 'serval',
    app_rate_25: '0.89%',
    avg_round_25: 3379,
    app_flat_25: 1,
    char_26: 'kafka',
    app_rate_26: '0.6%',
    avg_round_26: 0,
    app_flat_26: 0,
    char_27: 'black-swan',
    app_rate_27: '0.6%',
    avg_round_27: 0,
    app_flat_27: 0,
    char_28: 'dr-ratio',
    app_rate_28: '0.6%',
    avg_round_28: 0,
    app_flat_28: 0,
    char_29: 'seele',
    app_rate_29: '0.6%',
    avg_round_29: 0,
    app_flat_29: 0,
    char_30: 'tingyun-fugue',
    app_rate_30: '0.6%',
    avg_round_30: 0,
    app_flat_30: 0
  },
  {
    char: 'silver-wolf',
    app: 3.16,
    char_1: 'acheron',
    app_rate_1: '93.85%',
    avg_round_1: 3335,
    app_flat_1: 77,
    char_2: 'fu-xuan',
    app_rate_2: '40.78%',
    avg_round_2: 3273,
    app_flat_2: 23,
    char_3: 'sparkle',
    app_rate_3: '37.22%',
    avg_round_3: 3088,
    app_flat_3: 2,
    char_4: 'aventurine',
    app_rate_4: '33.01%',
    avg_round_4: 3366,
    app_flat_4: 34,
    char_5: 'pela',
    app_rate_5: '30.42%',
    avg_round_5: 3330,
    app_flat_5: 41,
    char_6: 'jiaoqiu',
    app_rate_6: '19.09%',
    avg_round_6: 3407,
    app_flat_6: 30,
    char_7: 'gallagher',
    app_rate_7: '10.68%',
    avg_round_7: 3381,
    app_flat_7: 16,
    char_8: 'sunday',
    app_rate_8: '3.88%',
    avg_round_8: 3245,
    app_flat_8: 1,
    char_9: 'robin',
    app_rate_9: '3.24%',
    avg_round_9: 3410,
    app_flat_9: 1,
    char_10: 'bronya',
    app_rate_10: '2.91%',
    avg_round_10: 0,
    app_flat_10: 0,
    char_11: 'lingsha',
    app_rate_11: '2.91%',
    avg_round_11: 3353,
    app_flat_11: 3,
    char_12: 'gepard',
    app_rate_12: '2.91%',
    avg_round_12: 3236,
    app_flat_12: 3,
    char_13: 'luocha',
    app_rate_13: '2.27%',
    avg_round_13: 3454,
    app_flat_13: 1,
    char_14: 'ruan-mei',
    app_rate_14: '1.94%',
    avg_round_14: 3457,
    app_flat_14: 1,
    char_15: 'huohuo',
    app_rate_15: '1.62%',
    avg_round_15: 0,
    app_flat_15: 0,
    char_16: 'the-herta',
    app_rate_16: '1.29%',
    avg_round_16: 3310,
    app_flat_16: 2,
    char_17: 'dr-ratio',
    app_rate_17: '1.29%',
    avg_round_17: 3410,
    app_flat_17: 1,
    char_18: 'march-7th',
    app_rate_18: '1.29%',
    avg_round_18: 3245,
    app_flat_18: 1,
    char_19: 'black-swan',
    app_rate_19: '0.97%',
    avg_round_19: 3085,
    app_flat_19: 1,
    char_20: 'herta',
    app_rate_20: '0.97%',
    avg_round_20: 3180,
    app_flat_20: 1,
    char_21: 'welt',
    app_rate_21: '0.65%',
    avg_round_21: 3106,
    app_flat_21: 1,
    char_22: 'trailblazer-preservation',
    app_rate_22: '0.65%',
    avg_round_22: 0,
    app_flat_22: 0,
    char_23: 'firefly',
    app_rate_23: '0.65%',
    avg_round_23: 0,
    app_flat_23: 0,
    char_24: 'tingyun-fugue',
    app_rate_24: '0.65%',
    avg_round_24: 3457,
    app_flat_24: 1,
    char_25: 'seele',
    app_rate_25: '0.65%',
    avg_round_25: 0,
    app_flat_25: 0,
    char_26: 'clara',
    app_rate_26: '0.65%',
    avg_round_26: 0,
    app_flat_26: 0,
    char_27: 'qingque',
    app_rate_27: '0.65%',
    avg_round_27: 0,
    app_flat_27: 0,
    char_28: 'kafka',
    app_rate_28: '0.65%',
    avg_round_28: 3163,
    app_flat_28: 1,
    char_29: 'trailblazer-harmony',
    app_rate_29: '0.32%',
    avg_round_29: 0,
    app_flat_29: 0,
    char_30: 'feixiao',
    app_rate_30: '0.32%',
    avg_round_30: 0,
    app_flat_30: 0
  },
  {
    char: 'clara',
    app: 1.76,
    char_1: 'robin',
    app_rate_1: '70.35%',
    avg_round_1: 3164,
    app_flat_1: 41,
    char_2: 'aventurine',
    app_rate_2: '36.05%',
    avg_round_2: 3187,
    app_flat_2: 21,
    char_3: 'sunday',
    app_rate_3: '31.98%',
    avg_round_3: 3154,
    app_flat_3: 21,
    char_4: 'sparkle',
    app_rate_4: '25.58%',
    avg_round_4: 3161,
    app_flat_4: 8,
    char_5: 'huohuo',
    app_rate_5: '23.84%',
    avg_round_5: 3131,
    app_flat_5: 16,
    char_6: 'fu-xuan',
    app_rate_6: '21.51%',
    avg_round_6: 3197,
    app_flat_6: 3,
    char_7: 'trailblazer-remembrance',
    app_rate_7: '19.19%',
    avg_round_7: 3170,
    app_flat_7: 16,
    char_8: 'luocha',
    app_rate_8: '11.63%',
    avg_round_8: 3106,
    app_flat_8: 8,
    char_9: 'march-7th-swordmaster',
    app_rate_9: '11.05%',
    avg_round_9: 3132,
    app_flat_9: 11,
    char_10: 'jade',
    app_rate_10: '8.14%',
    avg_round_10: 2977,
    app_flat_10: 1,
    char_11: 'yunli',
    app_rate_11: '5.81%',
    avg_round_11: 3178,
    app_flat_11: 1,
    char_12: 'ruan-mei',
    app_rate_12: '5.81%',
    avg_round_12: 2977,
    app_flat_12: 1,
    char_13: 'tingyun',
    app_rate_13: '5.23%',
    avg_round_13: 3056,
    app_flat_13: 3,
    char_14: 'feixiao',
    app_rate_14: '4.65%',
    avg_round_14: 3214,
    app_flat_14: 2,
    char_15: 'herta',
    app_rate_15: '3.49%',
    avg_round_15: 0,
    app_flat_15: 0,
    char_16: 'gallagher',
    app_rate_16: '2.33%',
    avg_round_16: 3196,
    app_flat_16: 2,
    char_17: 'lynx',
    app_rate_17: '1.74%',
    avg_round_17: 3178,
    app_flat_17: 1,
    char_18: 'the-herta',
    app_rate_18: '1.74%',
    avg_round_18: 0,
    app_flat_18: 0,
    char_19: 'lingsha',
    app_rate_19: '1.74%',
    avg_round_19: 3070,
    app_flat_19: 1,
    char_20: 'argenti',
    app_rate_20: '1.16%',
    avg_round_20: 0,
    app_flat_20: 0,
    char_21: 'silver-wolf',
    app_rate_21: '1.16%',
    avg_round_21: 0,
    app_flat_21: 0,
    char_22: 'acheron',
    app_rate_22: '1.16%',
    avg_round_22: 0,
    app_flat_22: 0,
    char_23: 'dr-ratio',
    app_rate_23: '0.58%',
    avg_round_23: 0,
    app_flat_23: 0,
    char_24: 'topaz',
    app_rate_24: '0.58%',
    avg_round_24: 3242,
    app_flat_24: 1,
    char_25: 'bailu',
    app_rate_25: '0.58%',
    avg_round_25: 3084,
    app_flat_25: 1,
    char_26: 'imbibitor-lunae',
    app_rate_26: '0.58%',
    avg_round_26: 0,
    app_flat_26: 0,
    char_27: 'black-swan',
    app_rate_27: '0.58%',
    avg_round_27: 0,
    app_flat_27: 0,
    char_28: 'firefly',
    app_rate_28: '0.58%',
    avg_round_28: 0,
    app_flat_28: 0,
    char_29: 'trailblazer-harmony',
    app_rate_29: '0.58%',
    avg_round_29: 0,
    app_flat_29: 0,
    char_30: 'pela',
    app_rate_30: '0.58%',
    avg_round_30: 0,
    app_flat_30: 0
  },
  {
    char: 'boothill',
    app: 1.43,
    char_1: 'ruan-mei',
    app_rate_1: '93.57%',
    avg_round_1: 3228,
    app_flat_1: 36,
    char_2: 'tingyun-fugue',
    app_rate_2: '64.29%',
    avg_round_2: 3263,
    app_flat_2: 15,
    char_3: 'gallagher',
    app_rate_3: '28.57%',
    avg_round_3: 3201,
    app_flat_3: 13,
    char_4: 'trailblazer-harmony',
    app_rate_4: '25.0%',
    avg_round_4: 3213,
    app_flat_4: 13,
    char_5: 'lingsha',
    app_rate_5: '22.86%',
    avg_round_5: 3378,
    app_flat_5: 7,
    char_6: 'sunday',
    app_rate_6: '15.0%',
    avg_round_6: 3167,
    app_flat_6: 3,
    char_7: 'aventurine',
    app_rate_7: '14.29%',
    avg_round_7: 3212,
    app_flat_7: 8,
    char_8: 'luocha',
    app_rate_8: '10.71%',
    avg_round_8: 3163,
    app_flat_8: 4,
    char_9: 'bronya',
    app_rate_9: '10.0%',
    avg_round_9: 3193,
    app_flat_9: 3,
    char_10: 'fu-xuan',
    app_rate_10: '7.86%',
    avg_round_10: 3215,
    app_flat_10: 3,
    char_11: 'firefly',
    app_rate_11: '2.14%',
    avg_round_11: 0,
    app_flat_11: 0,
    char_12: 'gepard',
    app_rate_12: '1.43%',
    avg_round_12: 2956,
    app_flat_12: 1,
    char_13: 'march-7th',
    app_rate_13: '0.71%',
    avg_round_13: 0,
    app_flat_13: 0,
    char_14: 'huohuo',
    app_rate_14: '0.71%',
    avg_round_14: 0,
    app_flat_14: 0,
    char_15: 'kafka',
    app_rate_15: '0.71%',
    avg_round_15: 0,
    app_flat_15: 0,
    char_16: 'trailblazer-remembrance',
    app_rate_16: '0.71%',
    avg_round_16: 3217,
    app_flat_16: 1,
    char_17: 'acheron',
    app_rate_17: '0.71%',
    avg_round_17: 0,
    app_flat_17: 0,
    char_18: 'argenti',
    app_rate_18: '0.71%',
    avg_round_18: 3199,
    app_flat_18: 1,
    char_19: '',
    app_rate_19: '0.00%',
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '',
    app_rate_20: '0.00%',
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '',
    app_rate_21: '0.00%',
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '',
    app_rate_22: '0.00%',
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'himeko',
    app: 1.4,
    char_1: 'ruan-mei',
    app_rate_1: '64.96%',
    avg_round_1: 3294,
    app_flat_1: 32,
    char_2: 'tingyun-fugue',
    app_rate_2: '62.77%',
    avg_round_2: 3345,
    app_flat_2: 29,
    char_3: 'lingsha',
    app_rate_3: '33.58%',
    avg_round_3: 3372,
    app_flat_3: 19,
    char_4: 'gallagher',
    app_rate_4: '28.47%',
    avg_round_4: 3240,
    app_flat_4: 19,
    char_5: 'trailblazer-harmony',
    app_rate_5: '25.55%',
    avg_round_5: 3248,
    app_flat_5: 12,
    char_6: 'firefly',
    app_rate_6: '21.17%',
    avg_round_6: 3312,
    app_flat_6: 2,
    char_7: 'the-herta',
    app_rate_7: '14.6%',
    avg_round_7: 3399,
    app_flat_7: 13,
    char_8: 'aventurine',
    app_rate_8: '9.49%',
    avg_round_8: 3408,
    app_flat_8: 10,
    char_9: 'robin',
    app_rate_9: '7.3%',
    avg_round_9: 3276,
    app_flat_9: 4,
    char_10: 'trailblazer-remembrance',
    app_rate_10: '5.84%',
    avg_round_10: 3436,
    app_flat_10: 7,
    char_11: 'feixiao',
    app_rate_11: '2.92%',
    avg_round_11: 3506,
    app_flat_11: 1,
    char_12: 'fu-xuan',
    app_rate_12: '2.92%',
    avg_round_12: 3456,
    app_flat_12: 2,
    char_13: 'huohuo',
    app_rate_13: '2.92%',
    avg_round_13: 3352,
    app_flat_13: 2,
    char_14: 'sunday',
    app_rate_14: '2.19%',
    avg_round_14: 3428,
    app_flat_14: 2,
    char_15: 'herta',
    app_rate_15: '1.46%',
    avg_round_15: 0,
    app_flat_15: 0,
    char_16: 'acheron',
    app_rate_16: '1.46%',
    avg_round_16: 0,
    app_flat_16: 0,
    char_17: 'march-7th-swordmaster',
    app_rate_17: '1.46%',
    avg_round_17: 0,
    app_flat_17: 0,
    char_18: 'luocha',
    app_rate_18: '1.46%',
    avg_round_18: 0,
    app_flat_18: 0,
    char_19: 'rappa',
    app_rate_19: '1.46%',
    avg_round_19: 3375,
    app_flat_19: 2,
    char_20: 'sparkle',
    app_rate_20: '0.73%',
    avg_round_20: 0,
    app_flat_20: 0,
    char_21: 'kafka',
    app_rate_21: '0.73%',
    avg_round_21: 0,
    app_flat_21: 0,
    char_22: 'black-swan',
    app_rate_22: '0.73%',
    avg_round_22: 0,
    app_flat_22: 0,
    char_23: 'imbibitor-lunae',
    app_rate_23: '0.73%',
    avg_round_23: 0,
    app_flat_23: 0,
    char_24: 'jiaoqiu',
    app_rate_24: '0.73%',
    avg_round_24: 0,
    app_flat_24: 0,
    char_25: 'pela',
    app_rate_25: '0.73%',
    avg_round_25: 0,
    app_flat_25: 0,
    char_26: 'dr-ratio',
    app_rate_26: '0.73%',
    avg_round_26: 0,
    app_flat_26: 0,
    char_27: 'bailu',
    app_rate_27: '0.73%',
    avg_round_27: 0,
    app_flat_27: 0,
    char_28: 'asta',
    app_rate_28: '0.73%',
    avg_round_28: 0,
    app_flat_28: 0,
    char_29: 'jade',
    app_rate_29: '0.73%',
    avg_round_29: 0,
    app_flat_29: 0,
    char_30: 'blade',
    app_rate_30: '0.73%',
    avg_round_30: 0,
    app_flat_30: 0
  },
  {
    char: 'jingliu',
    app: 1.13,
    char_1: 'sunday',
    app_rate_1: '74.77%',
    avg_round_1: 3166,
    app_flat_1: 16,
    char_2: 'huohuo',
    app_rate_2: '43.24%',
    avg_round_2: 3159,
    app_flat_2: 9,
    char_3: 'robin',
    app_rate_3: '40.54%',
    avg_round_3: 3194,
    app_flat_3: 6,
    char_4: 'ruan-mei',
    app_rate_4: '39.64%',
    avg_round_4: 3204,
    app_flat_4: 5,
    char_5: 'luocha',
    app_rate_5: '22.52%',
    avg_round_5: 3262,
    app_flat_5: 1,
    char_6: 'bronya',
    app_rate_6: '19.82%',
    avg_round_6: 0,
    app_flat_6: 0,
    char_7: 'fu-xuan',
    app_rate_7: '14.41%',
    avg_round_7: 3196,
    app_flat_7: 1,
    char_8: 'aventurine',
    app_rate_8: '11.71%',
    avg_round_8: 3201,
    app_flat_8: 4,
    char_9: 'trailblazer-remembrance',
    app_rate_9: '10.81%',
    avg_round_9: 3050,
    app_flat_9: 2,
    char_10: 'sparkle',
    app_rate_10: '5.41%',
    avg_round_10: 3079,
    app_flat_10: 1,
    char_11: 'pela',
    app_rate_11: '4.5%',
    avg_round_11: 3147,
    app_flat_11: 2,
    char_12: 'gallagher',
    app_rate_12: '4.5%',
    avg_round_12: 2968,
    app_flat_12: 1,
    char_13: 'lingsha',
    app_rate_13: '2.7%',
    avg_round_13: 0,
    app_flat_13: 0,
    char_14: 'herta',
    app_rate_14: '1.8%',
    avg_round_14: 0,
    app_flat_14: 0,
    char_15: 'tingyun',
    app_rate_15: '1.8%',
    avg_round_15: 0,
    app_flat_15: 0,
    char_16: 'lynx',
    app_rate_16: '0.9%',
    avg_round_16: 0,
    app_flat_16: 0,
    char_17: 'tingyun-fugue',
    app_rate_17: '0.9%',
    avg_round_17: 0,
    app_flat_17: 0,
    char_18: '',
    app_rate_18: '0.00%',
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '',
    app_rate_19: '0.00%',
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '',
    app_rate_20: '0.00%',
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '',
    app_rate_21: '0.00%',
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '',
    app_rate_22: '0.00%',
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'gepard',
    app: 0.9,
    char_1: 'acheron',
    app_rate_1: '70.45%',
    avg_round_1: 3298,
    app_flat_1: 15,
    char_2: 'pela',
    app_rate_2: '32.95%',
    avg_round_2: 3287,
    app_flat_2: 8,
    char_3: 'jiaoqiu',
    app_rate_3: '28.41%',
    avg_round_3: 3328,
    app_flat_3: 7,
    char_4: 'sparkle',
    app_rate_4: '26.14%',
    avg_round_4: 3220,
    app_flat_4: 3,
    char_5: 'sunday',
    app_rate_5: '20.45%',
    avg_round_5: 3329,
    app_flat_5: 5,
    char_6: 'the-herta',
    app_rate_6: '18.18%',
    avg_round_6: 3364,
    app_flat_6: 11,
    char_7: 'herta',
    app_rate_7: '15.91%',
    avg_round_7: 3356,
    app_flat_7: 10,
    char_8: 'black-swan',
    app_rate_8: '15.91%',
    avg_round_8: 3302,
    app_flat_8: 6,
    char_9: 'kafka',
    app_rate_9: '11.36%',
    avg_round_9: 3315,
    app_flat_9: 4,
    char_10: 'silver-wolf',
    app_rate_10: '10.23%',
    avg_round_10: 3236,
    app_flat_10: 3,
    char_11: 'robin',
    app_rate_11: '9.09%',
    avg_round_11: 3316,
    app_flat_11: 4,
    char_12: 'trailblazer-remembrance',
    app_rate_12: '6.82%',
    avg_round_12: 3390,
    app_flat_12: 4,
    char_13: 'bronya',
    app_rate_13: '6.82%',
    avg_round_13: 0,
    app_flat_13: 0,
    char_14: 'ruan-mei',
    app_rate_14: '5.68%',
    avg_round_14: 3036,
    app_flat_14: 2,
    char_15: 'jade',
    app_rate_15: '3.41%',
    avg_round_15: 3507,
    app_flat_15: 1,
    char_16: 'trailblazer-harmony',
    app_rate_16: '3.41%',
    avg_round_16: 3036,
    app_flat_16: 2,
    char_17: 'feixiao',
    app_rate_17: '2.27%',
    avg_round_17: 3225,
    app_flat_17: 1,
    char_18: 'march-7th-swordmaster',
    app_rate_18: '2.27%',
    avg_round_18: 3225,
    app_flat_18: 1,
    char_19: 'boothill',
    app_rate_19: '2.27%',
    avg_round_19: 2956,
    app_flat_19: 1,
    char_20: 'rappa',
    app_rate_20: '2.27%',
    avg_round_20: 3117,
    app_flat_20: 1,
    char_21: 'argenti',
    app_rate_21: '1.14%',
    avg_round_21: 0,
    app_flat_21: 0,
    char_22: 'tingyun',
    app_rate_22: '1.14%',
    avg_round_22: 0,
    app_flat_22: 0,
    char_23: 'imbibitor-lunae',
    app_rate_23: '1.14%',
    avg_round_23: 3219,
    app_flat_23: 1,
    char_24: 'asta',
    app_rate_24: '1.14%',
    avg_round_24: 0,
    app_flat_24: 0,
    char_25: 'jing-yuan',
    app_rate_25: '1.14%',
    avg_round_25: 0,
    app_flat_25: 0,
    char_26: '',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'moze',
    app: 0.87,
    char_1: 'feixiao',
    app_rate_1: '92.94%',
    avg_round_1: 3431,
    app_flat_1: 34,
    char_2: 'aventurine',
    app_rate_2: '80.0%',
    avg_round_2: 3424,
    app_flat_2: 29,
    char_3: 'robin',
    app_rate_3: '68.24%',
    avg_round_3: 3436,
    app_flat_3: 26,
    char_4: 'march-7th-swordmaster',
    app_rate_4: '11.76%',
    avg_round_4: 3400,
    app_flat_4: 5,
    char_5: 'lingsha',
    app_rate_5: '8.24%',
    avg_round_5: 3440,
    app_flat_5: 4,
    char_6: 'gallagher',
    app_rate_6: '7.06%',
    avg_round_6: 3414,
    app_flat_6: 3,
    char_7: 'topaz',
    app_rate_7: '5.88%',
    avg_round_7: 3378,
    app_flat_7: 2,
    char_8: 'sunday',
    app_rate_8: '4.71%',
    avg_round_8: 3351,
    app_flat_8: 2,
    char_9: 'jiaoqiu',
    app_rate_9: '3.53%',
    avg_round_9: 0,
    app_flat_9: 0,
    char_10: 'bronya',
    app_rate_10: '3.53%',
    avg_round_10: 0,
    app_flat_10: 0,
    char_11: 'dr-ratio',
    app_rate_11: '2.35%',
    avg_round_11: 0,
    app_flat_11: 0,
    char_12: 'jing-yuan',
    app_rate_12: '2.35%',
    avg_round_12: 3242,
    app_flat_12: 1,
    char_13: 'fu-xuan',
    app_rate_13: '2.35%',
    avg_round_13: 0,
    app_flat_13: 0,
    char_14: 'trailblazer-remembrance',
    app_rate_14: '1.18%',
    avg_round_14: 3504,
    app_flat_14: 1,
    char_15: 'imbibitor-lunae',
    app_rate_15: '1.18%',
    avg_round_15: 0,
    app_flat_15: 0,
    char_16: 'tingyun',
    app_rate_16: '1.18%',
    avg_round_16: 0,
    app_flat_16: 0,
    char_17: 'luocha',
    app_rate_17: '1.18%',
    avg_round_17: 0,
    app_flat_17: 0,
    char_18: 'blade',
    app_rate_18: '1.18%',
    avg_round_18: 3400,
    app_flat_18: 1,
    char_19: 'sparkle',
    app_rate_19: '1.18%',
    avg_round_19: 0,
    app_flat_19: 0,
    char_20: '',
    app_rate_20: '0.00%',
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '',
    app_rate_21: '0.00%',
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '',
    app_rate_22: '0.00%',
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'blade',
    app: 0.58,
    char_1: 'sunday',
    app_rate_1: '50.88%',
    avg_round_1: 3208,
    app_flat_1: 9,
    char_2: 'bronya',
    app_rate_2: '33.33%',
    avg_round_2: 3176,
    app_flat_2: 3,
    char_3: 'ruan-mei',
    app_rate_3: '28.07%',
    avg_round_3: 3351,
    app_flat_3: 4,
    char_4: 'robin',
    app_rate_4: '22.81%',
    avg_round_4: 3384,
    app_flat_4: 2,
    char_5: 'huohuo',
    app_rate_5: '21.05%',
    avg_round_5: 3179,
    app_flat_5: 5,
    char_6: 'luocha',
    app_rate_6: '21.05%',
    avg_round_6: 3266,
    app_flat_6: 3,
    char_7: 'sparkle',
    app_rate_7: '15.79%',
    avg_round_7: 3166,
    app_flat_7: 2,
    char_8: 'trailblazer-remembrance',
    app_rate_8: '15.79%',
    avg_round_8: 3102,
    app_flat_8: 2,
    char_9: 'lingsha',
    app_rate_9: '14.04%',
    avg_round_9: 3373,
    app_flat_9: 3,
    char_10: 'jade',
    app_rate_10: '12.28%',
    avg_round_10: 0,
    app_flat_10: 0,
    char_11: 'lynx',
    app_rate_11: '12.28%',
    avg_round_11: 3196,
    app_flat_11: 1,
    char_12: 'gallagher',
    app_rate_12: '12.28%',
    avg_round_12: 3242,
    app_flat_12: 2,
    char_13: 'jiaoqiu',
    app_rate_13: '8.77%',
    avg_round_13: 3183,
    app_flat_13: 1,
    char_14: 'fu-xuan',
    app_rate_14: '8.77%',
    avg_round_14: 3066,
    app_flat_14: 1,
    char_15: 'feixiao',
    app_rate_15: '7.02%',
    avg_round_15: 3292,
    app_flat_15: 2,
    char_16: 'march-7th-swordmaster',
    app_rate_16: '5.26%',
    avg_round_16: 3184,
    app_flat_16: 2,
    char_17: 'trailblazer-harmony',
    app_rate_17: '3.51%',
    avg_round_17: 3281,
    app_flat_17: 2,
    char_18: 'himeko',
    app_rate_18: '1.75%',
    avg_round_18: 0,
    app_flat_18: 0,
    char_19: 'imbibitor-lunae',
    app_rate_19: '1.75%',
    avg_round_19: 0,
    app_flat_19: 0,
    char_20: 'moze',
    app_rate_20: '1.75%',
    avg_round_20: 3400,
    app_flat_20: 1,
    char_21: 'tingyun-fugue',
    app_rate_21: '1.75%',
    avg_round_21: 0,
    app_flat_21: 0,
    char_22: '',
    app_rate_22: '0.00%',
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'bailu',
    app: 0.57,
    char_1: 'ruan-mei',
    app_rate_1: '28.57%',
    avg_round_1: 3286,
    app_flat_1: 3,
    char_2: 'kafka',
    app_rate_2: '26.79%',
    avg_round_2: 3274,
    app_flat_2: 2,
    char_3: 'black-swan',
    app_rate_3: '26.79%',
    avg_round_3: 3274,
    app_flat_3: 2,
    char_4: 'acheron',
    app_rate_4: '25.0%',
    avg_round_4: 3173,
    app_flat_4: 1,
    char_5: 'the-herta',
    app_rate_5: '23.21%',
    avg_round_5: 3418,
    app_flat_5: 8,
    char_6: 'robin',
    app_rate_6: '23.21%',
    avg_round_6: 3281,
    app_flat_6: 7,
    char_7: 'sunday',
    app_rate_7: '17.86%',
    avg_round_7: 3269,
    app_flat_7: 5,
    char_8: 'sparkle',
    app_rate_8: '16.07%',
    avg_round_8: 0,
    app_flat_8: 0,
    char_9: 'herta',
    app_rate_9: '14.29%',
    avg_round_9: 3393,
    app_flat_9: 4,
    char_10: 'trailblazer-remembrance',
    app_rate_10: '12.5%',
    avg_round_10: 3456,
    app_flat_10: 4,
    char_11: 'jade',
    app_rate_11: '10.71%',
    avg_round_11: 3400,
    app_flat_11: 4,
    char_12: 'jiaoqiu',
    app_rate_12: '8.93%',
    avg_round_12: 0,
    app_flat_12: 0,
    char_13: 'jing-yuan',
    app_rate_13: '8.93%',
    avg_round_13: 3281,
    app_flat_13: 3,
    char_14: 'tingyun-fugue',
    app_rate_14: '7.14%',
    avg_round_14: 3265,
    app_flat_14: 2,
    char_15: 'imbibitor-lunae',
    app_rate_15: '7.14%',
    avg_round_15: 0,
    app_flat_15: 0,
    char_16: 'firefly',
    app_rate_16: '5.36%',
    avg_round_16: 3153,
    app_flat_16: 1,
    char_17: 'tingyun',
    app_rate_17: '5.36%',
    avg_round_17: 0,
    app_flat_17: 0,
    char_18: 'rappa',
    app_rate_18: '3.57%',
    avg_round_18: 3377,
    app_flat_18: 1,
    char_19: 'trailblazer-harmony',
    app_rate_19: '3.57%',
    avg_round_19: 0,
    app_flat_19: 0,
    char_20: 'yunli',
    app_rate_20: '3.57%',
    avg_round_20: 3268,
    app_flat_20: 1,
    char_21: 'march-7th-swordmaster',
    app_rate_21: '3.57%',
    avg_round_21: 3425,
    app_flat_21: 2,
    char_22: 'feixiao',
    app_rate_22: '3.57%',
    avg_round_22: 3582,
    app_flat_22: 1,
    char_23: 'bronya',
    app_rate_23: '3.57%',
    avg_round_23: 3582,
    app_flat_23: 1,
    char_24: 'himeko',
    app_rate_24: '1.79%',
    avg_round_24: 0,
    app_flat_24: 0,
    char_25: 'welt',
    app_rate_25: '1.79%',
    avg_round_25: 0,
    app_flat_25: 0,
    char_26: 'asta',
    app_rate_26: '1.79%',
    avg_round_26: 3376,
    app_flat_26: 1,
    char_27: 'clara',
    app_rate_27: '1.79%',
    avg_round_27: 3084,
    app_flat_27: 1,
    char_28: 'pela',
    app_rate_28: '1.79%',
    avg_round_28: 0,
    app_flat_28: 0,
    char_29: 'argenti',
    app_rate_29: '1.79%',
    avg_round_29: 0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'dr-ratio',
    app: 0.47,
    char_1: 'aventurine',
    app_rate_1: '80.43%',
    avg_round_1: 3237,
    app_flat_1: 9,
    char_2: 'robin',
    app_rate_2: '69.57%',
    avg_round_2: 3266,
    app_flat_2: 8,
    char_3: 'topaz',
    app_rate_3: '60.87%',
    avg_round_3: 3226,
    app_flat_3: 6,
    char_4: 'gallagher',
    app_rate_4: '13.04%',
    avg_round_4: 0,
    app_flat_4: 0,
    char_5: 'march-7th-swordmaster',
    app_rate_5: '10.87%',
    avg_round_5: 3100,
    app_flat_5: 2,
    char_6: 'jiaoqiu',
    app_rate_6: '8.7%',
    avg_round_6: 0,
    app_flat_6: 0,
    char_7: 'sparkle',
    app_rate_7: '8.7%',
    avg_round_7: 0,
    app_flat_7: 0,
    char_8: 'silver-wolf',
    app_rate_8: '8.7%',
    avg_round_8: 3410,
    app_flat_8: 1,
    char_9: 'sunday',
    app_rate_9: '6.52%',
    avg_round_9: 0,
    app_flat_9: 0,
    char_10: 'moze',
    app_rate_10: '4.35%',
    avg_round_10: 0,
    app_flat_10: 0,
    char_11: 'tingyun',
    app_rate_11: '4.35%',
    avg_round_11: 0,
    app_flat_11: 0,
    char_12: 'asta',
    app_rate_12: '2.17%',
    avg_round_12: 0,
    app_flat_12: 0,
    char_13: 'luocha',
    app_rate_13: '2.17%',
    avg_round_13: 0,
    app_flat_13: 0,
    char_14: 'himeko',
    app_rate_14: '2.17%',
    avg_round_14: 0,
    app_flat_14: 0,
    char_15: 'tingyun-fugue',
    app_rate_15: '2.17%',
    avg_round_15: 0,
    app_flat_15: 0,
    char_16: 'clara',
    app_rate_16: '2.17%',
    avg_round_16: 0,
    app_flat_16: 0,
    char_17: 'ruan-mei',
    app_rate_17: '2.17%',
    avg_round_17: 0,
    app_flat_17: 0,
    char_18: 'jade',
    app_rate_18: '2.17%',
    avg_round_18: 0,
    app_flat_18: 0,
    char_19: 'lingsha',
    app_rate_19: '2.17%',
    avg_round_19: 0,
    app_flat_19: 0,
    char_20: 'trailblazer-remembrance',
    app_rate_20: '2.17%',
    avg_round_20: 0,
    app_flat_20: 0,
    char_21: 'yukong',
    app_rate_21: '2.17%',
    avg_round_21: 0,
    app_flat_21: 0,
    char_22: 'feixiao',
    app_rate_22: '2.17%',
    avg_round_22: 3164,
    app_flat_22: 1,
    char_23: '',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'lynx',
    app: 0.47,
    char_1: 'yunli',
    app_rate_1: '34.78%',
    avg_round_1: 3207,
    app_flat_1: 7,
    char_2: 'sunday',
    app_rate_2: '30.43%',
    avg_round_2: 3269,
    app_flat_2: 7,
    char_3: 'robin',
    app_rate_3: '28.26%',
    avg_round_3: 3232,
    app_flat_3: 6,
    char_4: 'the-herta',
    app_rate_4: '23.91%',
    avg_round_4: 3386,
    app_flat_4: 8,
    char_5: 'sparkle',
    app_rate_5: '19.57%',
    avg_round_5: 3196,
    app_flat_5: 1,
    char_6: 'trailblazer-remembrance',
    app_rate_6: '19.57%',
    avg_round_6: 3286,
    app_flat_6: 5,
    char_7: 'ruan-mei',
    app_rate_7: '17.39%',
    avg_round_7: 3198,
    app_flat_7: 2,
    char_8: 'jade',
    app_rate_8: '15.22%',
    avg_round_8: 3296,
    app_flat_8: 2,
    char_9: 'blade',
    app_rate_9: '15.22%',
    avg_round_9: 3196,
    app_flat_9: 1,
    char_10: 'acheron',
    app_rate_10: '13.04%',
    avg_round_10: 3280,
    app_flat_10: 2,
    char_11: 'herta',
    app_rate_11: '13.04%',
    avg_round_11: 3388,
    app_flat_11: 5,
    char_12: 'pela',
    app_rate_12: '8.7%',
    avg_round_12: 3280,
    app_flat_12: 2,
    char_13: 'tingyun',
    app_rate_13: '8.7%',
    avg_round_13: 0,
    app_flat_13: 0,
    char_14: 'jiaoqiu',
    app_rate_14: '8.7%',
    avg_round_14: 3280,
    app_flat_14: 2,
    char_15: 'bronya',
    app_rate_15: '8.7%',
    avg_round_15: 0,
    app_flat_15: 0,
    char_16: 'march-7th-swordmaster',
    app_rate_16: '8.7%',
    avg_round_16: 3293,
    app_flat_16: 3,
    char_17: 'clara',
    app_rate_17: '6.52%',
    avg_round_17: 3178,
    app_flat_17: 1,
    char_18: 'trailblazer-harmony',
    app_rate_18: '4.35%',
    avg_round_18: 0,
    app_flat_18: 0,
    char_19: 'feixiao',
    app_rate_19: '4.35%',
    avg_round_19: 3491,
    app_flat_19: 1,
    char_20: 'argenti',
    app_rate_20: '2.17%',
    avg_round_20: 3556,
    app_flat_20: 1,
    char_21: 'firefly',
    app_rate_21: '2.17%',
    avg_round_21: 0,
    app_flat_21: 0,
    char_22: 'jingliu',
    app_rate_22: '2.17%',
    avg_round_22: 0,
    app_flat_22: 0,
    char_23: 'jing-yuan',
    app_rate_23: '2.17%',
    avg_round_23: 3154,
    app_flat_23: 1,
    char_24: 'rappa',
    app_rate_24: '2.17%',
    avg_round_24: 0,
    app_flat_24: 0,
    char_25: '',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'guinaifen',
    app: 0.38,
    char_1: 'acheron',
    app_rate_1: '56.76%',
    avg_round_1: 3233,
    app_flat_1: 8,
    char_2: 'kafka',
    app_rate_2: '43.24%',
    avg_round_2: 3077,
    app_flat_2: 3,
    char_3: 'black-swan',
    app_rate_3: '43.24%',
    avg_round_3: 3164,
    app_flat_3: 3,
    char_4: 'gallagher',
    app_rate_4: '40.54%',
    avg_round_4: 3206,
    app_flat_4: 6,
    char_5: 'jiaoqiu',
    app_rate_5: '16.22%',
    avg_round_5: 3216,
    app_flat_5: 3,
    char_6: 'huohuo',
    app_rate_6: '16.22%',
    avg_round_6: 3104,
    app_flat_6: 1,
    char_7: 'lingsha',
    app_rate_7: '13.51%',
    avg_round_7: 3432,
    app_flat_7: 1,
    char_8: 'pela',
    app_rate_8: '10.81%',
    avg_round_8: 3249,
    app_flat_8: 3,
    char_9: 'luocha',
    app_rate_9: '10.81%',
    avg_round_9: 3142,
    app_flat_9: 2,
    char_10: 'aventurine',
    app_rate_10: '10.81%',
    avg_round_10: 3364,
    app_flat_10: 1,
    char_11: 'ruan-mei',
    app_rate_11: '8.11%',
    avg_round_11: 3432,
    app_flat_11: 1,
    char_12: 'tingyun-fugue',
    app_rate_12: '5.41%',
    avg_round_12: 3432,
    app_flat_12: 1,
    char_13: 'bronya',
    app_rate_13: '5.41%',
    avg_round_13: 0,
    app_flat_13: 0,
    char_14: 'sunday',
    app_rate_14: '5.41%',
    avg_round_14: 0,
    app_flat_14: 0,
    char_15: 'sparkle',
    app_rate_15: '5.41%',
    avg_round_15: 0,
    app_flat_15: 0,
    char_16: 'fu-xuan',
    app_rate_16: '2.7%',
    avg_round_16: 0,
    app_flat_16: 0,
    char_17: 'topaz',
    app_rate_17: '2.7%',
    avg_round_17: 0,
    app_flat_17: 0,
    char_18: 'firefly',
    app_rate_18: '2.7%',
    avg_round_18: 0,
    app_flat_18: 0,
    char_19: '',
    app_rate_19: '0.00%',
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '',
    app_rate_20: '0.00%',
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '',
    app_rate_21: '0.00%',
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '',
    app_rate_22: '0.00%',
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'asta',
    app: 0.3,
    char_1: 'kafka',
    app_rate_1: '55.17%',
    avg_round_1: 3370,
    app_flat_1: 3,
    char_2: 'black-swan',
    app_rate_2: '55.17%',
    avg_round_2: 3370,
    app_flat_2: 3,
    char_3: 'ruan-mei',
    app_rate_3: '24.14%',
    avg_round_3: 0,
    app_flat_3: 0,
    char_4: 'huohuo',
    app_rate_4: '20.69%',
    avg_round_4: 0,
    app_flat_4: 0,
    char_5: 'gallagher',
    app_rate_5: '17.24%',
    avg_round_5: 3269,
    app_flat_5: 2,
    char_6: 'tingyun-fugue',
    app_rate_6: '13.79%',
    avg_round_6: 3175,
    app_flat_6: 1,
    char_7: 'trailblazer-harmony',
    app_rate_7: '13.79%',
    avg_round_7: 3175,
    app_flat_7: 1,
    char_8: 'firefly',
    app_rate_8: '13.79%',
    avg_round_8: 0,
    app_flat_8: 0,
    char_9: 'luocha',
    app_rate_9: '13.79%',
    avg_round_9: 0,
    app_flat_9: 0,
    char_10: 'lingsha',
    app_rate_10: '10.34%',
    avg_round_10: 3175,
    app_flat_10: 1,
    char_11: 'the-herta',
    app_rate_11: '6.9%',
    avg_round_11: 3356,
    app_flat_11: 1,
    char_12: 'herta',
    app_rate_12: '6.9%',
    avg_round_12: 3356,
    app_flat_12: 1,
    char_13: 'dr-ratio',
    app_rate_13: '3.45%',
    avg_round_13: 0,
    app_flat_13: 0,
    char_14: 'topaz',
    app_rate_14: '3.45%',
    avg_round_14: 0,
    app_flat_14: 0,
    char_15: 'aventurine',
    app_rate_15: '3.45%',
    avg_round_15: 3346,
    app_flat_15: 1,
    char_16: 'rappa',
    app_rate_16: '3.45%',
    avg_round_16: 0,
    app_flat_16: 0,
    char_17: 'trailblazer-remembrance',
    app_rate_17: '3.45%',
    avg_round_17: 0,
    app_flat_17: 0,
    char_18: 'himeko',
    app_rate_18: '3.45%',
    avg_round_18: 0,
    app_flat_18: 0,
    char_19: 'bailu',
    app_rate_19: '3.45%',
    avg_round_19: 3376,
    app_flat_19: 1,
    char_20: 'jing-yuan',
    app_rate_20: '3.45%',
    avg_round_20: 0,
    app_flat_20: 0,
    char_21: 'sunday',
    app_rate_21: '3.45%',
    avg_round_21: 0,
    app_flat_21: 0,
    char_22: 'gepard',
    app_rate_22: '3.45%',
    avg_round_22: 0,
    app_flat_22: 0,
    char_23: 'feixiao',
    app_rate_23: '3.45%',
    avg_round_23: 3149,
    app_flat_23: 1,
    char_24: 'march-7th-swordmaster',
    app_rate_24: '3.45%',
    avg_round_24: 3149,
    app_flat_24: 1,
    char_25: 'fu-xuan',
    app_rate_25: '3.45%',
    avg_round_25: 3356,
    app_flat_25: 1,
    char_26: 'acheron',
    app_rate_26: '3.45%',
    avg_round_26: 0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'sampo',
    app: 0.25,
    char_1: 'kafka',
    app_rate_1: '58.33%',
    avg_round_1: 3226,
    app_flat_1: 4,
    char_2: 'black-swan',
    app_rate_2: '41.67%',
    avg_round_2: 0,
    app_flat_2: 0,
    char_3: 'ruan-mei',
    app_rate_3: '29.17%',
    avg_round_3: 3202,
    app_flat_3: 2,
    char_4: 'acheron',
    app_rate_4: '29.17%',
    avg_round_4: 3247,
    app_flat_4: 5,
    char_5: 'gallagher',
    app_rate_5: '29.17%',
    avg_round_5: 3193,
    app_flat_5: 2,
    char_6: 'huohuo',
    app_rate_6: '25.0%',
    avg_round_6: 3160,
    app_flat_6: 1,
    char_7: 'jiaoqiu',
    app_rate_7: '25.0%',
    avg_round_7: 3260,
    app_flat_7: 4,
    char_8: 'robin',
    app_rate_8: '16.67%',
    avg_round_8: 3308,
    app_flat_8: 1,
    char_9: 'aventurine',
    app_rate_9: '12.5%',
    avg_round_9: 3326,
    app_flat_9: 2,
    char_10: 'fu-xuan',
    app_rate_10: '8.33%',
    avg_round_10: 0,
    app_flat_10: 0,
    char_11: 'lingsha',
    app_rate_11: '8.33%',
    avg_round_11: 3308,
    app_flat_11: 1,
    char_12: 'luocha',
    app_rate_12: '8.33%',
    avg_round_12: 3218,
    app_flat_12: 2,
    char_13: 'sunday',
    app_rate_13: '4.17%',
    avg_round_13: 0,
    app_flat_13: 0,
    char_14: 'pela',
    app_rate_14: '4.17%',
    avg_round_14: 0,
    app_flat_14: 0,
    char_15: '',
    app_rate_15: '0.00%',
    avg_round_15: 0.0,
    app_flat_15: 0,
    char_16: '',
    app_rate_16: '0.00%',
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '',
    app_rate_17: '0.00%',
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '',
    app_rate_18: '0.00%',
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '',
    app_rate_19: '0.00%',
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '',
    app_rate_20: '0.00%',
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '',
    app_rate_21: '0.00%',
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '',
    app_rate_22: '0.00%',
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'welt',
    app: 0.23,
    char_1: 'acheron',
    app_rate_1: '69.57%',
    avg_round_1: 3143,
    app_flat_1: 5,
    char_2: 'gallagher',
    app_rate_2: '34.78%',
    avg_round_2: 3191,
    app_flat_2: 4,
    char_3: 'pela',
    app_rate_3: '26.09%',
    avg_round_3: 3162,
    app_flat_3: 1,
    char_4: 'sparkle',
    app_rate_4: '21.74%',
    avg_round_4: 0,
    app_flat_4: 0,
    char_5: 'aventurine',
    app_rate_5: '21.74%',
    avg_round_5: 3090,
    app_flat_5: 2,
    char_6: 'ruan-mei',
    app_rate_6: '17.39%',
    avg_round_6: 0,
    app_flat_6: 0,
    char_7: 'bronya',
    app_rate_7: '13.04%',
    avg_round_7: 0,
    app_flat_7: 0,
    char_8: 'sunday',
    app_rate_8: '13.04%',
    avg_round_8: 3227,
    app_flat_8: 1,
    char_9: 'jiaoqiu',
    app_rate_9: '13.04%',
    avg_round_9: 3219,
    app_flat_9: 2,
    char_10: 'silver-wolf',
    app_rate_10: '8.7%',
    avg_round_10: 3106,
    app_flat_10: 1,
    char_11: 'robin',
    app_rate_11: '8.7%',
    avg_round_11: 3227,
    app_flat_11: 1,
    char_12: 'tingyun-fugue',
    app_rate_12: '8.7%',
    avg_round_12: 0,
    app_flat_12: 0,
    char_13: 'fu-xuan',
    app_rate_13: '8.7%',
    avg_round_13: 0,
    app_flat_13: 0,
    char_14: 'argenti',
    app_rate_14: '4.35%',
    avg_round_14: 0,
    app_flat_14: 0,
    char_15: 'bailu',
    app_rate_15: '4.35%',
    avg_round_15: 0,
    app_flat_15: 0,
    char_16: 'black-swan',
    app_rate_16: '4.35%',
    avg_round_16: 3010,
    app_flat_16: 1,
    char_17: 'rappa',
    app_rate_17: '4.35%',
    avg_round_17: 0,
    app_flat_17: 0,
    char_18: 'trailblazer-harmony',
    app_rate_18: '4.35%',
    avg_round_18: 0,
    app_flat_18: 0,
    char_19: 'the-herta',
    app_rate_19: '4.35%',
    avg_round_19: 0,
    app_flat_19: 0,
    char_20: 'herta',
    app_rate_20: '4.35%',
    avg_round_20: 0,
    app_flat_20: 0,
    char_21: 'tingyun',
    app_rate_21: '4.35%',
    avg_round_21: 0,
    app_flat_21: 0,
    char_22: '',
    app_rate_22: '0.00%',
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'seele',
    app: 0.17,
    char_1: 'fu-xuan',
    app_rate_1: '82.35%',
    avg_round_1: 0,
    app_flat_1: 0,
    char_2: 'sparkle',
    app_rate_2: '70.59%',
    avg_round_2: 0,
    app_flat_2: 0,
    char_3: 'robin',
    app_rate_3: '47.06%',
    avg_round_3: 0,
    app_flat_3: 0,
    char_4: 'sunday',
    app_rate_4: '47.06%',
    avg_round_4: 0,
    app_flat_4: 0,
    char_5: 'tingyun',
    app_rate_5: '11.76%',
    avg_round_5: 0,
    app_flat_5: 0,
    char_6: 'silver-wolf',
    app_rate_6: '11.76%',
    avg_round_6: 0,
    app_flat_6: 0,
    char_7: 'trailblazer-remembrance',
    app_rate_7: '5.88%',
    avg_round_7: 0,
    app_flat_7: 0,
    char_8: 'herta',
    app_rate_8: '5.88%',
    avg_round_8: 0,
    app_flat_8: 0,
    char_9: 'ruan-mei',
    app_rate_9: '5.88%',
    avg_round_9: 0,
    app_flat_9: 0,
    char_10: 'aventurine',
    app_rate_10: '5.88%',
    avg_round_10: 0,
    app_flat_10: 0,
    char_11: 'bronya',
    app_rate_11: '5.88%',
    avg_round_11: 0,
    app_flat_11: 0,
    char_12: '',
    app_rate_12: '0.00%',
    avg_round_12: 0.0,
    app_flat_12: 0,
    char_13: '',
    app_rate_13: '0.00%',
    avg_round_13: 0.0,
    app_flat_13: 0,
    char_14: '',
    app_rate_14: '0.00%',
    avg_round_14: 0.0,
    app_flat_14: 0,
    char_15: '',
    app_rate_15: '0.00%',
    avg_round_15: 0.0,
    app_flat_15: 0,
    char_16: '',
    app_rate_16: '0.00%',
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '',
    app_rate_17: '0.00%',
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '',
    app_rate_18: '0.00%',
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '',
    app_rate_19: '0.00%',
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '',
    app_rate_20: '0.00%',
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '',
    app_rate_21: '0.00%',
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '',
    app_rate_22: '0.00%',
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'march-7th',
    app: 0.17,
    char_1: 'the-herta',
    app_rate_1: '41.18%',
    avg_round_1: 3612,
    app_flat_1: 4,
    char_2: 'acheron',
    app_rate_2: '35.29%',
    avg_round_2: 3245,
    app_flat_2: 1,
    char_3: 'herta',
    app_rate_3: '29.41%',
    avg_round_3: 3612,
    app_flat_3: 4,
    char_4: 'trailblazer-remembrance',
    app_rate_4: '29.41%',
    avg_round_4: 3612,
    app_flat_4: 4,
    char_5: 'silver-wolf',
    app_rate_5: '23.53%',
    avg_round_5: 3245,
    app_flat_5: 1,
    char_6: 'tingyun-fugue',
    app_rate_6: '17.65%',
    avg_round_6: 0,
    app_flat_6: 0,
    char_7: 'sparkle',
    app_rate_7: '17.65%',
    avg_round_7: 0,
    app_flat_7: 0,
    char_8: 'pela',
    app_rate_8: '17.65%',
    avg_round_8: 0,
    app_flat_8: 0,
    char_9: 'robin',
    app_rate_9: '11.76%',
    avg_round_9: 0,
    app_flat_9: 0,
    char_10: 'jade',
    app_rate_10: '11.76%',
    avg_round_10: 0,
    app_flat_10: 0,
    char_11: 'sunday',
    app_rate_11: '11.76%',
    avg_round_11: 3245,
    app_flat_11: 1,
    char_12: 'feixiao',
    app_rate_12: '5.88%',
    avg_round_12: 0,
    app_flat_12: 0,
    char_13: 'topaz',
    app_rate_13: '5.88%',
    avg_round_13: 0,
    app_flat_13: 0,
    char_14: 'boothill',
    app_rate_14: '5.88%',
    avg_round_14: 0,
    app_flat_14: 0,
    char_15: 'ruan-mei',
    app_rate_15: '5.88%',
    avg_round_15: 0,
    app_flat_15: 0,
    char_16: 'firefly',
    app_rate_16: '5.88%',
    avg_round_16: 0,
    app_flat_16: 0,
    char_17: 'trailblazer-harmony',
    app_rate_17: '5.88%',
    avg_round_17: 0,
    app_flat_17: 0,
    char_18: 'serval',
    app_rate_18: '5.88%',
    avg_round_18: 0,
    app_flat_18: 0,
    char_19: 'bronya',
    app_rate_19: '5.88%',
    avg_round_19: 0,
    app_flat_19: 0,
    char_20: 'rappa',
    app_rate_20: '5.88%',
    avg_round_20: 0,
    app_flat_20: 0,
    char_21: '',
    app_rate_21: '0.00%',
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '',
    app_rate_22: '0.00%',
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'qingque',
    app: 0.16,
    char_1: 'sparkle',
    app_rate_1: '81.25%',
    avg_round_1: 2994,
    app_flat_1: 1,
    char_2: 'fu-xuan',
    app_rate_2: '81.25%',
    avg_round_2: 0,
    app_flat_2: 0,
    char_3: 'sunday',
    app_rate_3: '31.25%',
    avg_round_3: 0,
    app_flat_3: 0,
    char_4: 'trailblazer-remembrance',
    app_rate_4: '18.75%',
    avg_round_4: 2994,
    app_flat_4: 1,
    char_5: 'jade',
    app_rate_5: '18.75%',
    avg_round_5: 0,
    app_flat_5: 0,
    char_6: 'huohuo',
    app_rate_6: '12.5%',
    avg_round_6: 2994,
    app_flat_6: 1,
    char_7: 'the-herta',
    app_rate_7: '12.5%',
    avg_round_7: 0,
    app_flat_7: 0,
    char_8: 'silver-wolf',
    app_rate_8: '12.5%',
    avg_round_8: 0,
    app_flat_8: 0,
    char_9: 'robin',
    app_rate_9: '12.5%',
    avg_round_9: 0,
    app_flat_9: 0,
    char_10: 'herta',
    app_rate_10: '6.25%',
    avg_round_10: 0,
    app_flat_10: 0,
    char_11: 'ruan-mei',
    app_rate_11: '6.25%',
    avg_round_11: 0,
    app_flat_11: 0,
    char_12: 'aventurine',
    app_rate_12: '6.25%',
    avg_round_12: 0,
    app_flat_12: 0,
    char_13: '',
    app_rate_13: '0.00%',
    avg_round_13: 0.0,
    app_flat_13: 0,
    char_14: '',
    app_rate_14: '0.00%',
    avg_round_14: 0.0,
    app_flat_14: 0,
    char_15: '',
    app_rate_15: '0.00%',
    avg_round_15: 0.0,
    app_flat_15: 0,
    char_16: '',
    app_rate_16: '0.00%',
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '',
    app_rate_17: '0.00%',
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '',
    app_rate_18: '0.00%',
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '',
    app_rate_19: '0.00%',
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '',
    app_rate_20: '0.00%',
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '',
    app_rate_21: '0.00%',
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '',
    app_rate_22: '0.00%',
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'hanya',
    app: 0.07,
    char_1: 'luocha',
    app_rate_1: '42.86%',
    avg_round_1: 0,
    app_flat_1: 0,
    char_2: 'the-herta',
    app_rate_2: '28.57%',
    avg_round_2: 0,
    app_flat_2: 0,
    char_3: 'imbibitor-lunae',
    app_rate_3: '28.57%',
    avg_round_3: 0,
    app_flat_3: 0,
    char_4: 'jing-yuan',
    app_rate_4: '28.57%',
    avg_round_4: 0,
    app_flat_4: 0,
    char_5: 'sunday',
    app_rate_5: '28.57%',
    avg_round_5: 0,
    app_flat_5: 0,
    char_6: 'fu-xuan',
    app_rate_6: '28.57%',
    avg_round_6: 0,
    app_flat_6: 0,
    char_7: 'serval',
    app_rate_7: '14.29%',
    avg_round_7: 0,
    app_flat_7: 0,
    char_8: 'huohuo',
    app_rate_8: '14.29%',
    avg_round_8: 0,
    app_flat_8: 0,
    char_9: 'herta',
    app_rate_9: '14.29%',
    avg_round_9: 0,
    app_flat_9: 0,
    char_10: 'aventurine',
    app_rate_10: '14.29%',
    avg_round_10: 0,
    app_flat_10: 0,
    char_11: 'tingyun',
    app_rate_11: '14.29%',
    avg_round_11: 0,
    app_flat_11: 0,
    char_12: 'ruan-mei',
    app_rate_12: '14.29%',
    avg_round_12: 0,
    app_flat_12: 0,
    char_13: 'acheron',
    app_rate_13: '14.29%',
    avg_round_13: 0,
    app_flat_13: 0,
    char_14: 'silver-wolf',
    app_rate_14: '14.29%',
    avg_round_14: 0,
    app_flat_14: 0,
    char_15: '',
    app_rate_15: '0.00%',
    avg_round_15: 0.0,
    app_flat_15: 0,
    char_16: '',
    app_rate_16: '0.00%',
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '',
    app_rate_17: '0.00%',
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '',
    app_rate_18: '0.00%',
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '',
    app_rate_19: '0.00%',
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '',
    app_rate_20: '0.00%',
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '',
    app_rate_21: '0.00%',
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '',
    app_rate_22: '0.00%',
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'natasha',
    app: 0.05,
    char_1: 'the-herta',
    app_rate_1: '40.0%',
    avg_round_1: 0,
    app_flat_1: 0,
    char_2: 'herta',
    app_rate_2: '40.0%',
    avg_round_2: 0,
    app_flat_2: 0,
    char_3: 'acheron',
    app_rate_3: '20.0%',
    avg_round_3: 0,
    app_flat_3: 0,
    char_4: 'kafka',
    app_rate_4: '20.0%',
    avg_round_4: 0,
    app_flat_4: 0,
    char_5: 'black-swan',
    app_rate_5: '20.0%',
    avg_round_5: 0,
    app_flat_5: 0,
    char_6: 'jade',
    app_rate_6: '20.0%',
    avg_round_6: 0,
    app_flat_6: 0,
    char_7: 'yunli',
    app_rate_7: '20.0%',
    avg_round_7: 0,
    app_flat_7: 0,
    char_8: 'robin',
    app_rate_8: '20.0%',
    avg_round_8: 0,
    app_flat_8: 0,
    char_9: 'tingyun',
    app_rate_9: '20.0%',
    avg_round_9: 0,
    app_flat_9: 0,
    char_10: 'rappa',
    app_rate_10: '20.0%',
    avg_round_10: 3379,
    app_flat_10: 1,
    char_11: 'tingyun-fugue',
    app_rate_11: '20.0%',
    avg_round_11: 3379,
    app_flat_11: 1,
    char_12: 'ruan-mei',
    app_rate_12: '20.0%',
    avg_round_12: 3379,
    app_flat_12: 1,
    char_13: 'trailblazer-remembrance',
    app_rate_13: '20.0%',
    avg_round_13: 0,
    app_flat_13: 0,
    char_14: '',
    app_rate_14: '0.00%',
    avg_round_14: 0.0,
    app_flat_14: 0,
    char_15: '',
    app_rate_15: '0.00%',
    avg_round_15: 0.0,
    app_flat_15: 0,
    char_16: '',
    app_rate_16: '0.00%',
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '',
    app_rate_17: '0.00%',
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '',
    app_rate_18: '0.00%',
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '',
    app_rate_19: '0.00%',
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '',
    app_rate_20: '0.00%',
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '',
    app_rate_21: '0.00%',
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '',
    app_rate_22: '0.00%',
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'trailblazer-preservation',
    app: 0.04,
    char_1: 'acheron',
    app_rate_1: '100.0%',
    avg_round_1: 0,
    app_flat_1: 0,
    char_2: 'bronya',
    app_rate_2: '50.0%',
    avg_round_2: 0,
    app_flat_2: 0,
    char_3: 'silver-wolf',
    app_rate_3: '50.0%',
    avg_round_3: 0,
    app_flat_3: 0,
    char_4: 'sparkle',
    app_rate_4: '50.0%',
    avg_round_4: 0,
    app_flat_4: 0,
    char_5: 'jiaoqiu',
    app_rate_5: '25.0%',
    avg_round_5: 0,
    app_flat_5: 0,
    char_6: 'pela',
    app_rate_6: '25.0%',
    avg_round_6: 0,
    app_flat_6: 0,
    char_7: '',
    app_rate_7: '0.00%',
    avg_round_7: 0.0,
    app_flat_7: 0,
    char_8: '',
    app_rate_8: '0.00%',
    avg_round_8: 0.0,
    app_flat_8: 0,
    char_9: '',
    app_rate_9: '0.00%',
    avg_round_9: 0.0,
    app_flat_9: 0,
    char_10: '',
    app_rate_10: '0.00%',
    avg_round_10: 0.0,
    app_flat_10: 0,
    char_11: '',
    app_rate_11: '0.00%',
    avg_round_11: 0.0,
    app_flat_11: 0,
    char_12: '',
    app_rate_12: '0.00%',
    avg_round_12: 0.0,
    app_flat_12: 0,
    char_13: '',
    app_rate_13: '0.00%',
    avg_round_13: 0.0,
    app_flat_13: 0,
    char_14: '',
    app_rate_14: '0.00%',
    avg_round_14: 0.0,
    app_flat_14: 0,
    char_15: '',
    app_rate_15: '0.00%',
    avg_round_15: 0.0,
    app_flat_15: 0,
    char_16: '',
    app_rate_16: '0.00%',
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '',
    app_rate_17: '0.00%',
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '',
    app_rate_18: '0.00%',
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '',
    app_rate_19: '0.00%',
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '',
    app_rate_20: '0.00%',
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '',
    app_rate_21: '0.00%',
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '',
    app_rate_22: '0.00%',
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'xueyi',
    app: 0.04,
    char_1: 'tingyun-fugue',
    app_rate_1: '75.0%',
    avg_round_1: 0,
    app_flat_1: 0,
    char_2: 'ruan-mei',
    app_rate_2: '75.0%',
    avg_round_2: 0,
    app_flat_2: 0,
    char_3: 'lingsha',
    app_rate_3: '50.0%',
    avg_round_3: 0,
    app_flat_3: 0,
    char_4: 'herta',
    app_rate_4: '25.0%',
    avg_round_4: 0,
    app_flat_4: 0,
    char_5: 'robin',
    app_rate_5: '25.0%',
    avg_round_5: 0,
    app_flat_5: 0,
    char_6: 'gallagher',
    app_rate_6: '25.0%',
    avg_round_6: 0,
    app_flat_6: 0,
    char_7: 'aventurine',
    app_rate_7: '25.0%',
    avg_round_7: 0,
    app_flat_7: 0,
    char_8: '',
    app_rate_8: '0.00%',
    avg_round_8: 0.0,
    app_flat_8: 0,
    char_9: '',
    app_rate_9: '0.00%',
    avg_round_9: 0.0,
    app_flat_9: 0,
    char_10: '',
    app_rate_10: '0.00%',
    avg_round_10: 0.0,
    app_flat_10: 0,
    char_11: '',
    app_rate_11: '0.00%',
    avg_round_11: 0.0,
    app_flat_11: 0,
    char_12: '',
    app_rate_12: '0.00%',
    avg_round_12: 0.0,
    app_flat_12: 0,
    char_13: '',
    app_rate_13: '0.00%',
    avg_round_13: 0.0,
    app_flat_13: 0,
    char_14: '',
    app_rate_14: '0.00%',
    avg_round_14: 0.0,
    app_flat_14: 0,
    char_15: '',
    app_rate_15: '0.00%',
    avg_round_15: 0.0,
    app_flat_15: 0,
    char_16: '',
    app_rate_16: '0.00%',
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '',
    app_rate_17: '0.00%',
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '',
    app_rate_18: '0.00%',
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '',
    app_rate_19: '0.00%',
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '',
    app_rate_20: '0.00%',
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '',
    app_rate_21: '0.00%',
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '',
    app_rate_22: '0.00%',
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'hook',
    app: 0.03,
    char_1: 'sparkle',
    app_rate_1: '66.67%',
    avg_round_1: 3031,
    app_flat_1: 1,
    char_2: 'lingsha',
    app_rate_2: '66.67%',
    avg_round_2: 3349,
    app_flat_2: 1,
    char_3: 'bronya',
    app_rate_3: '33.33%',
    avg_round_3: 3031,
    app_flat_3: 1,
    char_4: 'huohuo',
    app_rate_4: '33.33%',
    avg_round_4: 3031,
    app_flat_4: 1,
    char_5: 'tingyun-fugue',
    app_rate_5: '33.33%',
    avg_round_5: 3349,
    app_flat_5: 1,
    char_6: 'ruan-mei',
    app_rate_6: '33.33%',
    avg_round_6: 3349,
    app_flat_6: 1,
    char_7: 'sunday',
    app_rate_7: '33.33%',
    avg_round_7: 0,
    app_flat_7: 0,
    char_8: '',
    app_rate_8: '0.00%',
    avg_round_8: 0.0,
    app_flat_8: 0,
    char_9: '',
    app_rate_9: '0.00%',
    avg_round_9: 0.0,
    app_flat_9: 0,
    char_10: '',
    app_rate_10: '0.00%',
    avg_round_10: 0.0,
    app_flat_10: 0,
    char_11: '',
    app_rate_11: '0.00%',
    avg_round_11: 0.0,
    app_flat_11: 0,
    char_12: '',
    app_rate_12: '0.00%',
    avg_round_12: 0.0,
    app_flat_12: 0,
    char_13: '',
    app_rate_13: '0.00%',
    avg_round_13: 0.0,
    app_flat_13: 0,
    char_14: '',
    app_rate_14: '0.00%',
    avg_round_14: 0.0,
    app_flat_14: 0,
    char_15: '',
    app_rate_15: '0.00%',
    avg_round_15: 0.0,
    app_flat_15: 0,
    char_16: '',
    app_rate_16: '0.00%',
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '',
    app_rate_17: '0.00%',
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '',
    app_rate_18: '0.00%',
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '',
    app_rate_19: '0.00%',
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '',
    app_rate_20: '0.00%',
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '',
    app_rate_21: '0.00%',
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '',
    app_rate_22: '0.00%',
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'yukong',
    app: 0.03,
    char_1: 'aventurine',
    app_rate_1: '66.67%',
    avg_round_1: 3512,
    app_flat_1: 1,
    char_2: 'the-herta',
    app_rate_2: '33.33%',
    avg_round_2: 3512,
    app_flat_2: 1,
    char_3: 'jade',
    app_rate_3: '33.33%',
    avg_round_3: 3512,
    app_flat_3: 1,
    char_4: 'imbibitor-lunae',
    app_rate_4: '33.33%',
    avg_round_4: 0,
    app_flat_4: 0,
    char_5: 'tingyun',
    app_rate_5: '33.33%',
    avg_round_5: 0,
    app_flat_5: 0,
    char_6: 'huohuo',
    app_rate_6: '33.33%',
    avg_round_6: 0,
    app_flat_6: 0,
    char_7: 'dr-ratio',
    app_rate_7: '33.33%',
    avg_round_7: 0,
    app_flat_7: 0,
    char_8: 'topaz',
    app_rate_8: '33.33%',
    avg_round_8: 0,
    app_flat_8: 0,
    char_9: '',
    app_rate_9: '0.00%',
    avg_round_9: 0.0,
    app_flat_9: 0,
    char_10: '',
    app_rate_10: '0.00%',
    avg_round_10: 0.0,
    app_flat_10: 0,
    char_11: '',
    app_rate_11: '0.00%',
    avg_round_11: 0.0,
    app_flat_11: 0,
    char_12: '',
    app_rate_12: '0.00%',
    avg_round_12: 0.0,
    app_flat_12: 0,
    char_13: '',
    app_rate_13: '0.00%',
    avg_round_13: 0.0,
    app_flat_13: 0,
    char_14: '',
    app_rate_14: '0.00%',
    avg_round_14: 0.0,
    app_flat_14: 0,
    char_15: '',
    app_rate_15: '0.00%',
    avg_round_15: 0.0,
    app_flat_15: 0,
    char_16: '',
    app_rate_16: '0.00%',
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '',
    app_rate_17: '0.00%',
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '',
    app_rate_18: '0.00%',
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '',
    app_rate_19: '0.00%',
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '',
    app_rate_20: '0.00%',
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '',
    app_rate_21: '0.00%',
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '',
    app_rate_22: '0.00%',
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'sushang',
    app: 0.03,
    char_1: 'fu-xuan',
    app_rate_1: '66.67%',
    avg_round_1: 0,
    app_flat_1: 0,
    char_2: 'ruan-mei',
    app_rate_2: '66.67%',
    avg_round_2: 0,
    app_flat_2: 0,
    char_3: 'the-herta',
    app_rate_3: '33.33%',
    avg_round_3: 0,
    app_flat_3: 0,
    char_4: 'herta',
    app_rate_4: '33.33%',
    avg_round_4: 0,
    app_flat_4: 0,
    char_5: 'sunday',
    app_rate_5: '33.33%',
    avg_round_5: 0,
    app_flat_5: 0,
    char_6: 'tingyun-fugue',
    app_rate_6: '33.33%',
    avg_round_6: 0,
    app_flat_6: 0,
    char_7: 'lingsha',
    app_rate_7: '33.33%',
    avg_round_7: 0,
    app_flat_7: 0,
    char_8: '',
    app_rate_8: '0.00%',
    avg_round_8: 0.0,
    app_flat_8: 0,
    char_9: '',
    app_rate_9: '0.00%',
    avg_round_9: 0.0,
    app_flat_9: 0,
    char_10: '',
    app_rate_10: '0.00%',
    avg_round_10: 0.0,
    app_flat_10: 0,
    char_11: '',
    app_rate_11: '0.00%',
    avg_round_11: 0.0,
    app_flat_11: 0,
    char_12: '',
    app_rate_12: '0.00%',
    avg_round_12: 0.0,
    app_flat_12: 0,
    char_13: '',
    app_rate_13: '0.00%',
    avg_round_13: 0.0,
    app_flat_13: 0,
    char_14: '',
    app_rate_14: '0.00%',
    avg_round_14: 0.0,
    app_flat_14: 0,
    char_15: '',
    app_rate_15: '0.00%',
    avg_round_15: 0.0,
    app_flat_15: 0,
    char_16: '',
    app_rate_16: '0.00%',
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '',
    app_rate_17: '0.00%',
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '',
    app_rate_18: '0.00%',
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '',
    app_rate_19: '0.00%',
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '',
    app_rate_20: '0.00%',
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '',
    app_rate_21: '0.00%',
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '',
    app_rate_22: '0.00%',
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'dan-heng',
    app: 0.02,
    char_1: 'march-7th-swordmaster',
    app_rate_1: '100.0%',
    avg_round_1: 0,
    app_flat_1: 0,
    char_2: 'robin',
    app_rate_2: '100.0%',
    avg_round_2: 0,
    app_flat_2: 0,
    char_3: 'feixiao',
    app_rate_3: '50.0%',
    avg_round_3: 0,
    app_flat_3: 0,
    char_4: 'huohuo',
    app_rate_4: '50.0%',
    avg_round_4: 0,
    app_flat_4: 0,
    char_5: '',
    app_rate_5: '0.00%',
    avg_round_5: 0.0,
    app_flat_5: 0,
    char_6: '',
    app_rate_6: '0.00%',
    avg_round_6: 0.0,
    app_flat_6: 0,
    char_7: '',
    app_rate_7: '0.00%',
    avg_round_7: 0.0,
    app_flat_7: 0,
    char_8: '',
    app_rate_8: '0.00%',
    avg_round_8: 0.0,
    app_flat_8: 0,
    char_9: '',
    app_rate_9: '0.00%',
    avg_round_9: 0.0,
    app_flat_9: 0,
    char_10: '',
    app_rate_10: '0.00%',
    avg_round_10: 0.0,
    app_flat_10: 0,
    char_11: '',
    app_rate_11: '0.00%',
    avg_round_11: 0.0,
    app_flat_11: 0,
    char_12: '',
    app_rate_12: '0.00%',
    avg_round_12: 0.0,
    app_flat_12: 0,
    char_13: '',
    app_rate_13: '0.00%',
    avg_round_13: 0.0,
    app_flat_13: 0,
    char_14: '',
    app_rate_14: '0.00%',
    avg_round_14: 0.0,
    app_flat_14: 0,
    char_15: '',
    app_rate_15: '0.00%',
    avg_round_15: 0.0,
    app_flat_15: 0,
    char_16: '',
    app_rate_16: '0.00%',
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '',
    app_rate_17: '0.00%',
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '',
    app_rate_18: '0.00%',
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '',
    app_rate_19: '0.00%',
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '',
    app_rate_20: '0.00%',
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '',
    app_rate_21: '0.00%',
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '',
    app_rate_22: '0.00%',
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'yanqing',
    app: 0.02,
    char_1: 'robin',
    app_rate_1: '100.0%',
    avg_round_1: 0,
    app_flat_1: 0,
    char_2: 'ruan-mei',
    app_rate_2: '100.0%',
    avg_round_2: 0,
    app_flat_2: 0,
    char_3: 'aventurine',
    app_rate_3: '100.0%',
    avg_round_3: 0,
    app_flat_3: 0,
    char_4: '',
    app_rate_4: '0.00%',
    avg_round_4: 0.0,
    app_flat_4: 0,
    char_5: '',
    app_rate_5: '0.00%',
    avg_round_5: 0.0,
    app_flat_5: 0,
    char_6: '',
    app_rate_6: '0.00%',
    avg_round_6: 0.0,
    app_flat_6: 0,
    char_7: '',
    app_rate_7: '0.00%',
    avg_round_7: 0.0,
    app_flat_7: 0,
    char_8: '',
    app_rate_8: '0.00%',
    avg_round_8: 0.0,
    app_flat_8: 0,
    char_9: '',
    app_rate_9: '0.00%',
    avg_round_9: 0.0,
    app_flat_9: 0,
    char_10: '',
    app_rate_10: '0.00%',
    avg_round_10: 0.0,
    app_flat_10: 0,
    char_11: '',
    app_rate_11: '0.00%',
    avg_round_11: 0.0,
    app_flat_11: 0,
    char_12: '',
    app_rate_12: '0.00%',
    avg_round_12: 0.0,
    app_flat_12: 0,
    char_13: '',
    app_rate_13: '0.00%',
    avg_round_13: 0.0,
    app_flat_13: 0,
    char_14: '',
    app_rate_14: '0.00%',
    avg_round_14: 0.0,
    app_flat_14: 0,
    char_15: '',
    app_rate_15: '0.00%',
    avg_round_15: 0.0,
    app_flat_15: 0,
    char_16: '',
    app_rate_16: '0.00%',
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '',
    app_rate_17: '0.00%',
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '',
    app_rate_18: '0.00%',
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '',
    app_rate_19: '0.00%',
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '',
    app_rate_20: '0.00%',
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '',
    app_rate_21: '0.00%',
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '',
    app_rate_22: '0.00%',
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'luka',
    app: 0.02,
    char_1: 'acheron',
    app_rate_1: '50.0%',
    avg_round_1: 3251,
    app_flat_1: 1,
    char_2: 'jiaoqiu',
    app_rate_2: '50.0%',
    avg_round_2: 3251,
    app_flat_2: 1,
    char_3: 'gallagher',
    app_rate_3: '50.0%',
    avg_round_3: 3251,
    app_flat_3: 1,
    char_4: 'tingyun-fugue',
    app_rate_4: '50.0%',
    avg_round_4: 0,
    app_flat_4: 0,
    char_5: 'ruan-mei',
    app_rate_5: '50.0%',
    avg_round_5: 0,
    app_flat_5: 0,
    char_6: 'lingsha',
    app_rate_6: '50.0%',
    avg_round_6: 0,
    app_flat_6: 0,
    char_7: '',
    app_rate_7: '0.00%',
    avg_round_7: 0.0,
    app_flat_7: 0,
    char_8: '',
    app_rate_8: '0.00%',
    avg_round_8: 0.0,
    app_flat_8: 0,
    char_9: '',
    app_rate_9: '0.00%',
    avg_round_9: 0.0,
    app_flat_9: 0,
    char_10: '',
    app_rate_10: '0.00%',
    avg_round_10: 0.0,
    app_flat_10: 0,
    char_11: '',
    app_rate_11: '0.00%',
    avg_round_11: 0.0,
    app_flat_11: 0,
    char_12: '',
    app_rate_12: '0.00%',
    avg_round_12: 0.0,
    app_flat_12: 0,
    char_13: '',
    app_rate_13: '0.00%',
    avg_round_13: 0.0,
    app_flat_13: 0,
    char_14: '',
    app_rate_14: '0.00%',
    avg_round_14: 0.0,
    app_flat_14: 0,
    char_15: '',
    app_rate_15: '0.00%',
    avg_round_15: 0.0,
    app_flat_15: 0,
    char_16: '',
    app_rate_16: '0.00%',
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '',
    app_rate_17: '0.00%',
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '',
    app_rate_18: '0.00%',
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '',
    app_rate_19: '0.00%',
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '',
    app_rate_20: '0.00%',
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '',
    app_rate_21: '0.00%',
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '',
    app_rate_22: '0.00%',
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'misha',
    app: 0.01,
    char_1: 'robin',
    app_rate_1: '100.0%',
    avg_round_1: 0,
    app_flat_1: 0,
    char_2: 'sunday',
    app_rate_2: '100.0%',
    avg_round_2: 0,
    app_flat_2: 0,
    char_3: 'luocha',
    app_rate_3: '100.0%',
    avg_round_3: 0,
    app_flat_3: 0,
    char_4: '',
    app_rate_4: '0.00%',
    avg_round_4: 0.0,
    app_flat_4: 0,
    char_5: '',
    app_rate_5: '0.00%',
    avg_round_5: 0.0,
    app_flat_5: 0,
    char_6: '',
    app_rate_6: '0.00%',
    avg_round_6: 0.0,
    app_flat_6: 0,
    char_7: '',
    app_rate_7: '0.00%',
    avg_round_7: 0.0,
    app_flat_7: 0,
    char_8: '',
    app_rate_8: '0.00%',
    avg_round_8: 0.0,
    app_flat_8: 0,
    char_9: '',
    app_rate_9: '0.00%',
    avg_round_9: 0.0,
    app_flat_9: 0,
    char_10: '',
    app_rate_10: '0.00%',
    avg_round_10: 0.0,
    app_flat_10: 0,
    char_11: '',
    app_rate_11: '0.00%',
    avg_round_11: 0.0,
    app_flat_11: 0,
    char_12: '',
    app_rate_12: '0.00%',
    avg_round_12: 0.0,
    app_flat_12: 0,
    char_13: '',
    app_rate_13: '0.00%',
    avg_round_13: 0.0,
    app_flat_13: 0,
    char_14: '',
    app_rate_14: '0.00%',
    avg_round_14: 0.0,
    app_flat_14: 0,
    char_15: '',
    app_rate_15: '0.00%',
    avg_round_15: 0.0,
    app_flat_15: 0,
    char_16: '',
    app_rate_16: '0.00%',
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '',
    app_rate_17: '0.00%',
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '',
    app_rate_18: '0.00%',
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '',
    app_rate_19: '0.00%',
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '',
    app_rate_20: '0.00%',
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '',
    app_rate_21: '0.00%',
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '',
    app_rate_22: '0.00%',
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '',
    app_rate_23: '0.00%',
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '',
    app_rate_24: '0.00%',
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '',
    app_rate_25: '0.00%',
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '',
    app_rate_26: '0.00%',
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '',
    app_rate_27: '0.00%',
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '',
    app_rate_28: '0.00%',
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '',
    app_rate_29: '0.00%',
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '',
    app_rate_30: '0.00%',
    avg_round_30: 0.0,
    app_flat_30: 0
  }
];
